import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';
import { setlistaAsesores } from "../Actions/listaAsesores";
import { listaAsesoresRegistrados } from "../api/listaAsesores";

export const watchListaAsesores = function * (){
    yield takeEvery(actionTypes.GET_LISTA_ASESORES, fetchListaAsesores)
}

function* fetchListaAsesores(action){
    try{
        const data = yield call(listaAsesoresRegistrados, action.data)
        if(data){
            yield put(setlistaAsesores(data))
        }
    }catch(err){
        console.log(err)
    }
}