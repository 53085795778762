import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import {useDispatch } from 'react-redux';
import Button from 'rsuite/Button';

//ACTION
import { setNotificarUserMail } from '../../../../../Actions/notificarUser';
import { Modal } from 'rsuite';

import './modalEnviarMail.css';

const ModalEnviarMail = (props) => {
    const modalEnviarMail = props.modalEnviarMail;
    const setModalEnviarMail = props.setModalEnviarMail;
    const setOpen = props.setOpen
    const email = props.email
    const [backdrop, setBackdrop] = useState('static');
    const handleClose = () => setModalEnviarMail(false);

    const dispatch = useDispatch();
    const handleOpen = () => {
        setTimeout(() => {
            setOpen(true);
        }, 1000);
    }

    // ENVIAR FORMULARIO//
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        reValidateMode: "onChange"
    });

    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append("data", JSON.stringify(data));
        
        const dataSolicitud = {
            asunto: data.asunto,
            mensaje: data.mensaje,
            usuario: email,
        }
        reset();
        if (dataSolicitud != null) {
            dispatch(setNotificarUserMail(dataSolicitud));
            
        }
        setModalEnviarMail(false)
    };
    

    return (
        <>
            {(modalEnviarMail) &&
                <div className='modalEnvioDoc'>
                    <Modal className='proyecto-modal infoModal' backdrop={backdrop} open={modalEnviarMail} size="md" onClose={handleClose} >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Modal.Header className='headerModal'>
                            <Modal.Title className='headerModal'>
                                <div>
                                    <h4 style={{ margin: 'auto', color: 'white', textAlign: 'center' }}> Notificar al usuario </h4>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                                <div className="crear-proyecto-formulario container-mail">
                                        <div className=" add-evento">
                                            <div className="crear-proyecto-campo">
                                                <input className='input-proyecto'
                                                    autoComplete='off'
                                                    placeholder="Escriba un asunto a notificar..."
                                                    {...register("asunto", 
                                                    {required: true, 
                                                        pattern: /^[a-zA-Z\s]*$/i
                                                    })} 
                                                />  
                                                {errors?.asunto?.type === "required" && <p className='error'>Este campo es requerido</p>}
                                            </div>
                                        </div>
                                        <span style={{ padding: '10px' }}></span>
                                        <div className="add-evento">
                                            <div className="crear-proyecto-campo">
                                                <textarea className='input-proyecto mail-area'
                                                    placeholder="Ingrese un mensaje"
                                                    {...register("mensaje", {
                                                        required: "Campo requerido",
                                                        maxLength: {
                                                            value: 600,
                                                            message: "Máximo 600 caracteres"
                                                        },
                                                        minLength: {
                                                            value: 20,
                                                            message: "Minimo 20 caracteres"
                                                        }
                                                    })}
                                                />
                                                {errors.mensaje && <p className='error'>{errors.mensaje.message}</p>}
                                            </div>
                                        </div>
                                    </div> 
                            </Modal.Body>
                            <Modal.Footer className='contFooter'>
                                <Button className='buttonModal' type="submit" onClick={handleOpen}> Enviar </Button>
                                <Button className='buttonModal' onClick={handleClose}> Cancelar </Button>
                            </Modal.Footer>
                        </form>
                    </Modal>                                    
                </div>
            }
        </>
    );
}

export default ModalEnviarMail;
