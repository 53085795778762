import "./chatDefinitivo.css"
import { useState, useEffect } from "react";
import { BsFillChatSquareTextFill } from "react-icons/bs";
import { GoChevronDown } from "react-icons/go";
import { HiChevronLeft } from "react-icons/hi2";
import axios from "axios";
import { useRef } from "react";
import { useSelector } from "react-redux";
import {io} from "socket.io-client"
import moment from "moment";
import Conversaciones from "./Conversaciones/Conversaciones";
import Mensajes from "./Mensajes/Mensajes";



export default function ChatDefinitivo() {
  
    const [openButton, setOpenButton] = useState()
    const [openIcon, setOpenIcon] = useState()
    const [openConversation, setOpenConversation] = useState()
    const [conversations, setConversations] = useState([])
    const [currentChat, setCurrentChat] = useState(null)
    const [messages, setMessages] = useState([])
    const [newMessage, setNewMessage] = useState("")
    const [arrivalMessage, setArrivalMessage] = useState(null)
    const [senderAndReceiver, setSenderAndReceiver] = useState()
    const [listeningMessage, setListeningMessage] = useState()
    const [onlineUsers, setOnlineUsers] = useState()
    const socket = useRef()
    const datosUsuarioLogeado = useSelector((state) => state.reducerUsuarioDatos.data);
    const scrollRef = useRef()
    const fecha = moment().format('YYYY-MM-DD HH:mm:ss')

    useEffect(() => {
        socket.current = io("wss://laniakea.com.ar:8006", {secure: true, reconnect: true, rejectUnauthorized : false})
        //socket.current = io("ws://localhost:8900")
        socket.current.on("getMessage", data => {
            setArrivalMessage({
                user_id: data.user_id,
                content: data.content,
                estado_id: data.estado_id,
                fecha_creacion: fecha
            })
        })
    }, [])


    useEffect(() => {
        arrivalMessage &&
        senderAndReceiver?.includes(arrivalMessage.user_id) &&
        setMessages((prev)=>[...prev, arrivalMessage])
    }, [arrivalMessage, senderAndReceiver])

    

    useEffect(() => {
        if(datosUsuarioLogeado != null){
        socket.current.emit("addUser", datosUsuarioLogeado.id)
        socket.current.on("getUsers", users => {
            setOnlineUsers(users)
            console.log(onlineUsers, "soy onlineUsers dentro de getUsers")
            // if(tablaListaClientes !== null) {
            //     setOnlineUsers(tablaListaClientes.data.filter(c =>users.some(u =>u.userId === c.idUser)))
            // }
        })
        }
    },[datosUsuarioLogeado])

    useEffect(() => {
        if(datosUsuarioLogeado != null) {
            const getConversations = async () => {
                try {
                    const res = await axios.get("https://laniakea.com.ar/express/api/conversations/" + datosUsuarioLogeado.id)
                    //const res = await axios.get("http://localhost:8800/api/conversations/" + datosUsuarioLogeado.id)
                    setConversations(res.data)
                } catch (err) {
                    console.log(err)
                }
            }
            getConversations()
        }
    }, [datosUsuarioLogeado])
    
    useEffect(() => {
        const getMessages = async () => {
            try{
                const res = await axios.get("https://laniakea.com.ar/express/api/messages/" + currentChat.id)
                //const res = await axios.get("http://localhost:8800/api/messages/" + currentChat.id)
                setMessages(res.data)
            }catch(err){
                console.log(err)
            }
        }
        getMessages()
    }, [currentChat, listeningMessage])


    const handleSubmit = async (e) => {
        e.preventDefault()
        if(datosUsuarioLogeado != null) {
            const message = {
                user_id: datosUsuarioLogeado.id,
                content: newMessage,
                id_conversacion_id: currentChat.id,
                estado_id: 1,
            }

            const receiverId = senderAndReceiver.find((usuario) => usuario !== datosUsuarioLogeado.id)
            console.log(receiverId, "soy receiverId")
            socket.current.emit('sendMessage', {
                user_id: datosUsuarioLogeado.id,
                receiverId,
                content: newMessage,
                estado_id: 1
            })
            try{
                const res = await axios.post('https://laniakea.com.ar/express/api/messages/', message)
                //const res = await axios.post('http://localhost:8800/api/messages/', message)
                setMessages([...messages, res.data])
                setListeningMessage(res.data)
                setNewMessage("")
            }catch(err) {
                console.log(err)
            }
        }
    }

    useEffect(() => {
        scrollRef.current?.scrollIntoView({scrollBehavior: "smooth"})
    }, [messages])

    const pressEnter = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e);
        }
      }

      if(onlineUsers !== null) {
      console.log(onlineUsers, "soy online Users fuera del pedido")
    }
return (
    <>
        {openIcon ? 
        <div className="buttonChat" onClick={() => {setOpenIcon(false); setOpenButton(false)}}>
        <GoChevronDown data-aos="flip-down" data-aos-duration="500" className="iconArrow"/>
        </div>
        :
        <div className="buttonChat" onClick={() => {setOpenIcon(true); setOpenButton(true)}}>
        <BsFillChatSquareTextFill data-aos="flip-down" data-aos-duration="500" className="icon"/>
        </div>
        }
        {openButton &&
        <>
        <div className={!openConversation ? "containerChatFixed" : "containerChatFixedMessage"}>
            {!openConversation ?
            <>
                <div className="containerChat">
                    <div className="headerChat">
                        <h1 className="tituloBienvenidaChat">Bienvenido {datosUsuarioLogeado !== null && datosUsuarioLogeado.nombre}👋</h1>
                    </div>
                </div>
                <div className="conversaciones">
                    <div className="titleConversaciones">
                        <p className="tusConversaciones">Tus conversaciones</p>
                    </div>
                    <div className="containerScroll">
                        {conversations.map((c, k) => (
                            <div className="width100" onClick={() => {
                                setCurrentChat(c); 
                                setSenderAndReceiver([c.id_user_id, c.id_asesor_id]);
                                setOpenConversation(true)
                            }}
                            >
                                <Conversaciones
                                    conversation={c}
                                    key={k}
                                    onlineUsers={onlineUsers}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </>
            :
            <>
                <div className="chatOnline">
                    <div className="containerArrow" onClick={() => {setOpenConversation(false)}}>
                        <HiChevronLeft
                        className="arrowLeft"
                        />
                    </div>
                    {/* <ChatOnline
                        onlineUsers={onlineUsers}
                        conversations={conversations}
                        currentChat={currentChat} 
                        setCurrentChat={setCurrentChat}
                        setSenderAndReceiver={setSenderAndReceiver}
                    /> */}
                </div>
                <div className="mensajes">
                    <div className="containerScroll">
                        {messages.map((m,k) => (
                            <div ref={scrollRef} className="containerMensajes">
                                <Mensajes  key={k} message={m} own={datosUsuarioLogeado != null && m.user_id === datosUsuarioLogeado.id}/>
                            </div>
                        ))}
                    </div>
                    <div className="casillaEnviarMensajes">
                        <textarea 
                                className="chatMessageInput" 
                                placeholder="Escribe algo..."
                                onChange={(e) => setNewMessage(e.target.value)}
                                value={newMessage}
                                autoComplete='off'
                                onKeyDown={(e) => pressEnter(e)}
                        />
                        <button className="chatSubmitButton" onClick={handleSubmit}>
                        Enviar
                        </button>
                    </div>
                </div>
            </>
            }
        </div>
        </>
        }
        
    </>
  )
}
