import React from 'react'
import ProyectoInversor from './Section/Proyecto Inversor/ProyectoInversor'
import Cubo from './Section/Cubo/Cubo'
import TextInvertir from './Section/TextInvertir/TextInvertir'
import Footer from '../../Components/Footer/Footer'

export default function ComoInvertir() {
  return (
    <>
      <ProyectoInversor/>
      {/* <Cubo/> */}
      <TextInvertir />
    </>
  )
}
