import React, { useState, useEffect } from 'react'
import { useSpring, animated } from 'react-spring';
import { Table, Pagination, Loader, Popover, Whisper} from "rsuite";
import { useSelector, useDispatch } from 'react-redux';
import { getlistaClientesRegistrados } from '../../../Actions/listaClientesRegistrados';
import { getIdContrato } from '../../../Actions/agregarContrato';
import ModalEliminarCliente from './modalEliminarCliente/modalEliminarCliente';
import ModalVerContratos from './modalVerContratos/modalVerContratos';

const { Column, HeaderCell, Cell } = Table;

const Clientes = () => {

  const [modalContratos, setModalContratos] = useState(null);
  const [modalEliminarCliente, setModalEliminarCliente] = useState(null);

  const [activePage, setActivePage] = useState(1);
  const [estadoFiltro, setEstadoFiltro] = useState(2);
  const [busqueda, setBusqueda] = useState({
    dataBase: null,
  })

  const dispatch = useDispatch();
  const tablaListaClientes = useSelector(state => state.reducerlistaClientesRegistrados.data);
  const [loading, setLoading] = useState(true); {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  const fadeOut = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 600,
}) 

  useEffect(() => {
    const data = {
      busqueda: busqueda.dataBase,
      estado: estadoFiltro,
      page: activePage,
    }
    dispatch(getlistaClientesRegistrados(data));
  }, [activePage, busqueda, estadoFiltro])

  const inputChange = (event) => {
    setBusqueda({
      ...busqueda,
      [event.target.name]: event.target.value,
    });
  }

 
  return (
    <>
      {loading ?
        <div className='loading'>
            <Loader className='loadingColor' size="lg" />
        </div>
      :
      <animated.div style={fadeOut}>  
      <div className='box-separador'>
        <div>
          <h5 className='user-wellcome'> CLIENTES </h5>
        </div>
        <div className="categoria-titulo2">
          <div className='contenedor-filtros'>
            <input name="dataBase" className='buscador' value={busqueda.data} onChange={inputChange} placeholder="Buscar un cliente" />
          </div>
        </div>
      </div>

      <div className='tabla-container'>
        <Table
          height={550}
          data={tablaListaClientes != null ? tablaListaClientes.data : ''}
          locale={{ emptyMessage: "Sin clientes..." }}
        >

          <Column flexGrow={1.5}>
            <HeaderCell style={{ backgroundColor: '#96040E', color: "#fff" }} >Nombre</HeaderCell>
            <Cell >
            {(rowData) => {
                return (
                  <div className="boxNombre">
                    <div className="box-img-imagen-user">
                      <img
                        className="fotoUsuario"
                        alt="logoLogin"
                        src={
                          rowData.idUser != null && rowData.idFotoPerfil != null
                            ? "https://www.blackhound.com.ar/server/public/getImgProfile/" +
                              rowData.idUser
                            : "https://res.cloudinary.com/blackhound/image/upload/v1660676600/BLACKHOUND/Logprofile_wvmpml.png"
                        }
                      />
                    </div>
                    <div className="ajusteUbicacion">
                      {rowData.nombre + " " + rowData.apellido}
                    </div>
                  </div>
                );
              }}
            </Cell>
          </Column>

          <Column flexGrow={0.8}>
            <HeaderCell style={{ backgroundColor: '#96040E', color: "#fff" }} >DNI</HeaderCell>
            <Cell dataKey="dni" />
          </Column>

          <Column flexGrow={0.8}>
            <HeaderCell style={{ backgroundColor: '#96040E', color: "#fff" }} >Teléfono</HeaderCell>
            <Cell dataKey="telefono" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell style={{ backgroundColor: '#96040E', color: "#fff" }} >Correo electronico</HeaderCell>
            <Cell dataKey="email" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell style={{ backgroundColor: '#96040E', color: "#fff" }} >Asesor</HeaderCell>
            <Cell dataKey="asesorAsignado" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell style={{ backgroundColor: '#96040E', color: "#fff" }} >Inversiones Activas</HeaderCell>
            <Cell dataKey="solicitudesActivas" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell style={{ backgroundColor: '#96040E', color: "#fff" }} >Acciones</HeaderCell>
            <Cell className='estado-inversion'>
              {rowData => {
                function handleVerContratos() {
                  setModalContratos({ ...rowData })
                  dispatch(getIdContrato(rowData.idUser));
                }
                function handleEliminarCliente() {
                  setModalEliminarCliente({ ...rowData })
                }
                return (
                  <>
                    <div className='container-buttons-asesores'>
                      <Whisper placement="top" followCursor speaker={<Popover className="container-pop">Ver documentos</Popover>}>
                        <input className='button-cliente-verDocumento' type="button" value={1} onClick={handleVerContratos} />
                      </Whisper>
                      <Whisper placement="bottom" followCursor speaker={<Popover className="container-pop">Eliminar</Popover>}>
                        <input className='button-cliente-rechazo-Asesores' type="button" value={5} onClick={handleEliminarCliente} />
                      </Whisper>
                    </div>
                  </>
                )
              }
              }
            </Cell>
          </Column>

        </Table>
        <Pagination
          prev
          next
          size="md"
          total={tablaListaClientes != null ? tablaListaClientes.totalRegister : ""}
          limit={10}
          activePage={activePage}
          onChangePage={setActivePage}
          style={{ justifyContent: 'center', marginTop: '20px', maginBottom: '50px' }}
        />
      </div>

      <ModalEliminarCliente dataModalEliminarCliente={modalEliminarCliente} />
      <ModalVerContratos dataModalVerContratos={modalContratos} />
    </animated.div>
  }
    </>
  );
};

export default Clientes;