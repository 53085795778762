import React from 'react'
import { Modal, Button } from 'rsuite';

export default function ModalGeneric({open, setOpen, title, content, button, goTo, size}) {

    const handleClose = () => {
      setOpen(false)
    };
  return (
    
    <>
    <Modal className='modalContrasenaModificada' backdrop="static" size={size} open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>{handleClose(); goTo()}} appearance="primary">{button}</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}