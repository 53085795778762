import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call,put } from'redux-saga/effects';
import { inversionesUsuario } from'../api/inversionesUsuario';
import { setInversionesUsuario } from "../Actions/inversionesUsuario";

export const watchInversionesUsuario = function * (){
    yield takeEvery(actionTypes.GET_INVERSIONES_USUARIO, fetchInversionesUsuario)
}

function* fetchInversionesUsuario(action){
    try{
        const data = yield call(inversionesUsuario, action.data)
        if(data){
            yield put(setInversionesUsuario(data))
            //MODAL USUARIO REGISTRADO EXITOSO
        }
    }catch(err){
        console.log(err)
    }
}