import React, { useState, useEffect } from 'react'
import { useSpring, animated } from 'react-spring';
import { Table, Pagination, Loader, Popover, Whisper} from "rsuite";
import ModalEliminarAsesor from './modalEliminarAsesor/modalEliminarAsesor';
import { getlistaAsesores } from '../../../Actions/listaAsesores';
import { setEstadoCliente } from '../../../Actions/estadoCliente';

import { useSelector, useDispatch } from 'react-redux';
import './asesores.css';

const { Column, HeaderCell, Cell } = Table;

const Asesores = () => {

  const [modalEliminarAsesor, setModalEliminarAsesor] = useState(null);

  const [activePage, setActivePage] = useState(1);
  const [estadoFiltro, setEstadoFiltro] = useState(null);
  const [busqueda, setBusqueda] = useState({
    dataBase: null,
  })

  const dispatch = useDispatch();

  useEffect(() => {
    const data = {
      busqueda: busqueda.dataBase,
      estado: estadoFiltro,
      page: activePage,
    }
    dispatch(getlistaAsesores(data));
  }, [activePage, busqueda, estadoFiltro])

  const inputChange = (event) => {
    setBusqueda({
      ...busqueda,
      [event.target.name]: event.target.value,
    });
  }

  const handleEstado = (idUser, e) => {
    const dataCliente = {
      id: idUser,
      idEstado: e.target.value,
    }

    if (dataCliente.idEstado == 2) {
      dispatch(setEstadoCliente(dataCliente));
    }
    if (dataCliente.idEstado == 3) {
      dispatch(setEstadoCliente(dataCliente));
    }
  }

  const listaTotalAsesores = useSelector(state => state.reducerListaAsesores.data);

  const [loading, setLoading] = useState(true); {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  const fadeOut = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 500,
}) 

  return (
    <>
      {loading ?
        <div className='loading'>
            <Loader className='loadingColor' size="lg" />
        </div>
      :
      <animated.div style={fadeOut}>
        <div className='box-separador'>
          <div>
            <h5 className='user-wellcome'> ASESORES </h5>
          </div>
          <div className="categoria-titulo2">
            <div className='contenedor-filtros'>
              <input name="dataBase" className='buscador' value={busqueda.data} onChange={inputChange} placeholder="Buscar un asesor" />
            </div>
          </div>
      </div>

      <div className='tabla-container'>
        <Table
          height={550}
          data={listaTotalAsesores != null ? listaTotalAsesores.data : ''}
          locale={{ emptyMessage: "Sin clientes..." }}
        >

          <Column flexGrow={1.5}>
            <HeaderCell style={{backgroundColor:'#96040E', color:"#fff"}}>Nombre</HeaderCell>
            <Cell >
              {rowData => {
                return (
                  rowData.nombre + ' ' + rowData.apellido
                )
              }
              }
            </Cell>
          </Column>

        <Column flexGrow={1}>
          <HeaderCell style={{backgroundColor:'#96040E', color:"#fff"}} >Clientes pendientes</HeaderCell>
          <Cell dataKey="cantPendientes" />
        </Column>

          <Column flexGrow={1}>
            <HeaderCell style={{backgroundColor:'#96040E', color:"#fff"}} >Clientes activos</HeaderCell>
            <Cell dataKey="cantActivos" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell style={{backgroundColor:'#96040E', color:"#fff"}} >Turnos asignados</HeaderCell>
            <Cell dataKey="cantTurno" />
          </Column>

        <Column flexGrow={1}>
          <HeaderCell style={{backgroundColor:'#96040E', color:"#fff"}} >Acciones</HeaderCell>
            <Cell className='estado-inversion'>
              {rowData => {
                function handleModalEliminarAsesor() {
                  setModalEliminarAsesor({ ...rowData })
                }
                return (
                  <>
                    <div className='container-buttons-asesor'>
                      <Whisper placement="top" followCursor speaker={<Popover className="container-pop">Eliminar</Popover>}>
                        <input className="button-cliente-rechazo-Asesores" type="button" value={3} onClick={handleModalEliminarAsesor} />
                      </Whisper>
                    </div>
                  </>
                )
              }
              }
            </Cell>
          </Column>

        </Table>
        <Pagination
          prev
          next
          size="md"
          total={listaTotalAsesores != null ? listaTotalAsesores.totalRegister : ""}
          limit={10}
          activePage={activePage}
          onChangePage={setActivePage}
          style={{ justifyContent: 'center', marginTop: '20px', maginBottom: '50px' }}
        />
      </div>

      <ModalEliminarAsesor dataModalEliminarAsesor={modalEliminarAsesor} />
      </animated.div>
    }
    </>
  );
};

export default Asesores;