import { actionTypes } from "../Constantes/ActionTypes";
import { store } from "../store/index";

function reducerInversionesUsuario (state = store.inversionesusuario, action){
    switch(action.type){
        case actionTypes.SET_INVERSIONES_USUARIO:
            let InversionesUsuario = action.data
            return Object.assign({}, state, {
                data: InversionesUsuario,
            })
            default:
                return state
    }
}

export default reducerInversionesUsuario;