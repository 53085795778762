import React from 'react'
import Footer from '../../Components/Footer/Footer'
import MasInformacion from './Section/MasInformacion/MasInformacion'
import PreguntasFrecuentes from './Section/PreguntasFrecuentes/PreguntasFrecuentes'

export default function Contacto() {
  return (
    <>
      <div className='contacto-container' >
        <MasInformacion/>
        <PreguntasFrecuentes/>
      </div>
    </>

  )
}
