import { actionTypes } from "../Constantes/ActionTypes";
import { store } from "../store/index";

function reducerListaSolicitudesAdministrador (state = store.solicitudesAdmin, action){
    switch(action.type){
        case actionTypes.SET_TRAER_SOLICITUDES_ADMINISTRADOR:
            let SolicitudesRegistrados = action.data
            return Object.assign({}, state, {
                data: SolicitudesRegistrados,
            })
            default:
                return state
    }
}

export default reducerListaSolicitudesAdministrador;