import { actionTypes } from "../Constantes/ActionTypes";
import { store } from "../store/index";

function reducerTurnosAsesor(state = store.asesorTurno, action) {
    switch (action.type) {
        case actionTypes.SET_TURNO_ASESOR:
            let turnosAsesor = action.data
            return Object.assign({}, state, {
                data: turnosAsesor,
            })
        default:
            return state
    }
}

export default reducerTurnosAsesor;