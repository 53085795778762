import React, { useState, useEffect } from "react";
import { Table, Pagination, Loader, Popover, Whisper} from "rsuite";
import { useSpring, animated } from 'react-spring';
import InfoInversion from "./infoInversiones/infoInversiones";
import { useSelector, useDispatch } from "react-redux";
import { getlistaSolicitudesRegistradas } from "../../../Actions/listaSolicitudesRegistradas";
import { setEstadoSolicitud } from "../../../Actions/estadoSolicitud";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ModalDocumentacion from "./ModalDocumentacion/ModalDocumentacion";
import ModalEliminarInversion from "./eliminarInversion/eliminarInversion";

const { Column, HeaderCell, Cell } = Table;

export default function Inversiones() {
  const [modalSolicitud, setModalSolicitud] = useState(null);
  const [eliminarInversion, setEliminarInversion] = useState(null);
  const [dataModalContrato, setDataModalContrato] = useState(null);
  const [modalMailAprobado, setModalMailAprobado] = useState(null);

  const [activePage, setActivePage] = useState(1);
  const [estadoCategoria, setEstadoCategoria] = useState(2);
  const [busqueda, setBusqueda] = useState({
    dataBase: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const data = {
      busqueda: busqueda.dataBase,
      categoria: estadoCategoria,
      page: activePage,
    };

    dispatch(getlistaSolicitudesRegistradas(data));
  }, [activePage, busqueda, estadoCategoria]);

  const tablaListaSolicitudes = useSelector(
    (state) => state.reducerlistaSolicitudesRegistradas.data
  );

  const [loading, setLoading] = useState(true); {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  const fadeOut = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 500,
}) 

  return (
    <>
    {loading ?
        <div className='loading'>
            <Loader className='loadingColor' size="lg" />
        </div>
      :
      <animated.div style={fadeOut}>
      <div className="containerGeneric">
        <div>
          <h5 className="user-wellcome"> INVERSIONES </h5>
        </div>
        <div className="tabla-container">
          <Table
            // loading={loading}
            height={550}
            data={tablaListaSolicitudes != null ? tablaListaSolicitudes.data : ""}
            locale={{ emptyMessage: "Sin solicitudes..." }}
          >
            <Column flexGrow={1.5}>
              <HeaderCell>Nombre</HeaderCell>
              <Cell>
                {(rowData) => {
                  return (
                    <div className="boxNombre">
                      <div className="box-img-imagen-user">
                        <img
                          className="fotoUsuario"
                          alt="logoLogin"
                          src={
                            rowData.idUser != null && rowData.imgPerfil != null
                              ? "https://www.blackhound.com.ar/server/public/getImgProfile/" +
                                rowData.idUser
                              : "https://res.cloudinary.com/blackhound/image/upload/v1660676600/BLACKHOUND/Logprofile_wvmpml.png"
                          }
                        />
                      </div>
                      <div className="ajusteUbicacion">
                        {rowData.nombre + " " + rowData.apellido}
                      </div>
                    </div>
                  );
                }}
              </Cell>
            </Column>

            <Column flexGrow={1}>
              <HeaderCell>DNI</HeaderCell>
              <Cell>
                {(rowData) => {
                  return rowData.dni;
                }}
              </Cell>
            </Column>

            <Column flexGrow={1}>
              <HeaderCell>Empresa</HeaderCell>
              <Cell>
                {(rowData) => {
                  return (
                    <>
                      {rowData.proyecto == "Desarrollos Delsud" ? (
                        <img
                          className="proyecto-tamaño"
                          src="https://res.cloudinary.com/blackhound/image/upload/v1659525488/BLACKHOUND/desarrollos2_jw19om.svg"
                          alt="desarrollos"
                        />
                      ) : (
                        ""
                      )}
                      {rowData.proyecto == "Elemental Constructora" ? (
                        <img
                          className="proyecto-tamaño"
                          src="https://res.cloudinary.com/blackhound/image/upload/v1659540935/BLACKHOUND/elemental_oohxsp.svg"
                          alt="Elemental Constructora"
                        />
                      ) : (
                        ""
                      )}
                      {rowData.proyecto == "Eugénie" ? (
                        <img
                          className="proyecto-tamaño"
                          src="https://res.cloudinary.com/blackhound/image/upload/v1659541685/BLACKHOUND/eugenie_jljwc2.svg"
                          alt="Microcréditos"
                        />
                      ) : (
                        ""
                      )}
                      {rowData.proyecto == "Petricor Agrícola" ? (
                        <img
                          className="proyecto-tamaño"
                          src="https://res.cloudinary.com/blackhound/image/upload/v1659541779/BLACKHOUND/petricor_yutbqr.svg"
                          alt="Petricor Agrícola"
                        />
                      ) : (
                        ""
                      )}
                      {rowData.proyecto == "Elal" ? (
                        <img
                          className="proyecto-tamaño"
                          src="https://res.cloudinary.com/blackhound/image/upload/v1662854931/BLACKHOUND/elalll_sxpkmu.png"
                          alt="Petricor Agrícola"
                        />
                      ) : (
                        ""
                      )}
                    </>
                  );
                }}
              </Cell>
            </Column>

            <Column flexGrow={1}>
              <HeaderCell>Monto</HeaderCell>
              <Cell>
                {(rowData) => {
                  return rowData.moneda + rowData.monto;
                }}
              </Cell>
            </Column>

            <Column flexGrow={1}>
              <HeaderCell> Plazo </HeaderCell>
              <Cell>
                {(rowData) => {
                  return rowData.plazoInversion + ' meses';
                }}
              </Cell>
            </Column>

            <Column flexGrow={1}>
              <HeaderCell>Acciones</HeaderCell>
              <Cell className="estado-inversion">
                {(rowData) => {
                  function handleModalContratos() {
                    setDataModalContrato({ ...rowData });
                  }
                  function handleEliminarInversion() {
                    setEliminarInversion({ ...rowData });
                  }
                  return (
                    <>
                      <div className="container-buttons">
                        <Whisper placement="bottom" followCursor speaker={<Popover className="container-pop">Cargar documento</Popover>}>
                          <input
                            className="button-cliente-agregarDocu"
                            type="button"
                            value={2}
                            onClick={handleModalContratos}
                          />
                        </Whisper>
                        <Whisper placement="right" followCursor speaker={<Popover className="container-pop">Eliminar</Popover>}>
                          <input
                            className="button-cliente-rechazo-Asesores"
                            type="button"
                            value={5}
                            onClick={handleEliminarInversion}
                          />
                        </Whisper>
                      </div>
                    </>
                  );
                }}
              </Cell>
            </Column>
          </Table>
          <Pagination
            prev
            next
            size="md"
            total={
              tablaListaSolicitudes != null
                ? tablaListaSolicitudes.totalRegister
                : ""
            }
            limit={10}
            activePage={activePage}
            onChangePage={setActivePage}
            style={{
              justifyContent: "center",
              marginTop: "20px",
              maginBottom: "50px",
            }}
          />
        </div>
        <InfoInversion dataModalSolicitud={modalSolicitud} />
        <ModalDocumentacion datosUserContrato={dataModalContrato} />
        <ModalEliminarInversion dataEliminarInversion={eliminarInversion} />
      </div>
      </animated.div>
    }
    </>
  );
}
