import { actionTypes } from "../Constantes/ActionTypes"; 

export const getInversionesUsuario = (data) => {
    return {
        type: actionTypes.GET_INVERSIONES_USUARIO,
        data
    }
}

export const setInversionesUsuario = (data) => {
    return {
        type: actionTypes.SET_INVERSIONES_USUARIO,
        data
    }
}

