import React, { useEffect, useState } from 'react'

//RSUITE
import { Button, Modal } from 'rsuite';

import { useDispatch, useSelector } from 'react-redux';

//ACTION & REDUX
import { editarProyecto } from '../../../../Actions/editarProyecto';
import { getCategoriaProyectos } from "../../../../Actions/categoriasProyecto";


//STYLE
import './editarproyecto.css';

const EditarProyecto = (props) => {

  const dataModal = props.abrirModal;
  const dataProyecto = props.data;

  const [open, setOpen] = useState(dataModal != null);
  const handleClose = () => setOpen(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategoriaProyectos())
  }, []);

  useEffect(() => {
    if (dataModal != null) {
      setOpen(dataModal);
    }

  }, [dataModal]);

  if (dataProyecto != null) {

    var idProyecto = dataProyecto.data.idProyecto;
    var fecha = dataProyecto.data.fecha.date.substring(10, 0);
    var categoriaProyecto = dataProyecto.data.categoria;
    var moneda = dataProyecto.data.moneda;
    var montoMinimo = dataProyecto.data.montoMinimo;
    var plazoMinimo = dataProyecto.data.plazo;
    var tna = dataProyecto.data.tna;
    var imagenProyecto = "https://www.blackhound.com.ar/server/public/getImgId/" + dataProyecto.data.idImagenProyecto;
    var fotoUno = "https://www.blackhound.com.ar/server/public/getImgId/" + dataProyecto.data.idImagenUno;
    var fotoDos = "https://www.blackhound.com.ar/server/public/getImgId/" + dataProyecto.data.idImagenDos;


    var nombre = dataProyecto.data.nombre;
    var descripcion = dataProyecto.data.descripcion;
  }


  const [imgData, setImgData] = useState(imagenProyecto);
  const [picture, setPicture] = useState(null);

  const [mostrarImagenUno, setMostrarImagenUno] = useState();
  const [imagenUno, setImagenUno] = useState(fotoUno);

  const [mostrarImagenDos, setMostrarImagenDos] = useState();
  const [imagenDos, setImagenDos] = useState(fotoDos);

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };


  const onChangeImagenUno = (e) => {
    if (e.target.files[0]) {
      setMostrarImagenUno(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImagenUno(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };


  const onChangeImagenDos = (e) => {
    if (e.target.files[0]) {
      setMostrarImagenDos(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImagenDos(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };


  const [formProyecto, setFormProyecto] = useState({
    idProyecto: idProyecto,
    fecha: fecha,
    categoriaProyecto: categoriaProyecto,
    moneda: moneda,
    montoMinimo: montoMinimo,
    plazoMinimo: plazoMinimo,
    tna: tna,
    nombre: nombre,
    descripcion: descripcion,
  })

  const handleChange = (event) => {
    setFormProyecto({
      ...formProyecto,
      [event.target.name]: event.target.value
    });
    event.preventDefault();
  }

  //SETEAR CAMBIOS
  useEffect(() => {
    setFormProyecto({

      fecha: formProyecto.fecha,
      categoriaProyecto: formProyecto.categoriaProyecto,
      moneda: formProyecto.moneda,
      montoMinimo: formProyecto.montoMinimo,
      plazoMinimo: formProyecto.plazoMinimo,
      tna: formProyecto.tna,

      nombre: formProyecto.nombre,
      descripcion: formProyecto.descripcion,
    })
  }, [dataModal])



  function handleCloseSubmit() {
    const proyectoEditado = {
      id: idProyecto,
      fecha: fecha,
      categoria: nombreCategoria,
      tna: tna,
      moneda: nombreMoneda,
      montoMinimo: montoMinimo,
      plazoMinimo: plazoMinimo,
      nombre: nombre,
      descripcion: descripcion,
    }

    let formData = new FormData();
    formData.append("data", JSON.stringify(proyectoEditado))
    formData.append("imagenProyecto", picture)
    formData.append("imagenUno", mostrarImagenUno)
    formData.append("ImagenDos", mostrarImagenDos)
    dispatch(editarProyecto(formData));
    setOpen(false);

  }







  const categorias = useSelector(state => state.reducerCategoriasProyecto.data);
  const listaMonedas = useSelector(state => state.reducerListaMonedas.data);

  //TIPO DE MONEDA
  if (listaMonedas != null) {
    var monedas = [];
    const pu = listaMonedas.data;
    for (var i = 0; i < pu.length; i++) {
      monedas.push(<option value={pu[i].nombre} selected={moneda}  > {pu[i].nombre} </option>)
    }
  }

  //TIPO DE CATEGORIA
  if (categorias != null) {
    var checkbox = [];
    const pu = categorias.categorias;
    for (var i = 0; i < pu.length; i++) {
      checkbox.push(
        <label>{pu[i].nombre} </label>, <input name={categoriaProyecto} id={pu[i].nombre} defaultChecked={categoriaProyecto == pu[i].nombre ? true : false} type="radio" value={pu[i].id} onChange={handleChange} />, <br />

      )
    }
  }


  //TIPO DE CATEGORIA
  let nombreCategoria = formProyecto.categoriaProyecto;
  let nombreMoneda = 1;
  useEffect(() => {

    if (categorias != null && formProyecto.categoriaProyecto != null) {
      const post = categorias.categorias;
      for (var i = 0; i < post.length; i++) {
        if (post[i].nombre == formProyecto.categoriaProyecto) {
          nombreCategoria = post[i].id;
        }
      }
    }

    //TIPO DE MONEDA
    if (listaMonedas != null) {
      const mon = listaMonedas.data;
      for (var i = 0; i < mon.length; i++) {
        if (mon[i].nombre == formProyecto.moneda) {
          nombreMoneda = mon[i].id;
        }
      }
    }
  }, [formProyecto]);


  return (
    <>
      {(open) &&
        <div className="modal-container ">
          <Modal className='proyecto-modal editar-modal-container' open={open} onClose={handleClose} size={"full"} >
            <Modal.Body>
              <div className="crear-proyecto-container ">
                <div className="crear-proyecto-formulario">
                  <form>
                    <div style={{ display: 'flex' }}>
                      <div className='infoProyecto'>
                        <div className="crear-proyecto-campo">
                          <label >Fecha:</label>
                          <input type="date" id="fecha" name="fecha" value={formProyecto.fecha} onChange={handleChange} />
                        </div>
                        <div className="crear-proyecto-imagen">
                          <input className="boton-seleccionar-archivo" name="imagenProyecto" id="imagen" type="file" onChange={onChangePicture} />
                        </div>
                        <div className="crear-proyecto-campo">

                          <div className="campo-checkbox-categoria">
                            <p>Categoría de proyecto:</p>
                            <div className="campo-categoria-radio">
                              {checkbox}
                            </div>
                          </div>

                          <p>Valores:</p>
                          <div className="crear-proyecto-campo">
                            <label >TNA</label>
                            <input type="text" id="tna" name="tna" value={formProyecto.tna} onChange={handleChange} />
                          </div>
                          <div className="crear-proyecto-campo">
                            <label >Moneda</label>
                            <select name="moneda" onChange={handleChange} >
                              {monedas}
                            </select>
                          </div>
                          <div className="crear-proyecto-campo">
                            <label> Monto mínimo</label>
                            <input type="text" id="montomin" name="montoMinimo" value={formProyecto.montoMinimo} onChange={handleChange} />
                          </div>
                          <div className="crear-proyecto-campo">
                            <label> Plazo mínimo</label>
                            <input type="text" id="plazomin" name="plazoMinimo" value={formProyecto.plazoMinimo} onChange={handleChange} />
                          </div>
                        </div>
                      </div>
                      <div className='infoProyecto'>
                        <p className="informacion-proyecto">Información del proyecto</p>
                        <div className="crear-proyecto-campo">
                          <label >Nombre del proyecto</label>
                          <input type="text" id="nombre-asesor" name="nombre" value={formProyecto.nombre} onChange={handleChange} />
                        </div>
                        <div className="crear-proyecto-campo">
                          <label >Descripción del proyecto</label><br />
                          <textarea style={{ maxHeight: '220px', padding: '15px' }} type="text" id="nombre-asesor" name="descripcion" value={formProyecto.descripcion} onChange={handleChange} />
                        </div>
                        <div className="crear-proyecto-proyecto fotos-proyecto-imagen ">
                          <div className="imagenes-adjuntas">
                            <input type="file" name="imagenUno" onChange={onChangeImagenUno} className="foto-adjunta" />
                            <input type="file" name="imagenDos" onChange={onChangeImagenDos} className="foto-adjunta" />
                          </div>
                        </div>
                      </div>
                    </div>

                  </form>

                </div>
                <div className="proyecto-preview">
                  <div className="crear-proyecto-preview">
                    <div className="proyecto-imagen "><img src={imgData} /></div>
                    <p className="proyecto-titulo">{formProyecto.categoria}</p>
                    <div className="proyectos-descripcion">
                      <div className="proyectos-descripcion-item">
                        <img src="https://res.cloudinary.com/grupo-delsud/image/upload/v1648564021/Blackhound/tna_nhms4e.svg" />
                        <p className="proyectos-iconos-titulo">TNA</p>
                        <p className="proyectos-iconos-desc">{formProyecto.tna}</p>
                      </div>
                      <div className="proyectos-descripcion-item">
                        <img src="https://res.cloudinary.com/blackhound/image/upload/v1661865313/BLACKHOUND/Proyectos/iconMoneda_hmcbjw.svg" />
                        <p className="proyectos-iconos-titulo">Moneda</p>
                        <p className="proyectos-iconos-desc">{formProyecto.moneda}</p>
                      </div>
                      <div className="proyectos-descripcion-item">
                        <img src="https://res.cloudinary.com/blackhound/image/upload/v1661865313/BLACKHOUND/Proyectos/montoMinimo_j0ym0l.svg" />
                        <p className="proyectos-iconos-titulo">Monto mínimo </p>
                        <p className="proyectos-iconos-desc">{formProyecto.montoMinimo}</p>
                      </div>
                      <div className="proyectos-descripcion-item">
                        <img src="https://res.cloudinary.com/grupo-delsud/image/upload/v1648564021/Blackhound/calendario_bulhhb.svg" />
                        <p className="proyectos-iconos-titulo">Plazo mínimo</p>
                        <p className="proyectos-iconos-desc">{formProyecto.plazoMinimo}</p>
                      </div>
                    </div>
                  </div>
                  <div className="crear-proyecto-preview Der">
                    <p className="proyecto-back-titulo">{formProyecto.nombre}</p>
                    <p className="proyecto-back-desc">{formProyecto.descripcion}</p>
                    <div className="proyecto-back-img"><img src={imagenUno} /><img src={imagenDos} /></div>
                  </div>
                </div>

              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button className="boton-cargar-proyecto" onClick={handleCloseSubmit} >
                Guardar Proyecto
              </Button>
              <Button className="boton-cargar-proyecto" onClick={handleClose}>
                Cancelar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      }
    </>
  );
}

export default EditarProyecto;