import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Popover, Whisper} from "rsuite";
import ModalEditarProyecto from '../ModalEditarProyecto/ModalEditarProyecto';
import "./contentAcordion.css";


export default function ContenidoAcordion(props) {
  const data = props.data;

  const [modalEditarProyecto, setModalEditarProyecto] = useState(null);

  function handleEditarProyecto() {
    if (modalEditarProyecto == true) {
      setModalEditarProyecto(false);
    } else {
      setModalEditarProyecto(!modalEditarProyecto);
    }
  }
  
  return (
    <div className="proyecto-acordion-container">
      <div className="proyecto-accordion-imagenes">
        <img
          src={
            "https://www.blackhound.com.ar/server/public/getImgId/" +
            data.idImagenProyecto
          }
        />
        {/* <img
          src={
            "http://127.0.0.1:8000/getImgId/" +
            data.idImagenProyecto
          }
        /> */}
        <img
          src={
            "https://www.blackhound.com.ar/server/public/getImgId/" +
            data.idImagenDos
          }
        />
        {/* <img
          src={
            "http://127.0.0.1:8000/getImgId/" +
            data.idImagenDos
          }
        /> */}
      </div>
      <div className="proyecto-accordion-datos">
        <div className="container-edit">
        <Whisper placement="top" followCursor speaker={<Popover className="container-pop">Editar proyecto</Popover>}>
          <input className='button-proyecto-edit' type="button" value={1} onClick={handleEditarProyecto} />
        </Whisper>
        </div>
        <p className="proyecto-descripcion-en-acordion">{data.descripcion}</p>
        <div className="proyecto-data-en-acordion">
          <div className="proyectos-descripcion-item">
            <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897298/BLACKHOUND/tna_nhms4e_iz5fy8.svg" />
            <p className="proyectos-iconos-titulo">TNA</p>
            <p className="proyectos-iconos-desc">{data.tna}%</p>
          </div>
          <hr className="linea-divisora-proyectos-descripcion-item" />
          <div className="proyectos-descripcion-item">
            <img src="https://res.cloudinary.com/blackhound/image/upload/v1661865313/BLACKHOUND/Proyectos/iconMoneda_hmcbjw.svg" />
            <p className="proyectos-iconos-titulo">Moneda</p>
            <p className="proyectos-iconos-desc">{data.moneda}</p>
          </div>
          <hr className="linea-divisora-proyectos-descripcion-item" />
          <div className="proyectos-descripcion-item">
            <img src="https://res.cloudinary.com/blackhound/image/upload/v1661865313/BLACKHOUND/Proyectos/montoMinimo_j0ym0l.svg" />
            <p className="proyectos-iconos-titulo">Monto mínimo</p>
            <p className="proyectos-iconos-desc">{data.montoMinimo}</p>
          </div>
          <hr className="linea-divisora-proyectos-descripcion-item" />
          <div className="proyectos-descripcion-item">
            <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897305/BLACKHOUND/calendario_bulhhb_fwnlxz.svg" />
            <p className="proyectos-iconos-titulo">Plazo mínimo</p>
            <p className="proyectos-iconos-desc">{data.plazo} meses</p>
          </div>
        </div>
        <div className="proyecto-data-seccion-botones">
          {/* {data.idProyecto == "34" && (
            <Link
              className="proyecto-data-en-acordion-boton"
              to={"/FichaTecnicaDesarrollosDelsud"}
              state={{ from: data }}
            >
              Ficha técnica
            </Link>
          )}
          {data.idProyecto == "32" && (
            <Link
              className="proyecto-data-en-acordion-boton"
              to={"/FichaTecnicaElemental"}
              state={{ from: data }}
            >
              Ficha técnica
            </Link>
          )}
          {data.idProyecto == "30" && (
            <Link
              className="proyecto-data-en-acordion-boton"
              to={"/FichaTecnicaEugenie"}
              state={{ from: data }}
            >
              Ficha técnica
            </Link>
          )} */}
          {/* <Link
            className="proyecto-data-en-acordion-boton"
            to={"solicitudInversion"}
            state={{ from: data }}
          >
            Solicitar asesoramiento
          </Link> */}
        </div>
      </div>

      <ModalEditarProyecto dataModalEditarProyecto={modalEditarProyecto} data={data} />

    </div>

  );
}
