import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive"
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "../../FichasTecnicas/fichasTecnicas.css";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function FichaEugenie() {
  const isMobile = useMediaQuery({ maxDeviceWidth: 1024 })
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const data = JSON.parse(localStorage.getItem("datosFichaEugenie"))

  
  const datosUsuarioLogeado = useSelector(
    (state) => state.reducerUsuarioDatos.data
  );

  const ScrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'

    })
}


  return (
    <div className="ficha-tecnica-container">
      <div className="ficha-tecnica-inner-container">
        <div
          className={`header-img-ficha-tecnica ${
            "background-color-" + data.categoria
          }`}
        >
          <img
            src="https://res.cloudinary.com/blackhound/image/upload/v1668167033/BLACKHOUND/Ficha%20Tecnica/Eugenie/Eugenie_ohigzp.svg"
            alt=""
          />
        </div>
        <div className="ficha-tecnica-proyect-description-eugenie">
          <p>
            Eugénie es la primera empresa que ofrece microcréditos
            exclusivamente a mujeres emprendoras. Con sus líneas personal,
            emprendimiento y franquicia, atiende simultáneamente tres instancias
            en el desarrollo de un negocio, según su escala y particularidades.
            <br />
            <br />
            Además del crédito, Eugénie brinda capacitaciones en finanzas,
            marketing, y ventas para sus emprendedoras, generando además un
            ecosistema propio en el que las experiencias se cruzan y potencian.
          </p>
          <a className="red-text" target="_blank" href="https://eugenie.com.ar/">www.eugenie.com.ar</a>
        </div>
        <div className="carrousel-ficha-tecnica">
          <Swiper
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            loop={true}
            spaceBetween={10}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Thumbs]}
            className="slider-superior-grande"
          >
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663937069/BLACKHOUND/Ficha%20Tecnica/Eugenie/6-03_1_dxpufi.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663937067/BLACKHOUND/Ficha%20Tecnica/Eugenie/6-03_4_jyeaho.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663937067/BLACKHOUND/Ficha%20Tecnica/Eugenie/6-03_3_tbmm2v.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663937067/BLACKHOUND/Ficha%20Tecnica/Eugenie/6-03_2_wvfwvq.jpg" />
            </SwiperSlide>
          </Swiper>
          <hr className="linea-divisora-ficha-tecnica" />
          <Swiper
            onSwiper={setThumbsSwiper}
            navigation={true}
            loop={true}
            spaceBetween={20}
            slidesPerView={3}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="slider-inferior-thumbail"
          >
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663937069/BLACKHOUND/Ficha%20Tecnica/Eugenie/6-03_1_dxpufi.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663937067/BLACKHOUND/Ficha%20Tecnica/Eugenie/6-03_4_jyeaho.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663937067/BLACKHOUND/Ficha%20Tecnica/Eugenie/6-03_3_tbmm2v.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663937067/BLACKHOUND/Ficha%20Tecnica/Eugenie/6-03_2_wvfwvq.jpg" />
            </SwiperSlide>
          </Swiper>
        </div>
        <hr className="linea-divisora-ficha-tecnica" />
        <div className="ficha-tecnica-productos">
          <p className="ficha-tecnica-productos-titulo">PRODUCTOS</p>
          <div className="ficha-tecnica-productos-imagenes-contenedor">
            <div className="ficha-tecnica-productos-imagenes-fila-eugenie">
              <div className="ficha-tecnica-productos-imagenes-columna">
                <img
                  alt="Personales "
                  src="https://res.cloudinary.com/blackhound/image/upload/v1661454130/BLACKHOUND/Ficha%20Tecnica/Eugenie/Proyecto/Frame_2_rrh37z.svg"
                />
                <p>PERSONALES</p>
              </div>
              <div className="ficha-tecnica-productos-imagenes-columna">
                <img
                  alt="Emprendimiento"
                  src="https://res.cloudinary.com/blackhound/image/upload/v1661454130/BLACKHOUND/Ficha%20Tecnica/Eugenie/Proyecto/Frame_4_etksyt.svg"
                />
                <p>EMPRENDIMIENTOS</p>
              </div>
              <div className="ficha-tecnica-productos-imagenes-columna">
                <img
                  alt="Franquicias"
                  src="https://res.cloudinary.com/blackhound/image/upload/v1661454130/BLACKHOUND/Ficha%20Tecnica/Eugenie/Proyecto/Frame_5_merdrg.svg"
                />
                <p>FRANQUICIAS</p>
              </div>
            </div>
          </div>
        </div>
        <hr className="linea-divisora-ficha-tecnica" />
        {/* {from != null && ( */}
          <div className="proyecto-data-en-ficha-tecnica">
            {!isMobile ?
            <>
            <div className="proyectos-descripcion-item">
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897298/BLACKHOUND/tna_nhms4e_iz5fy8.svg" />
              <p className="fichaTecnicaTitleIcons">TNA</p>
              <p className="proyectos-iconos-desc">{data.tna}%</p>
            </div>
            <hr className="linea-divisora-proyectos-descripcion-item" />
            <div className="proyectos-descripcion-item">
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1661865313/BLACKHOUND/Proyectos/montoMinimo_j0ym0l.svg" />
              <p className="fichaTecnicaTitleIcons">Monto mínimo</p>
              <p className="proyectos-iconos-desc">
                {data.moneda} {data.montoMinimo}
              </p>
            </div>
            <hr className="linea-divisora-proyectos-descripcion-item" />
            <div className="proyectos-descripcion-item">
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897305/BLACKHOUND/calendario_bulhhb_fwnlxz.svg" />
              <p className="fichaTecnicaTitleIcons">Plazo mínimo</p>
              <p className="proyectos-iconos-desc">{data.plazo} meses</p>
            </div>
            </>
            :
            <>
            <div className="proyectos-descripcion-item-fichaDesarrollos">
              <div className="containerResponsiveItemsFicha">
                <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897298/BLACKHOUND/tna_nhms4e_iz5fy8.svg" />
                <div className="containerResponsiveItemP">
                  <p className="proyectos-iconos-titulo">TNA</p>
                  <p className="proyectos-iconos-desc">{data.tna}%</p>
                </div>
              </div>  
            </div>
            <div className="proyectos-descripcion-item-fichaDesarrollos">
            <div className="containerResponsiveItemsFicha">
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1661865313/BLACKHOUND/Proyectos/montoMinimo_j0ym0l.svg" />
                <div className="containerResponsiveItemP">
                    <p className="proyectos-iconos-titulo">Monto mínimo</p>
                    <p className="proyectos-iconos-desc">
                      {data.moneda} {data.montoMinimo}
                    </p>
                </div>
              </div>  
            </div>
            <div className="proyectos-descripcion-item-fichaDesarrollos">
              <div className="containerResponsiveItemsFicha">
                <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897305/BLACKHOUND/calendario_bulhhb_fwnlxz.svg" />
                <div className="containerResponsiveItemP">
                  <p className="proyectos-iconos-titulo">Plazo mínimo</p>
                  <p className="proyectos-iconos-desc">{data.plazo} meses</p>
                </div>
              </div>  
            </div>
            </>
            }
          </div>
        {/* )} */}
        {datosUsuarioLogeado != null && datosUsuarioLogeado.estadoNombre === "Aprobado" ?
        <div className="buttonToCalculator">
          <Link 
          onClick={() => {ScrollToTop(); localStorage.setItem("datosFicha", JSON.stringify(data))}} 
          to={"/HomeUsuarios/Dashboard/solicitudInversion"}
          >
          Proyectar inversión
          </Link>
        </div>
        : ""}
      </div>
    </div>
  );
}
