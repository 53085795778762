import { useSelector } from "react-redux";
import "./conversaciones.css";
import { format, register } from "timeago.js";

export default function Conversaciones({ conversation, onlineUsers }) {
  const datosUsuarioLogeado = useSelector(
    (state) => state.reducerUsuarioDatos.data
  );
  const localeFunc = (number, index, totalSec) => {
    return [
      ["Justo ahora", "En un rato"],
      ["Hace %s segundos", "En %s segundos"],
      ["Hace 1 minuto", "En 1 minuto"],
      ["Hace %s minutos", "En %s minutos"],
      ["Hace 1 hora", "En 1 hora"],
      ["Hace %s horas", "en %s horas"],
      ["Hace 1 día", "En 1 día"],
      ["Hace %s días", "En %s días"],
      ["Hace 1 semana", "En 1 semana"],
      ["Hace %s semanas", "En %s semanas"],
      ["Hace 1 mes", "En 1 mes"],
      ["Hace %s meses", "En %s meses"],
      ["Hace 1 año", "En 1 año"],
      ["Hace %s años", "En %s años"],
    ][index];
  };
  register("es ES", localeFunc);

  // const arrayIdOnline = [];
  // if(onlineUsers !== null) {
  //   for (let i = 0; i < onlineUsers.length; i++) {
  //     if (onlineUsers[i].userId === conversation.id_user_id) {
  //       arrayIdOnline.push(1);
  //     }
  //   }
  // }

  return (
    <>
      <div className="contenidoConversaciones">
        <div className="containerImagenConversacion">
          <img
            className="imagenConversacionUsuario"
            src="https://res.cloudinary.com/blackhound/image/upload/v1662396917/BLACKHOUND/Group_17778_ujcekc.svg"
            alt=""
          />
          {/* {arrayIdOnline == 1 ? (
            <div className="chatOnlineEstado"></div>
          ) : (
            <div className="chatOfflineEstado"></div>
          )} */}
        </div>

        <div className="containerInfoConversacion">
          {datosUsuarioLogeado !== null &&
          datosUsuarioLogeado.rol[0] === "ROLE_ASESOR" ? (
            <div>
              <p className="nombreConversacion">{conversation.nombre}</p>
              <p className="nombreConversacion">{conversation.apellido}</p>
            </div>
          ) : (
            <p className="nombreConversacion">
              {datosUsuarioLogeado.asesorNombre}
            </p>
          )}
          <p className="ultimaHora">
            {format(conversation.fecha_creacion, "es ES")}
          </p>
        </div>
      </div>
    </>
  );
}
