import React, { useRef, useState,useEffect } from "react";
import { BsInfoCircleFill } from 'react-icons/bs';

import InfoProyecto from "../../../Pages/Proyectos/Proyecto/InfoProyecto/InfoProyecto";

//Import Modaal
import EditarProyecto from "./EditarProyecto/EditarProyecto";

export default function ProyectoEditable(data) {
    const [abrirModal,SetAbrirModal]= useState(null);
    const [abrirModalInfo,setAbrirModalInfo]= useState(null);

    const handleProyectoModal=()=> {
      if(abrirModal== true){
        SetAbrirModal(false);
      }else{
        SetAbrirModal(!abrirModal);
      }
      
    }

    const handleProyectoModalInfo=()=> {
      if(abrirModalInfo== true){
        setAbrirModalInfo(false);
      }else{
        setAbrirModalInfo(!abrirModalInfo);   
      }
      
    }

    useEffect(()=>{
     
      if(abrirModalInfo== false){
        setAbrirModalInfo(!abrirModalInfo);
      }
    },[abrirModalInfo]);

  return (
      <>
    <div className="proyecto-caja">
        <div className="proyecto-imagen"><img src={"https://www.blackhound.com.ar/server/public/getImgId/"+data.data.idImagenProyecto }/></div>
        <p className="proyecto-titulo">{data.data.categoria}</p>
        <div className="info-proyecto" onClick={handleProyectoModalInfo}><BsInfoCircleFill/></div>
        <div className="proyectos-descripcion">
            <div className="proyectos-descripcion-item">
                <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897298/BLACKHOUND/tna_nhms4e_iz5fy8.svg"/>
                <p className="proyectos-iconos-titulo">TNA</p>
                <p className="proyectos-iconos-desc">{data.data.tna + "%"}</p>
            </div>
            <div className="proyectos-descripcion-item">
                <img src="https://res.cloudinary.com/blackhound/image/upload/v1661865313/BLACKHOUND/Proyectos/iconMoneda_hmcbjw.svg"/>
                <p className="proyectos-iconos-titulo">Moneda</p>
                <p className="proyectos-iconos-desc">{data.data.moneda}</p>
            </div>
            <div className="proyectos-descripcion-item">
                <img src="https://res.cloudinary.com/blackhound/image/upload/v1661865313/BLACKHOUND/Proyectos/montoMinimo_j0ym0l.svg"/>
                <p className="proyectos-iconos-titulo">Monto mínimo </p>
                <p className="proyectos-iconos-desc">{data.data.montoMinimo}</p>
            </div>
            <div className="proyectos-descripcion-item">
                <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897305/BLACKHOUND/calendario_bulhhb_fwnlxz.svg"/>
                <p className="proyectos-iconos-titulo">Plazo mínimo</p>
                <p className="proyectos-iconos-desc">{data.data.plazo}</p>
            </div>
        </div>
        
        <div className='proyecto-cajas-botones'>
          <button className="" onClick={handleProyectoModal}>Editar</button>
        </div>
    </div>
    <EditarProyecto abrirModal={abrirModal} data={data}/>
    <InfoProyecto abrirModalInfo={abrirModalInfo} dataModalInfo={data.data}/>
    </>
  )
}
