import { actionTypes } from "../Constantes/ActionTypes";
import { store } from "../store/index";

function reducerEstadisticasUsuario (state = store.estadisticasUsuario, action){
    switch(action.type){
        case actionTypes.SET_ESTADISTICAS_USUARIO:
            let estadisticasData = action.data
            return Object.assign({}, state, {
                data: estadisticasData,
            })
            default:
                return state
    }
}

export default reducerEstadisticasUsuario;