import { actionTypes } from "../Constantes/ActionTypes";
import { store } from "../store/index";

function reducerMostrarProyecto (state = store.mostrarproyecto, action){
    switch(action.type){
        case actionTypes.SET_MOSTRAR_LOS_PROYECTOS:
            let MostrarProyecto = action.data
            return Object.assign({}, state, {
                data: MostrarProyecto,
            })
            default:
                return state
    }
}

export default reducerMostrarProyecto;