import { actionTypes } from "../Constantes/ActionTypes"; 

export const setNotificarUserMail = (data) => {
    return {
        type: actionTypes.NOTIFICAR_MAIL_USUARIO,
        data
    }
}

export const setCancelarTurno = (data) => {
    return {
        type: actionTypes.CANCELAR_UN_TURNO,
        data
    }
}