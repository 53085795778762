import React, { useState } from "react";
import { Link } from "react-router-dom";
import SolicitudInversion from "./SolicitudInversion/SolicitudInversion";
import "./contenidoAcordion.css";
import { useMediaQuery } from "react-responsive";

export default function ContenidoAcordion(props) {
  const data = props.data;
  const isMobile = useMediaQuery({ maxDeviceWidth: 1024 });
  const datosDeUsuarioLogeado = props.datosDeUsuarioLogeado
 
  const ScrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'

    })
  }



  return (
    <div className="proyecto-acordion-container">
      <div className="proyecto-accordion-imagenes">
        <img
          src={
            "https://www.blackhound.com.ar/server/public/getImgId/" +
            data.idImagenProyecto
          }
        />
        <img
          src={
            "https://www.blackhound.com.ar/server/public/getImgId/" +
            data.idImagenDos
          }
        />
      </div>
      <div className="proyecto-accordion-datos">
        <p className="proyecto-descripcion-en-acordion">{data.descripcion}</p>
        <div className="proyecto-data-en-acordion">
          {!isMobile ? (
            <>
              <div className="proyectos-descripcion-item">
                <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897298/BLACKHOUND/tna_nhms4e_iz5fy8.svg" />
                <p className="proyectos-iconos-titulo">TNA</p>
                <p className="proyectos-iconos-desc">{data.tna}%</p>
              </div>
              <hr className="linea-divisora-proyectos-descripcion-item" />
              <div className="proyectos-descripcion-item">
                <img src="https://res.cloudinary.com/blackhound/image/upload/v1661865313/BLACKHOUND/Proyectos/iconMoneda_hmcbjw.svg" />
                <p className="proyectos-iconos-titulo">Moneda</p>
                <p className="proyectos-iconos-desc">{data.moneda}</p>
              </div>
              <hr className="linea-divisora-proyectos-descripcion-item" />
              <div className="proyectos-descripcion-item">
                <img src="https://res.cloudinary.com/blackhound/image/upload/v1661865313/BLACKHOUND/Proyectos/montoMinimo_j0ym0l.svg" />
                <p className="proyectos-iconos-titulo">Monto mínimo</p>
                <p className="proyectos-iconos-desc">{data.montoMinimo}</p>
              </div>
              <hr className="linea-divisora-proyectos-descripcion-item" />
              <div className="proyectos-descripcion-item">
                <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897305/BLACKHOUND/calendario_bulhhb_fwnlxz.svg" />
                <p className="proyectos-iconos-titulo">Plazo mínimo</p>
                <p className="proyectos-iconos-desc">{data.plazo} meses</p>
              </div>
            </>
          ) : (
            <>
              <div style={{display: "flex"}}>
                <div className="proyectos-descripcion-item">
                  <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897298/BLACKHOUND/tna_nhms4e_iz5fy8.svg" />
                  <p className="proyectos-iconos-titulo">TNA</p>
                  <p className="proyectos-iconos-desc">{data.tna}%</p>
                </div>
                <div className="proyectos-descripcion-item">
                  <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897291/BLACKHOUND/monto_cxwn8l_w1fwel.svg" />
                  <p className="proyectos-iconos-titulo">Monto mínimo</p>
                  <p className="proyectos-iconos-desc">{data.montoMinimo}</p>
                </div>
              </div>
              <div style={{display: "flex"}}>
                <div className="proyectos-descripcion-item">
                  <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897290/BLACKHOUND/moneda_uxljoi_uqwcrb.svg" />
                  <p className="proyectos-iconos-titulo">Moneda</p>
                  <p className="proyectos-iconos-desc">{data.moneda}</p>
                </div>
                <div className="proyectos-descripcion-item">
                  <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897305/BLACKHOUND/calendario_bulhhb_fwnlxz.svg" />
                  <p className="proyectos-iconos-titulo">Plazo mínimo</p>
                  <p className="proyectos-iconos-desc">{data.plazo} meses</p>
                </div>
              </div>
            </>
          )}
        </div>
        <div className={datosDeUsuarioLogeado.estadoNombre === "Revision" ? "proyecto-data-seccion-botones-documentation-disable" : "proyecto-data-seccion-botones"}>
          {data.idProyecto == "25" && (
          
            <Link
              target="_blank" 
              rel="noopener noreferrer"
              onClick={() => {ScrollToTop(); localStorage.setItem("datosFichaDesarrollosDelSud", JSON.stringify(data))}} 
              className="proyecto-data-en-acordion-boton"
              to={"/FichaTecnicaDesarrollosDelsud"}
              
            >
              Ficha técnica
            </Link>
          )}
          {data.idProyecto == "26" && (
            <Link
            target="_blank" 
            rel="noopener noreferrer"
            onClick={() => {ScrollToTop(); localStorage.setItem("datosFichaElemental", JSON.stringify(data))}}
              className="proyecto-data-en-acordion-boton"
              to={"/FichaTecnicaElemental"}
              
            >
              Ficha técnica
            </Link>
          )}
          {data.idProyecto == "27" && (
            <Link
            target="_blank" 
            rel="noopener noreferrer"
            onClick={() => {ScrollToTop(); localStorage.setItem("datosFichaEugenie", JSON.stringify(data))}} 
              className="proyecto-data-en-acordion-boton"
              to={"/FichaTecnicaEugenie"}
            >
              Ficha técnica
            </Link>
          )}
          {data.idProyecto == "28" && (
            <Link
              target="_blank" 
              rel="noopener noreferrer"
              onClick={() => {ScrollToTop(); localStorage.setItem("datosFichaElal", JSON.stringify(data))}} 
              className="proyecto-data-en-acordion-boton"
              to={"/FichaTecnicaElal"}
            >
              Ficha técnica
            </Link>
          )}
          {data.idProyecto === 24 && datosDeUsuarioLogeado.estadoNombre === "Revision" && (
            <p className="proximamentePetricor">PRÓXIMAMENTE</p>
          )}
          {datosDeUsuarioLogeado.estadoNombre === "Revision" ?
          ""
          :
          
          <Link
          onClick={() => {
            ScrollToTop(); localStorage.setItem("datosFicha", JSON.stringify(data))
          }
          }
          className={"proyecto-data-en-acordion-boton"}
          to={"solicitudInversion"}
          >
            Invertir
          </Link>
          }
        </div>
      </div>
    </div>
  );
}
