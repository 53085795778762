import { actionTypes } from "../Constantes/ActionTypes"; 

export const postEnviarDoc = (data) => {
    return {
        type: actionTypes.ENVIAR_DOCUMENTOS_USUARIO,
        data
    }
}
export const getRecibirDoc = (data) => {
    return {
        type: actionTypes.GET_RECIBIR_DOCUMENTOS_USUARIO,
        data
    }
}

export const setRecibirDoc = (data) => {
    return {
        type: actionTypes.SET_RECIBIR_DOCUMENTOS_USUARIO,
        data
    }
}

