import React,{useEffect, useState} from 'react'
import { Button,Modal} from 'rsuite';
import FormularioModal from './FormularioModal/FormularioModal';
import './proyectomodal.css'
const ProyectoModal = (props) => {

  const dataProyecto=props.datitaModalInfo;
  const dataModal = props.abrirModal;
  const [open, setOpen] = useState(dataModal!=null);
  const handleClose = () => setOpen(null);

  useEffect(()=>{
    if(dataModal != null  ){
      setOpen(dataModal);
    }
  },[dataModal]);
  
  return (
    <>
    {(open)&&
    <div className="modal-container">
    <Modal centered className='proyecto-modal' open={open} onClose={handleClose} size="lg"  >
      <Modal.Header style={{paddingTop: '25px'}}>
        <Modal.Title>
          <p className='modal-tittle'>Solicitud de asistencia comercial</p>
          <p className='modal-sub'>Complete los campos y te contactaremos para<br/> dar tu próximo paso hacia tu inversion</p>
          {/* <p className='modal-sub'>{dataProyecto.categoriaNombre}</p> */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-solicitud-proyectos'>
        <FormularioModal dataProyecto={dataProyecto}/>
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  </div>
    }
    </>
  );
}
export default ProyectoModal;