import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';

import { getTurnoAsesor } from "../../../../../Actions/turnoAsesor";
import { guardarTurno } from "../../../../../Actions/turno";
import { setEstadoSolicitud } from "../../../../../Actions/estadoSolicitud";
import { sendMailSolicitud } from "../../../../../Actions/enviarMailSolicitud";
import { getlistaSolicitudesRegistradas } from "../../../../../Actions/listaSolicitudesRegistradas";

import './addturno.css';
//ACTION
import { Modal } from 'rsuite';

const AgregarTurno = (props) => {
    const data3 = props.dataModalAgregarTurno;
    const [open, setOpen] = useState(data3 != null);
    const dispatch = useDispatch();
    const [activePage, setActivePage] = useState(1)
    const [estadoCategoria, setEstadoCategoria] = useState(1)
    const [busqueda, setBusqueda] = useState({
        dataBase: ""
    })
    var gapi = window.gapi

    var CLIENT_ID = "524545613071-7aasnlk9alin5q82o0h041pdofmj6pm3.apps.googleusercontent.com"
    var API_KEY = "AIzaSyACvZbhyRlUCDsNiMyJBQ5SeBAEtc6WCFc"
    var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
    var SCOPES = "https://www.googleapis.com/auth/calendar.events"

    const handleClick = () => {
        gapi.load('client:auth2', () => {

            gapi.client.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                discoveryDocs: DISCOVERY_DOCS,
                scope: SCOPES,
                plugin_name: 'PLUGIN',
            })

            gapi.auth2.getAuthInstance().signIn()
                .then(() => {

                    //SETEAR CAMPOS DEL EVENTO
                    var event = {
                        'summary': evento.tittle,
                        'location': 'Av. 7 n840, B1900 La Plata, Provincia de Buenos Aires',
                        'description': evento.description,
                        'start': {
                            'dateTime': evento.dateTimeInicio + ':00',
                            'timeZone': 'America/Argentina/Buenos_Aires'
                        },
                        'end': {
                            'dateTime': evento.dateTimeInicio + ':00',
                            'timeZone': 'America/Argentina/Buenos_Aires'
                        },
                        'recurrence': [
                            'RRULE:FREQ=DAILY;COUNT=1'
                        ],
                        'attendees': [
                            { 'email': data3.email },
                            // { 'email': 'sbrin@example.com' }
                        ],
                        'reminders': {
                            'useDefault': false,
                            'overrides': [
                                { 'method': 'email', 'minutes': 24 * 60 },
                                { 'method': 'popup', 'minutes': 10 }
                            ]
                        }
                    }
                    var request = gapi.client.calendar.events.insert({
                        'calendarId': 'primary',
                        'resource': event,
                    })
                    request.execute(event => {
                        if (event.status == 'confirmed') {
                            handleClose()
                            handleShow();
                            if (datosUsuarioLogeado != null) {
                                const dataAsesor = {
                                    idAsesor : datosUsuarioLogeado.id,
                                }
                                dispatch(getTurnoAsesor(dataAsesor));
                            }
                            const nuevoTurnoAsesor = {
                                fechaTurno: evento.dateTimeInicio,
                                idSolicitud: data3.idSolicitud,
                                // idEventoGoogle: event.id,
                                // idCalendarioGoogle: 'primary'
                            }
                            dispatch(guardarTurno(nuevoTurnoAsesor));
                            const dataSolicitud = {
                                id: data3.idSolicitud,
                                idEstado: 4,
                            }
                            const data = {
                                busqueda: busqueda.dataBase,
                                categoria: estadoCategoria,
                                page: activePage,
                            }
                            if (data3 && dataSolicitud && data != null) {
                                dispatch(setEstadoSolicitud(dataSolicitud));
                                dispatch(sendMailSolicitud(data3.email));
                                setTimeout(() => {
                                    dispatch(getlistaSolicitudesRegistradas(data));
                                 }, 1000);
                            }
                            setOpen(false);
                        }
                    })
                })
        })
    }

    useEffect(() => {
        if (data3 != null) {
            setOpen(data3);
        }
    }, [data3])

    const [show, setShow] = useState(false);
  
    const handleShow = () => setShow(true);
    const handleCloseConfirm = () => setShow(false);

    const [evento, setEvento] = useState({
        tittle: '',
        description: '',
        dateTimeInicio: '',
        dateTimeFin: '',
        emailAsesor: '',
    })

    const handleClose = () => {
        setEvento({
            tittle: '',
            description: '',
            dateTimeInicio: '',
            dateTimeFin: '',
            emailAsesor: '',
        })
        setOpen(false)
    };

    const onChangeEvent = (event) => {
        setEvento({
            ...evento,
            [event.target.name]: event.target.value
        });
    }

    const onChangeCliente = (event) => {
        setCliente({
            ...cliente,
            [event.target.name]: event.target.value
        });
    }

    const [backdrop, setBackdrop] = useState('static');
    const [asesor, setAsesor] = useState({
        cantAsesor: 0,
    });
    const [cliente, setCliente] = useState({
        cantCliente: 0,
    });

    
    const listaTotalAsesores = useSelector(state => state.reducerListaAsesores.data);
    const datosUsuarioLogeado = useSelector((state) => state.reducerUsuarioDatos.data);//OBTENER DATOS DEL USUARIO
    const tablaListaClientes = useSelector(state => state.reducerlistaClientesRegistrados.data);


    if (tablaListaClientes != null) {
        var arrayListaClientes = [];
        const listaClientes = tablaListaClientes.data;
        for (var i = 0; i < listaClientes.length; i++) {
            arrayListaClientes.push(
                <>
                    <option value={[i]}>{listaClientes[i].nombre + ' ' + listaClientes[i].apellido}</option>
                </>
            );
        }
    }

    return (
        <>
            {(open) &&
                <div className='modalEnvioDoc'>
                    <Modal className='proyecto-modal infoModal' backdrop={backdrop} open={open} size="md" onClose={handleClose} >
                        <Modal.Header className='headerModal'>
                            <Modal.Title className='headerModal'>
                                <div>
                                    <h4 style={{ margin: 'auto', color: 'white', textAlign: 'center' }}> Asignar un turno </h4>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="crear-proyecto-formulario">
                                <div className="contFormAddTurno">
                                    <div className="crear-proyecto-form add-evento">
                                        <div className="crear-proyecto-campo">
                                            <input className='input-proyecto' autoComplete='off' required placeholder="Título del turno" value={evento.tittle} name="tittle" type="text" onChange={onChangeEvent} />
                                        </div>
                                    </div>
                                    <div className="crear-proyecto-form add-evento">
                                        <div className="crear-proyecto-campo">
                                            <input className='input-proyecto' autoComplete='off' value={evento.dateTimeInicio} name="dateTimeInicio" type="datetime-local" onChange={onChangeEvent} />
                                        </div>
                                    </div>
                                </div>
                                <div className="crear-proyecto-campo cont-textarea">
                                    <textarea className='input-proyecto container-textarea' autoComplete='off' required placeholder="Ingrese una descripción" value={evento.description} name="description" type="text" onChange={onChangeEvent} ></textarea>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='contFooter'>
                            <button className='buttonModal' onClick={handleClick}> Agregar turno </button>
                            <button className='buttonModal' onClick={handleClose}> Cerrar </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            }
            {/* MODAL */}
            <Modal centered open={show} onClose={handleCloseConfirm} backdrop="static" >
                <Modal.Header>
                  <Modal.Body
                      style={{ fontSize: "20px", color: "white", textAlign: "center" }}
                      > Turno agregado correctamente! <br></br>
                      Revise su google calendar para más información.
                  </Modal.Body>
                </Modal.Header>
                <Modal.Footer className='contFooter'>
                  <button className='buttonModal' onClick={handleCloseConfirm}> Cerrar </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AgregarTurno;
