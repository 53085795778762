import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';
import { enviarMailCodigo } from "../api/enviarMailCodigo";
// import {  toast } from 'react-toastify';

export const watchEnviarMailCodigo = function * (){
    yield takeEvery(actionTypes.SEND_MAIL_CODIGO, fetchEnviarMailCodigo)
}

function* fetchEnviarMailCodigo(action){
    try{
        const data = yield call(enviarMailCodigo, action.data)
        if(data){
            // toast.success('¡Bienvenido a Blackhound!', {
            // });

        }
    }catch(err){
        console.log(err)
        // toast.error('Error al enviar Mail', {     
        // }); 
    }
}