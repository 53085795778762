import { actionTypes } from "../Constantes/ActionTypes";
import { store } from "../store/index";

function reducerObtenerChat(state = store.chat, action) {
    switch (action.type) {
        case actionTypes.SET_CONVERSACION:
            let obtenerChat = action.data
            return Object.assign({}, state, {
                data: obtenerChat,
            })

        case actionTypes.CANTIDAD_MENSAJES:
            let contadorMensaje = state.cantMensaje
            return Object.assign({}, state, {
                cantMensaje: contadorMensaje++,
            })

        case actionTypes.TRAER_CONVERSACION:
            let conversacion = state.chat
            conversacion.push(action.data) 
            return Object.assign({}, state, {
                data:  conversacion,
              }) 
              
        default:
            return state
    }
}

export default reducerObtenerChat;