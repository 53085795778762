import React,{useEffect, useState} from 'react'
import {Modal} from 'rsuite';
import './infoproyecto.css'



const InfoProyecto = (props) => {
  const dataInfo = props.abrirModalInfo;
  const dataModalInfo = props.dataModalInfo;
  const [open, setOpen] = useState(dataInfo!=null);
  const handleClose = () => setOpen(null);


  useEffect(()=>{
    if(dataInfo != null  ){
      setOpen(dataInfo);
    }
  },[dataInfo]);
 

  return (
    <>
    {(open)&&
    <div className="modal-container">
    <Modal className='proyecto-modal infoModal' open={open} size="lg" onClose={handleClose} >
    <Modal.Header>
        <Modal.Title>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <p className='modal-tittle'>{dataModalInfo.categoria}</p>
          <p className='modal-sub'>{dataModalInfo.descripcion}</p>
          <div className="proyecto-back-img">
              {/* {dataModalInfo.idImagenUno !=null ? <img src={"https://blackhound.com.ar/server/public/getImgId/"+dataModalInfo.idImagenUno}/> : ""}
              {dataModalInfo.idImagenDos !=null ? <img src={"https://blackhound.com.ar/server/public/getImgId/"+dataModalInfo.idImagenDos}/> : ""} */}

              {dataModalInfo.idImagenUno !=null ? <img src={"https://www.blackhound.com.ar/server/public/getImgId/"+dataModalInfo.idImagenUno}/> : ""}
              {dataModalInfo.idImagenDos !=null ? <img src={"https://www.blackhound.com.ar/server/public/getImgId/"+dataModalInfo.idImagenDos}/> : ""}
          </div>

      </Modal.Body>
      <Modal.Footer>
        <button className="solicitar-proyecto" onClick={handleClose}>Cerrar</button>
      </Modal.Footer>
    </Modal>
  </div>  
    }
    </>
  );
}

export default InfoProyecto;
