import React, { useState, useEffect } from 'react'
import { BsInstagram, BsWhatsapp, BsFacebook } from 'react-icons/bs';
import { useForm } from "react-hook-form";
import Modal from 'react-bootstrap/Modal'
import { MdEmail } from 'react-icons/md'
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {getDatosUsuario} from '../../Actions/datosUsuario';
import axios from 'axios';
import './footer.css'
import { useMediaQuery } from "react-responsive"

import { useLocation } from 'react-router-dom'

export default function Footer() {
    let location = useLocation();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // ENVIAR FORMULARIO//
    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        mode: "onChange"
    });

    const ScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'

        })
    }



    const datosUsuarioLogeado = useSelector(state => state.reducerUsuarioDatos.data);
    const [userLogin, setUserLogin] = useState("");
    const dispatch = useDispatch();
    const token = useSelector(state => state.reducerLogin.user_data);


    useEffect(() => {
        dispatch(getDatosUsuario());
    }, [token])

    useEffect(() => {
        if (datosUsuarioLogeado != null) {
            if (datosUsuarioLogeado.rol[0] == "ROLE_ADMIN") {
                setUserLogin("ROLE_ADMIN")


            }
            if (datosUsuarioLogeado.rol[0] == "ROLE_USER") {
                setUserLogin("ROLE_USER")

            }
            if (datosUsuarioLogeado.rol[0] == "ROLE_ASESOR") {
                setUserLogin("ROLE_ASESOR")

            }
        }
    }, [datosUsuarioLogeado])

    useEffect(() => {
        if (datosUsuarioLogeado != null) {
            if (datosUsuarioLogeado.rol[0] == "ROLE_ADMIN") {

            }
        }
    }, [datosUsuarioLogeado])


    const isMobile = useMediaQuery({ maxDeviceWidth: 1023 })

    const onSubmit = (data) => {
        //alert(JSON.stringify(data));
        axios.post(`https://blackhound.com.ar/server/public/correoNewsletter`, data)
            .then(function (response) {
                handleShow();
            })
            .catch(function (error) {
                console.log(error);
            });
    };



    return (
        <div>
            {userLogin === "ROLE_ADMIN" || userLogin === "ROLE_USER" || userLogin === "ROLE_ASESOR" ?
                <>

                    <div className="footer-container-sesionIniciada">

                        <div className='subcontainer-izq-footer'>
                            <div className='caracteristicas-icono-item'>
                                <img className='footer-img' src='https://res.cloudinary.com/blackhound/image/upload/v1657897303/BLACKHOUND/BH_Logo_epcj0a_ymvrrg.svg' />
                                <p className='text-derechos'>Todos los derechos reservados © 2022 </p>
                            </div>
                        </div>
                        <div className='zIndex'>
                            <div className='footer-info'>
                                <div className='nav-links-items'>
                                    <Link onClick={ScrollToTop} to="/Nosotros">Nosotros</Link>
                                    <span className='barra-form'> </span>
                                    <Link onClick={ScrollToTop} to="/ComoInvertir">Cómo invertir</Link>
                                    <span className='barra-form'> </span>
                                    <Link onClick={ScrollToTop} to="/Proyectos">Proyectos</Link>
                                    <span className='barra-form'> </span>
                                    <Link onClick={ScrollToTop} to="/Contacto">Contacto</Link>
                                    <span className='barra-form'> </span>
                                    <Link onClick={ScrollToTop} to="/Faqs">FAQ's</Link>
                                </div>
                            </div>
                            <div className='redes-footer'>
                                <div className='redes'>
                                    <a target="_blank" href='https://www.instagram.com/blackhound.inversiones'><img width='40px' src='https://res.cloudinary.com/blackhound/image/upload/v1660915398/BLACKHOUND/NavBar/instagram_qv4xaf.svg' alt='' /></a>
                                    <a target="_blank" href='https://www.facebook.com/Black-Hound-100753315619600'><img width='40px' src='https://res.cloudinary.com/blackhound/image/upload/v1660915400/BLACKHOUND/NavBar/facebook_op0knx.svg' alt='' /></a>
                                </div>
                            </div>
                        </div>

                    </div>
                    {!isMobile ?
                        <div className='subcontainer-subfooter'>
                            <div className='subfooter'>
                                <div className='child-subfooter'>
                                    <p> Una empresa de </p>
                                    <img className='imgGrupo' src='https://res.cloudinary.com/blackhound/image/upload/v1657900279/Grupo_56_l4ctfd.png' alt="Blackhound" />
                                </div>
                                <div onClick={ScrollToTop} className='child-arrow-subfooter'>
                                    <img src='https://res.cloudinary.com/blackhound/image/upload/v1657897302/BLACKHOUND/arrowUp_leaq9x_ktdu1a.svg' alt="Blackhound" />
                                </div>
                            </div>
                        </div>
                        :
                        <div className='subcontainer-subfooter-mobile'>
                            <div className='subfooterMobile'>
                                <div className='child-subfooter'>
                                    <p> Una empresa de </p>
                                    <img className='imgGrupo' src='https://res.cloudinary.com/blackhound/image/upload/v1657900279/Grupo_56_l4ctfd.png' alt="Blackhound" />
                                </div>
                                <div onClick={ScrollToTop} className='child-arrow-subfooter'>
                                    <img src='https://res.cloudinary.com/blackhound/image/upload/v1657897302/BLACKHOUND/arrowUp_leaq9x_ktdu1a.svg' alt="Blackhound" />
                                </div>
                                <div className='finishFooterMobile'>
                                    <p>Todos los derechos reservados © 2022</p>
                                </div>
                            </div>
                        </div>
                    }

                </>

                :
                <>
                    <div className='caracteristicas-container-footer' >
                        <div className='caja-caracteristicas-iconos container-empresas' data-aos="fade-in" data-aos-duration="1000" data-aos-delay="200">
                            <div className='caracteristicas-icono-item'>
                                <img className='img-empresas' src='https://res.cloudinary.com/blackhound/image/upload/v1657897312/BLACKHOUND/desarrollos-logo-svg_es82qn_y1nswi.svg' />
                            </div>

                            <div className='caracteristicas-icono-item' >
                                <img className='img-empresas' src='https://res.cloudinary.com/blackhound/image/upload/v1657897316/BLACKHOUND/elemental-logo-svg_wdy6pp_gqlkpq.svg' />
                            </div>

                            <div className='caracteristicas-icono-item'>
                                <img className='img-empresas' src='https://res.cloudinary.com/blackhound/image/upload/v1662468227/BLACKHOUND/Logo_p9hvvm.svg' />
                            </div>

                            <div className='caracteristicas-icono-item' >
                                <img className='img-empresas' src='https://res.cloudinary.com/blackhound/image/upload/v1657897319/BLACKHOUND/eugenie-logo-svg_oijeur_gef1j9.svg' />
                            </div>

                            <div className='caracteristicas-icono-item'>
                                <img className='img-empresas' src='https://res.cloudinary.com/blackhound/image/upload/v1657897293/BLACKHOUND/petricor-logo_sn6gmc_npgtum.svg' />
                            </div>

                        </div>
                    </div>
                    <div className='footer-container'>

                        <div className='subcontainer-izq-footer'>
                            <div className='caracteristicas-icono-item'>
                                <img className='footer-img' src='https://res.cloudinary.com/blackhound/image/upload/v1657897303/BLACKHOUND/BH_Logo_epcj0a_ymvrrg.svg' />
                                <p className='text-derechos'>Todos los derechos reservados © 2022 </p>
                            </div>
                        </div>
                        <div className='zIndex'>
                            <div className='footer-info'>
                                <div className='nav-links-items'>
                                    <Link onClick={ScrollToTop} to="/Nosotros">Nosotros</Link>
                                    <span className='barra-form'> </span>
                                    <Link onClick={ScrollToTop} to="/ComoInvertir">Cómo invertir</Link>
                                    <span className='barra-form'> </span>
                                    <Link onClick={ScrollToTop} to="/Proyectos">Proyectos</Link>
                                    <span className='barra-form'> </span>
                                    <Link onClick={ScrollToTop} to="/Contacto">Contacto</Link>
                                    <span className='barra-form'> </span>
                                    <Link onClick={ScrollToTop} to="/Faqs">FAQ's</Link>
                                </div>
                            </div>
                            <div className='redes-footer'>
                                <div className='redes'>
                                    <a target="_blank" href='https://www.instagram.com/blackhound.inversiones'><img width='40px' src='https://res.cloudinary.com/blackhound/image/upload/v1660915398/BLACKHOUND/NavBar/instagram_qv4xaf.svg' alt='' /></a>
                                    <a target="_blank" href='https://www.facebook.com/Black-Hound-100753315619600'><img width='40px' src='https://res.cloudinary.com/blackhound/image/upload/v1660915400/BLACKHOUND/NavBar/facebook_op0knx.svg' alt='' /></a>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* MODAL */}
                    {/* MODAL */}
                    {/* <Modal  size="md"  centered className='modal-agradecimiento subscripcion' show={show} ={handleClose}>
            <Modal.Body>
                <div className='agradecimiento-container' >
                    <p className='modal-agradecimiento-text'>Suscripción realizada con éxito.</p>
                    <img src='https://res.cloudinary.com/grupo-delsud/image/upload/v1648672725/Blackhound/stars_en3psv.svg'/>
                    <p className='modal-agradecimiento-desc'>Pronto tendrás novedades de BlackHound</p>
                </div>
                <button onClick={handleClose}>Cerrar</button>         
            </Modal.Body>
        </Modal> */}

                    {!isMobile ?
                        <div className='subcontainer-subfooter'>
                            <div className='subfooter'>
                                <div className='child-subfooter'>
                                    <p> Una empresa de </p>
                                    <img className='imgGrupo' src='https://res.cloudinary.com/blackhound/image/upload/v1657900279/Grupo_56_l4ctfd.png' alt="Blackhound" />
                                </div>
                                <div onClick={ScrollToTop} className='child-arrow-subfooter'>
                                    <img src='https://res.cloudinary.com/blackhound/image/upload/v1657897302/BLACKHOUND/arrowUp_leaq9x_ktdu1a.svg' alt="Blackhound" />
                                </div>
                            </div>
                        </div>
                        :
                        <div className='subcontainer-subfooter-mobile'>
                            <div className='subfooterMobile'>
                                <div className='child-subfooter'>
                                    <p> Una empresa de </p>
                                    <img className='imgGrupo' src='https://res.cloudinary.com/blackhound/image/upload/v1657900279/Grupo_56_l4ctfd.png' alt="Blackhound" />
                                </div>
                                <div onClick={ScrollToTop} className='child-arrow-subfooter'>
                                    <img src='https://res.cloudinary.com/blackhound/image/upload/v1657897302/BLACKHOUND/arrowUp_leaq9x_ktdu1a.svg' alt="Blackhound" />
                                </div>
                                <div className='finishFooterMobile'>
                                    <p>Todos los derechos reservados © 2022</p>
                                </div>
                            </div>
                        </div>
                    }
                </>
}
            </div>           
  )
} 
