import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';
import { listaClientes } from'../api/listaClientes';
import { setlistaClientesRegistrados } from "../Actions/listaClientesRegistrados";

export const watchListaClientesRegistrados = function * (){
    yield takeEvery(actionTypes.GET_LISTA_CLIENTES_REGISTRADOS, fetchListaClientesRegistrados)
}

function* fetchListaClientesRegistrados(action){
    try{
        const data = yield call(listaClientes, action.data)
        if(data){
            yield put(setlistaClientesRegistrados(data))
        }
    }catch(err){
        console.log(err)
    }
}