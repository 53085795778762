import React, { useRef, useState,useEffect } from "react";
import './proyectosrentables.css'
import Proyecto from '../../../Proyectos/Proyecto/Proyecto'
import { getMostrarProyecto } from "../../../../Actions/mostrarProyectos";
import { useSelector, useDispatch } from 'react-redux';

export default function ProyectosRentables() {
  const [activePage, setActivePage] = useState(1)
  const [estadoFiltro, setEstadoFiltro] = useState(null)
  const [busqueda, setBusqueda] = useState({
    dataBase: ""
  })
  const dispatch = useDispatch();
  useEffect(()=>{
    const data = {
      busqueda: busqueda.dataBase,
      estado: estadoFiltro,
      page: activePage,
      categoria:estadoFiltro,
      moneda:estadoFiltro,
    }
    dispatch(getMostrarProyecto(data));
  }, [activePage, busqueda, estadoFiltro])
  const listaProyectos = useSelector(state => state.reducerMostrarProyecto.data);
  if (listaProyectos != null) {
    var proyectos = [];
    var estadoAlinear= false;
    const pu = listaProyectos.data;
    for (var i = 0; i < pu.length; i++) {

        {estadoAlinear = !estadoAlinear}
        proyectos.push(<Proyecto key={[i]} data={pu[i]} clase={estadoAlinear}/>)

    }
  }
  
  return (
    <>
      <div className='proyectos-rentables-container tittle-container'>
          <h3> <span> Black Hound </span> es la plataforma de inversiones donde tu <br></br> dinero se destina a proyectos de la economía real. 
            <br></br> <span> Obtené rendimientos mensuales en pesos o dólares. </span> 
          </h3>
      </div>
      <div className='proyectos-rentables-container' >
          <h4>Proyectos <span> reales. </span> <br/> Rentabilidad <span> real. </span></h4>
          <div className="container-icon-hands">
            <img src='https://res.cloudinary.com/blackhound/image/upload/v1657897299/BLACKHOUND/white-hand_jiunyt_m3oubv.svg'/>
          </div>
      </div>
      <img className="markLeft" src="https://res.cloudinary.com/blackhound/image/upload/v1660243064/BLACKHOUND/Mask_group_ur2p0o.png" alt=""/>
      <div className='proyectos-home' data-aos="zoom-in" data-aos-duration="1000">
        {proyectos}
      <img className="markRightHome" src="https://res.cloudinary.com/blackhound/image/upload/v1661868266/BLACKHOUND/Home/Mask_group_ldg44u.png" alt=""/>
      </div>
    </>
  )
}