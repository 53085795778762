import React, {useState, useEffect} from 'react'
import { Link, Navigate, Routes, Route } from "react-router-dom";
import Button from 'rsuite/Button';
import {resultVerificar} from '../../Actions/login'
import {verificarLogin} from '../../Actions/login'
import UserEliminado from '../../Usuarios/Vistas/userEliminado/userEliminado';

import { useSelector, useDispatch } from 'react-redux';
import './iniciarsesion.css'

export default function IniciarSesion() {
  const [loadingButton, setLoadingButton] = useState(false);
  const userLogin = localStorage.getItem('token')
  const [errorLogin, setErrorLogin] = useState(false);
  const [login, setLogin] = useState({
    nameuser: '',
    clave: ''
  });

  const dispatch = useDispatch();

  const handleLogin = (e) => {
    setLogin({
      ...login,
      [e.target.name]: e.target.value});
  }
  
  const handleSubmitLogin = () => {
    setLoadingButton(true);
    setTimeout(() => {
      setLoadingButton(false);
    }, 2500);
    const user = {
      username: login.nameuser,
      password:login.clave
    }
    dispatch(resultVerificar(user));
    setTimeout(() => {
      if(userLogin === null){
        setErrorLogin(true);
      }
    }, 4000);
  }

   const pressEnter = (e) => {
    if(e.key === 'Enter') { 
      handleSubmitLogin();
     }
  }  
 

  const datosUsuarioLogeado = useSelector(state => state.reducerUsuarioDatos.data);

  var estadoUsuario=0;

  if(datosUsuarioLogeado != null){
    if( datosUsuarioLogeado.estadoNombre=="Aprobado"){
      estadoUsuario=1;
    }
    else if( datosUsuarioLogeado.estadoNombre=="Revision" || datosUsuarioLogeado.estadoNombre=="Rechazado" ){
      estadoUsuario=0;
    }
  }

  useEffect(()=>{
    if(datosUsuarioLogeado != null){
      if( datosUsuarioLogeado.estadoNombre=="Aprobado"){
        estadoUsuario=1;
      }
      else if( datosUsuarioLogeado.estadoNombre=="Revision" || datosUsuarioLogeado.estadoNombre=="Rechazado" ){
        estadoUsuario=0;
      }
    }
  },[datosUsuarioLogeado])



  if (userLogin && datosUsuarioLogeado != null){ 
        if(datosUsuarioLogeado.rol[0] == "ROLE_ADMIN"){
          return(
              <>  
                <Routes>
                  <Route path="/" element={<Navigate to="/HomeAdmin" />}/> 
                </Routes>
              </>
          );
        }
        else if(datosUsuarioLogeado.rol[0] == "ROLE_USER"){

          return(
            <>  
              <Routes>
                <Route path="/" element={<Navigate to={"/HomeUsuarios/Dashboard"}/>}/> 
              </Routes>
            </>
          );
        }
        else if(datosUsuarioLogeado.rol[0] == "ROLE_ASESOR" && datosUsuarioLogeado.estadoNombre != 'Rechazado'){

          return(
            <>  
              <Routes>
                <Route path="/" element={<Navigate to={"/HomeAsesores/dashboard" }/>}/> 
              </Routes>
            </>
          );
        }
        else if(datosUsuarioLogeado.estadoNombre == 'Rechazado' || datosUsuarioLogeado.estadoNombre == 'Eliminado' ){

          return(
            <>  
              <UserEliminado />
            </>
          );
        }

  }

  const ScrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'

    })
}
  return (
    <>
      <div className='login-background' >
        <img className='markLeft' src='https://res.cloudinary.com/blackhound/image/upload/v1660243064/BLACKHOUND/Mask_group_ur2p0o.png' alt=''/>
        <div className='login-container' data-aos="zoom-in" data-aos-duration="1000">
            <div className='box-login'>
                <p className='form-box-sub'>Bienvenido a la familia</p>
                <img src='https://res.cloudinary.com/blackhound/image/upload/v1663155194/BLACKHOUND/Iniciar%20Sesion/BlackHound_gdl2hr.svg' alt='' />
                <form className='fomulario-login'>
                    <input autoComplete='on' className='form-login-mail' placeholder='Email' type="email" name='nameuser' value={login.nameuser} onKeyPress={(e) => pressEnter(e)} onChange={handleLogin} />
                    <input autoComplete='on' className='form-login-clave' placeholder='Contraseña' type="password" name='clave' value={login.clave} onKeyPress={(e) => pressEnter(e)} onChange={handleLogin} />              
                    {errorLogin== true ? <p className='error'>Revise su usuario y/o contraseña</p>:""}
                    <Button className='form-login-enviar'  loading={loadingButton}  onClick={handleSubmitLogin} >INICIAR SESIÓN</Button>  
                </form>
                <div className='askForAccount'>
                  <p className='form-text'>¿Olvidaste tu contraseña?</p><Link className='form-registrarse-boton' to="/RecordarContrasena" onClick={ScrollToTop}>Restablecer Contraseña</Link>
                  <p className='form-text'>¿Todavía no tienes una cuenta?</p>
                  <Link className='form-registrarse-boton' onClick={ScrollToTop} to="/Registrarse">Regístrate Gratis</Link>
                </div>
            </div>
        </div>

      </div>
    </>
  )
}
