import React from 'react';
import TusAhorros from './Section/TusAhorros/TusAhorros'
import Caracteristicas from './Section/Caracteristicas/Caracteristicas'
import ProyectosRentables from './Section/ProyectosRentables/ProyectosRentables'
import TuFuturo from './Section/TuFuturo/TuFuturo'

export default function Home() {
  
  return (
    <>
      <TusAhorros/>
      <Caracteristicas/>
      <ProyectosRentables/>
      <TuFuturo/>
    </> 
  )
}
