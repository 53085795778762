import React from 'react'
import { Link } from 'react-router-dom';

//STYLE
import './textInvertir.css';

export default function TextInvertir() {

  return (
    <>
    <div className='como-invertir-text-container'>
        <div className='contTextInvertir' >

            {/* TEXT 1 */}
            <div className='contInvertir' data-aos="fade-in" data-aos-duration="1000">
              <div className="text-invertir-description">
                <h3 className='numbersMobile'> REGISTRO </h3>
                <p className='textInvertir'>
                  Creá tu cuenta completando tu información en el panel de registro.
                </p>
              </div>
              <div className="redBack">
                <img className='icon-invertir-huella' src="https://res.cloudinary.com/blackhound/image/upload/v1660757743/BLACKHOUND/ComoInvertir/blackhoundICON4_1_hvrdq0.svg" alt="blackhound" />
              </div>
            </div>

            {/* TEXT 2 */}
            <div className='contInvertir' data-aos="fade-in" data-aos-duration="1000">
              <div className="text-invertir-description">
                <h3 className='numbersMobile'> VALIDACIÓN </h3>
                <p className='textInvertir'>
                  Verificá tu usuario con el código que enviamos a tu mail. 
                  Luego, en la configuración de tu perfil, subí una foto del 
                  frente y dorso de tu DNI.
                </p>
              </div>
              <div className="redBack">
                <img className='icon-invertir-validacion' src="https://res.cloudinary.com/blackhound/image/upload/v1660762486/BLACKHOUND/ComoInvertir/validacion_ownbdo.svg" alt='' />
              </div>
            </div>

            {/* TEXT 3 */}
            <div className='contInvertir' data-aos="fade-in" data-aos-duration="1000">
              <div className="text-invertir-description">
                <h3 className='numbersMobile'> SELECCIÓN </h3>
                  <p className='textInvertir'>
                    Elegí el proyecto de inversión que se ajuste a tus posibilidades.  
                    Un miembro del equipo se contactará para asesorarte.
                  </p>
              </div>
              <div className="redBack">
                <img className='icon-invertir-seleccion' src="https://res.cloudinary.com/blackhound/image/upload/v1660762778/BLACKHOUND/ComoInvertir/keb212tkkhpozii4zthn.svg" alt="blackhound" />
              </div>
            </div>

            {/* TEXT 4 */}
            <div className='contInvertir' data-aos="fade-in" data-aos-duration="1000">
              <div className="text-invertir-description"> 
                <h3 className='numbersMobile'> APROBACIÓN </h3>
                <p className='textInvertir'>
                  Definido el proyecto, el tiempo y el monto de inversión, 
                  se emitirá una solicitud. Una vez aprobada, comienza el 
                  período de inversión.  
                </p>
              </div>
              <div className="redBack">
                <img className='icon-invertir-aprobacion' src="https://res.cloudinary.com/blackhound/image/upload/v1660762815/BLACKHOUND/ComoInvertir/Vector_gcbtwb.svg" alt="blackhound" />
              </div>
            </div>

        </div>
        {/* <div className='logoAtras'>
          <img className='fondoAguaNosotros' alt='fonto1' src='https://res.cloudinary.com/blackhound/image/upload/v1660243064/BLACKHOUND/Mask_group_ur2p0o.webp'/>
      </div> */}
    </div> 
    </>
  )
}
