import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import "./contacto.css";
import ModalGeneric from "../../../Components/ModalGeneric/ModalGeneric";

export default function Contacto() {
  const [open, setOpen] = useState();
  const [modal] = useState(
      {
          title: "Tu mensaje se envió con éxito.",
          content:"Nos pondremos en contacto en las próximas horas",
          button:"Aceptar",
          size: "xs"
      }
  )
  // ENVIAR FORMULARIO//
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    reValidateMode: "onChange",
  });
  const onSubmit = (data) => {
    //alert(JSON.stringify(data));
    axios
      .post(`https://blackhound.com.ar/server/public/ayuda`, data)
      .then(function (response) {
        setOpen(true)
        reset();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
    <ModalGeneric
      open={open}
      setOpen={setOpen}
      title={modal.title}
      content={modal.content}
      button={modal.button}
      size={modal.size}
    />
    <div className="containerContactoUsuario">
      <div className="containerTitleContacto">
        <h5 className="seccion-usuario-titulo">CONTACTO</h5>
      </div>
      <div
        className="formulario-contacto-container  sidebar-vista"
        id="formularioDeContacto"
      >
        <div className="formulario-contacto-vista pagina-contacto form-vista-usuario">
          <p className="titleContactUser">
            <b> Dejanos tu consulta,</b> <br></br>{" "}
            nos pondremos en contacto a la brevedad{" "}
          </p>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="formulario-en-columnas  "
          >
            <div className="fila-formulario">
              <div className="campo-formulario">
                <label htmlFor="nombreCompleto">Nombre y Apellido</label>
                <br />
                <input
                  autoComplete="off"
                  placeholder="Ingrese su nombre y apellido"
                  {...register("nombre", {
                    required: "Campo requerido",
                    maxLength: {
                      value: 40,
                      message: "Máximo 40 caracteres",
                      pattern:/^[a-zA-Z\s]*$/i
                    },
                  })}
                />
                {errors.nombre && (
                  <p className="error">{errors.nombre.message}</p>
                )}
                {errors.nombre?.type === 'pattern' && <p className='error'>Solo caracteres alfabéticos</p>}
              </div>
              <div className="campo-formulario">
                <label htmlFor="telefono  label-vista-usuario">Teléfono</label>
                <br />
                <input
                  autoComplete="off"
                  placeholder="Ingrese su teléfono"
                  type="number"
                  {...register("telefono", {
                    required: "Campo requerido",
                    maxLength: {
                      value: 20,
                      message: "Máximo 20 caracteres",
                    },
                  })}
                />

                {errors.telefono && (
                  <p className="error">{errors.telefono.message}</p>
                )}
              </div>
              <div className="campo-formulario">
                <label htmlFor="mail label-vista-usuario">Mail</label>
                <br />
                <input
                  autoComplete="off"
                  placeholder="Ingrese su mail"
                  type="text"
                  {...register("email", {
                    required: "Campo requerido",
                    pattern: {
                      value:
                      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
                      message: "Dirección de mail invalida",
                    },
                  })}
                />
                {errors.email && (
                  <p className="error">{errors.email.message}</p>
                )}
              </div>
            </div>
            <div className="fila-formulario">
              <div className="campo-formulario-mensaje-usuario">
                <label htmlFor="mensaje label-vista-usuario">Tu mensaje</label>
                <br />
                <textarea
                  placeholder="¿En qué podemos ayudarle?"
                  {...register("mensaje", {
                    required: "Campo requerido",
                    maxLength: {
                      value: 600,
                      message: "Máximo 600 caracteres",
                    },
                    minLength: {
                      value: 50,
                      message: "Minimo 50 caracteres",
                    },
                  })}
                />
                {errors.mensaje && (
                  <p className="error">{errors.mensaje.message}</p>
                )}
              </div>
            </div>
            <div className="formulario-contacto-footer vista-sidebar">
              <input
                className="boton-enviar-formulario-contacto"
                type="submit"
                value="Enviar"
              />
            </div>
          </form>
        </div>
      </div>
      </div>
    </>
  );
}
