
import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call} from'redux-saga/effects';
import { enviarMensaje } from'../api/enviarMensaje';
// import {  toast } from 'react-toastify';


export const watchEnviarMensaje = function * (){
    yield takeEvery(actionTypes.ENVIAR_MENSAJE, fetchEnviarMensaje)
}

function* fetchEnviarMensaje(action){
    try{
        const data = yield call(enviarMensaje, action.data)       
   
        if(data){
            
        }
    }catch(err){
        console.log(err)
        
    }
}

