import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call } from'redux-saga/effects';
import { apiCrearNotificacion } from "../api/crearNotificacion";

import 'react-toastify/dist/ReactToastify.css';

export const watchCrarNotificacion = function * (){
    yield takeEvery(actionTypes.SET_CREAR_NOTIFICACION, fetchCrearNotificacion)
}

function* fetchCrearNotificacion(action){
    try{
        const data = yield call(apiCrearNotificacion, action.data)
        if(data){
        }

    }catch(err){
        console.log(err)
    }
  
}

