import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';
import { apiDolar } from'../api/dolar';
import {setApiDolar} from '../Actions/dolar'

export const watchApiDolar = function * (){
    yield takeEvery(actionTypes.GET_DOLAR, fetchGetDolar)
}

function* fetchGetDolar(action){
    try{
        const data = yield call(apiDolar, action.data)
        if(data){
            yield put(setApiDolar(data))
        }
    }catch(err){
        console.log(err); 
    }
}

