import React from 'react'
import './masinformacion.css'

export default function MasInformacion() {
  return (
  <>
    <div className='nosotros-header'>
      <div className='nosotros-header-text-container' data-aos="zoom-in" data-aos-duration="1000">
          {/* <span className='nosotros-barra'> </span> */}
          <h5 className='nosotros-titulo'>¿QUERÉS MÁS INFORMACIÓN?</h5>
        </div>        
    </div> 
    {/*
    <div className='contacto-container'>
        <div className='contacto-info'>
             <div className='phone'>
                <img alt='phone' src='https://res.cloudinary.com/grupo-delsud/image/upload/v1647286065/Blackhound/phone_dg45tk.svg'/>
                <p className='contacto'>Contactate con soporte </p>
                <p className='contacto-rojo'>+54 221 4768804</p>    
            </div> 
            <div className='mail'>
                <img alt='mail' src='https://res.cloudinary.com/grupo-delsud/image/upload/v1647286064/Blackhound/mail_njvpu7.svg'/>
                <p className='contacto'>Podes mandarnos un mail </p>
                <p className='contacto-rojo'>info@blackhound.com.ar</p>    
            </div>         
        </div>
    </div>  

   */}

    </>
  )
}
