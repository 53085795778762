import React from 'react'
import { Modal, Button } from 'rsuite';

export default function ModalContrasenaModificada({open, setOpen}) {
    
    
    const handleClose = () => {
      setOpen(false)
    };
  return (
    
    <>
    <Modal className='modalContrasenaModificada' size="xs" open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Su contraseña a sido modificada</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="primary">
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
