import React,{useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

//ACTION
import { setEstadoCliente } from '../../../../../Actions/estadoCliente';
import {enviarMailCodigo} from '../../../../../Actions/enviarMailCodigo'
import { sendMailSolicitud } from '../../../../../Actions/enviarMailSolicitud';
import { getlistaClientesRegistrados } from '../../../../../Actions/listaClientesRegistrados';
import { setEstadoSolicitud } from '../../../../../Actions/estadoSolicitud';
import {Modal} from 'rsuite';
import { getlistaSolicitudesRegistradas } from '../../../../../Actions/listaSolicitudesRegistradas';

const ModalAprobado = (props) => {
    const modalAprobado = props.modalAprobado
    const setModalAprobado = props.setModalAprobado
    const idSolicitud = props.idSolicitud
    const email = props.email
    const [backdrop, setBackdrop] = useState('static');
    const handleClose = () => setModalAprobado(false);
    const dispatch = useDispatch();

    const [activePage, setActivePage] = useState(1)
    const [estadoFiltro, setEstadoFiltro] = useState(4);
    const [busqueda, setBusqueda] = useState({
        dataBase: ""
    })



    const handleEnviarMail = () => {
        const dataCliente = {
            id: idSolicitud,
            idEstado: 2,
        }
        const data = {
            busqueda: busqueda.dataBase,
            categoria: estadoFiltro,
            page: activePage,
        }
        const mailSolicitudAprobada = {
            email: email
        }
        if(dataCliente && data != null) {
            dispatch(setEstadoSolicitud(dataCliente));
            setTimeout(() => {
                dispatch(getlistaSolicitudesRegistradas(data));
                dispatch(sendMailSolicitud(JSON.stringify(mailSolicitudAprobada)));
            }, 1000);
        }
        handleClose(); 
    }

    
    return (
        <>
            {(modalAprobado) &&
                <div className='modalEnvioDoc'>
                    <Modal className='proyecto-modal infoModal' backdrop={backdrop}  open={modalAprobado} size="md" >
                    <Modal.Header className='headerModal'>
                        <Modal.Title className='headerModal'>
                            <div>
                                <h4 style={{margin: 'auto', color: 'white', textAlign: 'center'}}> ¡Mensaje Importante! </h4>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='textModal'>
                            ¿Desea realmente aprobar este usuario? <br></br>
                            Seleccione "Si" para aprobar esta cuenta <br></br>
                            o seleccione "No" para cancelar.                          
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='contFooter'>
                        <button className='buttonModal' onClick={handleEnviarMail}> Si </button>
                        <span style={{padding: '10px'}}></span>
                        <button className='buttonModal' onClick={handleClose}> No </button>
                    </Modal.Footer>
                    </Modal>
                </div>  
            }
        </>
    );
}

export default ModalAprobado;
