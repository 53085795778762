import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call,put } from'redux-saga/effects';
import { catagorias } from'../api/categoriaProyecto';
import { setCategoriaProyectos } from "../Actions/categoriasProyecto";

export const watchCategoriaProyecto = function * (){
    yield takeEvery(actionTypes.GET_CATEGORIA_PROYECTOS, fetchCategoriaProyecto)
}

function* fetchCategoriaProyecto(action){
    try{
        const data = yield call(catagorias, action.data)
        if(data){
            yield put(setCategoriaProyectos(data))
            //MODAL USUARIO REGISTRADO EXITOSO
        }
    }catch(err){
        console.log(err)
    }
}