import React, { useEffect, useState } from "react";
import Calendario from '@ericz1803/react-google-calendar';
import { css } from "@emotion/react";
import { useSpring, animated } from 'react-spring';
import { Modal, Loader } from 'rsuite';

//REDUX
import { useSelector, useDispatch } from 'react-redux';
import { getlistaAsesores } from '../../../Actions/listaAsesores';
import { getTurnoAsesor } from '../../../Actions/turnoAsesor';
import { guardarTurno } from '../../../Actions/turno';
import { getDatosUsuario } from "../../../Actions/datosUsuario";
import { getlistaClientesRegistrados } from "../../../Actions/listaClientesRegistrados";


const API_KEY = "AIzaSyBBZlmu6qXXJxfydbcsUWULcfNYxV_nW-Y";

export default function Turnos () {
  const [events, setEvents] = useState(null);
  const [modalCalendar, setModalCalendar] = useState(null);

  var gapi = window.gapi

  var CLIENT_ID = "524545613071-7aasnlk9alin5q82o0h041pdofmj6pm3.apps.googleusercontent.com"
  var API_KEY = "AIzaSyACvZbhyRlUCDsNiMyJBQ5SeBAEtc6WCFc"
  var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
  var SCOPES = "https://www.googleapis.com/auth/calendar.events"

  const [evento, setEvento] = useState({
    tittle: '',
    description: '',
    dateTimeInicio: '',
    dateTimeFin: '',
    emailAsesor: '',
  })
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setEvento({
      tittle: '',
      description: '',
      dateTimeInicio: '',
      dateTimeFin: '',
      emailAsesor: '',
    })
    setOpen(false)
  };
  const [backdrop, setBackdrop] = useState('static');
  const [asesor, setAsesor] = useState({
    cantAsesor: 0,
  });
  const [cliente, setCliente] = useState({
    cantCliente: 0,
  });
  // const [mailAsesor, setMailAsesor] = useState(null);

  const onChangeAsesor = (event) => {
    setAsesor({
      ...asesor,
      [event.target.name]: event.target.value
    });
  }

  const onChangeCliente = (event) => {
    setCliente({
      ...cliente,
      [event.target.name]: event.target.value
    });
  }

  const [activePage, setActivePage] = useState(1);
  const [estadoFiltro, setEstadoFiltro] = useState(2);
  const [busqueda, setBusqueda] = useState({
    dataBase: null,
  })
  const dispatch = useDispatch();
  const listaTotalAsesores = useSelector(state => state.reducerListaAsesores.data);
  const datosUsuarioLogeado = useSelector((state) => state.reducerUsuarioDatos.data);//OBTENER DATOS DEL USUARIO
  const tablaListaClientes = useSelector(state => state.reducerlistaClientesRegistrados.data);


  useEffect(() => {
    const data = {
      busqueda: busqueda.dataBase,
      estado: estadoFiltro,
      page: activePage,
    }
    dispatch(getlistaAsesores(data));
    dispatch(getlistaClientesRegistrados(data))
  }, [activePage, busqueda, estadoFiltro])


  if (listaTotalAsesores != null) {
    var selectAsesores = [];
    const asesores = listaTotalAsesores.data;
    for (var i = 0; i < asesores.length; i++) {
      selectAsesores.push(
        <>
          <option value={[i]}> {listaTotalAsesores.data[i].nombre + ' ' + listaTotalAsesores.data[i].apellido} </option>
        </>
      )
    }
  }

  if (listaTotalAsesores != null) {
    var selectEmailAsesores = [];
    selectEmailAsesores.push(
      <>
        <option style={{ color: 'white' }}> {listaTotalAsesores.data[asesor.cantAsesor].emailAsesor} </option>
      </>
    )
  }

  if (listaTotalAsesores != null) {
    var idAsesores = [];
    idAsesores.push(
      <>
        <p style={{ color: 'white' }}> {listaTotalAsesores.data[asesor.cantAsesor].idUser} </p>
      </>
    )
  }

 const onChangeEvent = (event) => {
    setEvento({
      ...evento,
      [event.target.name]: event.target.value
    });
  }

  let calendars = [
    { calendarId: "blackhoundelsud@gmail.com", color: "#96040E" },
    // {calendarId: "mariosoad60@gmail.com", color: "#96040E"}
  ];

  let styles = {
    calendar: { borderWidth: "3px" },
    today: css` color: red; border: 1px solid red;`
  }

  if(tablaListaClientes != null){
    var arrayListaClientes = [];
    const listaClientes = tablaListaClientes.data;
    for (var i = 0; i < listaClientes.length; i++) {
      arrayListaClientes.push(
        <>
          <option value={[i]}>{listaClientes[i].nombre + ' ' + listaClientes[i].apellido}</option>
        </>
      );
    }
  }

  const handleClick = () => {
    gapi.load('client:auth2', () => {

      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
        plugin_name: 'PLUGIN',
      })

      gapi.auth2.getAuthInstance().signIn()
        .then(() => {

          //SETEAR CAMPOS DEL EVENTO
          var event = {
            'summary': evento.tittle,
            'location': 'Av. 7 n840, B1900 La Plata, Provincia de Buenos Aires',
            'description': evento.description,
            'start': {
              'dateTime': evento.dateTimeInicio + ':00',
              'timeZone': 'America/Argentina/Buenos_Aires'
            },
            'end': {
              'dateTime': evento.dateTimeInicio + ':00',
              'timeZone': 'America/Argentina/Buenos_Aires'
            },
            'recurrence': [
              'RRULE:FREQ=DAILY;COUNT=1'
            ],
            'attendees': [
              { 'email': listaTotalAsesores.data[asesor.cantAsesor].emailAsesor },
              // { 'email': 'sbrin@example.com' }
            ],
            'reminders': {
              'useDefault': false,
              'overrides': [
                { 'method': 'email', 'minutes': 24 * 60 },
                { 'method': 'popup', 'minutes': 10 }
              ]
            }
          }
          var request = gapi.client.calendar.events.insert({
            'calendarId': 'primary',
            'resource': event,
          })
          request.execute(event => {
            if (event.status == 'confirmed') {

              if(datosUsuarioLogeado !=null){
                dispatch(getTurnoAsesor(datosUsuarioLogeado.id));
              }
              const nuevoTurnoAsesor = {
                fechaTurno: evento.dateTimeInicio,
                idUser: tablaListaClientes.data[cliente.cantCliente].idUser,
              }
              dispatch(guardarTurno(nuevoTurnoAsesor));

            }
          })

        })

    })
    
  }
  
  const [loading, setLoading] = useState(true); {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }

  const fadeOut = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 1500,
}) 

  return (
    <>
    {loading ?
        <div className='loading'>
            <Loader className='loadingColor' size="lg" />
        </div>
      :
      <animated.div style={fadeOut}>
        
       <div>
            <h5 className='user-wellcome'>TURNOS</h5>
        </div>
        <div className="categoria-titulo">
          <div className="circle-categoria"> </div> 
          <div className="proyecto-titulo-dash">TURNOS ASIGNADOS</div> 
          {/* DEJAR EL TITULO ESE DE TURNOS ASIGNADOS */}
        </div>
        {/* <div className="margin-boton">
      <button className="button-add" onClick={handleOpen}>Agregar nuevo turno</button>
      </div> */}

      <Calendario apiKey={API_KEY} calendars={calendars} language='ES' styles={styles} />
      
      {/* <div className='modalEnvioDoc'>
              <Modal className='proyecto-modal infoModal' backdrop={backdrop} open={open} size="md" onClose={handleClose} >
                <Modal.Header className='headerModal'>
                  <Modal.Title className='headerModal'>
                    <div>
                      <h4 style={{ margin: 'auto', color: 'white', textAlign: 'center' }}> Agregar un nuevo turno </h4>
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="crear-proyecto-formulario">
                    <div className="contForm">
                      <div className="crear-proyecto-form add-evento">
                        <div className="crear-proyecto-campo">
                          <input className='input-proyecto' autoComplete='off' required placeholder="Título" value={evento.tittle} name="tittle" type="text" onChange={onChangeEvent} />
                        </div>
                        <div className="crear-proyecto-campo">
                          <input className='input-proyecto' autoComplete='off' required placeholder="Descripción" value={evento.description} name="description" type="text" onChange={onChangeEvent} />
                        </div>
                      </div>
                      <div className="crear-proyecto-form add-evento">
                        <div className="crear-proyecto-campo">
                          <input className='input-proyecto' autoComplete='off' value={evento.dateTimeInicio} name="dateTimeInicio" type="datetime-local" onChange={onChangeEvent} />
                        </div>
                        <select className='select-proyecto' value={cliente.cantCliente}  name="cantCliente" onChange={onChangeCliente} >
                          <option selected="true" disabled="disabled">
                            Seleccione un cliente
                          </option>
                          {arrayListaClientes}
                        </select>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className='contFooter'>
                  <button className='buttonModal' onClick={handleClick}> Agregar turno </button>
                  <button className='buttonModal' onClick={handleClose}> Cerrar </button>
                </Modal.Footer>
              </Modal>
            </div> */}
      
      </animated.div>
    }
    </>
  );
};
