import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';
import { setEstadoClientes } from'../api/estadoCliente';

export const watchEstadoCliente = function * (){
    yield takeEvery(actionTypes.SET_ESTADO_CLIENTE, fetchEstadoCliente)
}

function* fetchEstadoCliente(action){
    try{
        const data = yield call(setEstadoClientes, action.data)
        if(data){
            //yield put(listaClientesRegistrados(data))
        }
    }catch(err){
        console.log(err)
    }
}