import { actionTypes } from "../Constantes/ActionTypes";
import { store } from "../store/index";

function reducerNotificacionesUsuario(state = store.notificacionesUsuario, action) {
    switch (action.type) {
        case actionTypes.SET_NOTIFICACIONES_USUARIO:
            let notifisUser = action.data
            return Object.assign({}, state, {
                data: notifisUser,
                contador: notifisUser.lenght
            })

        case actionTypes.CONTADOR_NOTIFICACION:
            let contadorNoti = state.contador
            return Object.assign({}, state, {
                contador: contadorNoti++,
            })

        default:
            return state
    }
}

export default reducerNotificacionesUsuario;