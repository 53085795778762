import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';
import { turnosAsesor } from "../api/turnosAsesor";
import { setTurnoAsesor } from "../Actions/turnoAsesor";

export const watchTurnosAsesor = function * (){
    yield takeEvery(actionTypes.GET_TURNO_ASESOR, fetchTurnosAsesor)
}

function* fetchTurnosAsesor(action){
    try{
        const data = yield call(turnosAsesor, action.data)
        if(data){
            yield put(setTurnoAsesor(data))
        }
    }catch(err){
        console.log(err)
    }
}