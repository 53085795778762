import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getDatosUsuario } from "../../../../Actions/datosUsuario";
import Button from 'rsuite/Button';
import { useSpring, animated } from "react-spring";

import axios from "axios";
// import { toast } from "react-toastify";

//Styles
import "./VerificacionRegistro.css";

//ACTION
import { enviarMailCodigo } from "../../../../Actions/enviarMailCodigo";
import { AgregarAsesorAUnUsuario } from "../../../../Actions/agregarAsesorAUnUsuario";

export default function VerificacionRegistro(props) {
  const data = props.data;
  const [validacionCodigo, setValidacionCodigo] = useState(null);
  const [loadingButton, setLoadingButton] = useState(false);
  const [timerId, setTimerId] = useState();
  const [mostrarTiempo, setMostrarTiempo] = useState(true);
  const [timer, setTimer] = useState(30);

  const dispatch = useDispatch();

  const [codigo, setCodigo] = useState({
    codigo1: "",
    codigo2: "",
    codigo3: "",
    codigo4: "",
    codigo5: "",
  });

  const handleCodigo = (e) => {
    const { name, value } = e.target;

    setCodigo((prev) => ({
      ...prev,
      [name]: value,
    }));
    const codigo = e.target.form;



    if (name === "codigo1" && value.length >= 1) {
      // const form = e.target.form;
      const index = [...codigo].indexOf(e.target);
      codigo.elements[index + 1].focus();
      // e.preventDefault();
    }
    if (name === "codigo2" && value.length >= 1) {
      // const form = e.target.form;
      const index = [...codigo].indexOf(e.target);
      codigo.elements[index + 1].focus();
      // e.preventDefault();
    }
    if (name === "codigo3" && value.length >= 1) {
      // const form = e.target.form;
      const index = [...codigo].indexOf(e.target);
      codigo.elements[index + 1].focus();
      // e.preventDefault();
    }
    if (name === "codigo4" && value.length >= 1) {
      // const form = e.target.form;
      const index = [...codigo].indexOf(e.target);
      codigo.elements[index + 1].focus();
      // e.preventDefault();
    }
    if (name === "codigo5" && value.length >= 1) {
      // const form = e.target.form;
      const index = [...codigo].indexOf(e.target);
      codigo.elements[index + 1].focus();
      // e.preventDefault();
    }
  };

  // useEffect(() => {
  //   if (validacionCodigo != null) {
  //     if (validacionCodigo.mensaje == "Codigo Correcto") {
  //       // dispatch(enviarMailCodigo());
  //       // toast.success("¡Código ingresado correctamente!", {});
  //     }
  //     if (validacionCodigo.mensaje == "Código Incorrecto") {
  //       // toast.error("Código incorrecto, revise los campos", {});
  //     }
  //   }
  // }, [validacionCodigo]);

  const onSubmit = () => {
    const infoData = {
      id: data.id
    }
    setLoadingButton(true);
    const token = localStorage.getItem("token");
    const enviarCodigoMailDato = {
      codigoMail:
        String(codigo.codigo1) +
        String(codigo.codigo2) +
        String(codigo.codigo3) +
        String(codigo.codigo4) +
        String(codigo.codigo5),
    };
    axios
      .post(
        `https://www.blackhound.com.ar/server/public/api/setCodigoUser`,
        enviarCodigoMailDato,
        { headers: { authorization: `Bearer ` + token } }
      )
      .then(function (response) {           
        setValidacionCodigo(response.data); 
        dispatch(getDatosUsuario());
        dispatch(AgregarAsesorAUnUsuario(infoData))
      })
      .catch(function (error) {
        console.log(error)
      });
      setTimeout(() => {
        setLoadingButton(false);
    }, 2500);    
    
  };

  const reenviarCodigo = () => {
    setMostrarTiempo(false);
    onStart();
    const token = localStorage.getItem("token");
    axios
      .get(
        `https://www.blackhound.com.ar/server/public/api/correoReenvioCodigo`,
        { headers: { authorization: `Bearer ` + token } }
      )
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  };

  const fadeOut = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 1500,
  });

  const onStart = () => {
    
    setTimerId(
      setInterval(() => {        
        setTimer(state => state - 1);
       }, 1000)
    ); 
  };

  useEffect(() => {
    if (timer == 0) {
      clearInterval(timerId);
      setMostrarTiempo(true);
      setTimer(30);
    }
  }, [timer]);

  return (
    <>
    <animated.div style={fadeOut}>
    <div className="verificacion-registro-container">
      <div className="containerSectionUserTitle">
        <h5 className="seccion-usuario-titulo">
          BIENVENIDO <span>{data.nombre}</span>
        </h5>
      </div>
      <div className="validar-mail-box">
        <p className="validar-mail-box-titulo" >VERIFICACIÓN DE REGISTRO</p>
        <p className="text-validar-mail-box">
          Te hemos enviado un <span>código</span> a la casilla de correo
          ingresada anteriormente.
          <br />
          Para completar la verificación, por favor{" "}
          <span className="text-validar-mail-box" >ingresa el código de activación</span> de 5 dígitos enviado.
        </p>
        <p className="text-validar-mail-box">¿No ves el mensaje? No te olvides de revisar la bandeja de spam</p>
        <div className="volver-enviar-codigo-section">
          <p className="text-validar-mail-box"> ¿No llego el código?  </p>       
          <input
            className={`${mostrarTiempo==false ? "desactivar input-enviar" : "input-enviar"}`}
            type="button"
            onClick={reenviarCodigo}
            value="Volver a enviar"
          />
          {mostrarTiempo==false ?  <p> {timer} </p> : ""}   
        </div>

        <form className="formulario-validar-mail">
          <div className="fomulario-campo">
            <input
              autoComplete="off"
              onChange={handleCodigo}
              name="codigo1"
              className="campo-codigo"
              pattern="[0-9]"
              type="text"
              maxlength="1"
              oninput="this.value=this.value.replace(/[^0-9]/g,'');"
              required
            />
            <input
              onChange={handleCodigo}
              name="codigo2"
              className="campo-codigo"
              pattern="[0-9]"
              type="text"
              maxlength="1"
              oninput="this.value=this.value.replace(/[^0-9]/g,'');"
              required
              autoComplete="off"
            />
            <input
              onChange={handleCodigo}
              name="codigo3"
              className="campo-codigo"
              pattern="[0-9]"
              type="text"
              maxlength="1"
              oninput="this.value=this.value.replace(/[^0-9]/g,'');"
              required
              autoComplete="off"
            />
            <input
              onChange={handleCodigo}
              name="codigo4"
              className="campo-codigo"
              pattern="[0-9]"
              type="text"
              maxlength="1"
              oninput="this.value=this.value.replace(/[^0-9]/g,'');"
              required
              autoComplete="off"
            />
            <input
              onChange={handleCodigo}
              name="codigo5"
              className="campo-codigo"
              pattern="[0-9]"
              type="text"
              maxlength="1"
              oninput="this.value=this.value.replace(/[^0-9]/g,'');"
              required
              autoComplete="off"
            />
          </div>
          {validacionCodigo != null && validacionCodigo.mensaje == "Código Incorrecto" ? <p className="error"> El código ingresado es inválido</p> : ""}
          <Button
            className="boton-enviar-codigo"
            onClick={onSubmit}
            loading={loadingButton}
          >Ingresá el código</Button>
        </form>
      </div>
    </div>
    </animated.div>
    </>
  );
}
