import { actionTypes } from "../Constantes/ActionTypes";
import { store } from "../store/index";

function reducerListaMonedas (state = store.monedita, action){
    switch(action.type){
        case actionTypes.SET_LISTA_DE_MONEDAS:
            let ListaMonedas = action.data
            return Object.assign({}, state, {
                data: ListaMonedas,
            })
            default:
                return state
    }
}

export default reducerListaMonedas;