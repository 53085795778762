import React, { useState, useEffect } from 'react';
import { Container, Header, Navbar, Content, Sidebar, Sidenav, Nav, Menu } from 'rsuite';
import {
  NavLink,
  Routes,
  Route,
} from "react-router-dom";
import { useSpring, animated } from 'react-spring';
// IMPORTAR VISTAR
import Dashboard from './Vistas/Dashboard/Dashboard'
import Clientes from './Vistas/Clientes/Clientes';
import Inversiones from './Vistas/Inversiones/Activas';
import Pendientes from './Vistas/Inversiones/Pendientes/Pendientes';
import Citados from './Vistas/Usuarios/Citados/Citados';
import Rechazados from './Vistas/Usuarios/Rechazados/Rechazados';
import Faqs from './Vistas/ManualVenta/FAQs/Faqs';
import Proyectos from './Vistas/ManualVenta/Proyectos/Proyectos';
import Glosario from './Vistas/ManualVenta/Glosario/Glosario';
import Turnos from './Vistas/Turnos/Turnos'
import ChatCliente from '../Components/Chat/ChatClient';
import CanalesChat from './Vistas/canalesChat/canalesChat';
import UsuariosAsesor from './Vistas/Usuarios/Pendientes/Pendientes';
//ACCIONES Y REDUX
import { useSelector, useDispatch } from 'react-redux';
import { getListaMonedas } from "./../Actions/listaMonedas";
import { getlistaClientesRegistrados } from '../Actions/listaClientesRegistrados';
import { getChat } from '../Actions/obtenerChat';
import { allConversacionesAsesor } from '../Actions/obtenerChat';

//CANCELAR VISTA MOBILE
import { useMediaQuery } from 'react-responsive'
import NoExisteMobile from '../Admin/Vistas/NoExisteMobile'

import './homeAsesor.css'
import 'rsuite/dist/rsuite.min.css';
import ChatDefinitivo from '../Components/ChatDefinitivo/ChatDefinitivo';


export default function HomeAsesor() {
  const [chat, setChat] = useState("Sin Mensajes");
  const [activePage, setActivePage] = useState(1);
  const [estadoFiltro, setEstadoFiltro] = useState(2);
  const [busqueda, setBusqueda] = useState({
    dataBase: null,
  })

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getListaMonedas())
  }, [])

  useEffect(() => {
    dispatch(allConversacionesAsesor())
  }, [chat])

  useEffect(() => {
    const data = {
      busqueda: busqueda.dataBase,
      estado: estadoFiltro,
      page: activePage,
    }
    dispatch(getlistaClientesRegistrados(data));
  }, [activePage, busqueda, estadoFiltro])


  const vistaMobile = useMediaQuery({
    query: '(min-width: 768px)'
  })

  const guardarIdChat = useSelector(
    (state) => state.reducerGuardarIdChat.data
  );

  const tablaListaClientes = useSelector(state => state.reducerlistaClientesRegistrados.data);
  const datosUsuarioLogeado = useSelector((state) => state.reducerUsuarioDatos.data);//OBTENER DATOS DEL USUARIO

  const fadeOut = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 1500,
  });

  useEffect(() => {
    if (tablaListaClientes != null) {
      dispatch(getChat({ userReceptor: guardarIdChat }));//35 DEBE SER UN ID DEL CLIENTE
    }
  }, [chat]);

  useEffect(() => {
    if (datosUsuarioLogeado && tablaListaClientes != null) {
      const urlAsesor = new URL("https://mercure.blackhound.com.ar/.well-known/mercure");
      dispatch(getChat({ userReceptor: guardarIdChat }));//35 DEBE SER UN ID DEL CLIENTE
      urlAsesor.searchParams.append(
        "topic",
        "https://blackhoundcomunication/chat/" + datosUsuarioLogeado.id + guardarIdChat //35 DEBE SER UN ID DEL CLIENTE
      );
      const eventSourceUser = new EventSource(urlAsesor);
      eventSourceUser.onmessage = function ({ data }) {
        setChat(JSON.parse(data).message);
      };
    }
  }, [datosUsuarioLogeado, tablaListaClientes]);

  return (
    <>
    <animated.div style={fadeOut}>
      {vistaMobile ?
        <div className='containerAdmin'>
          <div className='sideBarAdmin'>
            <div className='childSidebar '>

              <NavLink className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to="dashboard">
                <span><img className='navAdmin' alt='Informacio-general-icon' src='https://res.cloudinary.com/blackhound/image/upload/v1660658186/BLACKHOUND/logoDashx2_wufruo.webp' /></span>
                <p>Dashboard</p>
              </NavLink>

              <Sidenav>
                <Nav>
                  <Nav.Menu
                    icon={<img width="30px" src='https://res.cloudinary.com/blackhound/image/upload/v1661342486/BLACKHOUND/sideNavBar/Asesor/Solicitudes_r2dv0x.svg' />}
                    trigger="hover"
                    title={<p className='navMenu'>Usuarios</p>}
                    placement="rightStart"
                    style={{ fontSize: '18px', fontFamily: "Poppins" }}
                  >
                    <NavLink className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to="usuarios">
                        <span className='nav-icon-trans'><img className='navAdmin' alt='Informacio-general-icon' src='https://res.cloudinary.com/grupo-delsud/image/upload/v1647895574/Blackhound/InformacionGeneral_hsq1qp.svg' /></span>
                        <p>Pendientes</p>
                    </NavLink>
                    <NavLink className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to="citados">
                      <span className='nav-icon-trans'><img className='navAdmin' alt='Informacio-general-icon' src='https://res.cloudinary.com/grupo-delsud/image/upload/v1647895574/Blackhound/InformacionGeneral_hsq1qp.svg' /></span>
                      <p>Citados</p>
                    </NavLink>
                    <NavLink className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to="rechazados">
                      <span className='nav-icon-trans'><img className='navAdmin' alt='Informacio-general-icon' src='https://res.cloudinary.com/grupo-delsud/image/upload/v1647895574/Blackhound/InformacionGeneral_hsq1qp.svg' /></span>
                      <p>Rechazados</p>
                    </NavLink>

                  </Nav.Menu>

                  <Nav.Menu
                      icon={<img width="30px"  alt='Informacio-general-icon' src='https://res.cloudinary.com/blackhound/image/upload/v1661342537/BLACKHOUND/sideNavBar/Asesor/Inversiones_nmstkj.svg' />}
                      trigger="hover"
                      title={<p className='navMenu'>Inversiones</p>}
                      placement="rightStart"
                      style={{ fontSize: '18px', fontFamily: "Poppins" }}
                  >
                    <NavLink className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to="pendientes">
                      <span className='nav-icon-trans'><img className='navAdmin' alt='Informacio-general-icon' src='https://res.cloudinary.com/grupo-delsud/image/upload/v1647895574/Blackhound/InformacionGeneral_hsq1qp.svg' /></span>
                      <p>Pendientes</p>
                    </NavLink>
                    <NavLink className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to="inversiones">
                      <span className='nav-icon-trans'><img className='navAdmin' alt='Informacio-general-icon' src='https://res.cloudinary.com/grupo-delsud/image/upload/v1647895574/Blackhound/InformacionGeneral_hsq1qp.svg' /></span>
                      <p>Activas</p>
                    </NavLink>
                  </Nav.Menu>
                  
                  <NavLink className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to="clientes">
                    <span><img className='navAdmin' alt='Informacio-general-icon' src='https://res.cloudinary.com/blackhound/image/upload/v1660658067/BLACKHOUND/logoClientesx2_xlvhqg.webp' /></span>
                    <p>Clientes</p>
                  </NavLink>

                  <Nav.Menu
                    icon={<img width="30px" src='https://res.cloudinary.com/blackhound/image/upload/v1661342595/BLACKHOUND/sideNavBar/Asesor/manualDeVenta_maz8b7.svg' alt='' />}
                    trigger="hover"
                    title={<p className='navMenu'>Manual de venta</p>}
                    placement="rightStart"
                    style={{ fontSize: '18px', fontFamily: "Poppins" }}
                  >
                    <NavLink className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to="faqs">
                      <span className='nav-icon-trans'><img className='navAdmin' alt='Informacio-general-icon' src='https://res.cloudinary.com/grupo-delsud/image/upload/v1647895574/Blackhound/InformacionGeneral_hsq1qp.svg' /></span>
                      <p>FAQ's</p>
                    </NavLink>
                    <NavLink className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to="proyectos">
                      <span className='nav-icon-trans'><img className='navAdmin' alt='Informacio-general-icon' src='https://res.cloudinary.com/grupo-delsud/image/upload/v1647895574/Blackhound/InformacionGeneral_hsq1qp.svg' /></span>
                      <p>Proyectos</p>
                    </NavLink>
                    <NavLink className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to="glosario">
                      <span className='nav-icon-trans'><img className='navAdmin' alt='Informacio-general-icon' src='https://res.cloudinary.com/grupo-delsud/image/upload/v1647895574/Blackhound/InformacionGeneral_hsq1qp.svg' /></span>
                      <p>Glosario</p>
                    </NavLink>
                  </Nav.Menu>

                  <NavLink className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to="turnos">
                    <span><img className='navAdmin' alt='Crear-Proyecto-icon' src='https://res.cloudinary.com/blackhound/image/upload/v1660658126/BLACKHOUND/logoTurnosx2_oaj6h6.webp' /></span>
                    <p>Turnos</p>
                  </NavLink>
                </Nav>
              </Sidenav>

            </div>
          </div>

          <div className='routesAdmin'>
            <Routes path="/*">
              <Route index element={<Dashboard />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="pendientes" element={<Pendientes />} />
              <Route path="citados" element={<Citados />} />
              <Route path="rechazados" element={<Rechazados />} />
              <Route path="usuarios" element={<UsuariosAsesor />} />
              <Route path="clientes" element={<Clientes />} />
              <Route path="inversiones" element={<Inversiones />} />
              <Route path="faqs" element={<Faqs />} />
              <Route path="proyectos" element={<Proyectos />} />
              <Route path="glosario" element={<Glosario />} />
              <Route path="turnos" element={<Turnos />} />
              <Route path="canales" element={<CanalesChat />} />
            </Routes>
          </div>
          <div className='boxPositionAdmin'>
            <img alt='gotaDeAgua' src='https://res.cloudinary.com/blackhound/image/upload/v1660917252/BLACKHOUND/gotitadeaguaVistas_v2zou6.png' />
          </div>    
          <ChatDefinitivo/>      
        </div>


        :
        <NoExisteMobile />

      }
      </animated.div>
    </>
  )
}




