import React, { useRef, useState,useEffect } from "react";
import ProyectoModal from "./ProyectoModal/ProyectoModal";
import InfoProyecto from '../../../Pages/Proyectos/Proyecto/InfoProyecto/InfoProyecto';
import { BsInfoCircleFill } from 'react-icons/bs';
import { ImCross } from 'react-icons/im';
import { BsCheckLg } from 'react-icons/bs';
import { HiOutlineClock } from 'react-icons/hi';

export default function MisInversionesProyecto(data) {
    const [abrirModal,setAbrirModal]= useState(null);
    const [abrirModalInfo,setAbrirModalInfo]= useState(null);
 
    
      let estilo="";
      let icono="";

      if(data.data.estadoSolicitud== "Rechazado" ){
        estilo = "rechazado";
        icono = <ImCross/>;
      }
      if(data.data.estadoSolicitud== "Aprobado" ){
        estilo = "aprobado";
        icono = <BsCheckLg/>;
      }
      if(data.data.estadoSolicitud== "Revision" ){
        estilo = "pendiente";
        icono = <HiOutlineClock/>;
      }
    

    const handleProyectoModal=()=> {
      if(abrirModal== true){
        setAbrirModal(false);
      }else{
        setAbrirModal(!abrirModal);
      }
      
    }

    const handleProyectoModalInfo=()=> {
      if(abrirModalInfo== true){
        setAbrirModalInfo(false);
      }else{
        setAbrirModalInfo(!abrirModalInfo);
      }
      
    }
    useEffect(()=>{
      if(abrirModal== false){
        setAbrirModal(!abrirModal);
      }
      if(abrirModalInfo== false){
        setAbrirModalInfo(!abrirModalInfo);
      }
    },[abrirModal,abrirModalInfo]);


  return (
  <>
    <div className={`proyecto-caja  ${estilo}`}>
                <div className="proyecto-imagen"><img src={"https://www.blackhound.com.ar/server/public/getImgId/"+data.data.idImagenProyecto }/></div>
                <p className="proyecto-titulo">{data.data.categoria}</p>
                <div className="info-proyecto" onClick={handleProyectoModalInfo}><BsInfoCircleFill/></div>
                <div className="proyectos-descripcion">
                    <div className="proyectos-descripcion-item">
                        <img src="https://res.cloudinary.com/grupo-delsud/image/upload/v1648564021/Blackhound/tna_nhms4e.svg"/>
                        <p className="proyectos-iconos-titulo">TNA</p>
                        <p className="proyectos-iconos-desc">{data.data.tna}</p>
                    </div>
                    <div className="proyectos-descripcion-item">
                        <img src="https://res.cloudinary.com/blackhound/image/upload/v1661865313/BLACKHOUND/Proyectos/iconMoneda_hmcbjw.svg"/>
                        <p className="proyectos-iconos-titulo">Moneda</p>
                        <p className="proyectos-iconos-desc">{data.data.moneda}</p>
                    </div>
                    <div className="proyectos-descripcion-item">
                        <img src="https://res.cloudinary.com/blackhound/image/upload/v1661865313/BLACKHOUND/Proyectos/montoMinimo_j0ym0l.svg"/>
                        <p className="proyectos-iconos-titulo">Monto mínimo </p>
                        <p className="proyectos-iconos-desc">{data.data.montoMinimo}</p>
                    </div>
                    <div className="proyectos-descripcion-item">
                        <img src="https://res.cloudinary.com/grupo-delsud/image/upload/v1648564021/Blackhound/calendario_bulhhb.svg"/>
                        <p className="proyectos-iconos-titulo">Plazo mínimo</p>
                        <p className="proyectos-iconos-desc">{data.data.plazo}</p>
                    </div>                    
                </div>

                {/* <div className="icono-proyecto">{icono}</div>
                <button className="solicitar-proyecto" onClick={handleProyectoModal}>Ver Inversion</button> */}
    </div>
    <ProyectoModal  abrirModal={abrirModal} datitaModalInfo={data.data.idProyecto}/>
    <InfoProyecto  abrirModalInfo={abrirModalInfo} dataModalInfo={data.data}/>
 </>
  )
}
