import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive"
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "../../FichasTecnicas/fichasTecnicas.css";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const ScrollToTop = () => {
  window.scrollTo({
      top: 0,
      behavior: 'smooth'

  })
}

export default function FichaElal() {
  const isMobile = useMediaQuery({ maxDeviceWidth: 1024 })
  const data = JSON.parse(localStorage.getItem("datosFichaElal"))
  const [thumbsSwiper, setThumbsSwiper] = useState(null);


  const datosUsuarioLogeado = useSelector(
    (state) => state.reducerUsuarioDatos.data
  );




  return (
    <div className="ficha-tecnica-container">
      <div className="ficha-tecnica-inner-container">
        <div className="header-img-ficha-tecnica background-color-Elal">
          <img src="https://res.cloudinary.com/blackhound/image/upload/v1668168511/BLACKHOUND/Ficha%20Tecnica/Elal/Logo_vozupw.svg" alt=""/>
        </div>
        <div className="ficha-tecnica-proyect-description">
          <p className="ficha-tecnica-proyect-description-p">
          Elal es una institución de vanguardia desarrollada 
          por Grupo Delsud con el objetivo de potenciar y 
          concretar proyectos de bienes y servicios vinculados a la ciencia y la tecnología, 
          los cuales aporten soluciones a problemas actuales y sean
          susceptibles de ser escalados para la producción
          industrial y/o consumo masivo.
          </p>
          {/* <p className="red-text">www.elaventure.com.ar</p> */}
        </div>
        <div className="carrousel-ficha-tecnica">
          <Swiper
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            loop={true}
            spaceBetween={10}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Thumbs]}
            className="slider-superior-grande"
          >
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663932589/BLACKHOUND/Ficha%20Tecnica/Elal/image_21_nd4dbl.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663935828/BLACKHOUND/Ficha%20Tecnica/Elal/IMG_7334_1_npmxon.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663935935/BLACKHOUND/Ficha%20Tecnica/Elal/6-03_3_umg81i.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663935934/BLACKHOUND/Ficha%20Tecnica/Elal/6-03_2_gzfpdq.jpg" />
            </SwiperSlide>
          </Swiper>
          <hr className="linea-divisora-ficha-tecnica" />
          <Swiper
            onSwiper={setThumbsSwiper}
            navigation={true}
            loop={true}
            spaceBetween={20}
            slidesPerView={3}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="slider-inferior-thumbail"
          >
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663932589/BLACKHOUND/Ficha%20Tecnica/Elal/image_21_nd4dbl.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663935828/BLACKHOUND/Ficha%20Tecnica/Elal/IMG_7334_1_npmxon.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663935935/BLACKHOUND/Ficha%20Tecnica/Elal/6-03_3_umg81i.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663935934/BLACKHOUND/Ficha%20Tecnica/Elal/6-03_2_gzfpdq.jpg" />
            </SwiperSlide>
          </Swiper>
        </div>
        <hr className="linea-divisora-ficha-tecnica" />
        <div className="ficha-tecnica-productos">
          <p className="ficha-tecnica-productos-titulo">PRODUCTOS</p>
          <div className="ficha-tecnica-productos-imagenes-contenedor">
            <div className="ficha-tecnica-productos-imagenes-fila-eugenie">
              <div className="ficha-tecnica-productos-imagenes-columna-elal">
                <img
                  alt=""
                  src="https://res.cloudinary.com/blackhound/image/upload/v1662388901/BLACKHOUND/Ficha%20Tecnica/Elal/logoProactiva_bxzswc.svg"
                />
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <hr className="linea-divisora-ficha-tecnica" />
        {/* {from != null && ( */}
          <div className="proyecto-data-en-ficha-tecnica">
            {!isMobile ?
            <>
            <div className="proyectos-descripcion-item">
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897298/BLACKHOUND/tna_nhms4e_iz5fy8.svg" />
              <p className="proyectos-iconos-titulo">TASA</p>
              <p className="proyectos-iconos-titulo" style={{margin: 0}}>VARIABLE</p>
              <p className="proyectos-iconos-desc">(Sujeta al rendimiento promedio de todos los proyectos invertidos)</p>
            </div>
            <hr className="linea-divisora-proyectos-descripcion-item" />
            <div className="proyectos-descripcion-item">
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1661865313/BLACKHOUND/Proyectos/montoMinimo_j0ym0l.svg" />
              <p className="proyectos-iconos-titulo">Monto mínimo</p>
              <p className="proyectos-iconos-desc">
                {data.moneda} {data.montoMinimo}
              </p>
            </div>
            <hr className="linea-divisora-proyectos-descripcion-item" />
            <div className="proyectos-descripcion-item">
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897305/BLACKHOUND/calendario_bulhhb_fwnlxz.svg" />
              <p className="proyectos-iconos-titulo">Plazo mínimo</p>
              <p className="proyectos-iconos-desc">{data.plazo} meses</p>
            </div>
            </>
            :
            <>
            <div className="proyectos-descripcion-item-fichaDesarrollos">
              <div className="containerResponsiveItemsFicha">
                <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897298/BLACKHOUND/tna_nhms4e_iz5fy8.svg" />
                <div className="containerResponsiveItemP">
                  <p className="proyectos-iconos-titulo">TNA</p>
                  <p className="proyectos-iconos-desc">{data.tna}%</p>
                </div>
              </div>  
            </div>
            <div className="proyectos-descripcion-item-fichaDesarrollos">
            <div className="containerResponsiveItemsFicha">
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1661865313/BLACKHOUND/Proyectos/montoMinimo_j0ym0l.svg" />
                <div className="containerResponsiveItemP">
                    <p className="proyectos-iconos-titulo">Monto mínimo</p>
                    <p className="proyectos-iconos-desc">
                      {data.moneda} {data.montoMinimo}
                    </p>
                </div>
              </div>  
            </div>
            <div className="proyectos-descripcion-item-fichaDesarrollos">
              <div className="containerResponsiveItemsFicha">
                <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897305/BLACKHOUND/calendario_bulhhb_fwnlxz.svg" />
                <div className="containerResponsiveItemP">
                  <p className="proyectos-iconos-titulo">Plazo mínimo</p>
                  <p className="proyectos-iconos-desc">{data.plazo} meses</p>
                </div>
              </div>  
            </div>
            </>
            }
          </div>
        {/* )} */}
        {datosUsuarioLogeado != null && datosUsuarioLogeado.estadoNombre === "Aprobado" ?
        <div className="buttonToCalculator">
          <Link 
          onClick={() => {ScrollToTop(); localStorage.setItem("datosFicha", JSON.stringify(data))}}  
          to={"/HomeUsuarios/Dashboard/solicitudInversion"}
          >
          Proyectar inversión
          </Link>
        </div>
        : ""}
      </div>
    </div>
  );
}
