import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';
import { apiEnviarContrato } from'../api/agregarContrato';
// import {  toast } from 'react-toastify';

export const watchEnviarContrato = function * (){
    yield takeEvery(actionTypes.ENVIAR_CONTRATO, fetchEnviarContrato)
}

function* fetchEnviarContrato(action){
    try{
        const data = yield call(apiEnviarContrato, action.data)
        if(data){
            // toast.success('Contrato guardado!', {
            // });
        }
    }catch(err){
        console.log(err)
        // toast.error('Error al cargar contrato.', {     
        // }); 
    }
}

