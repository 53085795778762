import { actionTypes } from "../Constantes/ActionTypes";
import { store } from "../store/index";

function reducerImagenPerfil (state = store.imagenPerfil, action){
    switch(action.type){
        case actionTypes.SET_IMAGEN_PERFIL:
            let imagenPerfil = action.data
            return Object.assign({}, state, {
                data: imagenPerfil,
            })
            default:
                return state
    }
}

export default reducerImagenPerfil;