import React, { useState, useEffect } from 'react'

import { Button, Modal } from 'rsuite';

import './infoInversiones.css';

export default function InfoInversiones(props) {

  const data = props.dataModalSolicitud;

  const [open, setOpen] = useState(false);
  const [backdrop, setBackdrop] = useState('static');
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (data != null) {
        setOpen(data);
    }
  }, [data])

  return (
    <>
    {(open) && 
      <div className="modal-container">
        <Modal backdrop={backdrop} open={open} className='modal-clientes' size={'lg'} onClose={handleClose}>
            <Modal.Title className='solicitud-modal-header'>

            </Modal.Title>
            <Modal.Body>
            <div className='informacion-cliente-container'>
                    <div className='informacion-cliente'>
                        <div className='informacion-cliente-col' >
                          <label>Nombre/s apellido/s:</label><br/>
                          <input value={data.nombre + ' ' + data.apellido} /> <br/>
                          <label>DNI:</label><br/>
                          <input value={data.dni}/><br/>
                          <label>Teléfono: </label><br/>
                          <input value={data.telefono}/><br/>
                        </div>
                        <div className='informacion-cliente-col'>
                          <label>Email: </label><br/>
                          <input value={data.email}/><br/>
                          <label>Tipo de Contacto: </label><br/>
                          <input value={data.tipoContacto}/><br/>
                          <label>Franja Horaria: </label><br/>
                          <input value={data.franjaHorario}/><br/>
                        </div>
                    </div>
                 
                </div>  
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleClose} className="buttonModal modal-clientes-boton-salir">
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      }
    </>
  )
}
