import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";

//ACTION
import { setNotificarUserMail } from '../../../../../Actions/notificarUser';
import { Modal, Button } from 'rsuite';

const ModalEnviarMail = (props) => {
    const data3 = props.dataModalEnviarMail;
    const [open, setOpen] = useState(data3 != null);
    const [backdrop, setBackdrop] = useState('static');
    const handleClose = () => setOpen(false);
    const dispatch = useDispatch();

    const [activePage, setActivePage] = useState(1)
    const [estadoCategoria, setEstadoCategoria] = useState(4)
    const [busqueda, setBusqueda] = useState({
        dataBase: ""
    })

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleCloseConfirm = () => setShow(false);
    const [mailUsuario, setMailUsuario] = useState({
        asunto: '',
        mensaje: ''
    });

    useEffect(() => {
        if (data3 != null) {
            setOpen(data3);
        }
    }, [data3])

    // ENVIAR FORMULARIO//
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm({
        mode: "onChange"
    });
    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append("data", JSON.stringify(data));
        reset();
        const dataSolicitud = {
            asunto: data.asunto,
            mensaje: data.mensaje,
            usuario: data3.email,
        }
        if (dataSolicitud != null) {
            dispatch(setNotificarUserMail(dataSolicitud));
        }
        setOpen(false);
        handleShow();
    };

    return (
        <>
            {(open) &&

                <div className='modalEnvioDoc'>
                    <Modal className='proyecto-modal infoModal' backdrop={backdrop} open={open} size="md" onClose={handleClose} >
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Modal.Header className='headerModal'>
                                <Modal.Title className='headerModal'>
                                    <div>
                                        <h4 style={{ margin: 'auto', color: 'white', textAlign: 'center' }}> Notificar al usuario </h4>
                                    </div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="crear-proyecto-formulario container-mail">
                                    <div className=" add-evento">
                                        <div className="crear-proyecto-campo">
                                            {/* <input className='input-proyecto' placeholder="Escriba un asunto" value={mailUsuario.asunto} name="asunto" type="text" onChange={onChangeNotificar} /> */}
                                            <input className='input-proyecto'
                                                autoComplete='off'
                                                placeholder="Escriba un asunto a notificar..."
                                                {...register("asunto",
                                                    {
                                                        required: true,
                                                        pattern: /^[a-zA-Z\s]*$/i
                                                    })}
                                            />
                                            {errors?.asunto?.type === "required" && <p className='error'>Este campo es requerido</p>}
                                        </div>
                                    </div>
                                    <span style={{ padding: '10px' }}></span>
                                    <div className="add-evento">
                                        <div className="crear-proyecto-campo">
                                            {/* <textarea className='input-proyecto' 
                                                    value={mailUsuario.mensaje} name="mensaje" 
                                                    onChange={onChangeNotificar} ></textarea> */}
                                            <textarea className='input-proyecto mail-area'
                                                placeholder="Ingrese un mensaje"
                                                {...register("mensaje", {
                                                    required: "Campo requerido",
                                                    maxLength: {
                                                        value: 600,
                                                        message: "Máximo 600 caracteres"
                                                    },
                                                    minLength: {
                                                        value: 20,
                                                        message: "Minimo 20 caracteres"
                                                    }
                                                })}
                                            />
                                            {errors.mensaje && <p className='error'>{errors.mensaje.message}</p>}
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer className='contFooter'>
                                <Button className='buttonModal' type="submit"> Enviar </Button>
                                <Button className='buttonModal' onClick={handleClose}> Cancelar </Button>
                            </Modal.Footer>
                        </form>
                    </Modal>

                    {/* MODAL */}
                    <Modal centered open={show} onClose={handleCloseConfirm} backdrop="static" >
                        <Modal.Header>
                            <Modal.Body style={{ fontSize: "20px", color: "white", textAlign: "center" }}> 
                                Usuario notificado correctamente.
                            </Modal.Body>
                        </Modal.Header>
                        <Modal.Footer className='contFooter'>
                            <button className='buttonModal' onClick={handleCloseConfirm}> Cerrar </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            }
        </>
    );
}

export default ModalEnviarMail;
