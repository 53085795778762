import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer'
import {
    BrowserRouter,
    Routes,
    Route,
  } from "react-router-dom";
import Home from '../../Pages/Home/Home';
import Nosotros from '../../Pages/Nosotros/Nosotros';
import ComoInvertir from '../../Pages/Como Invertir/ComoInvertir';
import Proyectos from '../../Pages/Proyectos/Proyectos';
import Contacto from '../../Pages/Contacto/Contacto';
import Faqs from '../../Pages/Faqs/Faqs';
import IniciarSesion from '../../Pages/IniciarSesion/IniciarSesion';
import Registrarse from '../../Pages/Registrarse/Registrarse';
import ValidarMail from '../../Pages/Registrarse/ValidarMail';
import FichaDesarrollos from '../../Usuarios/Vistas/UserDashboard/FichasTecnicas/FichaDesarrollos/FichaDesarrollos';
import FichaElemental from '../../Usuarios/Vistas/UserDashboard/FichasTecnicas/FichaElemental/FichaElemental';
import FichaEugenie from '../../Usuarios/Vistas/UserDashboard/FichasTecnicas/FichaEugenie/FichaEugenie';
import FichaElal from '../../Usuarios/Vistas/UserDashboard/FichasTecnicas/FichaElal/FichaElal';
import CambiarContrasena from '../../Pages/IniciarSesion/CambiarContrasena';
import RecordarContrasena from '../../Pages/IniciarSesion/RecordarContrasena';
import UserEliminado from '../../Usuarios/Vistas/userEliminado/userEliminado';
import ChatClient from '../Chat/ChatClient';
//VISTAS HOME
import HomeAsesores from '../../Asesores/HomeAsesor'
import HomeUsuarios from '../../Usuarios/HomeUsuarios'
import HomeAdmin from '../../Admin/HomeAdmin'
import { useSpring, animated } from 'react-spring';


export default function Rutas() {

  const fadeOut = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 250,
  })

  return (
    <>  
       
        <BrowserRouter>
          <animated.div style={fadeOut}>

            <Navbar/>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/Nosotros" element={<Nosotros />} />
                    <Route path="/ComoInvertir" element={<ComoInvertir />} />
                    <Route path="/Proyectos" element={<Proyectos />} />
                    <Route path="/Contacto" element={<Faqs />} />
                    <Route path="/Faqs" element={<Contacto />} />    
                    <Route path="/IniciarSesion/*" element={<IniciarSesion />} />    
                    <Route path="/Registrarse" element={<Registrarse />} /> 
                    <Route path="/ValidarMail/*" element={<ValidarMail />} /> 
                    <Route path="/FichaTecnicaDesarrollosDelsud" element={<FichaDesarrollos />} />   
                    <Route path="/FichaTecnicaElemental" element={<FichaElemental />} />   
                    <Route path="/FichaTecnicaEugenie" element={<FichaEugenie />} />  
                    <Route path="/FichaTecnicaElal" element={<FichaElal />} />
                    
                    <Route path="/HomeAsesores/*" element={<HomeAsesores />} />  
                    <Route path="/HomeUsuarios/*" element={<HomeUsuarios />} />     
                    <Route path="/HomeAdmin/*" element={<HomeAdmin />} />        

                    <Route path="/eliminado" element={<UserEliminado />} />        

                    <Route path="/chatBlack" element={<ChatClient />} />   
                    <Route path="/RecordarContrasena" element={<RecordarContrasena />} />        
                    <Route path="/resetPassword/:id" element={<CambiarContrasena />} />       
                </Routes>
                <Footer/>  
          </animated.div>
        </BrowserRouter>

    </>

  )
}
