import React, { useState, useEffect } from "react";
import { Table, Pagination, Loader, Popover, Whisper } from "rsuite";
import { useSpring, animated } from 'react-spring';
import { useSelector, useDispatch } from "react-redux";
import ModalAprobado from "./ModalAprobado/modalAprobado";
import ModalEnviarMail from "./ModalEnviarMail/ModalEnviarMail";
import { getlistaSolicitudesRegistradas } from "../../../../Actions/listaSolicitudesRegistradas";

import Tooltip from 'react-bootstrap/Tooltip';
import "./citados.css";
import ModalRechazarSolicitudUser from "./ModalRechazarSolicitud/ModalRechazarSolicitudUser";

const { Column, HeaderCell, Cell } = Table;

export default function Citados() {
  const [open, setOpen] = useState(false);
  const [modalAprobado, setModalAprobado] = useState(false);
  const [modalEnviarMail, setModalEnviarMail] = useState(null);
  const [modalRechazar, setModalRechazar] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [estadoCategoria, setEstadoCategoria] = useState(4);
  const [busqueda, setBusqueda] = useState({
    dataBase: "",
  });
  const [idSolicitud, setIdSolicitud] = useState()
  const [email, setEmail] = useState()
  const [loading, setLoading] = useState(true);
  {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  const fadeOut = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 500,
  })
  const dispatch = useDispatch();

  useEffect(() => {
    const data = {
      busqueda: busqueda.dataBase,
      categoria: estadoCategoria,
      page: activePage,
    };
    dispatch(getlistaSolicitudesRegistradas(data));
  }, [activePage, busqueda, estadoCategoria]);

  const inputChange = (event) => {
    setBusqueda({
      ...busqueda,
      [event.target.name]: event.target.value,
    });
  };

  const tablaListaSolicitudes = useSelector(
    (state) => state.reducerlistaSolicitudesRegistradas.data
  );

  const TooltipNotificar = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Notificar
    </Tooltip>
  );

  return (
    <>
      {loading ?
        <div className='loading'>
          <Loader className='loadingColor' size="lg" />
        </div>
        :
        <animated.div style={fadeOut}>
          <div className="containerGeneric">
            <div>
              <h5 className="user-wellcome"> USUARIOS / CITADOS </h5>
            </div>
            <div className="categoria-titulo2">
              <div className="circle-categoria"> </div>
              <div className="proyecto-titulo-dash">CONFIRMACIÓN DE INVERSIÓN</div>
            </div>

            <div className="tabla-container">
              <Table
                // loading={loading}
                height={550}
                data={tablaListaSolicitudes != null ? tablaListaSolicitudes.data : ""}
                locale={{ emptyMessage: "Sin solicitudes" }}
              >
                <Column flexGrow={1.5}>
                  <HeaderCell>Nombre</HeaderCell>
                  <Cell>
                    {(rowData) => {
                      return (
                        <div className="boxNombre">
                          <div className="box-img-imagen-user">
                            <img
                              className="fotoUsuario"
                              alt="logoLogin"
                              src={
                                rowData.idUser != null && rowData.imgPerfil != null
                                  ? "https://www.blackhound.com.ar/server/public/getImgProfile/" +
                                  rowData.idUser
                                  : "https://res.cloudinary.com/blackhound/image/upload/v1660676600/BLACKHOUND/Logprofile_wvmpml.png"
                              }
                            />
                          </div>
                          <div className="ajusteUbicacion">
                            {rowData.nombre + " " + rowData.apellido}
                          </div>
                        </div>
                      );
                    }}
                  </Cell>
                </Column>

                <Column flexGrow={0.8}>
                  <HeaderCell>DNI</HeaderCell>
                  <Cell>
                    {(rowData) => {
                      return rowData.dni;
                    }}
                  </Cell>
                </Column>

                <Column flexGrow={0.8}>
                  <HeaderCell>Teléfono</HeaderCell>
                  <Cell>
                    {(rowData) => {
                      return rowData.telefono;
                    }}
                  </Cell>
                </Column>

                <Column flexGrow={1.2}>
                  <HeaderCell>Correo electrónico</HeaderCell>
                  <Cell>
                    {(rowData) => {
                      return rowData.email;
                    }}
                  </Cell>
                </Column>

                <Column flexGrow={1}>
                  <HeaderCell>Empresa</HeaderCell>
                  <Cell>
                    {(rowData) => {
                      return (
                        <>
                          {rowData.proyecto == "Desarrollos Delsud" ? (
                            <Whisper placement="bottom" followCursor speaker={<Popover className="container-pop"> <b>Plazo:</b> {rowData.plazoInversion + ' meses'} <br></br> <b>Monto:</b> ${rowData.monto}</Popover>}>
                              <img
                                className="proyecto-tamaño-asesor-pendiente"
                                src="https://res.cloudinary.com/blackhound/image/upload/v1659525488/BLACKHOUND/desarrollos2_jw19om.svg"
                                alt="desarrollos"
                              />
                            </Whisper>
                          ) : (
                            ""
                          )}
                          {rowData.proyecto == "Elemental Constructora" ? (
                            <Whisper placement="bottom" followCursor speaker={<Popover className="container-pop"> <b>Plazo:</b> {rowData.plazoInversion + ' meses'} <br></br> <b>Monto:</b> ${rowData.monto}</Popover>}>
                              <img
                                className="proyecto-tamaño-asesor-pendiente"
                                src="https://res.cloudinary.com/blackhound/image/upload/v1659540935/BLACKHOUND/elemental_oohxsp.svg"
                                alt="Elemental Constructora"
                              />
                            </Whisper>
                          ) : (
                            ""
                          )}
                          {rowData.proyecto == "Eugénie" ? (
                            <Whisper placement="bottom" followCursor speaker={<Popover className="container-pop"> <b>Plazo:</b> {rowData.plazoInversion + ' meses'} <br></br> <b>Monto:</b> ${rowData.monto}</Popover>}>
                              <img
                                className="proyecto-tamaño-asesor-pendiente"
                                src="https://res.cloudinary.com/blackhound/image/upload/v1659541685/BLACKHOUND/eugenie_jljwc2.svg"
                                alt="Microcréditos"
                              />
                            </Whisper>
                          ) : (
                            ""
                          )}
                          {rowData.proyecto == "Petricor Agrícola" ? (
                            <Whisper placement="bottom" followCursor speaker={<Popover className="container-pop"> <b>Plazo:</b> {rowData.plazoInversion + ' meses'} <br></br> <b>Monto:</b> ${rowData.monto}</Popover>}>
                              <img
                                className="proyecto-tamaño-asesor-pendiente"
                                src="https://res.cloudinary.com/blackhound/image/upload/v1659541779/BLACKHOUND/petricor_yutbqr.svg"
                                alt="Petricor Agrícola"
                              />
                            </Whisper>
                          ) : (
                            ""
                          )}
                          {rowData.proyecto == "Elal" ? (
                            <Whisper placement="bottom" followCursor speaker={<Popover className="container-pop"> <b>Plazo:</b> {rowData.plazoInversion + ' meses'} <br></br> <b>Monto:</b> ${rowData.monto}</Popover>}>
                              <img
                                className="proyecto-tamaño"
                                src="https://res.cloudinary.com/blackhound/image/upload/v1662854931/BLACKHOUND/elalll_sxpkmu.png"
                                alt="Petricor Agrícola"
                              />
                            </Whisper>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    }}
                  </Cell>
                </Column>

                <Column flexGrow={1}>
                  <HeaderCell>Turno</HeaderCell>
                  <Cell>
                    {
                      (rowData) => {
                        return rowData.turnoUser != 'sin turno' ? rowData.turnoUser.date.substring(0, 16) + 'hs' : 'sin turno';
                      }}
                  </Cell>
                </Column>

                <Column flexGrow={1}>
                  <HeaderCell>Acciones</HeaderCell>
                  <Cell className="estado-inversion">
                    {(rowData) => {
                      function handleModalAprobado() {
                        setModalAprobado(true);
                        setIdSolicitud(rowData.idSolicitud)
                        setEmail(rowData.email)
                      }
                      function handleModalNotificar() {
                        setModalEnviarMail({ ...rowData });
                      }
                      function handleModalRechazar() {
                        setModalRechazar({ ...rowData });
                      }
                      return (
                        <>
                          <div className="container-buttons2">
                            <Whisper placement="top" followCursor speaker={<Popover className="container-pop">Aprobar</Popover>}>
                              <input
                                className={`button-cliente-aprob ${rowData.idSolicitudEStado == 2
                                    ? "button-cliente-aprob"
                                    : ""
                                  }`}
                                type="button"
                                value={2}
                                onClick={handleModalAprobado}
                              />
                            </Whisper>
                            <Whisper placement="bottom" followCursor speaker={<Popover className="container-pop">Notificar</Popover>}>
                              <input
                                className={`button-cliente-mail ${rowData.idSolicitudEStado == 4
                                    ? "button-rechazo-mail"
                                    : ""
                                  }`}
                                type="button"
                                value={4}
                                onClick={handleModalNotificar}
                              />
                            </Whisper>
                            <Whisper placement="bottom" followCursor speaker={<Popover className="container-pop">Rechazar</Popover>}>
                              <input
                                className={`button-cliente-rechazo ${rowData.idSolicitudEStado == 3
                                    ? "button-rechazo-active"
                                    : ""
                                  }`}
                                type="button"
                                value={3}
                                onClick={handleModalRechazar}
                            />
                            </Whisper>
                          </div>
                        </>
                      );
                    }}
                  </Cell>
                </Column>
              </Table>
              <Pagination
                prev
                next
                size="md"
                total={
                  tablaListaSolicitudes != null
                    ? tablaListaSolicitudes.totalRegister
                    : ""
                }
                limit={10}
                activePage={activePage}
                onChangePage={setActivePage}
                style={{
                  justifyContent: "center",
                  marginTop: "20px",
                  maginBottom: "50px",
                }}
              />
            </div>
            {/* <InfoSolicitud dataModalSolicitud={modalSolicitud} />
              <ModalMailSolicitud dataModalMail={modalMailAprobado} /> */}
            <ModalAprobado 
            modalAprobado={modalAprobado}
            setModalAprobado={setModalAprobado}
            idSolicitud={idSolicitud}
            email={email}
            />
            <ModalEnviarMail dataModalEnviarMail={modalEnviarMail} />
            <ModalRechazarSolicitudUser dataModalRechazarSolicitud={modalRechazar} />
          </div>
        </animated.div>
      }
    </>
  );
}
