import React, { useRef, useState,useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";


import { useSelector, useDispatch } from 'react-redux';
import ProyectoEditable from "./ProyectoEditable";

import {getMostrarProyecto} from '../../../Actions/mostrarProyectos'

// Import Swiper styles
import "swiper/css";

import "./proyectosactivos.css";

export default function ProyectosActivos() {

  const [activePage, setActivePage] = useState(1)
  const [estadoFiltro, setEstadoFiltro] = useState(null)
  const [busqueda, setBusqueda] = useState({
    dataBase: ""
  })

    
    const dispatch = useDispatch();

    useEffect(() => {
      const data = {
        busqueda: busqueda.dataBase,
        estado: estadoFiltro,
        page: activePage,
        categoria:estadoFiltro,
        moneda:estadoFiltro,
      }
      dispatch(getMostrarProyecto(data));
    }, []);


    const listaProyectos = useSelector(state => state.reducerMostrarProyecto.data);

    if (listaProyectos != null) {
      var proyectos = [];
      const pu = listaProyectos.data;
      for (var i = 0; i < pu.length; i++) {
        proyectos.push(<SwiperSlide><ProyectoEditable data={pu[i]}/></SwiperSlide>)
      }
    }

  return (
    <>
      <Swiper        
        slidesPerView={1}
        spaceBetween={40}
        className="Proyectos"
        loop={true}
        breakpoints={{     
          768: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 50,
          }
        }}       
      >
       {proyectos}
        
      </Swiper>
 

      </>
  );
}
