import React, { useEffect, useState } from 'react'
import { useSpring, animated } from 'react-spring';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboard } from "../../../Actions/dashboard";
import {Loader} from "rsuite"
import { Link } from 'react-router-dom';
import './dashboardAsesor.css'


export default function Dashboard() {
    
    const dispatch = useDispatch();
    const dashboardAdmin = useSelector(state => state.reducerDashboard.data);
    useEffect(() => {
        dispatch(getDashboard());
    }, []);

    const [loading, setLoading] = useState(true); {
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    
      const fadeOut = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        delay: 500,
    }) 

    return (
      <>
      {loading ?
        <div className='loading'>
            <Loader className='loadingColor' size="lg" />
        </div>
      :
      <animated.div style={fadeOut}>
        <div className='containerGeneric'>
        <div>
            <h5 className='user-wellcome'>BIENVENIDO <span style={{textTransform:'uppercase'}}>ASESOR</span></h5>
        </div>
        <div className="categoria-titulo2">
            <div className="circle-categoria"> </div> 
            <div className="proyecto-titulo-dash">INFORMACIÓN GENERAL</div>
          </div>
        <div className='dasboard-containerAsesor'>
            <div className='dasboard-col-rightAsesor'>
                
                <div className='usuarios-familia'>
                    <div className='dashboard-box-header'>
                        <div className='container-icon-dash'>
                            <img className='icon-dash' src='https://res.cloudinary.com/blackhound/image/upload/v1658520797/BLACKHOUND/user-pendiente_yzf3da.svg'/>
                        </div>
                        <div className='text-container-dash'>
                            <h5 className='dashboard-box-tittle'> USUARIOS PENDIENTES</h5>
                            <p> Hay { dashboardAdmin != null ? dashboardAdmin.userPendiente : "----" } solicitudes pendientes de cita. </p>
                            <div className='container-button'>
                                <Link to="/HomeAsesores/usuarios" className='button-dashAsesor'>Ver solicitudes</Link>
                            </div>
                        </div>
                    </div>                
                </div> 

                <div className='usuarios-familia'>
                    <div className='dashboard-box-header'>
                        <div className='container-icon-dash'>
                            <img className='icon-dash' src='https://res.cloudinary.com/blackhound/image/upload/v1658520797/BLACKHOUND/turnos-asignados_hrsgg9.svg'/>
                        </div>
                        <div className='text-container-dash'>
                            <h5 className='dashboard-box-tittle'> TURNOS ASIGNADOS </h5>
                            <p> Hay { dashboardAdmin != null ? dashboardAdmin.cantTurnos : "----" } turnos asignados </p>
                            <div className='container-button'>
                                <Link to="/HomeAsesores/turnos" className='button-dashAsesorTurno'>Ver turnos</Link>
                            </div>
                        </div>
                    </div>                   
                </div>   

            </div>

            <div className='dasboard-col-rightAsesor'>

                <div className='usuarios-familia'>
                    <div className='dashboard-box-header'>
                        <div className='container-icon-dash'>
                            <img className='icon-dash' src='https://res.cloudinary.com/blackhound/image/upload/v1658520797/BLACKHOUND/inversion-activa_vvgvhh.svg'/>
                        </div>
                        <div className='text-container-dash'>
                            <h5 className='dashboard-box-tittle'> INVERSIONES ACTIVAS</h5>
                            <p> Hay { dashboardAdmin != null ? dashboardAdmin.cantAprobadasSolicitudes : "----" } inversiones activas. </p>
                            <div className='container-button'>
                                <Link to="/HomeAsesores/inversiones" className='button-dashAsesor'>Ver inversiones</Link>
                            </div>
                        </div>
                    </div>                
                </div>
              
            </div>

        </div>
    </div>
    </animated.div> 
    }
      </>

  )
}
