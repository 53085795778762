import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call,put } from'redux-saga/effects';

import { apiIdContrato } from "../api/agregarContrato";
import { setIdContrato } from "../Actions/agregarContrato";

export const watchGuardarIdContrato = function * (){
    yield takeEvery(actionTypes.GET_ID_CONTRATO, fetchGuardarIdContrato)
}

function* fetchGuardarIdContrato(action){
    try{
        const data = yield call(apiIdContrato, action.data)
        if(data){
            yield put(setIdContrato(data))
        }
    }catch(err){
        console.log(err)
    }
}

