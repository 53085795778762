import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//ACTION
import { setEstadoSolicitud } from '../../../../Actions/estadoSolicitud';
import { getlistaSolicitudesRegistradas } from '../../../../Actions/listaSolicitudesRegistradas';
import { Modal } from 'rsuite';
const ModalEliminarInversion = (props) => {
    const data3 = props.dataEliminarInversion;
    const [open, setOpen] = useState(data3 != null);
    const [backdrop, setBackdrop] = useState('static');
    const handleClose = () => setOpen(false);
    const dispatch = useDispatch();

    const [activePage, setActivePage] = useState(1)
    const [estadoCategoria, setEstadoCategoria] = useState(2)
    const [busqueda, setBusqueda] = useState({
        dataBase: ""
    })

    useEffect(() => {
        if (data3 != null) {
            setOpen(data3);
        }
    }, [data3])

    const handleEnviarMail = () => {
        const dataSolicitud = {
            id: data3.idSolicitud,
            idEstado: 5,
        }
        const data = {
            busqueda: busqueda.dataBase,
            categoria: estadoCategoria,
            page: activePage,
        }
        if (data3 && dataSolicitud && data != null) {
            dispatch(setEstadoSolicitud(dataSolicitud));
            setTimeout(() => {
                dispatch(getlistaSolicitudesRegistradas(data));
            }, 1500); 
        }
        setOpen(false);
    }
    return (
        <>
            {(open) &&

                <div className='modalEnvioDoc'>
                    <Modal className='proyecto-modal infoModal' backdrop={backdrop} open={open} size="md" onClose={handleClose} >
                        <Modal.Header className='headerModal'>
                            <Modal.Title className='headerModal'>
                                <div>
                                    <h4 style={{ margin: 'auto', color: 'white', textAlign: 'center' }}> ¡Mensaje Importante! </h4>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='textModal'>
                                ¿Desea realmente eliminar esta solicitud? <br></br>
                                Seleccione "Si" para eliminar la solicitud <br></br>
                                o seleccione "No" para cancelar.
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='contFooter'>
                            <button className='buttonModal' onClick={handleEnviarMail}> Si </button>
                            <span style={{ padding: '10px' }}></span>
                            <button className='buttonModal' onClick={handleClose}> No </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            }
        </>
    );
}

export default ModalEliminarInversion;
