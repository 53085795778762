import { actionTypes } from "../Constantes/ActionTypes"; 

export const setNotificacionUsuario = (data) => {
    return {
        type: actionTypes.SET_NOTIFICACIONES_USUARIO,
        data
    }
}

export const getNotificacionUsuario = (data) => {
    return {
        type: actionTypes.GET_NOTIFICACIONES_USUARIO,
        data
    }
}

export const cambiarEstadoNotificaciones = (data) => {
    return {
        type: actionTypes.CAMBIAR_ESTADO_NOTIFICACIONES,
        data
    }
}

export const contadorNotificaciones = (data) => {
    return {
        type: actionTypes.CONTADOR_NOTIFICACION,
        data
    }
}