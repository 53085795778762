import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import "./listaSolicitudesAprobadas.css";

export default function ListaSolicitudesAprobadas(props) {
  const data = props.data;
  const estadisticas = props.estadisticas;

  if (estadisticas != null) {
    var fechaAprobacion = data.fecha.date;
    var fechaAprobacionRecortada = estadisticas.fechaInicioInversion.date.substring(0, 10);
    var fechaAprobacionFormateada = moment(fechaAprobacionRecortada).format("YYYY-MM-DD");
    var fechaVencimiento = moment(fechaAprobacionFormateada).add(estadisticas.plazoInvertido, "month").calendar();
  }

  //Ganancia x dia
    let retornoEsperadoInversion = estadisticas != null ? estadisticas.retornoEsperadoInversion : ""
    let inversionPorDia = Math.round(retornoEsperadoInversion / 365)
    //Ganancia acumulada
    let fechaActual = new Date().getTime()
    let fechaInicioInversion = estadisticas != null ? estadisticas.fechaInicioInversion.date.slice(0, 10) : ""
    let fechaInicioDate = new Date(fechaInicioInversion).getTime()
    let resultadoFechas = fechaActual - fechaInicioDate
    //Resultado ganancia acumulada
    let inversionAcumulada = inversionPorDia * Math.round(resultadoFechas / (1000 * 60 * 60 * 24))
    let inversionMensual = Math.round(estadisticas != null ? estadisticas.retornoInvertidoALaFecha : "")

    let dateGrafico = moment(fechaInicioInversion).add(30,"days").calendar()

  const [chatUser, setChatUser] = useState(null);

  function handleChat() {
    setChatUser({ ...data });
  }

  return (
    <>
      <div className="proyecto-acordion-container-solicitudes-aprobadas">
        <div className="proyecto-data-en-acordion-solicitudes-aprobadas-imagenes">
          <img
            src={
              "https://www.blackhound.com.ar/server/public/getImgId/" +
              data.idImagenProyecto
            }
          />
        </div>
        <div className="proyecto-accordion-datos-solicitudes-aprobadas">
          <div className="proyecto-data-en-acordion-solicitudes-aprobadas">
            <div className="proyecto-data-en-acordion-solicitudes-aprobadas-item">
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897305/BLACKHOUND/calendario_bulhhb_fwnlxz.svg" />
              <div className="proyecto-data-en-acordion-solicitudes-aprobadas-item-text">
                <p className="proyecto-data-en-acordion-solicitudes-aprobadas-text-sub">
                  Vencimiento
                </p>
                <p className="proyecto-data-en-acordion-solicitudes-aprobadas-text-sub-bold">
                  {fechaVencimiento}
                </p>
              </div>
            </div>
            <div className="proyecto-data-en-acordion-solicitudes-aprobadas-item">
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897291/BLACKHOUND/monto_cxwn8l_w1fwel.svg" />
              <div className="proyecto-data-en-acordion-solicitudes-aprobadas-item-text">
                <p className="proyecto-data-en-acordion-solicitudes-aprobadas-text-sub">
                  Monto
                </p>
                <p className="proyecto-data-en-acordion-solicitudes-aprobadas-text-sub-bold">
                  {data.moneda}
                  {data.montoInvertido}
                </p>
              </div>
            </div>
          </div>
          <div className="proyecto-data-rendimiento">
            <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897290/BLACKHOUND/moneda_uxljoi_uqwcrb.svg" />
            <div>
              <p className="proyectos-data-rendimiento-titulo">Rendimientos</p>
              <div className="proyectos-data-rendimiento-valor">
                <p>Día: {data.moneda}{inversionPorDia}</p>
                <p>Mensual: {data.moneda}{estadisticas != null ? inversionMensual : ''}</p>
                <p>Acumulado al día de la fecha: {data.moneda} {estadisticas != null ? inversionAcumulada : "0"}</p>
              </div>
            </div>
          </div>
          <div className="proyecto-data-seccion-botones">
            {data.nombre == "Desarrollos" && (
              <Link
                className="proyecto-data-en-acordion-boton"
                to={"/FichaTecnicaDesarrollosDelsud"}
                state={{ from: data }}
              >
                Ficha técnica
              </Link>
            )}
            {data.nombre == "Elemental Constructora" && (
              <Link
                className="proyecto-data-en-acordion-boton"
                to={"/FichaTecnicaElemental"}
                state={{ from: data }}
              >
                Ficha técnica
              </Link>
            )}
            {data.nombre == "Microcréditos" && (
              <Link
                className="proyecto-data-en-acordion-boton"
                to={"/FichaTecnicaEugenie"}
                state={{ from: data }}
              >
                Ficha técnica
              </Link>
            )}
            {data.nombre == "Elal" && (
              <Link
                className="proyecto-data-en-acordion-boton"
                to={"/FichaTecnicaElal"}
                state={{ from: data }}
              >
                Ficha técnica
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

