import React from 'react'
import { NavLink, useNavigate } from "react-router-dom";
import { verificarLogin } from '../../../Actions/login';
import { useSelector, useDispatch } from 'react-redux';

export default function UserEliminado() {
  const dispatch = useDispatch();

  //CERRAR SESION
  const navegarCerrarSesion = useNavigate();
  const cerraSession = () => {
    dispatch(verificarLogin(false))
    localStorage.removeItem('token');
    localStorage.clear()
    return (navegarCerrarSesion('/'))
  }

  return (
    <>
      <div className='validar-mail-container'>
        <div className='validar-mail-box' data-aos="zoom-in" data-aos-duration="1000">
          <p>
            Su usuario ha sido rechazado/eliminado<br />
            Contacte a soporte para más información.
          </p>
          <button className="boton-registrarse" onClick={cerraSession} >Cerrar</button>
        </div>
      </div>
    </>
  )
}
