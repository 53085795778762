import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';
import { listaMonedas } from'../api/listaMonedas';
import { setListaMonedas } from "../Actions/listaMonedas";

export const watchListaMonedas = function * (){
    yield takeEvery(actionTypes.LISTA_DE_MONEDAS, fetchListaMonedas)
}

function* fetchListaMonedas(action){
    try{
        const data = yield call(listaMonedas, action.data)
        if(data){
            yield put(setListaMonedas(data))
            //MODAL USUARIO REGISTRADO EXITOSO
        }
    }catch(err){
        console.log(err)
    }
}