import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';
import { enviarMailSolicitud } from'../api/enviarMailSolicitud';
// import {  toast } from 'react-toastify';

export const watchEnviarMailSolicitud = function * (){
    yield takeEvery(actionTypes.SEND_MAIL_SOLICITUD, fetchEnviarMailSolicitud)
}

function* fetchEnviarMailSolicitud(action){
    try{
        const data = yield call(enviarMailSolicitud, action.data)
        if(data){
            // toast.success('Mail enviado correctamente', {
            // });
        }
    }catch(err){
        console.log(err)
        // toast.error('Error al enviar Mail', {     
        // }); 
    }
}

