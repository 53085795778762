import React, { useEffect, useState } from "react";
import { Link, Navigate, Routes, Route } from "react-router-dom";
import VerificacionRegistro from "./VerificacionRegistro/VerificacionRegistro";
import DashboardPasos from "./DashboardPasos/DashboardPasos";
import { useSelector } from "react-redux";
import UserEliminado from "../userEliminado/userEliminado";

export default function UserDashboard() {
  const [userValidado, setUserValidado] = useState();
  const datosUsuarioLogeado = useSelector(
    (state) => state.reducerUsuarioDatos.data
  );

  useEffect(() => {
    if (
      datosUsuarioLogeado != null &&
      datosUsuarioLogeado.codigoUser == false
    ) {
      setUserValidado(false);
    }
    if (datosUsuarioLogeado != null && datosUsuarioLogeado.codigoUser == true) {
      setUserValidado(true);
    }

  }, [datosUsuarioLogeado]);

  return (
    <>
      {userValidado == false && <VerificacionRegistro data={datosUsuarioLogeado} /> }
      {userValidado == true && <DashboardPasos /> }
      {datosUsuarioLogeado != null && datosUsuarioLogeado.estadoNombre == 'Rechazado' && 
        (
          <>  
            <Routes>
              <Route path="/" element={<Navigate to={"/eliminado" }/>}/> 
            </Routes>
          </>
        )
      }
    </>
  );
}
