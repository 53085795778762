import { actionTypes } from "../Constantes/ActionTypes"; 

export const getApiDolar = (data) => {
    return {
        type: actionTypes.GET_DOLAR,
        data
    }
}

export const setApiDolar = (data) => {
    return {
        type: actionTypes.SET_DOLAR,
        data
    }
}

