import { actionTypes } from "../Constantes/ActionTypes";
import { store } from "../store/index";

function reducerListaTurno (state = store.listaTurno, action){
    switch(action.type){
        case actionTypes.SET_TURNO_USER:
            let listaTurnoUser = action.data
            return Object.assign({}, state, {
                data: listaTurnoUser,
            })
            default:
                return state
    }
}

export default reducerListaTurno;