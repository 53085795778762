import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ModalGeneric from "../../../../Components/ModalGeneric/ModalGeneric"
//ACTION
import { getlistaSolicitudesRegistradas } from '../../../../Actions/listaSolicitudesRegistradas';
import { AgregarAsesorAUnUsuario } from '../../../../Actions/agregarAsesorAUnUsuario';
import { Modal } from 'rsuite';
import { setEstadoCliente } from '../../../../Actions/estadoCliente';
import { getlistaClientesRegistrados } from '../../../../Actions/listaClientesRegistrados';
import { getUpdateAsesor } from '../../../../Actions/updateAsesor';

const ModalAsignarAsesorUser = (props) => {
    const modalAsignarAsesorUser = props.modalAsignarAsesorUser
    const setModalAsignarAsesorUser = props.setModalAsignarAsesorUser
    const setSelect = props.setSelect
    const select = props.select
    const idCliente = props.idCliente
    const idAsesor = props.idAsesor
    const [open, setOpen] = useState();
    const [backdrop, setBackdrop] = useState('static');
    
    const handleClose = () => {
    setModalAsignarAsesorUser(false);
    setSelect(false)    
    }
    const dispatch = useDispatch();
    
    const handleOpen = () => setOpen(true);


    const [activePage, setActivePage] = useState(1)
    const [estadoFiltro, setEstadoFiltro] = useState(1);
    const [busqueda, setBusqueda] = useState({
        dataBase: ""
    })
    const asesorAsignado = {
        content: "Asesor asignado correctamente.",
        button: "Cerrar"
    }


    const handleAprobarSolicitud = (e) => {
        const dataAsesor = {
            id: idCliente,
            idAsesor: idAsesor,
         }
        const dataSolicitud = {
            id: idCliente,
            idEstado: 1,
        }
        const data = {
            busqueda: busqueda.dataBase,
            estado : estadoFiltro,
            page: activePage,
        }
        if (dataSolicitud && data != null) {
            dispatch(getUpdateAsesor(dataAsesor))
            dispatch(setEstadoCliente(dataSolicitud));
            setTimeout(() => {
                dispatch(getlistaClientesRegistrados(data));
                handleOpen();
            }, 1500);
        }
        setSelect(false)
        setModalAsignarAsesorUser(false);
    }

    return (
        <>
            {(modalAsignarAsesorUser) &&
                    <Modal className='proyecto-modal infoModal' backdrop={backdrop} open={modalAsignarAsesorUser} size="md" >
                        <Modal.Header className='headerModal'>
                            <Modal.Title className='headerModal'>
                                <div>
                                    <h4 style={{ margin: 'auto', color: 'white', textAlign: 'center' }}> ¡Mensaje Importante! </h4>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='textModal'>
                                ¿Desea asignar este asesor? <br></br>
                                Seleccione "Si" para aprobarla <br></br>
                                o seleccione "No" para cancelar.
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='contFooter'>
                            <button className='buttonModal' onClick={handleAprobarSolicitud}> Si </button>
                            <span style={{ padding: '10px' }}></span>
                            <button className='buttonModal' onClick={handleClose}> No </button>
                        </Modal.Footer>
                    </Modal>
                }
                <ModalGeneric
                open={open}
                setOpen={setOpen}
                content={asesorAsignado.content}
                button={asesorAsignado.button}
                />
        </>
    );
}

export default ModalAsignarAsesorUser;
