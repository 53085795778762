import React from 'react';
import './App.css';

import {Provider} from 'react-redux';
import rootReducer from './reducer';
import rootSaga from './sagas';
import createSagaMiddleware from '@redux-saga/core';
import { applyMiddleware, createStore } from 'redux';
import { ToastContainer, toast } from 'react-toastify';

import IniciarSesion from './Pages/IniciarSesion/IniciarSesion';

import Calendar from './calendario';
//Importar Rutas
import Rutas from './Components/Rutas/Rutas';


function App() {

  const sagasMiddleware = createSagaMiddleware()
  const store = createStore(rootReducer, applyMiddleware(sagasMiddleware))
  sagasMiddleware.run(rootSaga)
 
  return (
    <>
      <Provider store={store}>
          <div style={{background:'#000'}}>
            <Rutas/>
          </div>
        <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
      </Provider>
    </>
  );
}

export default App;
