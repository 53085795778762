import { actionTypes } from "../Constantes/ActionTypes";
import { store } from "../store/index";

function reducerValidarUserRegistro (state = store.userRegistro, action){
    switch(action.type){
        case actionTypes.SET_VALIDAR_USER_REGISTRO:
            let validarRegistro = action.data
            return Object.assign({}, state, {
                data: validarRegistro,
            })
        default:
            return state
    }
}

export default reducerValidarUserRegistro;