import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

//styles
import "./documentos.css"

export default function Documentos() {
  const listaContratos = useSelector(
    (state) => state.reducerGuardarIdContrato.data
  );

  if (listaContratos != null) {
    var listaContratosArray = [];
    for (var i = 0; i < listaContratos.data.length; i++) {
      listaContratosArray.push(
        <div key={i} className="contratos-user">
          <div className="contratos-user-col">
            <img src={
                "https://www.blackhound.com.ar/server/public/getImgId/" +
                listaContratos.data[i].idProyecto
              }
            />
            <p>{listaContratos.data[i].nombre}</p>
          </div>
          <div className="contratos-user-col">
            {/* <a target="_blank" href={"http://127.0.0.1:8000/getDocumentacionProyectoUser/" + listaContratos.data[i].id }  >Ver</a> */}
            <a
              href={
                "https://www.blackhound.com.ar/server/public/getDocumentacionProyectoUser/" +
                listaContratos.data[i].id
              }
              download
            >
              Descargar
            </a>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="containerDocumentos">
      <h5 className="seccion-usuario-titulo">DOCUMENTOS</h5>
      {listaContratos != null && listaContratos.data != 0?
        <div>{listaContratosArray}</div>
        :
        <div className="sin-contratos-container">
          <img src="https://res.cloudinary.com/blackhound/image/upload/v1661897970/BLACKHOUND/two-files-folders-svgrepo-com_2_ecm7ek.svg" />
          <p>No hay documentos disponibles</p>
        </div>
      }
    </div>
  );
}
