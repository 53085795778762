import React, { useState } from 'react'
import "./validarMail.css"
import axios from 'axios';
import Footer from '../../Components/Footer/Footer';

export default function ValidarMail() {

    const [codigo, setCodigo] = useState({
        codigo1: "",
        codigo2: "",
        codigo3: "",
        codigo4: ""
    })

    function handleCodigo(e) {
        setCodigo({
            ...codigo,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = () => {
        var codigoMail = String(codigo.codigo1) + String(codigo.codigo2) + String(codigo.codigo3) + String(codigo.codigo4);
        axios.post(`https://blackhound.com.ar/server/public/setCodigoUser`, codigoMail)
            .then(function (response) {
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    return (
        <>
            <div className='validar-mail-container'>
                <div className='validar-mail-box'>
                    <h5>Verificación de registro</h5>
                    <p className='text-validar-mail-box'>
                        Te hemos enviado un <span>código</span> a la casilla de correo ingreada anteriormente.<br />
                        Para completar la verificación, por favor <span>ingrese el código de activación</span> de 4 dígitos enviado.
                    </p>
                    <form className='formulario-validar-mail' >
                        <div className='fomulario-campo'>
                            <input onChange={handleCodigo} name="codigo1" className='campo-codigo' pattern="/^[0-9]*$/i" type="text" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" required />
                            <input onChange={handleCodigo} name="codigo2" className='campo-codigo' pattern="/^[0-9]*$/i" type="text" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" required />
                            <input onChange={handleCodigo} name="codigo3" className='campo-codigo' pattern="/^[0-9]*$/i" type="text" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" required />
                            <input onChange={handleCodigo} name="codigo4" className='campo-codigo' pattern="/^[0-9]*$/i" type="text" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" required />
                        </div>
                        <input type="submit" className='boton-registrarse' value="ENVIAR CÓDIGO" onClick={(onSubmit)} />
                    </form>
                </div>
            </div>
        </>
    )
}
