import apiInstance from './api'

export const enviarDocumentos = (params) => {
    return apiInstance.post('/api/setImgDni',params, { headers:{"Content-Type": "multipart/form-data"}})
    .then(res =>{
            return res.data;
    })

    .catch(error => {
        console.error(error.response);
        throw error.response
    });
}

export const recibirIdsDocumentos = (params) => {
    return apiInstance.get('/idsImgDni/' + params)
    .then(res =>{
            return res.data;
    })

    .catch(error => {
        console.error(error.response);
        throw error.response
    });
}