import { combineReducers } from "redux"
import { routerReducer } from "react-router-redux"
import reducerRegistro from "./registroUser";
import reducerlistaClientesRegistrados from "./listaClientesRegistrados";
import reducerCrearProyecto from "./crearProyecto";
import reducerListaMonedas from "./listaMonedas";
import reducerlistaSolicitudesRegistradas from "./listaSolicitudesRegistrados";
import reducerMostrarProyecto from "./mostrarProyectos";
import reducerCategoriasProyecto from "./categoriasProyecto"
import reducerDashboard from './dashboard';
import reducerUsuarioDatos from './usuarioDatos'
import reducerInversionesUsuario from "./inversionesUsuario";
import reducerDocumentos from "./documentosUsuario";
import reducerLogin from "./login";
import reducerEstadisticasUsuario from "./estadisticasUsuario";
import reducerDolar from './dolar';
import reducerNotificacionesUsuario from "./notificacionesUser";
import reducerListaAsesores from "./listaAsesores";
import reducerTurnosAsesor from "./turnosAsesor";
import reducerAgregarAsesorSolicitud from "./agregarAsesorSolicitud";
import reducerImagenPerfil from "./imagenPerfil";
import reducerGuardarIdContrato from "./recibirContrato";
import reducerObtenerChat from "./obtenerChat";
import reducerGuardarIdChat from "./guardarIdChat";
import reducerValidarUserRegistro from "./ValidarUserRegistro";
// import reducerTurno from "./turno";
import reducerListaTurno from "./turnosUser";
import reducerAllConversaciones from "./allConversacionesAsesor";
import reducerListaSolicitudesAdministrador from "./listaSolicitudesAdministrador";

const rootReducer = combineReducers({
    routing: routerReducer,
    reducerRegistro:reducerRegistro,
    reducerlistaClientesRegistrados:reducerlistaClientesRegistrados,
    reducerCrearProyecto:reducerCrearProyecto,
    reducerListaMonedas:reducerListaMonedas,
    reducerlistaSolicitudesRegistradas:reducerlistaSolicitudesRegistradas,
    reducerMostrarProyecto:reducerMostrarProyecto,
    reducerCategoriasProyecto:reducerCategoriasProyecto,
    reducerDashboard:reducerDashboard,
    reducerUsuarioDatos:reducerUsuarioDatos,
    reducerInversionesUsuario:reducerInversionesUsuario,
    reducerDocumentos:reducerDocumentos,
    reducerLogin:reducerLogin,
    reducerEstadisticasUsuario:reducerEstadisticasUsuario,
    reducerDolar:reducerDolar,
    reducerNotificacionesUsuario:reducerNotificacionesUsuario,
    reducerListaAsesores: reducerListaAsesores,
    reducerTurnosAsesor: reducerTurnosAsesor,
    reducerAgregarAsesorSolicitud: reducerAgregarAsesorSolicitud,
    reducerImagenPerfil:reducerImagenPerfil,
    reducerGuardarIdContrato: reducerGuardarIdContrato,
    reducerObtenerChat: reducerObtenerChat,
    reducerGuardarIdChat: reducerGuardarIdChat,
    reducerValidarUserRegistro:reducerValidarUserRegistro,
    // reducerTurno: reducerTurno,
    reducerListaTurno: reducerListaTurno,
    reducerAllConversaciones: reducerAllConversaciones,
    reducerListaSolicitudesAdministrador: reducerListaSolicitudesAdministrador,
})

export default rootReducer;