//THREE JS
import * as THREE from "three"
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';

let currentMount = null
const floatPoints = []
const floatPoints2 = []
const floatPoints3 = []
const floatPoints4 = []

//SCENE
    const scene = new THREE.Scene();
    scene.background = new THREE.Color('#000000')
    const cameraGroup = new THREE.Group()
    scene.add(cameraGroup)

    const camera = new THREE.PerspectiveCamera(
        25,
        100 / 100,
        0.1,
        1000
    )
    scene.add(camera);
    camera.position.z = 60
    camera.position.y = 10
    camera.position.x = 15
    camera.lookAt(new THREE.Vector3());

//RENDERER
    const renderer = new THREE.WebGLRenderer()

//ORBIT CONTROLS
    const controls = new OrbitControls(camera, renderer.domElement)
    controls.enableDamping = true;
    controls.enabled = false;

//RESIZE
const resize = () => {
    
    renderer.setSize(
        currentMount.clientWidth,
        currentMount.clientHeight
    )
    camera.aspect = currentMount.clientWidth / currentMount.clientHeight
    camera.updateProjectionMatrix()
}
window.addEventListener('resize', resize)

//PIEZAS MODELO
    //MODEL GLTF LOADER
    const cubo1 = new GLTFLoader()
    cubo1.name = "cubo 1"
    const cube1Material = new THREE.MeshBasicMaterial()
    cubo1.material = cube1Material
    cubo1.load('./cubo/piezas/Cubo_1.glb',
        (gltf) => { 
        gltf.scene.position.set(-7, -24.5, -50)
        gltf.scene.rotation.set(0, 0, 0)
        gltf.scene.scale.set(0.4,0.4,0.4)
        scene.add(gltf.scene)
    },
        () => {  },
        () => {  },
    )

    //MODEL GLTF LOADER
    const cubo2 = new GLTFLoader()
    cubo2.name = "cubo 2"
    const cube2Material = new THREE.MeshBasicMaterial()
    cubo2.material = cube2Material
    cubo2.load('./cubo/piezas/Cubo_2.glb',
        (gltf) => { 
        gltf.scene.position.set(-7, -24, -50)
        gltf.scene.rotation.set(0, 0, 0)
        gltf.scene.scale.set(0.4,0.4,0.4)
        scene.add(gltf.scene)
    },
        () => {  },
        () => {  },
    )

    //MODEL GLTF LOADER
    const cubo3 = new GLTFLoader()
    cubo3.load('./cubo/piezas/Cubo_3.glb',
        (gltf) => { 
        gltf.scene.position.set(-7.5, -24, -50)
        gltf.scene.rotation.set(0, 0, 0)
        gltf.scene.scale.set(0.4,0.4,0.4)
        scene.add(gltf.scene)
    },
        () => {  },
        () => {  },
    )

    //MODEL GLTF LOADER
    const cubo4 = new GLTFLoader()
    cubo4.load('./cubo/piezas/Cubo_4.glb',
        (gltf) => { 
        gltf.scene.position.set(-7.5, -24.5, -50)
        gltf.scene.rotation.set(0, 0, 0)
        gltf.scene.scale.set(0.4,0.4,0.4)
        scene.add(gltf.scene)
    },
        () => {  },
        () => {  },
    )


//LIGHTS
    //AMBIENT LIGHT
    const AO = new THREE.AmbientLight(0xffffff, 1)
    scene.add(AO)

    //SPOT LIGHT
    const spotLight = new THREE.SpotLight( 0xffffff, 1 );
    spotLight.position.set( -40, 7, 120 );
    spotLight.angle = Math.PI / 2;
    spotLight.distance = 200;
    scene.add( spotLight );

    //POINT LIGHT
    const pointLight1 = new THREE.PointLight(0xffffff, 1)
    pointLight1.position.set(0, 50, 30)
    scene.add(pointLight1)

    //POINT LIGHT
    const pointLight2 = new THREE.PointLight(0xffffff, 1)
    pointLight2.position.set(50, 50, 0)
    scene.add(pointLight2)

    //DIRECTIONAL LIGHT
    const directionalLight = new THREE.DirectionalLight(
        0xffffff,
        0.5
    )
    directionalLight.position.set(20, 20, 50)
    //scene.add(directionalLight)

//HDR ENVIRONMENT MAP
const enviromentMap = new THREE.CubeTextureLoader()
const envMap = enviromentMap.load([
    './media/hdrFullLight/px.png',
    './media/hdrFullLight/nx.png',
    './media/hdrFullLight/py.png',
    './media/hdrFullLight/ny.png',
    './media/hdrFullLight/pz.png',
    './media/hdrFullLight/nz.png'
])
//SCENA CON ENVMAP
scene.environment = envMap
//FONDO ENVMAP
//scene.background = envMap

//RAYCASTER
const raycaster = new THREE.Raycaster()
//POINTER
const pointer = new THREE.Vector2(-100, -100)
function onPointerMove(event){
    pointer.x = (event.clientX / window.innerWidth) * 2 - 1
    pointer.y = -(event.clientY / window.innerHeight) * 2 + 1
}
window.addEventListener("pointermove", onPointerMove)

//RENDER SCENE
const animate = () => {

    raycaster.setFromCamera(pointer, camera)
    const intersects = raycaster.intersectObjects(
        scene.children,
        true
    )

    //MOUSE LEAVE
    scene.traverse(child => {
        if (child instanceof THREE.Mesh){
            child.material.color.set(0xffffff)
        }
    })

    //MOUSE HOVER
    if(intersects.length){
        intersects[0].object.material.color.set(0xF73525)
    }

    //MOVE FLOAT POINT
    for(const floatPoint of floatPoints){
        const screenPositions = floatPoint.position.clone()
            screenPositions.project(camera)
        const positionX = 
            screenPositions.x * currentMount.clientWidth * 0.5
        const positionY = 
            screenPositions.y * -currentMount.clientHeight * 0.5

        floatPoint.element.style.transform = `translate(${positionX}px, ${positionY}px)`
    }

        controls.update()
        renderer.render(scene,camera)
        requestAnimationFrame(animate)
    }
    animate()

//INICIA SCENE
    export const mountScene4 = (mountRef4) => {
        currentMount = mountRef4.current
        resize()
        currentMount.appendChild(renderer.domElement)
    }

//CLEAN SCENE
    export const cleanUpScene4 = () => {
        scene.dispose()
        currentMount.removeChild(renderer.domElement)
    }

//FETCH POINTS
export const fetchFloatPointsElements1 = () => {
    floatPoints.push({
        position: new THREE.Vector3(10, 25, 0),
        element: document.querySelector(".float-point-0")
    })
}

export const fetchFloatPointsElements2 = () => {
    floatPoints2.push({
        position2: new THREE.Vector3(1, 25, 0),
        element2: document.querySelector(".float-point1")
    })
}

export const fetchFloatPointsElements3 = () => {
    floatPoints3.push({
        position3: new THREE.Vector3(1, 25, 20),
        element3: document.querySelector(".float-point-2")
    })
}

export const fetchFloatPointsElements4 = () => {
    floatPoints4.push({
        position: new THREE.Vector3(1, 25, 0),
        element: document.querySelector(".float-point-3")
    })
}
