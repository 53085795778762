import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';
import { listaSolicitudes } from'../api/listaSolicitudes';
import { setlistaSolicitudesRegistradas } from "../Actions/listaSolicitudesRegistradas"; 

export const watchListaSolicitudesRegistrados = function * (){
    yield takeEvery(actionTypes.GET_LISTA_SOLICITUDES_REGISTRADAS, fetchListaSolicitudesRegistradas)
}

function* fetchListaSolicitudesRegistradas(action){
    try{
        const data = yield call(listaSolicitudes, action.data)
        if(data){
            yield put(setlistaSolicitudesRegistradas(data))
        }
    }catch(err){
        console.log(err)
    }
}