import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from 'redux-saga/effects';
import { registroUser } from '../api/registroUser';
// import {  toast } from 'react-toastify';
import { Link, Navigate, Routes, Route } from 'react-router-dom';
import IniciarSesion from "../Pages/IniciarSesion/IniciarSesion";
import { setValidarUserRegistro } from "../Actions/ValidarUerRegistro";

export const watchRegistroUser = function* () {
    yield takeEvery(actionTypes.ENVIO_REGISTRO_USUARIO, fetchRegistro)
}

function* fetchRegistro(action) {
    try {
        const data = yield call(registroUser, action.data)
        if (data) {
            if (data.message === "Usuario creado") {
                //yield put(getRegistro(data))
                // toast.success('¡Gracias por registrarse!', {
                // });            
                if (data.token) {
                    localStorage.setItem('token', data.token);
                    // setTimeout(()=>{
                    //     {window.location = '/HomeUsuarios/Dashboard'}
                    // }, 2000);
                }
            }
            yield put(setValidarUserRegistro(data))
        }
    } catch (err) {
        console.log(err)
        // toast.error('Error al crear su registro, revise los campos', {}); 
    }
}