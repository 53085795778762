import React, { useState, useEffect } from 'react';
import { Sidenav, Nav } from 'rsuite';
import {
  NavLink,
  Routes,
  Route,
} from "react-router-dom";
import { useSpring, animated } from 'react-spring';

// import default style
import 'rsuite/dist/rsuite.min.css';

// import views
import Usuarios from './Vistas/Usuarios/Usuarios';
import Clientes from './Vistas/Clientes/Clientes';
import ProyectosActivos from '../Admin/Vistas/ProyectosActivos/ProyectosActivos';
import Solicitudes from '../Admin/Vistas/Solicitudes/Solicitudes';
import Inversiones from './Vistas/Inversiones/inversiones';
import CrearProyecto from '../Admin/Vistas/CrearProyecto/CrearProyecto';
import Dashboard from '../Admin/Vistas/Dashboard/Dashboard';
import Notificaciones from '../Usuarios/Vistas/Notificaciones/Notificaciones';
import Asesores from './Vistas/Asesores/asesores';
import TurnosAdd from './Vistas/Turnos/turnosAdd';
import SolicitudInversion from '../Usuarios/Vistas/UserDashboard/DashboardPasos/ContenidoAcordion/SolicitudInversion/SolicitudInversion';

// import css
import './homeadmin.css'

//Actions 
import { getListaMonedas } from "./../Actions/listaMonedas";
import { useSelector, useDispatch } from 'react-redux';

/* Confirmadion edu commit- 16/8/22*/
import { useMediaQuery } from 'react-responsive'
import NoExisteMobile from './Vistas/NoExisteMobile'
import Proyectos from './Vistas/Proyectos/Proyectos';

import { setCrearNotificacion } from '../Actions/crearNotificacion';
import { contadorNotificaciones } from '../Actions/notificacionUsuario';

export default function HomeAdmin() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getListaMonedas())
  }, []);

  const vistaMobile = useMediaQuery({
    query: '(min-width: 768px)'
  })

  const fadeOut = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 1500,
  });

  function handleCrearNotificacion() {
    const pruebaNotificacion = {
      iduser : 47,
      contenido: '1111 holaaa tonyyy gasti'
    }
    dispatch(setCrearNotificacion(pruebaNotificacion))
  }
  
  return (
    <>
    <animated.div style={fadeOut}>
      {vistaMobile ?
        <>
          <div className='containerAdmin'>

            <div className='sideBarAdmin'>
              <div className='childSidebar '>
                {/* <button style={{color: 'black'}} onClick={handleCrearNotificacion} >
                  Crear notificacion
                </button> */}

                <NavLink className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to="dashboard">
                  <span><img className='navAdmin' alt='Informacio-general-icon' src='https://res.cloudinary.com/blackhound/image/upload/v1660658186/BLACKHOUND/logoDashx2_wufruo.webp' /></span>
                  <p>Dashboard</p>
                </NavLink>

                <Sidenav>
                  <Nav>
                    <Nav.Menu
                      icon={<img width="30px" src='https://res.cloudinary.com/blackhound/image/upload/v1661342486/BLACKHOUND/sideNavBar/Asesor/Solicitudes_r2dv0x.svg' />}
                      trigger="hover"
                      title={<p className='navMenu'>Solicitudes</p>}
                      placement="rightStart"
                      style={{ fontSize: '18px', fontFamily: "Poppins" }}
                    >
                      <NavLink className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to="usuarios">
                        <span className='nav-icon-trans'><img className='navAdmin' alt='Informacio-general-icon' src='https://res.cloudinary.com/grupo-delsud/image/upload/v1647895574/Blackhound/InformacionGeneral_hsq1qp.svg' /></span>
                        <p>Usuarios</p>
                      </NavLink>
                      <NavLink className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to="solicitudes-inversion">
                        <span className='nav-icon-trans'><img className='navAdmin' alt='Informacio-general-icon' src='https://res.cloudinary.com/grupo-delsud/image/upload/v1647895574/Blackhound/InformacionGeneral_hsq1qp.svg' /></span>
                        <p>Inversiones</p>
                      </NavLink>
                    </Nav.Menu>

                    <NavLink className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to="asesores">
                      <span><img className='navAdmin' alt='Crear-Proyecto-icon' src='https://res.cloudinary.com/blackhound/image/upload/v1660658014/BLACKHOUND/logoAsesoresx2-_u200sw.webp' /></span>
                      <p>Asesores</p>
                    </NavLink>

                    <NavLink className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to="clientes">
                      <span><img className='navAdmin' alt='Informacio-general-icon' src='https://res.cloudinary.com/blackhound/image/upload/v1660658067/BLACKHOUND/logoClientesx2_xlvhqg.webp' /></span>
                      <p>Clientes</p>
                    </NavLink>

                    <NavLink className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to="inversiones">
                      <span><img className='navAdmin' alt='Informacio-general-icon' src='https://res.cloudinary.com/blackhound/image/upload/v1661342537/BLACKHOUND/sideNavBar/Asesor/Inversiones_nmstkj.svg' /></span>
                      <p>Inversiones</p>
                    </NavLink>

                    <NavLink className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to="proyectos">
                      <span><img className='navAdmin' alt='Crear-Proyecto-icon' src='https://res.cloudinary.com/blackhound/image/upload/v1661799068/BLACKHOUND/sideNavBar/Admin/Proyecto_odrlbb.svg' /></span>
                      <p>Proyectos</p>
                    </NavLink>

                    <NavLink className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to="turnos">
                      <span><img className='navAdmin' alt='Crear-Proyecto-icon' src='https://res.cloudinary.com/blackhound/image/upload/v1660658126/BLACKHOUND/logoTurnosx2_oaj6h6.webp' /></span>
                      <p>Turnos</p>
                    </NavLink>

                  </Nav>
                </Sidenav>

              </div>
            </div>
            <div className='routesAdmin'>
              <Routes path="/*">
                <Route index element={<Dashboard />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="usuarios" element={<Usuarios />} />
                <Route path="inversiones" element={<Inversiones />} />
                <Route path="asesores" element={<Asesores />} />
                <Route path="clientes" element={<Clientes />} />
                <Route path="proyectosActivos" element={<ProyectosActivos />} />
                <Route path="solicitudes-inversion" element={<Solicitudes />} />
                <Route path="crearProyecto" element={<CrearProyecto />} />
                <Route path="notificaciones" element={<Notificaciones />} />
                <Route path="proyectos" element={<Proyectos />} />
                <Route path="turnos" element={<TurnosAdd />} />

                <Route path="dashboard/solicitudInversion" element={<SolicitudInversion />} />
              </Routes>
            </div>
            <div className='boxPositionAdmin'>
              <img alt='gotaDeAgua' src='https://res.cloudinary.com/blackhound/image/upload/v1660917252/BLACKHOUND/gotitadeaguaVistas_v2zou6.png' />
            </div>
          </div>
        </>

        :
        <NoExisteMobile />

        }
      
      </animated.div>
    </>
  )
}




