import React, { useEffect, useState } from "react";
import { Panel, PanelGroup, Placeholder, Loader } from "rsuite";
import { useSpring, animated } from "react-spring";
import { useSelector, useDispatch } from "react-redux";
import { getMostrarProyecto } from "../../../Actions/mostrarProyectos";
import ContenidoAcordion from "./contenidoAcordion/ContenidoAcordion";
import ModalCrearProyecto from "./ModalCrearProyecto/ModalCrearProyecto";

export default function Proyectos() {
  const [modalCrearProyecto, setModalCrearProyecto] = useState(false);
  const [modalEditarProyecto, setModalEditarProyecto] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [estadoFiltro, setEstadoFiltro] = useState(null);
  const [monedita, setMonedita] = useState(null);
  const [busqueda, setBusqueda] = useState({
    dataBase: "",
  });

  const listaProyectos = useSelector(
    (state) => state.reducerMostrarProyecto.data
  );

  const dispatch = useDispatch();
  useEffect(() => {
    const data = {
      busqueda: busqueda.dataBase,
      estado: estadoFiltro,
      page: activePage,
      categoria: estadoFiltro,
      moneda: monedita,
    };
    dispatch(getMostrarProyecto(data));
  }, [activePage, busqueda, estadoFiltro, monedita]);

  if (listaProyectos != null) {
    var accordionArray = [];
    const datosProyectos = listaProyectos.data;
    for (var i = 0; i < listaProyectos.data.length; i++) {
      accordionArray.push(
        <Panel
          header={
            <div className="rs-panel-header header-accordion" style={{ backgroundColor: datosProyectos[i].color }}>
              <img
                className="logo-proyecto"
                src={
                  "https://www.blackhound.com.ar/server/public/getImgId/" +
                  datosProyectos[i].idImagenUno
                }
              />
            </div>
          }
          eventKey={i}
          id={"panel" + i}
        >
          <ContenidoAcordion data={datosProyectos[i]} />
        </Panel>
      );
    }
  }

  function handleCrearProyecto() {
    if (modalCrearProyecto) {
      setModalCrearProyecto(false);
    } else {
      setModalCrearProyecto(true);
    }
  }

  const [loading, setLoading] = useState(true);
  {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  const fadeOut = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 1000,
  });

  return (
    <>
      {loading ? (
        <div className="loading">
          <Loader className="loadingColor" size="lg" />
        </div>
      ) : (
        <animated.div style={fadeOut}>
          <div className="containerProyects">
            <div style={{ color: "white", fontSize: "32px", fontWeight:"500" }} className='user-wellcome separacion-usuarios'>PROYECTOS</div>
            <div className="boton-flex">
              <input
                className="button-add"
                type="button"
                onClick={handleCrearProyecto}
                value="Agregar proyecto"
              />
            </div>
            <PanelGroup className="acordion-proximas-inversiones" accordion>
              {accordionArray}
            </PanelGroup>

            <ModalCrearProyecto dataModalCrearProyecto={modalCrearProyecto} />
          </div>
        </animated.div>
      )}
    </>
  );
}
