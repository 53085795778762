import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';
import { enviarMailUsuario } from'../api/enviarMailUsuario';
// import {  toast } from 'react-toastify';

export const watchEnviarMailUsuario = function * (){
    yield takeEvery(actionTypes.SEND_MAIL_USUARIO, fetchEnviarMailUsuario)
}

function* fetchEnviarMailUsuario(action){
    try{
        const data = yield call(enviarMailUsuario, action.data)
        if(data){
            // toast.success('Mail enviado correctamente', {
            // });

        }
    }catch(err){
        console.log(err)
        // toast.error('Error al enviar Mail', {     
        // }); 
    }
}

