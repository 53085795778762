import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

//ACTION
import { setEstadoSolicitud } from '../../../../Actions/estadoSolicitud';
import { getlistaSolicitudesRegistradas } from '../../../../Actions/listaSolicitudesRegistradas';
import { sendMailSolicitud } from '../../../../Actions/enviarMailSolicitud';
import { Modal } from 'rsuite';

const ModalAprobadoSolicitud = (props) => {
    const data3 = props.dataModalAprobadoSolicitud;
    const [open, setOpen] = useState(data3 != null);
    const [backdrop, setBackdrop] = useState('static');
    const handleClose = () => setOpen(false);
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleCloseConfirm = () => setShow(false);

    const [activePage, setActivePage] = useState(1)
    const [estadoCategoria, setEstadoCategoria] = useState(1)
    const [busqueda, setBusqueda] = useState({
        dataBase: ""
    })

    useEffect(() => {
        if (data3 != null) {
            setOpen(data3);
        }
    }, [data3])

    const handleAprobarSolicitud = () => {
        const dataSolicitud = {
            id: data3.idSolicitud,
            idEstado: 2,
        }
        const data = {
            busqueda: busqueda.dataBase,
            categoria: estadoCategoria,
            page: activePage,
        }
        const mailSolicitudAprobada = {
            email: data3.email
        }
        if (dataSolicitud && data != null) {
            dispatch(setEstadoSolicitud(dataSolicitud));
            setTimeout(() => {
                dispatch(getlistaSolicitudesRegistradas(data));
                dispatch(sendMailSolicitud(JSON.stringify(mailSolicitudAprobada)));
                handleShow()
            }, 1500);

        }
        setOpen(false);
    }
    return (
        <>
            {(open) &&
                    <Modal className='proyecto-modal infoModal' backdrop={backdrop} open={open} size="md" onClose={handleClose} >
                        <Modal.Header className='headerModal'>
                            <Modal.Title className='headerModal'>
                                <div>
                                    <h4 style={{ margin: 'auto', color: 'white', textAlign: 'center' }}> ¡Mensaje Importante! </h4>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='textModal'>
                                ¿Desea realmente aprobar esta solicitud? <br></br>
                                Seleccione "Si" para aprobarla <br></br>
                                o seleccione "No" para cancelar.
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='contFooter'>
                            <button className='buttonModal' onClick={handleAprobarSolicitud}> Si </button>
                            <span style={{ padding: '10px' }}></span>
                            <button className='buttonModal' onClick={handleClose}> No </button>
                        </Modal.Footer>
                    </Modal>
                }   
                    {/* MODAL */}
                    <Modal centered open={show} onClose={handleCloseConfirm} backdrop="static" >
                        <Modal.Header>
                            <Modal.Body
                                style={{ fontSize: "20px", color: "white", textAlign: "center" }}
                            > Solicitud aprobada correctamente.
                            </Modal.Body>
                        </Modal.Header>
                        <Modal.Footer className='contFooter'>
                            <button className='buttonModal' onClick={handleCloseConfirm}> Cerrar </button>
                        </Modal.Footer>
                    </Modal>
        </>
    );
}

export default ModalAprobadoSolicitud;
