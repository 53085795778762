import { actionTypes } from "../Constantes/ActionTypes";
import { store } from "../store/index";

function reducerDashboard (state = store.dashboard, action){
    switch(action.type){
        case actionTypes.DASHBOARD_RECIBE_SET_USER:
            let DashboardGeneral = action.data
            return Object.assign({}, state, {
                data: DashboardGeneral,
            })
            default:
                return state
    }
}

export default reducerDashboard;