import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';
import { apiEditarPerfil } from "../api/editarPerfil";

// import {  toast } from 'react-toastify';

export const watchEditarPerfil = function * (){
    yield takeEvery(actionTypes.SET_EDITAR_PERFIL, fetchEditarPerfil)
}

function* fetchEditarPerfil(action){
    try{
        const data = yield call(apiEditarPerfil, action.data)
        if(data){
            // yield put(editarPerfil(data))
            // toast.success('Perfil editado correctamente', {});
        }
    }catch(err){
        console.log(err)
    }
}