export const actionTypes = {
  DASHBOARD_RECIBE_GET_USER: "DASHBOARD_RECIBE_GET_USER",
  DASHBOARD_RECIBE_SET_USER: "DASHBOARD_RECIBE_SET_USER",

  ENVIO_REGISTRO_USUARIO: "ENVIO_REGISTRO_USUARIO",

  SET_ESTADO_CLIENTE: "SET_ESTADO_CLIENTE",
  SET_ESTADO_SOLICITUD: "SET_ESTADO_SOLICITUD",

  CREAR_PROYECTO_NUEVO: "CREAR_PROYECTO_NUEVO",

  LISTA_DE_MONEDAS: "LISTA_DE_MONEDAS",
  SET_LISTA_DE_MONEDAS: "SET_LISTA_DE_MONEDAS",

  MOSTRAR_LOS_PROYECTOS: "MOSTRAR_LOS_PROYECTOS",
  SET_MOSTRAR_LOS_PROYECTOS: "SET_MOSTRAR_LOS_PROYECTOS",

  GET_CATEGORIA_PROYECTOS: "GET_CATEGORIA_PROYECTOS",
  SET_CATEGORIA_PROYECTOS: "SET_CATEGORIA_PROYECTOS",

  SET_EDIT_PROYECTO: "SET_EDIT_PROYECTO",

  ENVIAR_NUEVA_CITA: "ENVIAR_NUEVA_CITA",

  ENVIAR_DOCUMENTOS_USUARIO: "ENVIAR_DOCUMENTOS_USUARIO",
  GET_RECIBIR_DOCUMENTOS_USUARIO: "GET_RECIBIR_DOCUMENTOS_USUARIO",
  SET_RECIBIR_DOCUMENTOS_USUARIO: "SET_RECIBIR_DOCUMENTOS_USUARIO",

  SET_ASESOR_A_SOLICITUD: "SET_ASESOR_A_SOLICITUD",
  GET_ASESOR_A_SOLICITUD: "GET_ASESOR_A_SOLICITUD",

  SET_TURNO_ASESOR: "SET_TURNO_ASESOR",
  GET_TURNO_ASESOR: "GET_TURNO_ASESOR",

  GET_USUARIO_DATA: "GET_USUARIO_DATA",
  SET_USUARIO_DATA: "SET_USUARIO_DATA",

  GET_INVERSIONES_USUARIO: "GET_INVERSIONES_USUARIO",
  SET_INVERSIONES_USUARIO: "SET_INVERSIONES_USUARIO",

  GET_NOTIFICACIONES_USUARIO: "GET_NOTIFICACIONES_USUARIO",
  SET_NOTIFICACIONES_USUARIO: "SET_NOTIFICACIONES_USUARIO",

  //SOLICITUDES
  GET_LISTA_SOLICITUDES_REGISTRADAS: "GET_LISTA_SOLICITUDES_REGISTRADAS",
  SET_LISTA_SOLICITUDES_REGISTRADAS: "SET_LISTA_SOLICITUDES_REGISTRADAS",

  //CLIENTES
  GET_LISTA_CLIENTES_REGISTRADOS: "GET_LISTA_CLIENTES_REGISTRADOS",
  SET_LISTA_CLIENTES_REGISTRADOS: "SET_LISTA_CLIENTES_REGISTRADOS",

  //ENVIO MAILS
  SEND_MAIL_USUARIO: "SEND_MAIL_USUARIO",
  SEND_MAIL_SOLICITUD: "SEND_MAIL_SOLICITUD",


  //LOGIN
  VERIFICAR_INGRESO_LOGIN: "VERIFICAR_INGRESO_LOGIN",
  RESULT_VERIFICAR_LOGIN: "RESULT_VERIFICAR_LOGIN",
  SET_LOGIN: "SET_LOGIN",

  //DASHBOARD USUARIO
  GET_ESTADISTICAS_USUARIO: "GET_ESTADISTICAS_USUARIO",
  SET_ESTADISTICAS_USUARIO: "SET_ESTADISTICAS_USUARIO",

  //API DOLAR
  GET_DOLAR: "GET_DOLAR",
  SET_DOLAR: "SET_DOLAR",

  //LISTA ASESORES
  GET_LISTA_ASESORES: "GET_LISTA_ASESORES",
  SET_LISTA_ASESORES: "SET_LISTA_ASESORES",

  //IMAGEN PERFIL
  GET_IMAGEN_PERFIL: "GET_IMAGEN_PERFIL",
  SET_IMAGEN_PERFIL: "SET_IMAGEN_PERFIL",

  //CONTRATOS
  ENVIAR_CONTRATO: "ENVIAR_CONTRATO",
  GET_ID_CONTRATO: "GET_ID_CONTRATO",
  SET_ID_CONTRATO: "SET_ID_CONTRATO",

  //EDITAR PERFIL
  SET_EDITAR_PERFIL: "SET_EDITAR_PERFIL",

  //CHAT
  ENVIAR_MENSAJE: "ENVIAR_MENSAJE",
  GET_CONVERSACION: "GET_CONVERSACION",
  SET_CONVERSACION: "SET_CONVERSACION",
  SET_ID_CLIENTE: 'SET_ID_CLIENTE',
  CANTIDAD_MENSAJES: 'CANTIDAD_MENSAJES',
  ALL_CANTIDAD_MENSAJES: 'ALL_CANTIDAD_MENSAJES',
  TODOS_LAS_CONVERSACIONES: 'TODOS_LAS_CONVERSACIONES',
  SET_TODOS_LAS_CONVERSACIONES: 'SET_TODOS_LAS_CONVERSACIONES',
  ESTADO_DE_MENSAJES: 'ESTADO_DE_MENSAJES',
  TRAER_CONVERSACION: 'TRAER_CONVERSACION',

  //VALIDAR USER REGISTRO
  SET_VALIDAR_USER_REGISTRO: "SET_VALIDAR_USER_REGISTRO",

  //MAIL CODIGO
  SEND_MAIL_CODIGO: "SEND_MAIL_CODIGO,",

  //TURNO
  SET_TURNO_USER: "SET_TURNO_USER",
  GET_TURNO_USER: "GET_TURNO_USER",
  GUARDAR_TURNO_USER: "GUARDAR_TURNO_USER",

  //CANCELAR TURNO
  CANCELAR_TURNO: "CANCELAR_TURNO",

  //CREAR CATEGORIA
  SET_CREAR_CATEGORIA: "SET_CREAR_CATEGORIA",

  //NOTIFICAR POR MAIL USUARIO
  CONTADOR_NOTIFICACION: 'CONTADOR_NOTIFICACION',
  NOTIFICAR_MAIL_USUARIO: 'NOTIFICAR_MAIL_USUARIO',
  CAMBIAR_ESTADO_NOTIFICACIONES: 'CAMBIAR_ESTADO_NOTIFICACIONES',

  //CREAR NOTIFICACION
  SET_CREAR_NOTIFICACION: 'SET_CREAR_NOTIFICACION',

  //CANCELAR TURNO
  CANCELAR_UN_TURNO:"CANCELAR_UN_TURNO",

  //ASIGNAR ASESOR AL USUARIO
  AGREGAR_ASESOR_A_UN_USUARIO: "AGREGAR_ASESOR_A_UN_USUARIO",

  //TREAER Y ENVIAR ESTADO 1 Y 4 SOLICITUDES PARA ADMINISTRADOR
  GET_TRAER_SOLICITUDES_ADMINISTRADOR: "GET_TRAER_SOLICITUDES_ADMINISTRADOR",
  SET_TRAER_SOLICITUDES_ADMINISTRADOR: "SET_TRAER_SOLICITUDES_ADMINISTRADOR",

  //UPDATE ASESOR
  GET_UPDATE_ASESOR: "GET_UPDATE_ASESOR",

  //ELIMINAR DOCUMENTACION DEL USUARIO
  ELIMINAR_DOCUMENTACION_USUARIO: "ELIMINAR_DOCUMENTACION_USUARIO",
};
