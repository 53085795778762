import React from 'react'
import './preguntasfrecuentes.css'
import { Tab, Row,Col,Nav,Accordion} from 'react-bootstrap';

export default function PreguntasFrecuentes() {
    

    return (
    <div className='preguntas-frecuentes-container'>

        <div className='preguntas-frecuentes-titular' data-aos="zoom-in" data-aos-duration="1000"  data-aos-delay="300">
            <span className='preguntas-frecuentes-barra'> </span>
            <h5 className='preguntas-frecuentes-titulo'>PREGUNTAS FRECUENTES</h5>
        </div>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first" >
            <Row>
                <Col sm={3}>
                    
                <Nav variant="pills" className="flex-column" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="800">
                    <Nav.Item>
                    <Nav.Link className='paddingMobile' eventKey="first">Empresa</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link className='paddingMobile' eventKey="second">Invertir</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Nav.Link className='paddingMobile' eventKey="third">Proyectos</Nav.Link>
                    </Nav.Item>
                </Nav>
                </Col>
                <Col sm={9}>
                <Tab.Content data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="1200">
                    <Tab.Pane eventKey="first">
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item className='itemAsk' eventKey="0">
                                <Accordion.Header className='askFrecuentHeader'> ¿Qué es Black Hound?</Accordion.Header>
                                <Accordion.Body className='askFrecuentBody'>
                                    <p className='text-tab-desc'>
                                        Somos una plataforma de inversión que llegó para revolucionar 
                                        el mundo de las finanzas. Queremos que cualquier persona pueda 
                                        poner a trabajar su dinero, sin necesidad de grandes sumas. 
                                        El dinero depositado irá a proyectos productivos reales, en 
                                        sectores como desarrollo urbanístico, construcción, agro y finanzas. 
                                        Todos son verificables, y ofrecen altas tasas de rentabilidad en pesos 
                                        y en dólares. 
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item className='itemAsk' eventKey="1">
                                <Accordion.Header className='askFrecuentHeader'>Historia </Accordion.Header>
                                <Accordion.Body className='askFrecuentBody'>
                                     <p className='text-tab-desc'>
                                        Somos parte de Grupo Delsud, un conjunto de empresas nacionales 
                                        que apuestan al desarrollo en negocios de distintas áreas. 
                                        Con una cartera diversificada, y cuatro años de vida, el Grupo 
                                        ha probado con creces el funcionamiento de su modelo de negocios, 
                                        donde cada unidad integra armónicamente sus actividades en el conjunto.
                                    </p>
                                    <p className='textResalted'>
                                        Black Hound fue pensado para que potenciales inversores puedan participar 
                                        de los proyectos que el Grupo desarrolla, a la medida de su capacidad financiera 
                                        y necesidad en cuanto a plazos de retorno. 
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item className='itemAsk' eventKey="2">
                                <Accordion.Header className='askFrecuentHeader'>¿Con qué socios trabajamos? </Accordion.Header>
                                <Accordion.Body className='askFrecuentBody'>
                                    <p className='text-tab-desc'>
                                         Somos parte de un conjunto de empresas denominado Grupo Delsud. 
                                         Se trata de múltiples negocios que apuestan día a día por el crecimiento nacional. 
                                         La empresa cumplió recientemente sus tres años de antigüedad, recorrido durante el 
                                         cual ha duplicado la cantidad de negocios existentes y superado ampliamente todos los objetivos.
                                            <br/><br/>
                                         Black Hound nace con la intención de permitir que distintos inversores puedan gestionar sus participaciones en los proyectos de forma muy simple, con la posibilidad ser parte de más de un proyecto a la vez.
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>                        
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                        <Accordion defaultActiveKey="3">
                                <Accordion.Item className='itemAsk' eventKey="3">
                                    <Accordion.Header className='askFrecuentHeader'> ¿Quién puede participar?</Accordion.Header>
                                    <Accordion.Body className='askFrecuentBody'>
                                        <p className='text-tab-desc'>
                                            Cualquier persona mayor de edad con una cuenta bancaria o wallet puede participar
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item className='itemAsk' eventKey="4">
                                    <Accordion.Header className='askFrecuentHeader'>¿Cómo puedo invertir? </Accordion.Header>
                                    <Accordion.Body className='askFrecuentBody'>
                                        <p className='text-tab-desc'>
                                            Nuestros proyectos ofrecen bajos montos de ingreso, así como tiempos de devolución flexibles. 
                                            Elegí el proyecto que más se ajuste a tus necesidades, seleccionando el capital a invertir y 
                                            el tiempo de devolución pretendido. 
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item className='itemAsk' eventKey="5">
                                    <Accordion.Header className='askFrecuentHeader'>¿Cuál es el monto mínimo?</Accordion.Header>
                                    <Accordion.Body className='askFrecuentBody'>
                                        <p className='text-tab-desc'>
                                            El monto base para invertir es de $5000 (cinco mil pesos) 
                                        </p>

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item className='itemAsk' eventKey="6">
                                    <Accordion.Header className='askFrecuentHeader'>¿Cuándo voy a recuperar mi participación?</Accordion.Header>
                                    <Accordion.Body className='askFrecuentBody'>
                                        <p className='text-tab-desc'>
                                            Puede recuperarse en el plazo mínimo de un mes, junto con el rendimiento
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion> 
                    
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                    <Accordion defaultActiveKey="7">
                                <Accordion.Item className='itemAsk' eventKey="7">
                                    <Accordion.Header className='askFrecuentHeader'> ¿Puedo participar en todos los proyectos?</Accordion.Header>
                                    <Accordion.Body className='askFrecuentBody'>
                                        <p className='text-tab-desc'>
                                            Sí, la participación es irrestricta y puede ser en simultáneo.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item className='itemAsk' eventKey="8">
                                    <Accordion.Header className='askFrecuentHeader'>¿Cuáles son los tiempos de los proyectos?</Accordion.Header>
                                    <Accordion.Body className='askFrecuentBody'>
                                        <p className='text-tab-desc'>
                                            Los tiempos varían según la opción elegida. 
                                            Los proyectos con tiempos más cortos ofrecen recuperar el dinero en un mes,
                                            y los de mayor plazo pueden llegar hasta un año.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item className='itemAsk' eventKey="9">
                                    <Accordion.Header className='askFrecuentHeader'>¿En qué áreas puedo invertir?</Accordion.Header>
                                    <Accordion.Body className='askFrecuentBody'>
                                        <p className='text-tab-desc'>
                                            Las áreas de inversión incluyen desarrollo urbanístico, construcción, agro y finanzas.
                                        </p>

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item className='itemAsk' eventKey="10">
                                    <Accordion.Header className='askFrecuentHeader'>¿Cuáles son los requisitos para poder participar en Black Hound?</Accordion.Header>
                                    <Accordion.Body className='askFrecuentBody'>
                                        <p className='text-tab-desc'>
                                            Ser mayor de 18 años y tener una cuenta CBU o CVU
                                        </p>

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item className='itemAsk' eventKey="11">
                                    <Accordion.Header className='askFrecuentHeader'>¿Hay algún límite de capital para participar?</Accordion.Header>
                                    <Accordion.Body className='askFrecuentBody'>
                                        <p className='text-tab-desc'>
                                            No hay un límite, siempre que se cuente con el monto base de $5000. 
                                            Tampoco hay límite a la cantidad de proyectos en los que se puede participar. 
                                        </p>

                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion> 
                    
                    
                    </Tab.Pane>
                </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    </div>
    
  )
}

