import React,{useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

//ACTION
import { setEstadoCliente } from '../../../../Actions/estadoCliente';
import { getlistaClientesRegistrados } from '../../../../Actions/listaClientesRegistrados';
import {Modal} from 'rsuite';

// import './modalAprobado.css';

const ModalRechazado = (props) => {
    const data2 = props.dataModalRechazado;
    const [backdrop, setBackdrop] = useState('static');
    const [open, setOpen] = useState(data2 != null);
    const handleClose = () => setOpen(false);
    const dispatch = useDispatch();

    const [activePage, setActivePage] = useState(1)
    const [estadoFiltro, setEstadoFiltro] = useState(1);
    const [busqueda, setBusqueda] = useState({
        dataBase: ""
    })

    useEffect(() => {
        if (data2 != null) {
            setOpen(data2);
        }
    }, [data2])

    const handleRechazar = () => {
        const dataCliente = {
            id: data2.idUser,
            idEstado: 3,
        }
        const data = {
            busqueda: busqueda.dataBase,
            estado: estadoFiltro,
            page: activePage,
        }
        if(data2 != null){
            dispatch(setEstadoCliente(dataCliente));
            setTimeout(() => {
                dispatch(getlistaClientesRegistrados(data));
            }, 1000)
        }
        setOpen(false);
    }

    return (
        <>
            {(open) &&
                <div className='modalEnvioDoc'>
                    <Modal className='proyecto-modal infoModal' backdrop={backdrop}  open={open} size="md" onClose={handleClose} >
                    <Modal.Header className='headerModal'>
                        <Modal.Title className='headerModal'>
                            <div>
                                <h4 style={{margin: 'auto', color: 'white', textAlign: 'center'}}> ¡Mensaje Importante! </h4>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='textModal'>
                            ¿Desea realmente rechazar este usuario? <br></br>
                            Seleccione "Si" para rechazar esta cuenta <br></br>
                            o seleccione "No" para cancelar.                          
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='contFooter'>
                        <button className='buttonModal' onClick={handleRechazar}> Si </button>
                        <span style={{padding: '10px'}}></span>
                        <button className='buttonModal' onClick={handleClose}> No </button>
                    </Modal.Footer>
                    </Modal>
                </div>  
            }
        </>
    );
}

export default ModalRechazado;
