import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';
import { turnoUser } from "../api/turno";

export const watchEnviarTurno = function * (){
    yield takeEvery(actionTypes.GUARDAR_TURNO_USER, fetchEnviarTurno)
}

function* fetchEnviarTurno(action){
    try{
        const data = yield call(turnoUser, action.data)
        if(data){
        }
    }catch(err){
        console.log(err)
    }
}

