import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call } from'redux-saga/effects';
import { crearCategoria } from "../api/crearCategoria";

import 'react-toastify/dist/ReactToastify.css';

export const watchCrearCategoria = function * (){
    yield takeEvery(actionTypes.SET_CREAR_CATEGORIA, fecthCrearCategoria)
}

function* fecthCrearCategoria(action){
    try{
        const data = yield call(crearCategoria, action.data)
        if(data){
        }

    }catch(err){
        console.log(err)
    }
  
}

