import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'rsuite';

export default function ModalSuEmailASidoEnviado({open, setOpen}) {
  
  const navigation = useNavigate()
    
    const handleClose = () => {
      setOpen(false)
      setTimeout(()=>{
        navigation('/IniciarSesion')
      }, 500); 
    }
  return (
    
    <>
    <Modal className='modalContrasenaModificada' size="xs" open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Recuperación de contraseña</Modal.Title>
          <Modal.Body>Por favor, revise su casilla de correo para continuar</Modal.Body>
        </Modal.Header>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="primary">
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}