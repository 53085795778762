import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import axios from 'axios';
import ModalSuEmailASidoEnviado from './ModalContrasenaModificada/ModalSuEmailASidoEnviado';
import { Button } from 'rsuite';

export default function RecordarContrasena() {
    const [loadingButton, setLoadingButton] = useState(false);

    const [open, setOpen] = useState(false);
    const [messageError, setMessageError] = useState(false)
    // ENVIAR FORMULARIO//
    const {
        register,
        formState: { errors },
        getValues,
        handleSubmit
    } = useForm({
        mode: "onChange"
    });
    const onSubmit = (data) => {
        setLoadingButton(true);
        setTimeout(() => {
            setLoadingButton(false);
        }, 2500);
        axios.post(`https://www.blackhound.com.ar/server/public/correoResetContrasena`, data)
            .then(function (res) {
                setOpen(true)
            })
            .catch(function (error) {
                setMessageError(true)
            });
    };


  const pressEnter = (e) => {
    if(e.key === 'Enter') { 
        onSubmit();
     }
  }  
    return (
        <>
            <ModalSuEmailASidoEnviado
                open={open}
                setOpen={setOpen}
            />
            <div className='validar-mail-container'>
                <div className='validar-mail-box-cambiar-contrasena' data-aos="zoom-in" data-aos-duration="1000">
                    <h5>Restablecer contraseña</h5>
                    <p className='validar-mail-box-cambiar-contrasena-p'>
                        Ingrese la dirección de correo electrónico asociado a su cuenta <span>BlackHound</span><br />
                        Le enviaremos a su casilla el acceso para cambiar su contraseña.
                    </p>
                    <form className='formulario-validar-mail' onSubmit={handleSubmit(onSubmit)}>
                        <div className='fomulario-campo recuperar-contrasena'>
                            <input
                                onKeyPress={(e) => pressEnter(e)} 
                                type="text"
                                autoComplete='off'
                                placeholder='ejemplo@ejemplo.com'
                                //value={registro.email}  
                                {...register("mail", { required: true, pattern: /^\S+@\S+$/i })}
                            //onChange={handleRegistro} 
                            />

                        </div>
                        {messageError === true  ? <p style={{padding:"0px"}}  className='errorRestablecerContrasena'>Ingrese un email válido.</p> : ""}
                        {errors?.mail?.type === "required" && <p style={{padding:"0px"}} className='errorRestablecerContrasena'>Este campo es requerido</p>}
                        {errors?.mail?.type === "pattern" && (
                            <p style={{padding:"0px"}} className='errorRestablecerContrasena'>Formato de mail incorrecto</p>
                        )}
                        <Button style={{ width: "30%" }} type="submit" loading={loadingButton} className="boton-registrarse" >ENVIAR</Button>

                    </form>


                </div>
            </div>
        </>
    )
}
