import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';
import { updateAsesor } from "../api/updateAsesor";
import { getUpdateAsesor } from "../Actions/updateAsesor";

export const watchUpdateAsesor = function * (){
    yield takeEvery(actionTypes.GET_UPDATE_ASESOR, fetchUpdateAsesor)
}

function* fetchUpdateAsesor(action){
    try{
        const data = yield call(updateAsesor, action.data)
        if(data){
            yield put(getUpdateAsesor(data))
        }
    }catch(err){
        console.log(err)
    }
}