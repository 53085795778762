import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call } from'redux-saga/effects';
import { crearProyecto } from'../api/crearProyecto';
// import {  toast } from 'react-toastify';

// import 'react-toastify/dist/ReactToastify.css';

export const watchCrearProyecto = function * (){
    yield takeEvery(actionTypes.CREAR_PROYECTO_NUEVO, fetchCrearProyecto)
}

function* fetchCrearProyecto(action){
    try{
        const data = yield call(crearProyecto, action.data)
        if(data){
            // toast.success('Proyecto creado!', {});
        }

    }catch(err){
        console.log(err)
        // toast.error('Error al crear proyecto,', {}); 
        // toast.error('Complete todos los campos,', {});
    }
  
}

