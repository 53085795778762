
export const store = {
    dashboard: {
        data: null,
    },
    registro: {
        data: null,
    },
    listaTurno: {
        data: null,
    },
    turno: {
        data: null,
    },
    clientes: {
        data: null,
    },
    asesores: {
        data: null,
    },
    proyecto: {
        data: null,
    },
    monedita: {
        data: null,
    },
    solicitudes: {
        data: null,
    },
    mostrarproyecto: {
        data: null,
    },
    categoriasproyecto: {
        data: null,
    },
    enviarcita: {
        data: null,
    },
    datausuario: {
        data: null,
    },
    inversionesusuario: {
        data: null,
    },
    documentosUsuario: {
        data: null,
    },
    login: {
        user_data: null,
    },
    estadisticasUsuario: {
        data: null,
    },
    dolar: {
        data: null,
    },
    notificacionesUsuario: {
        data: null,
        contador: 0,
    },
    asesorTurno: {
        data: null,
    },
    agregarAsesorSolicitud: {
        data:null,
    },
    imagenPerfil: {
        data:null,
    },
    guardarIdContrato:{
        data:null,
    },
    chat:{
        data:null,
        cantMensaje: 0,
    },
    guardarIdChat:{
        data:null,
    },
    allConversaciones: {
        data:null,
        cantMensaje: 0,
    },
    userRegistro:{
        data:null,
    },
    solicitudesAdmin: {
        data:null,
    }
}