import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal } from 'rsuite';

//ACTION
import { getCategoriaProyectos } from '../../../../Actions/categoriasProyecto';
import { editarProyecto } from '../../../../Actions/editarProyecto';
import { getMostrarProyecto } from '../../../../Actions/mostrarProyectos';

const ModalEditarProyecto = (props) => {
    const dataProyecto = props.data;
    const dataModal = props.dataModalEditarProyecto
    const [open, setOpen] = useState(dataModal != null);
    const [backdrop, setBackdrop] = useState('static');
    const handleClose = () => setOpen(false);
    const [datos, setDatos] = useState({
        id:dataProyecto.idProyecto,
        fecha:dataProyecto.fecha.date.substring(10, 0),
        categoria:dataProyecto.categoriaId,
        moneda:dataProyecto.monedaId,
        montoMinimo:dataProyecto.montoMinimo,
        plazoMinimo:dataProyecto.plazo,
        tna:dataProyecto.tna,
        color:dataProyecto.color,
        nombre:dataProyecto.nombre,
        descripcion:dataProyecto.descripcion,
        imagenProyecto:"https://www.blackhound.com.ar/server/public/getImgId/" + dataProyecto.idImagenProyecto,
        fotoUno:"https://www.blackhound.com.ar/server/public/getImgId/" + dataProyecto.idImagenUno,
        fotoDos:"https://www.blackhound.com.ar/server/public/getImgId/" + dataProyecto.idImagenDos
    })


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCategoriaProyectos())
    }, []);

    useEffect(() => {
        if (dataModal != null) {
            setOpen(dataModal);
        }
    }, [dataModal]);


    const [activePage, setActivePage] = useState(1);
    const [estadoFiltro, setEstadoFiltro] = useState(null);
    const [monedita, setMonedita] = useState(null);
    const [busqueda, setBusqueda] = useState({
      dataBase: "",
    });
  

    const [imgData, setImgData] = useState(datos.imagenProyecto);
    const [picture, setPicture] = useState(null);

    const [mostrarImagenUno, setMostrarImagenUno] = useState();
    const [imagenUno, setImagenUno] = useState(datos.fotoUno);

    const [mostrarImagenDos, setMostrarImagenDos] = useState();
    const [imagenDos, setImagenDos] = useState(datos.fotoDos);

    const onChangePicture = (e) => {
        if (e.target.files[0]) {
            setPicture(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImgData(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onChangeImagenUno = (e) => {
        if (e.target.files[0]) {
            setMostrarImagenUno(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImagenUno(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    const onChangeImagenDos = (e) => {
        if (e.target.files[0]) {
            setMostrarImagenDos(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImagenDos(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }

   const onChangeDatos = (e) =>{
    setDatos({
        ...datos,
        [e.target.name]: e.target.value,
      });
   }

    function handleCloseSubmit() {
        let formData = new FormData();
        formData.append("data", JSON.stringify(datos))
        formData.append("imagenProyecto", picture)
        formData.append("imagenUno", mostrarImagenUno)
        formData.append("imagenDos", mostrarImagenDos)
        dispatch(editarProyecto(formData));
        const data = {
            busqueda: busqueda.dataBase,
            estado: estadoFiltro,
            page: activePage,
            categoria: estadoFiltro,
            moneda: monedita,
          };
        setTimeout(() => {
            dispatch(getMostrarProyecto(data));
        }, 2500);
        setOpen(false);

    }

    const categorias = useSelector(state => state.reducerCategoriasProyecto.data);
    const listaMonedas = useSelector(state => state.reducerListaMonedas.data);

    //TIPO DE MONEDA
    if (listaMonedas != null) {
        var monedas = [];
        const pu = listaMonedas.data;
        for (var i = 0; i < pu.length; i++) {
            monedas.push(<option value={pu[i].id} selected={dataProyecto.monedaId == pu[i].id} > {pu[i].nombre} </option>)
        }
    }

    //TIPO DE CATEGORIA
    if (categorias != null) {
        var tipoCategorias = [];
        const catego = categorias.categorias;
        for (var i = 0; i < catego.length; i++) {
            tipoCategorias.push(<option value={catego[i].id} selected={dataProyecto.categoriaId == catego[i].id} > {catego[i].nombre} </option>)
        }
    }

    return (
        <>
            {(open) &&
                <div className="modal-container ">
                    <Modal className='proyecto-modal editar-modal-container' backdrop={backdrop} open={open} onClose={handleClose} size={"lg"} >
                        <Modal.Body>
                            <div className="crear-proyecto-container ">
                                <div className="crear-proyecto-formulario">
                                    <form>
                                        <div style={{ display: 'flex' }}>
                                            <div className='infoProyecto'>
                                                <div className="crear-proyecto-campo">
                                                    <p className='tittle-label-input'>Fecha de creación</p>
                                                    <input className='input-proyecto' type="date" id="fecha" name="fecha" value={datos.fecha} onChange={onChangeDatos} />
                                                </div>
                                                <div className="crear-proyecto-campo">
                                                    <div className="campo-checkbox-categoria">
                                                        <p className='tittle-label-input'>Categoria del proyecto</p>
                                                        <select className='select-proyecto' name="categoria" onChange={onChangeDatos} >
                                                            {tipoCategorias}
                                                        </select>
                                                    </div>
                                                    {/* <p className='tittle-label-input'>Valores de inversión</p> */}
                                                    <div className="crear-proyecto-campo">
                                                        <p className='tittle-label-input'>Tipo de moneda</p>
                                                        <select className='select-proyecto' name="moneda" onChange={onChangeDatos} >
                                                            {monedas}
                                                        </select>
                                                    </div>
                                                    <div className="crear-proyecto-campo">
                                                        <p className='tittle-label-input'>TNA</p>
                                                        <input className='input-proyecto' autoComplete='off' type="text" id="tna" name="tna" value={datos.tna} onChange={onChangeDatos} />
                                                    </div>
                                                    <div className="crear-proyecto-campo">
                                                        <p className='tittle-label-input'> Monto mínimo</p>
                                                        <input className='input-proyecto' autoComplete='off' type="text" id="montomin" name="montoMinimo" value={datos.montoMinimo} onChange={onChangeDatos} />
                                                    </div>
                                                    <div className="crear-proyecto-campo">
                                                        <p className='tittle-label-input'> Plazo mínimo</p>
                                                        <input className='input-proyecto' autoComplete='off' type="text" id="plazomin" name="plazoMinimo" value={datos.plazoMinimo} onChange={onChangeDatos} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='infoProyecto'>
                                                <p className="informacion-proyecto">Información del proyecto</p>
                                                <div className="crear-proyecto-campo">
                                                    <p className='tittle-label-input'>Nombre del proyecto</p>
                                                    <input type="text" id="nombre-asesor" name="nombre" value={datos.nombre} onChange={onChangeDatos} />
                                                </div>
                                                <div className="crear-proyecto-campo">
                                                    <p className='tittle-label-input'>Descripción del proyecto</p>
                                                    <textarea className='textarea-crear' type="text" id="nombre-asesor" name="descripcion" value={datos.descripcion} onChange={onChangeDatos} />
                                                </div>
                                                <div>
                                                    <p className='tittle-label-input'>Color proyecto</p>
                                                    <input type="color" name="color" value={datos.color} onChange={onChangeDatos} className="campo-color" />
                                                </div>
                                                <div className="crear-proyecto-campo" style={{ marginTop: '10px' }}>
                                                    <p className='tittle-label-input'>Logo proyecto</p>
                                                    <input type="file" name="imagenUno" onChange={onChangeImagenUno} className="foto-adjunta" />
                                                </div>
                                                <div className="crear-proyecto-campo" style={{ marginTop: '10px' }} >
                                                    <p className='tittle-label-input'>Imagen uno proyecto</p>
                                                    <input className="foto-adjunta" name="imagenProyecto" id="imagen" type="file" onChange={onChangePicture} />
                                                </div>
                                                <div className="crear-proyecto-campo" style={{ marginTop: '10px' }} >
                                                    <p className='tittle-label-input'>Imagen dos proyecto</p>
                                                    <input type="file" name="imagenDos" onChange={onChangeImagenDos} className="foto-adjunta" />
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Modal.Body >

                        <Modal.Footer>
                            <Button className="boton-cargar-proyecto" onClick={handleCloseSubmit} >
                                Guardar Proyecto
                            </Button>
                            <Button className="boton-cargar-proyecto" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Modal.Footer>
                    </Modal >
                </div >
            }
        </>
    );
}

export default ModalEditarProyecto;
