import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';
import { estadoSolicitud } from'../api/estadoSolicitud';

export const watchEstadoSolicitud = function * (){
    yield takeEvery(actionTypes.SET_ESTADO_SOLICITUD, fetchEstadoSolicitud)
}

function* fetchEstadoSolicitud(action){
    try{
        const data = yield call(estadoSolicitud, action.data)
        if(data){
            //yield put(listaClientesRegistrados(data))
        }
    }catch(err){
        console.log(err)
    }
}