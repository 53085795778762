import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call,put } from'redux-saga/effects';
import { estadisticas } from'../api/estadisticasUsuario';
import {setEstadisticasUsuario} from '../Actions/estadisticasUsuario';

export const watchEstadisticasUsuario = function * (){
    yield takeEvery(actionTypes.GET_ESTADISTICAS_USUARIO, fetchEstadisticasUsuario)
}

function* fetchEstadisticasUsuario(action){
    try{
        const data = yield call(estadisticas, action.data)
        if(data){
            yield put(setEstadisticasUsuario(data))
            //MODAL USUARIO REGISTRADO EXITOSO
        }
    }catch(err){
        console.log(err)
    }
}