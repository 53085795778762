import { actionTypes } from "../Constantes/ActionTypes";
import { store } from "../store/index";

function reducerListaAsesores (state = store.asesores, action){
    switch(action.type){
        case actionTypes.SET_LISTA_ASESORES:
            let listaAsesoresRegistrados = action.data
            return Object.assign({}, state, {
                data: listaAsesoresRegistrados,
            })
            default:
                return state
    }
}

export default reducerListaAsesores;