import { actionTypes } from "../Constantes/ActionTypes";
import { store } from "../store/index";

function reducerlistaSolicitudesRegistradas (state = store.solicitudes, action){
    switch(action.type){
        case actionTypes.SET_LISTA_SOLICITUDES_REGISTRADAS:
            let SolicitudesRegistrados = action.data
            return Object.assign({}, state, {
                data: SolicitudesRegistrados,
            })
            default:
                return state
    }
}

export default reducerlistaSolicitudesRegistradas;