import { actionTypes } from "../Constantes/ActionTypes"; 

export const getMostrarProyecto = (data) => {
    return {
        type: actionTypes.MOSTRAR_LOS_PROYECTOS,
        data
    }
}

export const setMostrarProyecto = (data) => {
    return {
        type: actionTypes.SET_MOSTRAR_LOS_PROYECTOS,
        data
    }
}

