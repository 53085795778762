import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';

import { apiCancelarTurno } from "../api/cancelarTurno";
import { cancelarTurnoUser } from "../Actions/cancelarTurno";

export const watchCancelarTurno = function * (){
    yield takeEvery(actionTypes.CANCELAR_TURNO, fetchCancelarTurno)
}

function* fetchCancelarTurno(action){
    try{
        const data = yield call(apiCancelarTurno, action.data)
            if(data){
               // yield put(cancelarTurnoUser(data))
            }
    }catch(err){
        console.log(err)
    }
}