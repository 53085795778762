import React from 'react';
import { useRef, useEffect } from 'react'
import { cleanUpScene2, mountScene2 } from './scriptModel/modelMoneda';
import './tusahorros.css'

export default function TusAhorros() {

  const mountRef2 = useRef(null)

  useEffect(() => {
    mountScene2(mountRef2)
    //CLEAN SCENE 
    return () => {
      cleanUpScene2()
    }
  }, [])

  return (
    <div className='home-container tus-ahorros-container'  >
      <div className='tus-ahorros-text-container' data-aos="zoom-in" data-aos-duration="1000">
        <h4><span>Un sabueso en las finanzas </span> <br></br>
          Asegurá la rentabilidad <br /> de tus inversiones </h4>
      </div>
      <div className="tus-ahorros-imagen-pesos" data-aos="zoom-in" data-aos-duration="1400" data-aos-delay="500">
        <img className='imagen3D-mobile' src='https://res.cloudinary.com/blackhound/image/upload/v1663160411/BLACKHOUND/Home/BH_1_cfftqg.svg' />
        <div className='Contenedor3D' ref={mountRef2} style={{ width: '100%', height: '70vh' }} >
        </div>
      </div>
    </div>
  )
}
