import { actionTypes } from "../Constantes/ActionTypes";
import { store } from "../store/index";

function reducerCategoriasProyecto (state = store.categoriasproyecto, action){
    switch(action.type){
        case actionTypes.SET_CATEGORIA_PROYECTOS:
            let CategoriasProyecto = action.data
            return Object.assign({}, state, {
                data: CategoriasProyecto,
            })
            default:
                return state
    }
}

export default reducerCategoriasProyecto;