import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';
import { recibirIdsDocumentos } from'../api/enviarDocumentos';
import { setRecibirDoc } from "../Actions/enviarDocumentos";

export const watchRecibirDocumentos = function * (){
    yield takeEvery(actionTypes.GET_RECIBIR_DOCUMENTOS_USUARIO, fetchRecibirDocumentos)
}

function* fetchRecibirDocumentos(action){
    try{
        const data = yield call(recibirIdsDocumentos, action.data)
        if(data){
            yield put(setRecibirDoc(data))
        }
    }catch(err){
        console.log(err)
    }
}

