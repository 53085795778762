import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';
import { obtenerChat } from'../api/obtenerChat';
import { setChat } from "../Actions/obtenerChat"; 
import { cantidadMensajes } from "../Actions/obtenerChat";


export const watchObtenerChat = function * (){
    yield takeEvery(actionTypes.GET_CONVERSACION, fetchObtenerConversacion)
}

function* fetchObtenerConversacion(action){
    try{
        const data = yield call(obtenerChat, action.data)
        if(data){
            yield put(setChat(data))
            yield put(cantidadMensajes(data))
        }
    }catch(err){
        console.log(err)
    }
}