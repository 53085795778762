import React, { useState, useEffect } from "react";
import { getImagenPerfil } from "../../../Actions/imagenPerfil";
import { getDatosUsuario } from "../../../Actions/datosUsuario";
import { postEnviarDoc } from "../../../Actions/enviarDocumentos";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import { Popover, Whisper, Modal, Button } from "rsuite";
import ModalGeneric from "../../../Components/ModalGeneric/ModalGeneric"
//Action
import { editarPerfil } from "../../../Actions/editarPerfil";

//Import Styles
import "./perfil.css";

//React Icon
import { GrEdit } from "react-icons/gr";
import { ImCross } from "react-icons/im";
import { getNotificacionUsuario } from "../../../Actions/notificacionUsuario";

export default function Perfil() {
  const datosUsuarioLogeado = useSelector(
    (state) => state.reducerUsuarioDatos.data
  );

  const dispatch = useDispatch();

  const [loadingButton, setLoadingButton] = useState(false);
  const [imagenesDoc, setImagenesDoc] = useState({
    imagenFrente: null,
    imagenDorso: null,
  });
  const [activarBotonGuardarCambiosPersonales, setActivarBotonGuardarCambiosPersonales] = useState();
  const [activarBotonGuardarCambiosContacto, setActivarBotonGuardarCambiosContacto] = useState();

  const [mostrarImagenFrente, setMostrarImagenFrente] = useState();
  const [mostrarImagenDorso, setMostrarImagenDorso] = useState();

  const [imagenPerfilUser, setImagenPerfilUser] = useState();
  const [mostrarImagenPerfil, setMostrarImagenPerfil] = useState("");

  const [editarDatosPersonales, setEditarDatosPersonales] = useState(true);
  const [datosPersonales, setDatosPersonales] = useState({
    nombreUser: "",
    apellidoUser: "",
    dni: "",
    email: null,
    telefono: null,
  });

  const modalPhotoPerfil = {
    title: "Su foto de perfil ha sido guardada correctamente",
    content:"",
    button:"Aceptar",
    size:"xs"
  }

  const [editarDatosDeContacto, setEditarDatosDeContacto] = useState(true);

  const [datosDeContacto, setDatosDeContacto] = useState({
    nombreUser: null,
    apellidoUser: null,
    dni: null,
    email: null,
    telefono: "",
  });
  
  const [pagina, setPagina] = useState(1)

  const [open, setOpen] = useState()

  const [openModalDoc, setOpenModalDoc] = useState()

  const [maxNumber, setMaxNumber] = useState()
  useEffect(() => {
    if (datosUsuarioLogeado != null) {
      datosPersonales.nombreUser = datosUsuarioLogeado.nombre;
      datosPersonales.apellidoUser = datosUsuarioLogeado.apellido;
      datosPersonales.dni = datosUsuarioLogeado.dni;
      datosDeContacto.email = datosUsuarioLogeado.email;
      datosDeContacto.telefono = datosUsuarioLogeado.telefono;      
      if(datosUsuarioLogeado.fotoPerfil==null){
        setMostrarImagenPerfil("https://res.cloudinary.com/blackhound/image/upload/v1662396917/BLACKHOUND/Group_17778_ujcekc.svg")
      }
      if(datosUsuarioLogeado.fotoPerfil==true){
        setMostrarImagenPerfil("https://www.blackhound.com.ar/server/public/getImgProfile/" + datosUsuarioLogeado.id )
      }
    }
  }, [datosUsuarioLogeado]);

  const mostrarImagen = (e) => {
    if (e.target.files[0]) {
      imagenesDoc.imagenFrente = e.target.files[0];
      imagenesDoc.imagenDorso = e.target.files[1];
      const readerFrente = new FileReader();
      readerFrente.addEventListener("load", () => {
        setMostrarImagenFrente(readerFrente.result);
      });
      readerFrente.readAsDataURL(e.target.files[0]);
      const readerDorso = new FileReader();
      readerDorso.addEventListener("load", () => {
        setMostrarImagenDorso(readerDorso.result);
      });
      readerDorso.readAsDataURL(e.target.files[1]);
    }
  };

  const capturarImagenPerfil = (e) => {
    if (e.target.files[0]) {
      setImagenPerfilUser(e.target.files[0]);
      const readerImagenPerfil = new FileReader();
      readerImagenPerfil.addEventListener("load", () => {
        setMostrarImagenPerfil(readerImagenPerfil.result);
      });
      readerImagenPerfil.readAsDataURL(e.target.files[0]);
    }
  };


  function handleChangeDatosDeContacto(e) {
    if (editarDatosDeContacto === false) {
      setDatosDeContacto({
        ...datosDeContacto,
        [e.target.name]: e.target.value,
      });


    }
  }

  useEffect(() => {
    if(editarDatosDeContacto==false){
      if(datosDeContacto.telefono.length>=8 && datosDeContacto.telefono.length<12){
        setMaxNumber(true);
        setActivarBotonGuardarCambiosContacto(true);
      }      
      if(datosDeContacto.telefono.length<7){
        setMaxNumber(false);
        setActivarBotonGuardarCambiosContacto(false);
      } 
      if(datosDeContacto.telefono.length>=12){
        setMaxNumber(false);
        setActivarBotonGuardarCambiosContacto(false);
      } 
    }

  }, [datosDeContacto,editarDatosDeContacto])

  function eliminarImagenFrente() {
    setMostrarImagenFrente("");
    imagenesDoc.imagenFrente = null;
  }

  function eliminarImagenDorso() {
    setMostrarImagenDorso("");
    imagenesDoc.imagenDorso = null;
  }

  function enviarImagenPerfil(){
    let imagenPerfilData = new FormData();      
    imagenPerfilData.append("profilePhoto", imagenPerfilUser)
    setLoadingButton(true);
    dispatch(getImagenPerfil(imagenPerfilData));
    setTimeout(()=>{
      dispatch(getDatosUsuario());
      setLoadingButton(false);
      setOpen(true)
    }, 4500);
  }

  useEffect(()=>{
    const data = {
      page: pagina
    }
    dispatch(getNotificacionUsuario(data))
  }, [])

  function handleEnviarDocumentos(){
    const data = {
      page: pagina
    }
    let documentosData = new FormData();      
    documentosData.append("imgFrente", imagenesDoc.imagenFrente)
    documentosData.append("imgDorso", imagenesDoc.imagenDorso)
    setLoadingButton(true);
    dispatch(postEnviarDoc(documentosData));
    setTimeout(()=>{
      setLoadingButton(false);
      setOpenModalDoc(true)
      dispatch(getDatosUsuario());
      dispatch(getNotificacionUsuario(data))
    }, 1500);
  }




  function enviarDatosEditadosContacto(){
    dispatch(editarPerfil(datosDeContacto));
    dispatch(getDatosUsuario());
    setActivarBotonGuardarCambiosContacto(false);
    setEditarDatosDeContacto(true);
  }

  // ENVIAR FORMULARIO//
  const {
     register,
     formState: { errors },
     handleSubmit
 } = useForm({
     mode: "onChange"
 });
 const onSubmit = (data) => {
  const datosPersonales={
    nombreUser: data.nombreUser,
    apellidoUser: data.apellidoUser,
    dni: null,
    email: null,
    telefono: data.telefono,
  }
  dispatch(editarPerfil(datosPersonales));
  dispatch(getDatosUsuario());
  setActivarBotonGuardarCambiosPersonales(false);
  setEditarDatosPersonales(true);
 };

  const isMobile = useMediaQuery({ maxDeviceWidth: 1024 });

  const mostrarImagenDeFrente = (e) => {
    if (e.target.files[0]) {
      imagenesDoc.imagenFrente = e.target.files[0];
      const readerFrente = new FileReader();
      readerFrente.addEventListener("load", () => {
        setMostrarImagenFrente(readerFrente.result);
      });
      readerFrente.readAsDataURL(e.target.files[0]);
    }
  };

  const mostrarImagenDeDorso = (e) => {
    if (e.target.files[0]) {
      imagenesDoc.imagenDorso = e.target.files[0];
      const readerDorso = new FileReader();
      readerDorso.addEventListener("load", () => {
        setMostrarImagenDorso(readerDorso.result);
      });
      readerDorso.readAsDataURL(e.target.files[0]);
    }
  };



  // useEffect(() => {
  //   if (maxNumber && (+maxNumber.length < 0 || +maxNumber.length > 10)) setMaxNumber(0)
  // }, [maxNumber])

  // const handleChangeNumber = ({ target: { value } }) => setMaxNumber(value);


  return (
    <div className="perfil-container">
      <h5 className="titulo-seccion">PERFIL</h5>
      <div className="datos-usuario">
        <div className="datos-personales">
          <div className="validacion-seccion-titulo-perfil">
            <span className="cuadrado"></span>
            <p>DATOS PERSONALES</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} >
            <div className="datos-personales-box">
            <p>
              <b>NOMBRE: </b>
              <input
                readOnly={editarDatosPersonales}
                type="text"
                {...register("nombreUser", {                  
                  onChange: () => setActivarBotonGuardarCambiosPersonales(true),
                  maxLength: 15,
                  required: true,
                  pattern: /^[a-zA-Z\s]*$/i
                  })}
                defaultValue={
                  datosUsuarioLogeado != null ? datosUsuarioLogeado.nombre : ""
                }
              />
            </p>
            {errors?.nombreUser?.type === "required" && <p className='error'>Este campo es requerido</p>}
                {errors?.nombreUser?.type === "maxLength" && (
                    <p className='error'>El nombre no puede exceder los 20 caracteres</p>
                )}
                {errors?.nombreUser?.type === "pattern" && (
                    <p className='error'>Solo caracteres alfabéticos</p>
                )}
            <p>
              <b>APELLIDO: </b>
              <input
                readOnly={editarDatosPersonales}
                type="text"
                {...register("apellidoUser", 
                {
                    onChange: () => setActivarBotonGuardarCambiosPersonales(true),
                    required: true,
                    pattern: /^[a-zA-Z\s]*$/i
                })} 
                defaultValue={
                  datosUsuarioLogeado != null
                    ? datosUsuarioLogeado.apellido
                    : ""
                }
              />
            </p>
            {errors?.apellidoUser?.type === "required" && <p className='error'>Este campo es requerido</p>}
            {errors?.apellidoUser?.type === "pattern" && (
                    <p className='error'>Solo carácteres alfabéticos</p>)}
            <p>
              <b>DNI: </b>
              <input
               {...register("dni", 
               {
                  
               })} 
               defaultValue={
                 datosUsuarioLogeado != null
                   ? datosUsuarioLogeado.dni
                   : ""
               }
                readOnly={true}
              />
            </p>
            <Whisper placement="top" followCursor speaker={<Popover className="container-pop">Editar perfil</Popover>}>
                <div className="icono-editar" onClick={() => {setEditarDatosPersonales(false); setActivarBotonGuardarCambiosPersonales(true)}}>
                  <GrEdit   
                  
                  />
              </div>
            </Whisper>
            </div>
            {activarBotonGuardarCambiosPersonales &&  <button className="guardar-cambios-editar" >Guardar cambios personales</button>}   

          </form>
 

        </div>
        <div className="datos-de-contacto">
          <div className="datos-personales">
            <div className="validacion-seccion-titulo-perfil">
              <span className="cuadrado"></span>
              <p>DATOS DE CONTACTO</p>
            </div>
            <div className="datos-personales-box">
              <p>
                <b>Mail: </b>
                <input
                  readOnly={true}
                  type="text"
                  defaultValue={
                    datosUsuarioLogeado != null ? datosUsuarioLogeado.email : ""
                  }
                />
              </p>
              
              <p>
                <b>Teléfono: </b>
                <input
                  readOnly={editarDatosDeContacto}
                  name="telefono"
                  autoComplete="off"
                  // min="10"
                  // maxLength="10"
                  type="number"
                  onChange={(e)=>{handleChangeDatosDeContacto(e)}}
                  // ; handleChangeNumber(e)
                  defaultValue={
                    datosUsuarioLogeado != null
                      ? datosUsuarioLogeado.telefono
                      : ""
                  }
                />
              </p>
              {maxNumber === false ? <p className="error">Por favor, ingrese un número de teléfono válido</p> : ""}

              <Whisper placement="top" followCursor speaker={<Popover className="container-pop">Editar perfil</Popover>}>
                <div className="icono-editar">
                  <GrEdit onClick={() => setEditarDatosDeContacto(false)} />
                </div>
              </Whisper>
            </div>
            {activarBotonGuardarCambiosContacto &&  <button className="guardar-cambios-editar" onClick={enviarDatosEditadosContacto}>Guardar cambios contacto</button>}   
          </div>
        </div>
      </div>

      <div className="mostrar-etapa-dato">
        {datosUsuarioLogeado != null && datosUsuarioLogeado.isDocumentacion == false  ?(
          <div className="etapa-documentacion">
            <div className="validacion-seccion-titulo">
              <span className="cuadrado"></span>
              <p>VALIDACIÓN DE DATOS</p>
            </div>
            {!isMobile ? (
              <div className="etapa-documentacion-box">
                <div className="etapa-documentacion-seccion-adjuntar-docs">
                  <div className="etapa-documentacion-column-right">
                    <img src="https://res.cloudinary.com/blackhound/image/upload/v1661353251/BLACKHOUND/Usuario/Dashboard/sin_documentos.svg" />
                    <div className="etapa-documentacion-text">
                      <p className="etapa-documentacion-box-bold">
                        Enviar copia de DNI / LE / LC
                      </p>
                      <p className="subtitulo-validacion">
                        (Formato .jpg, .png hasta 10MB)
                      </p>
                    </div>
                  </div>
                  <div className='botones-adjuntar-doc'>
                      <p className="text-adjuntar">Adjuntar frente</p>
                      <label id="documentacion" htmlFor="adjuntarFrente" className="boton-adjuntar activado">Seleccionar archivos</label>
                      <input name="imagenFrente" onChange={mostrarImagenDeFrente}  disabled={datosUsuarioLogeado != null  && datosUsuarioLogeado.isDocumentacion ==false ? false : true} id="adjuntarFrente" type="file" accept="image/png, image/jpeg"/>
                      <p className="text-adjuntar">Adjuntar dorso</p>
                      <label htmlFor="adjuntarDorso" className="boton-adjuntar activado" >Seleccionar archivos</label>
                      <input name="imagenDorso" onChange={mostrarImagenDeDorso} disabled={datosUsuarioLogeado != null  && datosUsuarioLogeado.isDocumentacion ==false ? false : true} id="adjuntarDorso" type="file" accept="image/png, image/jpeg"/>
                      {datosUsuarioLogeado != null  && datosUsuarioLogeado.isDocumentacion == true ? <span className="texto-documentos-pendientes">Documentos enviados y pendientes de validación. </span> : ""}
                  </div>
                </div>
                {imagenesDoc.imagenFrente != null ||
                imagenesDoc.imagenDorso != null ? (
                  <>
                    <hr className="linea-divisora-documentacion" />
                    <div className="etapa-documentacion-seccion-mostrar-docs">
                      {/* <p>
                        {imagenesDoc.imagenDorso != null ? (
                          "2 archivos adjuntos"
                        ) : (
                          <p className="error-cargarndo-docs">
                            Falta adjuntar un archivo
                          </p>
                        )}
                      </p> */}
                      <div className="seccion-imagenes-documentos">
                        <div className="seccion-imagenes-documentos-box-imagenes">
                          {imagenesDoc.imagenFrente != null && (
                            <div className="mostrar-imagen-frente-caja">
                              <ImCross
                                className="eliminar-imagen-doc"
                                onClick={eliminarImagenFrente}
                              />
                              <img src={mostrarImagenFrente} />
                            </div>
                          )}
                          {imagenesDoc.imagenDorso != null && (
                            <div className="mostrar-imagen-dorso-caja">
                              <ImCross
                                className="eliminar-imagen-doc"
                                onClick={eliminarImagenDorso}
                              />
                              <img src={mostrarImagenDorso} />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {
                imagenesDoc.imagenFrente != null && imagenesDoc.imagenDorso === null || imagenesDoc.imagenFrente === null && imagenesDoc.imagenDorso != null || 
                imagenesDoc.imagenFrente != null && imagenesDoc.imagenDorso != null ?
                  <div className="seccion-imagenes-documentos-boton-enviar">
                    <Button className="boton-enviar-docs" disabled={imagenesDoc.imagenFrente != null && imagenesDoc.imagenDorso === null || 
                    imagenesDoc.imagenFrente === null && imagenesDoc.imagenDorso != null } 
                    loading={loadingButton} onClick={handleEnviarDocumentos} >Enviar</Button>
                  </div> : ""
                }
              </div>
            ) : (
              <div className="etapa-documentacion-box">
                <div className="etapa-documentacion-seccion-adjuntar-docs">
                  <div className="etapa-documentacion-column-right">
                    <img src="https://res.cloudinary.com/blackhound/image/upload/v1661353251/BLACKHOUND/Usuario/Dashboard/sin_documentos.svg" />
                    <div className="etapa-documentacion-text">
                      <p className="etapa-documentacion-box-bold">
                        Enviar copia de DNI / LE / LC
                      </p>
                      <p className="subtitulo-validacion">
                      (Formato .jpg, .png hasta 10MB)
                      </p>
                    </div>
                  </div>
                  {/* <div className="botones-adjuntar-doc">
                    <label
                      id="documentacion"
                      htmlFor="adjuntarFrente"
                      className="boton-adjuntar activado"
                    >
                      Seleccionar archivos
                    </label>
                    <input                      
                      name="imagenFrente"
                      style={{ display: "none" }}
                      onChange={mostrarImagen}
                      disabled={
                        datosUsuarioLogeado != null &&
                        datosUsuarioLogeado.isDocumentacion == false
                          ? false
                          : true
                      }
                      id="adjuntarFrente"
                      type="file"
                      accept="image/png, image/jpeg, image/jpg,application/pdf"
                    />
                  </div> */}
                  <div className='botones-adjuntar-doc'>
                    <div className="containerAdjuntarDocMobile">
                      <div className="containerDocumentMobile">
                        <p className="text-adjuntar">Adjuntar frente</p>
                        <label id="documentacion" htmlFor="adjuntarFrente" className="boton-adjuntar activado">Seleccionar archivo</label>
                        <input name="imagenFrente" onChange={mostrarImagenDeFrente} disabled={datosUsuarioLogeado != null && datosUsuarioLogeado.isDocumentacion == false ? false : true} id="adjuntarFrente" type="file" accept="image/png, image/jpeg" />
                      </div>
                      <div className="containerDocumentMobile">
                        <p className="text-adjuntar">Adjuntar dorso</p>
                        <label htmlFor="adjuntarDorso" className="boton-adjuntar activado" >Seleccionar archivo</label>
                        <input name="imagenDorso" onChange={mostrarImagenDeDorso} disabled={datosUsuarioLogeado != null && datosUsuarioLogeado.isDocumentacion == false ? false : true} id="adjuntarDorso" type="file" accept="image/png, image/jpeg" />
                      </div>
                    </div>
                    {datosUsuarioLogeado != null && datosUsuarioLogeado.isDocumentacion == true ? <span className="texto-documentos-pendientes">Documentos enviados y pendientes de validación. </span> : ""}
                  </div>
                  {imagenesDoc.imagenFrente != null ||
                    imagenesDoc.imagenDorso != null ? (
                    <>
                      <hr className="linea-divisora-documentacion" />
                      <div className="etapa-documentacion-seccion-mostrar-docs">
                        <p>
                          {imagenesDoc.imagenDorso != null ? (
                            "2 archivos adjuntos"
                          ) : (
                            <p className="error-cargarndo-docs">
                              Falta adjuntar un archivo
                            </p>
                          )}
                        </p>
                        <div className="seccion-imagenes-documentos">
                          <div className="seccion-imagenes-documentos-box-imagenes">
                            {imagenesDoc.imagenFrente != null && (
                              <div className="mostrar-imagen-frente-caja">
                                <ImCross
                                  className="eliminar-imagen-doc"
                                  onClick={eliminarImagenFrente}
                                />
                                <img src={mostrarImagenFrente} />
                              </div>
                            )}
                            {imagenesDoc.imagenDorso != null && (
                              <div className="mostrar-imagen-dorso-caja">
                                <ImCross
                                  className="eliminar-imagen-doc"
                                  onClick={eliminarImagenDorso}
                                />
                                <img src={mostrarImagenDorso} />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {
                    imagenesDoc.imagenFrente != null && imagenesDoc.imagenDorso === null || imagenesDoc.imagenFrente === null && imagenesDoc.imagenDorso != null || 
                    imagenesDoc.imagenFrente != null && imagenesDoc.imagenDorso != null ?
                      <div className="seccion-imagenes-documentos-boton-enviar">
                        <Button className="boton-enviar-docs" disabled={imagenesDoc.imagenFrente != null && imagenesDoc.imagenDorso === null || 
                        imagenesDoc.imagenFrente === null && imagenesDoc.imagenDorso != null } 
                        loading={loadingButton} onClick={handleEnviarDocumentos} >Enviar</Button>
                      </div> : ""
                  }
                </div>
              </div>
            )}
          </div>
        ): (
          datosUsuarioLogeado != null && datosUsuarioLogeado.estadoNombre != "Aprobado" ?
          <>
            <div className="etapa-documentacion-box">
              <div className="etapa-documentacion-seccion-adjuntar-docs">
                <div className="etapa-documentacion-column-right">
                  <img src="https://res.cloudinary.com/blackhound/image/upload/v1661353251/BLACKHOUND/Usuario/Dashboard/sin_documentos.svg" />
                </div>
                <div className="etapa-documentacion-text">
                  <p className="etapa-documentacion-box-bold">
                    Tu documentación está siendo revisada
                  </p>
                </div>
              </div>
            </div>
          </>
          : ''
        )}
      </div>

      {/* <div className="mostrar-etapa-dato">
        {datosUsuarioLogeado != null &&
        datosUsuarioLogeado.isDocumentacion == false ? (
          <div className="etapa-documentacion">
            <div className="validacion-seccion-titulo">
              <span className="cuadrado"></span>
              <p>VALIDACIÓN DE DATOS</p>
            </div>
            <div className="etapa-documentacion-box">
              <div className="etapa-documentacion-seccion-adjuntar-docs">
                <div className="etapa-documentacion-column-right">
                  <img src="https://res.cloudinary.com/blackhound/image/upload/v1661353251/BLACKHOUND/Usuario/Dashboard/sin_documentos.svg" />
                  <div className="etapa-documentacion-text">
                    <p className="etapa-documentacion-box-bold">
                      Enviar copia de DNI / LE / LC
                    </p>
                    <p>
                      Adjuntá una foto de tu DNI / LE / LC <br /> (Frente y
                      dorso de tu DNI).
                    </p>
                  </div>
                </div>
                <div className="botones-adjuntar-doc">
                  <label
                    id="documentacion"
                    htmlFor="adjuntarFrente"
                    className="boton-adjuntar activado"
                  >
                    Seleccionar archivos
                  </label>
                  <input
                    multiple
                    name="imagenFrente"
                    style={{ display: "none" }}
                    onChange={mostrarImagen}
                    disabled={
                      datosUsuarioLogeado != null &&
                      datosUsuarioLogeado.isDocumentacion == false
                        ? false
                        : true
                    }
                    id="adjuntarFrente"
                    type="file"
                    accept="image/png, image/gif, image/jpeg" 
                  />
                </div>
              </div>
              {imagenesDoc.imagenFrente != null ||
              imagenesDoc.imagenDorso != null ? (
                <>
                  <hr className="linea-divisora-documentacion" />
                  <div className="etapa-documentacion-seccion-mostrar-docs">
                    <p>
                      {imagenesDoc.imagenDorso != null ? (
                        "2 archivos adjuntos"
                      ) : (
                        <p className="error-cargarndo-docs">
                          falta adjuntar un archivo
                        </p>
                      )}
                    </p>
                    <div className="seccion-imagenes-documentos">
                      <div className="seccion-imagenes-documentos-box-imagenes">
                        {imagenesDoc.imagenFrente != null && (
                          <div className="mostrar-imagen-frente-caja">
                            <ImCross
                              className="eliminar-imagen-doc"
                              onClick={eliminarImagenFrente}
                            />
                            <img src={mostrarImagenFrente} />
                          </div>
                        )}
                        {imagenesDoc.imagenDorso != null && (
                          <div className="mostrar-imagen-dorso-caja">
                            <ImCross
                              className="eliminar-imagen-doc"
                              onClick={eliminarImagenDorso}
                            />
                            <img src={mostrarImagenDorso} />
                          </div>
                        )}
                      </div>
                      <div className="seccion-imagenes-documentos-boton-enviar">
                        <input
                          className="boton-enviar-docs"
                          type="submit"
                          value="Enviar"
                          onClick={handleEnviarDocumentos}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div> */}

      <div>
        <div className="validacion-seccion-titulo">
          <span className="cuadrado"></span>
          <p>MI CUENTA</p>
        </div>
        <div className="cargar-imagen-perfil-box">
          <p className="cargar-imagen-perfil-box-title">Cargá tu imagen de perfil</p>
          <label
            htmlFor="imagenPerfil"
            className="boton-adjuntar-imagen-perfil "
          >
            
            <img className="imagen" src={mostrarImagenPerfil} />
            <div className="overlay">
              <div className="text">Editar Foto</div>
            </div>
          </label>
          <input
            name="imagenDePerfil"
            style={{ display: "none" }}
            onChange={capturarImagenPerfil}
            id="imagenPerfil"
            type="file"
            accept="image/png, image/gif, image/jpeg"
          />
          <p className="imagen-perfil-descripcion">
            Elegí una foto personal, donde se vea claramente tu rostro y no
            estes acompañado/a.
          </p>
          {/* {datosUsuarioLogeado != null && mostrarImagenPerfil != "https://res.cloudinary.com/blackhound/image/upload/v1661526082/BLACKHOUND/Frame_6_nf4dua.svg" 
          && mostrarImagenPerfil != "https://www.blackhound.com.ar/server/public/getImgProfile/" + datosUsuarioLogeado.id ? 
           : "" } */}
          <Button type="button" loading={loadingButton}  className="guardar-imagen-perfil" onClick={enviarImagenPerfil} > Guardar Imagen </Button>
        </div>
      </div>
      <ModalGeneric
      open={open}
      setOpen={setOpen}
      title={modalPhotoPerfil.title}
      button={modalPhotoPerfil.button}
      size={modalPhotoPerfil.size}
      />
      <Modal className='modalContrasenaModificada' backdrop="static" size="xs" open={openModalDoc} onClose={setOpenModalDoc}>
        <Modal.Header>
          <Modal.Title>Su documentación fue enviada con éxito.</Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>{setOpenModalDoc(false)}} appearance="primary">Cerrar</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
