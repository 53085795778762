import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import ModalCancelarTurno from './modalCancelarTurno/modalCancelarTurno';
import './turnos.css';

export default function Turnos() {
  const [cancelarTurno, setCancelarTurno] = useState(null);

  const listaTurnos = useSelector(
    (state) => state.reducerListaTurno.data
  );
  const [modalCancelar, setModalCancelar] = useState(null);
  function handleCancelarTurno() {
    setModalCancelar({ ...modalCancelar })
  }

  if (listaTurnos != null && listaTurnos.data != null) {
    var listaTurnosUsuario = [];
    for (var i = 0; i < listaTurnos.data.length; i++) {
      let guardarTurno = listaTurnos.data[i];
      listaTurnosUsuario.push(
        <>
          <div className="turnos-user">
            <div className='container-data-turno'>
              <div>
                <img className="tam-calendar-turno" src='https://res.cloudinary.com/blackhound/image/upload/v1661269425/BLACKHOUND/Asesor/Solicitudes/Pendientes/AsignarTurno_op4cpb.svg' alt='' />
              </div>
              <div>
                <div className="contratos-user-col">
                  <p className='tittle-turnos'>Fecha: {listaTurnos.data[i].fecha.date.substring(0, 10)}</p>
                </div>
                <div className="contratos-user-col">
                  <p className='tittle-turnos'>Horario: {listaTurnos.data[i].fecha.date.substring(16, 10)}</p>
                </div>
              </div>
            </div>
            {/* <div>
              <input className="button-proyecto-turno" onClick={()=>handleCancelarTurno(guardarTurno)} type="button" value="Cancelar turno" />
            </div> */}
          </div>
        </>
      );
    }
  }

  return (
    <div className='containerTurnos'>
      <h5 className="titulo-seccion">TURNOS</h5>
      <div className="categoria-titulo2">
        <div className="containerHeaderTurnos">
        <div className="containerTitleTurnos"><div className='circle-categoria'/>TURNOS ASIGNADOS</div>
      <p className='tittle-turnos'>Recuerde que todas las citas se realizan en <b>Av. 7 Nª 840, La Plata.</b></p>
      </div>
      </div>
      {listaTurnos != null && listaTurnos.data != null ?
        <div className='containerListaTurnosUsuario'>
        {listaTurnosUsuario}
        </div>
        :
        <div className="sin-contratos-container">
          <img className="tam-calendar-turno" 
          src='https://res.cloudinary.com/blackhound/image/upload/v1668000917/BLACKHOUND/Turnos/Frame_dk1vxt.svg' />
          <p>No hay turnos disponibles</p>
        </div>
      }

      <ModalCancelarTurno dataModalCancelar={cancelarTurno} />
    </div>
  );
}
