import { all } from'redux-saga/effects';
import { watchRegistroUser } from './watchRegistroUser';
import { watchListaClientesRegistrados } from './watchListaClientesRegistrados';
import { watchEstadoCliente } from './watchEstadoCliente';
import { watchCrearProyecto } from './watchCrearProyecto';
import { watchListaMonedas } from './watchListaMonedas';
import { watchListaSolicitudesRegistrados } from './watchListaSolicitudesRegistrados';
import { watchMostrarProyectos } from './watchMostrarProyectos';
import { watchCategoriaProyecto } from './watchCategoriasProyectos';
import { watchDashboard } from './watchDashboard';
import { watchEstadoSolicitud } from './watchEstadoSolicitud';
import { watchEditarProyecto } from './watchEditarProyecto';
import { watchEnviarCita} from './watchEnviarCita';
import { watchLoginService } from  './watchLogin';
import { watchEnviarDocumentos } from './enviarDocumentos';
import { watchDatosUsuario } from './watchUsuarioData';
import { watchInversionesUsuario } from './watchInversionesUsuario';
import { watchRecibirDocumentos} from './recibirDocumentacion'
import { watchEstadisticasUsuario} from './watchEstadisticasUsuario'
import { watchApiDolar} from './watchDolar'
import { watchEnviarMailUsuario } from './watchEnviarMailUsuario';
import { watchEnviarMailSolicitud } from './watchEnviarMailSolicitud';
import { watchListaAsesores } from './watchListaAsesores';
import { watchNotificacionesUsuario } from './watchNotificacionesUsuario';
import { watchTurnosAsesor } from './watchTurnosAsesor';
import { watchAgregarAsesorSolicitud } from './watchAgregarAsesorSolicitud';
import { watchImagenPerfil } from './watchImagenPerfil';
import { watchGuardarIdContrato } from './watchGuardarIdContrato';
import { watchEnviarContrato } from './watchEnviarContrato';
import { watchEditarPerfil } from './watchEditarPerfil';
import { watchObtenerChat } from './watchObtenerChat';
import { watchEnviarMensaje } from './watchEnviarMensaje';
import { watchEnviarMailCodigo } from './watchEnviarMailCodigo';
import { watchTurnos } from './watchTurno';
import { watchEnviarTurno } from './watchEnviarTurno';
import { watchCrearCategoria } from './watchCrearCategoria';
import { watchNotificaruser } from './watchNotificarUser';
import { watchCancelarTurno } from './watchCancelarTurno';
import { watchCrarNotificacion } from './watchCrearNotificacion';
import { watchEstadoNotificaciones } from './watchNotificacionesEstado';
import { watchAllConversaciones } from './watchAllConversaciones';
import { watchEstadoMensaje } from './watchEstadoMensaje';
import 'react-toastify/dist/ReactToastify.css';
import { watchAgregarAsesorAUnUsuario } from './watchAgregarAsesorAUsuario';
import { watchListaSolicitudesAdministrador } from './watchListaSolicitudesAdministrador';
import { watchUpdateAsesor } from './watchUpdateAsesor';
import { watchEliminarDocumentacionUsuario } from './eliminarDocumentacion';

export default function * rootSaga(){
    yield all ([
        watchRegistroUser(),
        watchListaClientesRegistrados(),
        watchEstadoCliente(),
        watchCrearProyecto(),
        watchListaMonedas(),
        watchListaSolicitudesRegistrados(),
        watchMostrarProyectos(),
        watchCategoriaProyecto(),
        watchDashboard(),
        watchEstadoSolicitud(),
        watchEditarProyecto(),
        watchEnviarCita(),
        watchLoginService(),
        watchEnviarDocumentos(),
        watchDatosUsuario(),
        watchInversionesUsuario(),
        watchRecibirDocumentos(),
        watchEstadisticasUsuario(),
        watchApiDolar(),
        watchEnviarMailUsuario(),
        watchEnviarMailSolicitud(),
        watchListaAsesores(),
        watchNotificacionesUsuario(),
        watchTurnosAsesor(),
        watchAgregarAsesorSolicitud(),
        watchImagenPerfil(),
        watchGuardarIdContrato(),
        watchEnviarContrato(),
        watchObtenerChat(),
        watchEnviarMensaje(),
        watchEditarPerfil(),
        watchEnviarMailCodigo(),
        watchTurnos(),
        watchEnviarTurno(),
        watchCrearCategoria(),
        watchNotificaruser(),
        watchCancelarTurno(),
        watchCrarNotificacion(),
        watchEstadoNotificaciones(),
        watchAllConversaciones(),
        watchEstadoMensaje(),
        watchAgregarAsesorAUnUsuario(),
        watchListaSolicitudesAdministrador(),
        watchUpdateAsesor(),
        watchEliminarDocumentacionUsuario(),
    ])
 
}