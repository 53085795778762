import { actionTypes } from "../Constantes/ActionTypes"; 

export const getDashboard = (data) => {
    return {
        type: actionTypes.DASHBOARD_RECIBE_GET_USER,
        data
    }
}

export const setDashboard = (data) => {
    return {
        type: actionTypes.DASHBOARD_RECIBE_SET_USER,
        data
    }
}

