import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import ModalContrasenaModificada from './ModalContrasenaModificada/ModalContrasenaModificada';


export default function CambiarContrasena(props) {
    const [loadingButton, setLoadingButton] = useState(true);
    const { id } = useParams();
    const [open, setOpen] = useState(false);

    const {
        register,
        formState: { errors },
        getValues,
        handleSubmit
    } = useForm();

    const onSubmit = (data) => {
        setLoadingButton(true);
        setTimeout(() => {
            setLoadingButton(false);
        }, 1500);
        const recuperarContrasena = {
            password: data.password,
            codigoMail: id,
        }
        axios.post(`https://www.blackhound.com.ar/server/public/updateContrasena`, recuperarContrasena)
            .then(function (response) {
                setOpen(true)
                setTimeout(() => {
                    navigation("/IniciarSesion")
                }, 1500);
            })
            .catch(function (error) {
            });
    };
    const navigation = useNavigate()
    return (
        <>
            <ModalContrasenaModificada
                open={open}
                setOpen={setOpen}
            />
            <div className='validar-mail-container'>
                <div className='validar-mail-box-cambiar-contrasena' data-aos="zoom-in" data-aos-duration="1000">
                    <h5>Modificar Contraseña</h5>
                    <form className='formulario-validar-mail' onSubmit={handleSubmit(onSubmit)}>
                        <div className='fomulario-campo'>
                            <input type="password"
                                placeholder='Ingrese una nueva contraseña'
                                autoComplete='off'
                                {...register("password", { required: true, minLength: 8, pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/g, })}

                            />
                        </div>
                        {errors.password?.type === "required" && <p style={{padding:"0px"}} className='error'>Este campo es requerido</p>}
                        {errors?.password?.type === "pattern" && (
                            <p style={{padding:"0px"}} className='error'>La contraseña debe tener al menos una mayuscula, <br />un número y un mínimo de 8 caracteres</p>
                        )}
                        {errors?.password?.type === "minLength" && (
                            <p style={{padding:"0px"}} className='error'>Mínimo 8 caracteres</p>
                        )}

                        <div className='fomulario-campo'>
                            <input type="password"
                                placeholder='Repita su nueva contraseña'
                                autoComplete='off'
                                //value={registro.passwordConfirmation}   
                                {...register("passwordConfirmation", {
                                    required: true, pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/g,
                                    validate: {
                                        matchesPreviousPassword: (value) => {
                                            const { password } = getValues();
                                            return password === value || "Las contraseñas no coinciden";
                                        }
                                    }
                                })}
                            />
                        </div>
                        {errors.password?.type === "required" && <p style={{padding:"0px"}} className='error'>Confirmar contraseña para continuar</p>}
                        {errors?.password?.type === "pattern" && (
                            <p style={{padding:"0px"}} className='error'>La contraseña debe tener al menos una mayuscula,<br />un número y un mínimo de 8 caracteres</p>
                        )}
                        <button style={{ width: "35%" }} type="submit" loading={loadingButton} className="boton-registrarse cambiar-contrasena" >CAMBIAR CONTRASEÑA</button>
                    </form>


                </div>
            </div>
        </>
    )
}
