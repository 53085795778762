import { actionTypes } from "../Constantes/ActionTypes";
import { store } from "../store/index";

function reducerDocumentos (state = store.documentosUsuario, action){
    switch(action.type){
        case actionTypes.SET_RECIBIR_DOCUMENTOS_USUARIO:
            let documentosData = action.data
            return Object.assign({}, state, {
                data: documentosData,
            })
            default:
                return state
    }
}

export default reducerDocumentos;