import { actionTypes } from "../Constantes/ActionTypes"; 

export const getChat = (data) => {
    return {
        type: actionTypes.GET_CONVERSACION,
        data
    }
}

export const setChat = (data) => {
    return {
        type: actionTypes.SET_CONVERSACION,
        data
    }
}

export const postEnviarMensaje = (data) => {
    return {
        type: actionTypes.ENVIAR_MENSAJE,
        data
    }
}

export const setIdCliente = (data) => {
    return {
        type: actionTypes.SET_ID_CLIENTE,
        data
    }
}

export const cantidadMensajes = (data) => {
    return {
        type: actionTypes.CANTIDAD_MENSAJES,
        data
    }
}

export const allConversacionesAsesor = (data) => {
    return {
        type: actionTypes.TODOS_LAS_CONVERSACIONES,
        data
    }
}

export const setAllConversacionesAsesor = (data) => {
    return {
        type: actionTypes.SET_TODOS_LAS_CONVERSACIONES,
        data
    }
}
export const allCantidadMensajes = (data) => {
    return {
        type: actionTypes.ALL_CANTIDAD_MENSAJES,
        data
    }
}

export const changeEstadoMensajes = (data) => {
    return {
        type: actionTypes.ESTADO_DE_MENSAJES,
        data
    }
}
