import React, { useState, useEffect } from 'react'
import Proyecto from './Proyecto/Proyecto'
import { useSelector, useDispatch } from 'react-redux';
import { getMostrarProyecto } from '../../Actions/mostrarProyectos';
import './paginaproyectos.css'

export default function Proyectos() {
  const [activePage, setActivePage] = useState(1)
  const [estadoFiltro, setEstadoFiltro] = useState(null)
  const [monedita, setMonedita] = useState(null)
  const [busqueda, setBusqueda] = useState({
    dataBase: ""
  })
  const [alinear, setAlinear] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const data = {
      busqueda: busqueda.dataBase,
      estado: estadoFiltro,
      page: activePage,
      categoria: estadoFiltro,
      moneda: monedita,
    }
    dispatch(getMostrarProyecto(data));
  }, [activePage, busqueda, estadoFiltro, monedita])

  const listaProyectos = useSelector(state => state.reducerMostrarProyecto.data);

  if (listaProyectos != null) {
    var proyectos = [];
    var estadoAlinear= false;
    const pu = listaProyectos.data;
    for (var i = 0; i < pu.length; i++) {
      {estadoAlinear = !estadoAlinear}
      proyectos.push(
        <>
          <Proyecto key={[i]} data={pu[i]} clase={estadoAlinear} />

        </>,
      );
    }
  }


  return (
    <>
      <div  >
        <div className='nosotros-header'>
          <div className='nosotros-header-text-container' data-aos="zoom-in" data-aos-duration="1000">
            {/* <span className='nosotros-barra'> </span> */}
            <h5 className='nosotros-titulo'>NUESTRAS OPORTUNIDADES DE INVERSIÓN</h5>
          </div>
        </div>

        <div className='proyectos-container'>
          <div className='proyectos-container-cajas'>
            <div className='proyecto' >
              {proyectos}
            </div>
            <div className='logoAtras'>
              <img className='fondoAguaProyectos' alt='fonto1' src='https://res.cloudinary.com/blackhound/image/upload/v1660243064/BLACKHOUND/Mask_group_ur2p0o.webp' />
            </div>
          </div>

        </div>

      </div>
    </>
  )
}
