import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import axios from 'axios';
import './faqs.css'
import ModalGeneric from '../../Components/ModalGeneric/ModalGeneric';
export default function Faqs() {
    const [open, setOpen] = useState(false);
    const [modal] = useState(
        {
            title: "Tu mensaje se envió con éxito.",
            content:"Nos pondremos en contacto a la brevedad.",
            button:"Aceptar",
            size:"sm"
        }
    )
    // ENVIAR FORMULARIO//
    const {
        register,
        reset,
        formState: { errors },
        handleSubmit
    } = useForm({
        mode: "onChange"
    });
    const onSubmit = (data) => {
        //alert(JSON.stringify(data));
        axios.post(`https://blackhound.com.ar/server/public/ayuda`, data,)
            .then(function (response) {
                setOpen(true)
                reset()
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    return (
        <>
        <ModalGeneric
        open={open}
        setOpen={setOpen}
        title={modal.title}
        content={modal.content}
        button={modal.button}
        size={modal.size}
        />
            <div className='nosotros-header'>
                <div className='nosotros-header-text-container' data-aos="zoom-in" data-aos-duration="1000">
                    {/* <span className='nosotros-barra'> </span> */}
                    <h5 className='nosotros-titulo'>Llegamos para que invertir sea <span>sencillo</span>, <span>accesible</span> y <span>seguro</span>. </h5>
                </div>
            </div>
            <div className='formulario-contacto-container' id="formularioDeContacto">
                <div className='formulario-contacto pagina-contacto contacto-landing'>
                    <p className='formulario-contacto-linea582-titulo'>
                        <span> Dejanos tu consulta, </span> <br />
                        <span>nos pondremos en contacto a la brevedad </span>
                    </p>
                    <form onSubmit={handleSubmit(onSubmit)} className="formulario-en-columnas" >
                        <div className='fila-formulario'>
                            <div className='campo-formulario'>
                                <label htmlFor="nombreCompleto">Nombre y Apellido</label><br />
                                <input
                                    autoComplete='off'
                                    placeholder="Escriba su nombre y apellido..."
                                    {...register("nombre", {
                                        required: "Campo requerido",
                                        maxLength: {
                                            value: 40,
                                            message: "Máximo 40 caracteres"
                                        },
                                        pattern:/^[a-zA-Z\s]*$/i
                                    })}
                                />
                                {errors.nombre && <p className='errorContacto'>{errors.nombre.message}</p>}
                                {errors.nombre?.type === 'pattern' && <p className='error'>Solo caracteres alfabéticos</p>}
                            </div>
                            <div className='campo-formulario'>
                                <label htmlFor="telefono">Teléfono</label><br />
                                <input
                                    autoComplete='off'
                                    placeholder="Escriba su télefono..."
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    {...register("telefono", {
                                        required: "Campo requerido",
                                        maxLength: {
                                            value: 20,
                                            message: "Máximo 20 caracteres"
                                        }
                                    })}
                                />

                                {errors.telefono && <p className='errorContacto'>{errors.telefono.message}</p>}
                            </div>
                            <div className='campo-formulario'>
                                <label htmlFor="mail">Mail</label><br />
                                <input
                                    autoComplete='off'
                                    placeholder="Escriba su mail..."
                                    type="text"
                                    {...register("email", {
                                        required: "Campo requerido",
                                        pattern: {
                                            value: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
                                            message: "Dirección de mail invalida"
                                        }
                                    })}
                                />
                                {errors.email && <p className='errorContacto'>{errors.email.message}</p>}
                            </div>
                        </div>
                        <div className='fila-formulario'>
                            <div className='campo-formulario-mensaje'>
                                <label htmlFor="mensaje">Tu mensaje</label><br />
                                <textarea
                                    placeholder="Ingresa tu mensaje"
                                    {...register("mensaje", {
                                        required: "Campo requerido",
                                        maxLength: {
                                            value: 600,
                                            message: "Máximo 600 caracteres"
                                        },
                                        minLength: {
                                            value: 20,
                                            message: "Minimo 20 caracteres"
                                        }
                                    })}
                                />
                                {errors.mensaje && <p className='errorContacto'>{errors.mensaje.message}</p>}
                            </div>
                        </div>
                        <div className='formulario-contacto-footer'>
                            <input className="boton-enviar-formulario-contacto" type="submit" value="Enviar" />
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
