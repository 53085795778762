import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'rsuite';

//ACTION
import { getCategoriaProyectos } from '../../../../Actions/categoriasProyecto';
import { getListaMonedas } from '../../../../Actions/listaMonedas';
import { getCrearProyecto } from '../../../../Actions/crearProyecto';
import { getMostrarProyecto } from '../../../../Actions/mostrarProyectos';
import { setCrearCategoria } from '../../../../Actions/crearCategoria';

//ICONS
import { AiFillPlusCircle } from 'react-icons/ai';


import './modalCrear.css';

const ModalCrearProyecto = (props) => {
    const data3 = props.dataModalCrearProyecto;
    const [open, setOpen] = useState(data3 != null);
    const [backdrop, setBackdrop] = useState('static');
    const handleClose = () => setOpen(false);

    const [datos, setDatos] = useState({
        fecha: "",
        categoria: "",
        tna: "",
        moneda: "Pesos",
        montoMinimo: "",
        plazoMinimo: "",
        nombre: "Titulo del Proyecto",
        descripcion: "Lorem",
        color:"#1f1f1f",
    });
    

    const [nuevaCategoria, setNuevaCategoria] = useState({
        nuevaCategoria:"",
    });
    const [activePage, setActivePage] = useState(1);
    const [estadoFiltro, setEstadoFiltro] = useState(null);
    const [monedita, setMonedita] = useState(null);
    const [busqueda, setBusqueda] = useState({
      dataBase: "",
    });
  

    const dispatch = useDispatch();
    const categorias = useSelector(state => state.reducerCategoriasProyecto.data);
    const listaMonedas = useSelector(state => state.reducerListaMonedas.data);

    useEffect(() => {
        dispatch(getCategoriaProyectos())
        dispatch(getListaMonedas())
    }, []);

    const [picture, setPicture] = useState(null);
    const [imgData, setImgData] = useState("https://res.cloudinary.com/blackhound/image/upload/v1657897325/BLACKHOUND/imagencrearproyecto-01_vuvryh_k4zcjs.png");

    const [mostrarImagenUno, setMostrarImagenUno] = useState();
    const [imagenUno, setImagenUno] = useState("https://res.cloudinary.com/blackhound/image/upload/v1657897325/BLACKHOUND/imagencrearproyecto-01_vuvryh_k4zcjs.png");

    const [mostrarImagenDos, setMostrarImagenDos] = useState();
    const [imagenDos, setImagenDos] = useState("https://res.cloudinary.com/blackhound/image/upload/v1657897325/BLACKHOUND/imagencrearproyecto-01_vuvryh_k4zcjs.png");

    const onChangePicture = (e) => {
        if (e.target.files[0]) {
            setPicture(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImgData(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onChangeImagenUno = (e) => {
        if (e.target.files[0]) {
            setMostrarImagenUno(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImagenUno(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onChangeImagenDos = (e) => {
        if (e.target.files[0]) {
            setMostrarImagenDos(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImagenDos(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onChangeDatos = (e) => {
        setDatos({
            ...datos,
            [e.target.name]: e.target.value
        });
    };

    const onChangeNuevaCategoria =(e) =>{        
        setNuevaCategoria({
            ...nuevaCategoria,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        const data = {
            busqueda: busqueda.dataBase,
            estado: estadoFiltro,
            page: activePage,
            categoria: estadoFiltro,
            moneda: monedita,
          };
        dispatch(getMostrarProyecto(data));
    }, [activePage, busqueda, estadoFiltro]);

    function handleCrearProyecto() {
        const nuevoProyecto = {
            fecha: datos.fecha,
            categoria: datos.categoria,
            tna: datos.tna,
            moneda: datos.moneda,
            montoMinimo: datos.montoMinimo,
            plazoMinimo: datos.plazoMinimo,
            nombre: datos.nombre,
            descripcion: datos.descripcion,
            color:datos.color,
        }
        const data = {
            busqueda: busqueda.dataBase,
            estado: estadoFiltro,
            page: activePage,
            categoria: estadoFiltro,
            moneda: monedita,
          };

        let formData = new FormData();
        formData.append("data", JSON.stringify(nuevoProyecto))
        formData.append("imagenProyecto", picture)
        formData.append("imagenUno", mostrarImagenUno)
        formData.append("ImagenDos", mostrarImagenDos)
        dispatch(getCrearProyecto(formData));
        setTimeout(() => {
            dispatch(getMostrarProyecto(data));
          }, 1500);
        setOpen(false);
    }

    //TIPO DE MONEDA
    if (listaMonedas != null) {
        var monedas = [];
        const pu = listaMonedas.data;
        for (var i = 0; i < pu.length; i++) {
            monedas.push(<option value={pu[i].id} > {pu[i].nombre} </option>)
        }
    }

    //TIPO DE CATEGORIA
    if (categorias != null) {
        var tipoCategorias = [];
        const catego = categorias.categorias;
        for (var i = 0; i < catego.length; i++) {
            tipoCategorias.push(<option value={catego[i].id} > {catego[i].nombre} </option>)
        }
    }

    useEffect(() => {
        if (data3 != null) {
            setOpen(data3);
        }
    }, [data3])

    const handleEnviarMail = () => {
        setOpen(false);
    }

    function crearCategoria(){
        const data ={
           nombre: nuevaCategoria.nuevaCategoria
        } 
        dispatch(setCrearCategoria(data))
        dispatch(getCategoriaProyectos())
    }

    return (
        <>
            {(open) &&

                <div className='modalEnvioDoc'>
                    <Modal className='proyecto-modal' backdrop={backdrop} open={open} size="lg" onClose={handleClose} >
                        <Modal.Header className='headerModal'>
                            <Modal.Title className='headerModal'>
                                <div className='container-tittle-modal'>
                                    <h2 className='tittle-modal-crear'> Crear nuevo proyecto </h2>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="crear-proyecto-formulario">
                                <form>
                                    <div className="contForm">
                                        <div className="crear-proyecto-form">
                                            {/* <div className="crear-proyecto-campo">
                                                <p className='tittle-label-input'>Fecha de creación</p>
                                                <input className='input-proyecto' type="date" id="fecha" name="fecha" onChange={onChangeDatos} />
                                            </div> */}

                                            <div className="crear-proyecto-campo" style={{ marginTop: '10px' }}>
                                                 <div className="crear-proyecto-campo campo-nueva-categoria">
                                                    <input className='input-proyecto' autoComplete='off' type="text" id="nuevaCategoriaProyecto" name="nuevaCategoria" placeholder='Ingrese nueva categoria' onChange={onChangeNuevaCategoria} />
                                                    <AiFillPlusCircle className='icono-mas-nueva-categoria' onClick={crearCategoria}/>
                                                </div>
                                                <select className='select-proyecto' name="categoria" onChange={onChangeDatos} >
                                                    <option selected="true" disabled="disabled">
                                                        Seleccione una categoría
                                                    </option>
                                                    {tipoCategorias}
                                                </select>
                                                <div className="crear-proyecto-campo">
                                                    <input className='input-proyecto' autoComplete='off' type="text" id="tna" name="tna" placeholder='Ingrese un TNA' onChange={onChangeDatos} />
                                                </div>
                                                <div className="crear-proyecto-campo">
                                                    <select className='select-proyecto' name="moneda" onChange={onChangeDatos} >
                                                        <option selected="true" disabled="disabled">
                                                            Seleccione una moneda
                                                        </option>
                                                        {monedas}
                                                    </select>
                                                </div>
                                                <div className="crear-proyecto-campo">
                                                    <input className='input-proyecto' autoComplete='off' type="text" id="montomin" name="montoMinimo" placeholder='Ingrese el monto minimo' onChange={onChangeDatos} />
                                                </div>
                                                <div className="crear-proyecto-campo">
                                                    <input className='input-proyecto' autoComplete='off' type="text" id="plazomin" name="plazoMinimo" placeholder='Ingrese un plazo minimo' onChange={onChangeDatos} />
                                                </div>
                                                <div>
                                                    <p className='tittle-label-input'>Color proyecto</p>
                                                    <input type="color" name="color" onChange={onChangeDatos} className="campo-color" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="contForm2">
                                            <div className="crear-proyecto-campo">
                                                <p className='tittle-label-input'>Nombre del proyecto</p>
                                                <input className='input-proyecto' autoComplete='off' type="text" id="nombre-asesor" name="nombre" onChange={onChangeDatos} />
                                            </div>
                                            <div className="crear-proyecto-campo" style={{ marginTop: '8px' }}>
                                                <p className='tittle-label-input'>Descripción del proyecto</p>
                                                <textarea className='textarea-crear' type="text" id="nombre-asesor" name="descripcion" onChange={onChangeDatos} />
                                            </div>

                                                <div>
                                                    <p className='tittle-label-input'>Logo proyecto</p>
                                                    <input type="file" name="imagenUno" onChange={onChangeImagenUno} className="foto-adjunta" />
                                                </div>

                                            <div className="crear-proyecto-campo" style={{ marginTop: '10px' }}>
                                                <p className='tittle-label-input'>Imagen del proyecto 1</p>
                                                <input type="file" name="imagenDos" onChange={onChangeImagenDos} className="foto-adjunta" />
                                            </div>
                                            <div className="crear-proyecto-campo" style={{ marginTop: '10px' }}>
                                                <p className='tittle-label-input'>Imagen del proyecto 2</p>
                                                <input id="imagen" name="portada" type="file" onChange={onChangePicture} className="foto-adjunta" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='contFooter'>
                            <button className='buttonModal' onClick={handleCrearProyecto}> Cargar Proyecto </button>
                            <span style={{ padding: '10px' }}></span>
                            <button className='buttonModal' onClick={handleClose}> Cancelar </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            }
        </>
    );
}

export default ModalCrearProyecto;
