import React from "react";
import "./noExisteMobile.css";

function NoExisteMobile() {
  return (
    <>
      <div className="fondo-mobile-Nodisponible">
        <div className="contenedor-mobile-Nodisponible">
          <div className="boxFoto">
            <p className="tituloMobile-noDisponible">
              No se escuentra disponible, por favor acceda desde
              un ordenador!
            </p>
            {/* <img width='80%' alt='logoError' src='https://res.cloudinary.com/blackhound/image/upload/v1660914486/BLACKHOUND/icons8-cancelar-144_fqxaiy.png'/> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default NoExisteMobile;
