import { actionTypes } from "../Constantes/ActionTypes"; 

export const getTraerEstadosSolicitudAdministrador = (data) => {
    return {
        type: actionTypes.GET_TRAER_SOLICITUDES_ADMINISTRADOR,
        data
    }
}

export const setTraerEstadosSolicitudAdministrador = (data) => {
    return {
        type: actionTypes.SET_TRAER_SOLICITUDES_ADMINISTRADOR,
        data
    }
}

