import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, Routes, Route } from "react-router-dom";
import { useSpring, animated } from "react-spring";
// import default style
import "rsuite/dist/rsuite.min.css"; // or 'rsuite/dist/rsuite.min.css'
// import views
import Turnos from "./Vistas/Turnos/Turnos";
import Documentos from "./Vistas/Documentos/Documentos";
import Perfil from "./Vistas/Perfil/Perfil";
import Contacto from "./Vistas/Contacto/contacto";
import Notificaciones from "./Vistas/Notificaciones/Notificaciones";
import UserDashboard from "./Vistas/UserDashboard/UserDashboard";
import SolicitudInversion from "./Vistas/UserDashboard/DashboardPasos/ContenidoAcordion/SolicitudInversion/SolicitudInversion";
import MisInversiones from "./Vistas/MisInversiones/MisInversiones";
import ChatClient from "../Components/Chat/ChatClient";
//Acctions
import { getIdContrato } from "../Actions/agregarContrato";
import { getInversionesUsuario } from "../Actions/inversionesUsuario";
import { getEstadisticasUsuario } from "../Actions/estadisticasUsuario";
import { getNotificacionUsuario } from "../Actions/notificacionUsuario";
import { getTurno } from "../Actions/turno";
import { getChat } from "../Actions/obtenerChat";
import { getDatosUsuario } from "../Actions/datosUsuario";

// import css
import "./homeusuarios.css";
import "../Admin/homeadmin.css";
import { useMediaQuery } from 'react-responsive'
import { Navbar, Offcanvas } from "react-bootstrap";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import ChatDefinitivo from "../Components/ChatDefinitivo/ChatDefinitivo";


const Page = () => {
  const datosUsuarioLogeado = useSelector((state) => state.reducerUsuarioDatos.data);
  const [notificacion, setNotificacion] = useState(null);
  const [avisoNotificacion, setAvisoNotificacion] = useState(false);
  const [chat, setChat] = useState("Sin Mensajes");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  //OBTENER NOTIFICACIONES
  const notificacionesBD = useSelector((state) => state.reducerNotificacionesUsuario.data);

  useEffect(() => {
    dispatch(getInversionesUsuario());
  }, [notificacion]);

  useEffect(() => {
    if (datosUsuarioLogeado != null) {
      dispatch(getIdContrato(datosUsuarioLogeado.id));
    }
  }, [datosUsuarioLogeado]);

  useEffect(() => {
    dispatch(getEstadisticasUsuario());
  }, [notificacion]);

  useEffect(() => {
    dispatch(getTurno());
  }, [notificacion]);

  const [pagina, setPagina] = useState(1)
  useEffect(() => {
    const data = {
      page : pagina
    }
    dispatch(getNotificacionUsuario(data));
  }, [notificacion]); 

 
  useEffect(() => {
    dispatch(getDatosUsuario());
  }, [notificacion]);
  
useEffect(() => {
  if (datosUsuarioLogeado != null) {
        const url = new URL(
          "https://mercure.blackhound.com.ar/.well-known/mercure"
        );
        url.searchParams.append(
          "topic",
          "https://blackhoundnotifications/notification/" + datosUsuarioLogeado.id
        ); //MERCURE
        const eventSource = new EventSource(url);
        eventSource.onmessage = function ({ data }) {
          //toast.success(JSON.parse(data).message, {});
          setNotificacion(JSON.parse(data).message);
          setAvisoNotificacion(true);
        };
  }
}, [datosUsuarioLogeado])

  let totalNoVisto = 0;
  if (notificacionesBD != null && notificacionesBD.status != 'Sin Notificaciones') {
    for (let i = 0; i < notificacionesBD.data.length; i++) {
      if(notificacionesBD.data[i].estado === "No visto"){
        totalNoVisto++;
      }
    }
  }


  /*CHAT*/
  useEffect(() => {
    if (datosUsuarioLogeado != null) {
      setTimeout(() => {
        dispatch(getChat({ userReceptor: datosUsuarioLogeado.idasesor }));
      }, 500);
    }
  }, [chat]);


  useEffect(() => {
    if (datosUsuarioLogeado != null) {
      const urlUser = new URL(
        "https://mercure.blackhound.com.ar/.well-known/mercure"
      );
      dispatch(getChat({ userReceptor: datosUsuarioLogeado.idasesor }));
      urlUser.searchParams.append(
        "topic",
        "https://blackhoundcomunication/chat/" +
        datosUsuarioLogeado.idasesor +
        datosUsuarioLogeado.id
      );
      const eventSourceUser = new EventSource(urlUser);
      eventSourceUser.onmessage = function ({ data }) {
        dispatch(getChat({ userReceptor: datosUsuarioLogeado.idasesor }));
        setChat(JSON.parse(data).message);
      };
    }
  }, []);
  /*FIN CHAT*/
  
  const isMobile = useMediaQuery({ maxDeviceWidth: 1023 })

  const fadeOut = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 1000,
  });

  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <>
      <animated.div style={fadeOut}>
        <div className='containerUser'>
          {!isMobile ?
            <div className='sideBarAdmin userSidebar'>
              <div className='childSidebar userChildSidebar'>
                <NavLink className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to={"Dashboard"}>
                  <span><img className='navAdmin' alt='Proyectos-Activos-icon' src='https://res.cloudinary.com/blackhound/image/upload/v1662062617/BLACKHOUND/Usuario/sideNavBar/desboard_hbbhse.svg' /></span>
                  <p>Dashboard</p>
                </NavLink>
                {datosUsuarioLogeado != null && datosUsuarioLogeado.codigoUser == false ? "" :
                  <>
                    <NavLink className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to="notificaciones">
                      <span>
                        <img className='navAdmin' alt='Proyectos-Activos-icon' src='https://res.cloudinary.com/blackhound/image/upload/v1662062624/BLACKHOUND/Usuario/sideNavBar/notificacionesUsuario_jgsvbn.svg' />
                      </span>
                      <p>Notificaciones</p>
                      {totalNoVisto > 0 ?
                        <div className="notificacion-number">{totalNoVisto}</div> 
                        : ''
                      }
                    </NavLink>

                    <NavLink className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to={"perfil"}>
                      <span><img className='navAdmin' alt='Perfil-icon' src='https://res.cloudinary.com/blackhound/image/upload/v1662062624/BLACKHOUND/Usuario/sideNavBar/perfilUsuario_gz29ku.svg' /></span>
                      <p>Perfil</p>
                    </NavLink>
                    <NavLink className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to={"turnos"}>
                      <span><img className='navAdmin' alt='Proyectos-Activos-icon' src='https://res.cloudinary.com/blackhound/image/upload/v1662062624/BLACKHOUND/Usuario/sideNavBar/turnosUsuario_dse06j.svg' /></span>
                      <p>Turnos</p>
                    </NavLink>
                    <NavLink className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to="documentos">
                      <span><img className='navAdmin' alt='Proyectos-Activos-icon' src='https://res.cloudinary.com/blackhound/image/upload/v1662062624/BLACKHOUND/Usuario/sideNavBar/documentosUsuario_fxz4ta.svg' /></span>
                      <p>Documentos</p>
                    </NavLink>
                    <NavLink className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to="contactoUser">
                      <span><img className='navAdmin' alt='Proyectos-Activos-icon' src='https://res.cloudinary.com/blackhound/image/upload/v1657897302/BLACKHOUND/ayuda-01_c2dwgt_xakq8v.svg' /></span>
                      <p>Contacto</p>
                    </NavLink>
                  </>
                }
              </div>
            </div>
            :
            <>
              <div className="containerSideNavBarResponsive">
                <AiOutlineMenuUnfold className="iconResponsiveUser" style={{ color: "#fff" }} onClick={handleShow} />
              </div>
              <Navbar.Offcanvas
                show={show}
                onHide={handleClose}
                id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel"
              >
                <Offcanvas.Header>
                  <Offcanvas.Title className='offcanvasLogo' id={`offcanvasNavbarLabel-expand-${"md"}`}>
                    <img className="boton-cierre" onClick={handleClose} src={'https://res.cloudinary.com/blackhound/image/upload/v1660933404/BLACKHOUND/NavBar/equis_lg2spj.svg'} />
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body onHide={handleClose}>
                  <NavLink onClick={() => { handleClose(); ScrollToTop() }} className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to={"Dashboard"}>
                    <span><img className='navAdmin' alt='Proyectos-Activos-icon' src='https://res.cloudinary.com/blackhound/image/upload/v1662062617/BLACKHOUND/Usuario/sideNavBar/desboard_hbbhse.svg' /></span>
                    <p>Dashboard</p>
                  </NavLink>
                  <NavLink onClick={() => { handleClose(); ScrollToTop() }} className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to="notificaciones">
                    <span>
                      {/* <Badge className="aviso-notificacion"></Badge> */}
                      <img className='navAdmin' alt='Proyectos-Activos-icon' src='https://res.cloudinary.com/blackhound/image/upload/v1662062624/BLACKHOUND/Usuario/sideNavBar/notificacionesUsuario_jgsvbn.svg' />
                    </span>
                    <p>Notificaciones</p>
                    {totalNoVisto > 0 ?
                        <div className="notificacion-number">{totalNoVisto}</div> 
                        : ''
                      }
                  </NavLink>

                  <NavLink onClick={() => { handleClose(); ScrollToTop() }} className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to={"perfil"}>
                    <span><img className='navAdmin' alt='Perfil-icon' src='https://res.cloudinary.com/blackhound/image/upload/v1662062624/BLACKHOUND/Usuario/sideNavBar/perfilUsuario_gz29ku.svg' /></span>
                    <p>Perfil</p>
                  </NavLink>
                  <NavLink onClick={() => { handleClose(); ScrollToTop() }} className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to={"turnos"}>
                    <span><img className='navAdmin' alt='Proyectos-Activos-icon' src='https://res.cloudinary.com/blackhound/image/upload/v1662062624/BLACKHOUND/Usuario/sideNavBar/turnosUsuario_dse06j.svg' /></span>
                    <p>Turnos</p>
                  </NavLink>
                  <NavLink onClick={() => { handleClose(); ScrollToTop() }} className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to="documentos">
                    <span><img className='navAdmin' alt='Proyectos-Activos-icon' src='https://res.cloudinary.com/blackhound/image/upload/v1662062624/BLACKHOUND/Usuario/sideNavBar/documentosUsuario_fxz4ta.svg' /></span>
                    <p>Documentos</p>
                  </NavLink>
                  <NavLink onClick={() => { handleClose(); ScrollToTop() }} className={({ isActive }) => (isActive ? 'activeAdmin' : 'nav-admin-link')} to="contactoUser">
                    <span><img className='navAdmin' alt='Proyectos-Activos-icon' src='https://res.cloudinary.com/blackhound/image/upload/v1657897302/BLACKHOUND/ayuda-01_c2dwgt_xakq8v.svg' /></span>
                    <p>Contacto</p>
                  </NavLink>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </>
          }

          <div className="routesAdmin">
            <Routes path="/*">
              <Route path="dashboard" element={<UserDashboard />} />
              <Route path="perfil" element={<Perfil />} />
              <Route path="turnos" element={<Turnos />} />
              <Route path="notificaciones" element={<Notificaciones />} />
              <Route path="contactoUser" element={<Contacto />} />
              <Route path="documentos" element={<Documentos />} />
              <Route path="dashboard/solicitudInversion" element={<SolicitudInversion />} />
              <Route path="misInversiones" element={<MisInversiones />} />
            </Routes>
          </div>
        </div>
        <ChatDefinitivo/>
        {/* <ChatClient /> */}
      </animated.div>
    </>
  );
};
export default Page;
