import { actionTypes } from "../Constantes/ActionTypes";
import { store } from "../store/index";

function reducerRegistro (state = store.registro, action){
    switch(action.type){
        case actionTypes.ENVIO_REGISTRO_USUARIO:
            let Registro = action.data
            return Object.assign({}, state, {
                data: Registro,
            })
            default:
                return state
    }
}

export default reducerRegistro;