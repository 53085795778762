import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useLocation } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { useMediaQuery } from "react-responsive"
import "../../FichasTecnicas/fichasTecnicas.css";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function FichaElemental() {
  const isMobile = useMediaQuery({ maxDeviceWidth: 1024 })
  const data = JSON.parse(localStorage.getItem("datosFichaElemental"))
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  
  const datosUsuarioLogeado = useSelector(
    (state) => state.reducerUsuarioDatos.data
  );

  const ScrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'

    })
}
  return (
    <div className="ficha-tecnica-container">
      <div className="ficha-tecnica-inner-container">
        <div
          className={`header-img-ficha-tecnica ${
            "background-color-" + data.categoria
          }`}
        >
          <img src="https://res.cloudinary.com/blackhound/image/upload/v1668169362/BLACKHOUND/Ficha%20Tecnica/Elemental/Group_121_c4x9ac.svg" alt=""/>
        </div>
        <div className="ficha-tecnica-proyect-description">
          <p>
          Elemental Constructora ofrece un sistema único de inversión por metro cuadrado, 
          que asegura una rentabilidad anual fija en dólares, así como un ingreso mensual en pesos.
          <br />
          <br />
          Apuntado al mercado de vivienda modular, el dinero invertido se destina a la construcción de 
          casas alquilables en todo el país. Además de la renta fija anual en dólares, los m2 adquiridos 
          corresponden a un proporcional del alquiler, dando al inversor un ingreso mensual en pesos.
          <br />
          <br />
          Las viviendas modulares permiten ser transportadas a todo el país, 
          donde los dueños de un lote pueden optar por alquilarlas. 
          Estas opciones son más económicas que una tradicional, generando mayores 
          posibilidades de acceso a la vivienda. Además, su corto tiempo de producción 
          permite satisfacer rápidamente una demanda de crecimiento ininterrumpido.
          </p>
        </div>
        {!isMobile ? 
        <div className="containerFichaElemental">
          <h3 className="subTitleFichaElemental">¿Cómo funciona?</h3>
          <div className="pasosFichaElemental">
            <div className="containerCardsFichaElemental">
                <img src="https://res.cloudinary.com/blackhound/image/upload/v1668169867/BLACKHOUND/Ficha%20Tecnica/Elemental/dimensions_icon_160945_1_wykrm3.svg" alt=""/>
                <div style={{width: "190px"}} className="displayRowComponentFichaElemental">
                  <div className="numberFichaElemental">1</div>
                  <p>Simulá tu inversión<br/>
                  en base a los metros<br/>
                  cuadrados a adquirir.</p>
                </div>
            </div>
            <div className="containerCardsFichaElemental">
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1668169867/BLACKHOUND/Ficha%20Tecnica/Elemental/callcenter_icon_220023_1_txfohp.svg" alt=""/>
                <div style={{width: "185px"}} className="displayRowComponentFichaElemental">
                  <div className="numberFichaElemental">2</div>
                  <p>Un asesor se pondrá<br/>
                  en contacto.</p>
                </div>
            </div>
            <div className="containerCardsFichaElemental">
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1668169867/BLACKHOUND/Ficha%20Tecnica/Elemental/985761_1_saerc9.svg" alt=""/>
                <div style={{width: "175px"}} className="displayRowComponentFichaElemental">
                  <div className="numberFichaElemental">3</div>
                  <p>Comprá desde 1 m²<br/>
                  en adelante.</p>
                </div>
            </div>
            <div className="containerCardsFichaElemental">
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1668169867/BLACKHOUND/Ficha%20Tecnica/Elemental/money-svgrepo-com_1_zkwdvm.svg" alt=""/>
                <div style={{width: "260px"}} className="displayRowComponentFichaElemental">
                  <div className="numberFichaElemental">4</div>
                  <p>Cada 30 días recibirás el <br/> 
                  proporcional del alquiler en<br/>
                  pesos, y finalizados los 12<br/>
                  meses, el capital mas el interés<br/>
                  fijo en dólares</p>
                </div>
            </div>
          </div>
          <div className="containerSecondSectionFichaElemental">
            <h3 className="subTitleFichaElemental">Ventajas</h3>
            <div className="pasosFichaElementalsecondSection">
              <div className="containerLiFichaElemental">
                <li>Total de los alquileres garantizados</li>
                <li>Llegada a todo el país</li>
                <li>Mercado de prefabricadas</li>
                <li>Bajo monto de acceso</li>
              </div>
              <div className="containerLiFichaElemental">
                <li>Doble renta, fija y variable</li>
                <li>Inversión en pesos/dólares, retorno en dólares</li>
                <li>Atención personalizada</li>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="containerFichaElemental">
        <h3 className="subTitleFichaElemental">¿Cómo funciona?</h3>
        <div className="pasosFichaElemental">
          <div className="containerCardsFichaElemental">
            <div>
            <img src="https://res.cloudinary.com/blackhound/image/upload/v1668169867/BLACKHOUND/Ficha%20Tecnica/Elemental/dimensions_icon_160945_1_wykrm3.svg" alt=""/>
            </div>

              <div className="displayRowComponentFichaElemental">
                <div className="numberFichaElemental">1</div>
                <p>Simulá tu inversión<br/>
                en base a los metros<br/>
                cuadrados a adquirir.</p>
              </div>
          </div>
          <div className="containerCardsFichaElemental">
            <div>
            <img src="https://res.cloudinary.com/blackhound/image/upload/v1668169867/BLACKHOUND/Ficha%20Tecnica/Elemental/callcenter_icon_220023_1_txfohp.svg" alt=""/>
            </div>

              <div className="displayRowComponentFichaElemental">
                <div className="numberFichaElemental">2</div>
                <p>Un asesor se pondrá<br/>
                en contacto.</p>
              </div>
          </div>
          <div className="containerCardsFichaElemental">
            <div>
            <img src="https://res.cloudinary.com/blackhound/image/upload/v1668169867/BLACKHOUND/Ficha%20Tecnica/Elemental/985761_1_saerc9.svg" alt=""/>
            </div>

              <div className="displayRowComponentFichaElemental">
                <div className="numberFichaElemental">3</div>
                <p>Comprá desde 1 m²<br/>
                en adelante.</p>
              </div>
          </div>
          <div className="containerCardsFichaElemental">
            <div>
            <img src="https://res.cloudinary.com/blackhound/image/upload/v1668169867/BLACKHOUND/Ficha%20Tecnica/Elemental/money-svgrepo-com_1_zkwdvm.svg" alt=""/>
            </div>

              <div className="displayRowComponentFichaElemental">
                <div className="numberFichaElemental">4</div>
                <p style={{width: "100%"}}>Cada 30 días recibirás el  
                proporcional del alquiler en
                pesos, y finalizados los 12
                meses, el capital mas el interés
                fijo en dólares</p>
              </div>
          </div>
        </div>
        <div className="containerSecondSectionFichaElemental">
          <h3 className="subTitleFichaElemental">Ventajas</h3>
          <div className="pasosFichaElementalsecondSection">
            <div className="containerLiFichaElemental">
              <li>Total de los alquileres garantizados</li>
              <li>Llegada a todo el país</li>
              <li>Mercado de prefabricadas</li>
              <li>Bajo monto de acceso</li>
              <li>Doble renta, fija y variable</li>
              <li>Inversión en pesos/dólares, retorno en dólares</li>
              <li>Atención personalizada</li>
            </div>
          </div>
        </div>
      </div>
        }
        <div className="containerLinkFichaElemental">
          <a className="red-text" target="_blank" href="https://elementalconstructora.com.ar/" >www.elementalconstructora.com.ar</a>
        </div>
        <div className="carrousel-ficha-tecnica">
          <Swiper
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            loop={true}
            spaceBetween={10}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Thumbs]}
            className="slider-superior-grande"
          >
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663937422/BLACKHOUND/Ficha%20Tecnica/Elemental/5-03_1_pfe0hj.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663937411/BLACKHOUND/Ficha%20Tecnica/Elemental/image_9_vodgu4.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663937411/BLACKHOUND/Ficha%20Tecnica/Elemental/5-03_1_bsduht.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663937411/BLACKHOUND/Ficha%20Tecnica/Elemental/image_10_obmxsp.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1668179051/BLACKHOUND/Ficha%20Tecnica/Elemental/image_9_shbhln.png" />
            </SwiperSlide>
          </Swiper>
          <hr className="linea-divisora-ficha-tecnica" />
          <Swiper
            onSwiper={setThumbsSwiper}
            navigation={true}
            loop={true}
            spaceBetween={20}
            slidesPerView={3}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="slider-inferior-thumbail"
          >
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663937422/BLACKHOUND/Ficha%20Tecnica/Elemental/5-03_1_pfe0hj.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663937411/BLACKHOUND/Ficha%20Tecnica/Elemental/image_9_vodgu4.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663937411/BLACKHOUND/Ficha%20Tecnica/Elemental/5-03_1_bsduht.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663937411/BLACKHOUND/Ficha%20Tecnica/Elemental/image_10_obmxsp.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1668179051/BLACKHOUND/Ficha%20Tecnica/Elemental/image_9_shbhln.png" />
            </SwiperSlide>
          </Swiper>
        </div>
        <hr className="linea-divisora-ficha-tecnica" />
        <div className="ficha-tecnica-productos">
          <p className="ficha-tecnica-productos-titulo">PRODUCTOS</p>
          <div className="ficha-tecnica-productos-imagenes-contenedor">
            <div className="ficha-tecnica-productos-imagenes-fila-elemental">
              <img
                className="imageProductFichaElemental"
                alt="UMA I"
                src="https://res.cloudinary.com/blackhound/image/upload/v1668179644/BLACKHOUND/Ficha%20Tecnica/Elemental/Group_1789_r6ik9e.svg"
              />
              <img
                className="ficusQuincho"
                alt="Ficus Quincho"
                src="https://res.cloudinary.com/blackhound/image/upload/v1661448308/BLACKHOUND/Ficha%20Tecnica/Elemental/Proyecto/quincho-ficus_1_wtqd9l.svg"
              />
              <img
                className="imageProductFichaElemental"
                alt="Laurel"
                src="https://res.cloudinary.com/blackhound/image/upload/v1668179791/BLACKHOUND/Ficha%20Tecnica/Elemental/Group_1787_xngvvo.svg"
              />
            </div>
          </div>
        </div>
        <hr className="linea-divisora-ficha-tecnica" />
          <div className="proyecto-data-en-ficha-tecnica-elemental">
            {!isMobile ?
            <>
            <div className="containerElemental">
              <div className="proyectos-descripcion-item-elemental-tna">
                <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897298/BLACKHOUND/tna_nhms4e_iz5fy8.svg" />
                <p className="proyectos-iconos-titulo-elemental">TNA</p>
                <p className="proyectos-iconos-desc-elemental">{data.tna}%</p>
              </div>
              <img className="masFichaElemental" src="https://res.cloudinary.com/blackhound/image/upload/v1668183506/BLACKHOUND/Ficha%20Tecnica/Elemental/vvzjecmnty0srchzloqs.png" alt=""/>
              <div className="proyectos-descripcion-item-elemental-tna">
                <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897298/BLACKHOUND/tna_nhms4e_iz5fy8.svg" />
                <p className="proyectos-iconos-titulo-elemental">PROPORCIONAL VARIABLE EN PESOS</p>
              </div>
            </div>
            <hr className="linea-divisora-proyectos-descripcion-item" />
            <div className="proyectos-descripcion-item-elemental-montoMinimo">
              <img className="montoMinimoFichaElemental" src="https://res.cloudinary.com/blackhound/image/upload/v1668181199/BLACKHOUND/Ficha%20Tecnica/Elemental/Group_193_lyvtsa.svg" />
              <p className="proyectos-iconos-titulo">Monto mínimo</p>
              <p className="proyectos-iconos-desc">
                {data.moneda} {data.montoMinimo}
              </p>
            </div>
            <hr className="linea-divisora-proyectos-descripcion-item" />
            <div className="proyectos-descripcion-item-elemental-plazoMinimo">
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1668182033/BLACKHOUND/Ficha%20Tecnica/Elemental/Group_194_ihread.svg" />
              <p className="proyectos-iconos-titulo">Plazo mínimo</p>
              <p className="proyectos-iconos-desc">{data.plazo} meses</p>
            </div>
            </>
            :
            <>
              <div className="containerElemental">
                <div className="proyectos-descripcion-item-elemental-tna">
                  <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897298/BLACKHOUND/tna_nhms4e_iz5fy8.svg" />
                  <p className="proyectos-iconos-titulo-elemental">TNA</p>
                  <p className="proyectos-iconos-desc-elemental">{data.tna}%</p>
                </div>
                  <img className="masFichaElemental" src="https://res.cloudinary.com/blackhound/image/upload/v1668183506/BLACKHOUND/Ficha%20Tecnica/Elemental/vvzjecmnty0srchzloqs.png" alt=""/>
                <div className="proyectos-descripcion-item-elemental-tna">
                  <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897298/BLACKHOUND/tna_nhms4e_iz5fy8.svg" />
                  <p className="proyectos-iconos-titulo-elemental">PROPORCIONAL VARIABLE EN PESOS</p>
                </div>
            </div>
            <div className="proyectos-descripcion-item-fichaDesarrollos">
              <div className="containerResponsiveItemsFicha">
                <img src="https://res.cloudinary.com/blackhound/image/upload/v1661865313/BLACKHOUND/Proyectos/montoMinimo_j0ym0l.svg" />
                <div className="containerResponsiveItemP">
                  <p className="proyectos-iconos-titulo">Monto mínimo</p>
                  <p className="proyectos-iconos-desc">
                  {data.moneda} {data.montoMinimo}
                </p>
                </div>
              </div>  
            </div>
            <div className="proyectos-descripcion-item-fichaDesarrollos">
              <div className="containerResponsiveItemsFicha">
                <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897305/BLACKHOUND/calendario_bulhhb_fwnlxz.svg" />
                <div className="containerResponsiveItemP">
                  <p className="proyectos-iconos-titulo">Plazo mínimo</p>
                  <p className="proyectos-iconos-desc">{data.plazo} meses</p>
                </div>
              </div>  
            </div>
            </>
            }  
          </div>
        {datosUsuarioLogeado != null && datosUsuarioLogeado.estadoNombre === "Aprobado" ?
        <div className="buttonToCalculator">
          <Link 
          onClick={() => {ScrollToTop(); localStorage.setItem("datosFicha", JSON.stringify(data))}} 
          to={"/HomeUsuarios/Dashboard/solicitudInversion"}
          >Proyectar inversión</Link>
        </div>
        : ""
        }
      </div>
    </div>
  );
}
