import { actionTypes } from "../Constantes/ActionTypes"; 

export const getTurnoAsesor = (data) => {
    return {
        type: actionTypes.GET_TURNO_ASESOR,
        data
    }
}

export const setTurnoAsesor = (data) => {
    return {
        type: actionTypes.SET_TURNO_ASESOR,
        data
    }
}
