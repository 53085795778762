import apiInstance from './api';

export const datosUsuario = () => {
    return apiInstance.get('/api/idUser')
    .then(res =>{
            return res.data;
    })
    .catch(error => {
        console.error(error.response);
        throw error.response
    });
}