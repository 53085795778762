import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call,put } from'redux-saga/effects';
import { notificarUsuario } from "../api/notificarUsuario";
import { setNotificarUserMail } from "../Actions/notificarUser";

export const watchNotificaruser= function * (){
    yield takeEvery(actionTypes.NOTIFICAR_MAIL_USUARIO, fetchNotificarUsuario)
}

function* fetchNotificarUsuario(action){
    try{
        const data = yield call(notificarUsuario, action.data)
        if(data){
            yield put(setNotificarUserMail(data))
        }
    }catch(err){
        console.log(err)
    }
}