import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';
import { listaTurnosUser } from "../api/turnoLista";
import { setTurno } from "../Actions/turno";

export const watchTurnos = function * (){
    yield takeEvery(actionTypes.GET_TURNO_USER, fetchTurnos)
}

function* fetchTurnos(action){
    try{
        const data = yield call(listaTurnosUser, action.data)
        if(data){
            yield put(setTurno(data))
        }
    }catch(err){
        console.log(err)
    }
}