import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';
import {listaSolicitudesAdministrador} from "../api/listaSolicitudesAdministrador"
import { setTraerEstadosSolicitudAdministrador } from "../Actions/listAdministrador"; 

export const watchListaSolicitudesAdministrador = function * (){
    yield takeEvery(actionTypes.GET_TRAER_SOLICITUDES_ADMINISTRADOR, fetchListaSolicitudesAdministrador)
}

function* fetchListaSolicitudesAdministrador(action){
    try{
        const data = yield call(listaSolicitudesAdministrador, action.data)
        if(data){
            yield put(setTraerEstadosSolicitudAdministrador(data))
        }
    }catch(err){
        console.log(err)
    }
}