import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call } from'redux-saga/effects';
import { enviarCita } from'../api/enviarCita';

export const watchEnviarCita = function * (){
    yield takeEvery(actionTypes.ENVIAR_NUEVA_CITA, fetchEnviarCita)
}

function* fetchEnviarCita(action){
    try{
        const data = yield call(enviarCita, action.data)
        if(data){
            //yield put(getRegistro(data))
            //MODAL USUARIO REGISTRADO EXITOSO
        }
    }catch(err){
        console.log(err)
    }
}