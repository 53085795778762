import { actionTypes } from "../Constantes/ActionTypes";
import { store } from "../store/index";

function reducerAllConversaciones (state = store.allConversaciones, action){
    switch(action.type){
        case actionTypes.SET_TODOS_LAS_CONVERSACIONES:
            let todasConversaciones = action.data
            return Object.assign({}, state, {
                data: todasConversaciones,
            })

        case actionTypes.ALL_CANTIDAD_MENSAJES:
            let contadorMensaje = state.cantMensaje
            return Object.assign({}, state, {
                cantMensaje: contadorMensaje++,
            })

            default:
                return state
    }
}

export default reducerAllConversaciones;