import { actionTypes } from "../Constantes/ActionTypes";
import { store } from "../store/index";

function reducerDolar (state = store.dolar, action){
    switch(action.type){
        case actionTypes.SET_DOLAR:
            let dolarActual = action.data
            return Object.assign({}, state, {
                data: dolarActual,
            })
            default:
                return state
    }
}

export default reducerDolar;