import { actionTypes } from "../Constantes/ActionTypes"; 

export const getEstadisticasUsuario = (data) => {
    return {
        type: actionTypes.GET_ESTADISTICAS_USUARIO,
        data
    }
}

export const setEstadisticasUsuario = (data) => {
    return {
        type: actionTypes.SET_ESTADISTICAS_USUARIO,
        data
    }
}

