import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';
import { eliminarDocumentacionUsuario } from'../api/eliminarDocumentacionUsuario';
import { eliminarDocumentacion } from "../Actions/eliminarDocumentacion";


export const watchEliminarDocumentacionUsuario = function * (){
    yield takeEvery(actionTypes.ELIMINAR_DOCUMENTACION_USUARIO, fetchEliminarDocumentacionUsuario)
}

function* fetchEliminarDocumentacionUsuario(action){
    try{
        const data = yield call(eliminarDocumentacionUsuario, action.data)
        if(data){
            yield put(eliminarDocumentacion())
        }
    }catch(err){
        console.log(err)
    }
}

