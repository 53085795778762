import { actionTypes } from "../Constantes/ActionTypes"; 

export const enviarContrato = (data) => {
    return {
        type: actionTypes.ENVIAR_CONTRATO,
        data
    }
}

export const setIdContrato = (data) => {
    return {
        type: actionTypes.SET_ID_CONTRATO,
        data
    }
}

export const getIdContrato = (data) => {
    return {
        type: actionTypes.GET_ID_CONTRATO,
        data
    }
}



