import React from 'react'
import Footer from '../../Components/Footer/Footer'
import './nosotros.css'

export default function Nosotros() {
  return (
    <>
    <div className='position' >
    <div className='nosotros-header'>
      <div className='nosotros-header-text-container' data-aos="zoom-in" data-aos-duration="1000">
        {/* <span className='nosotros-barra'></span> */}
        <h5 className='nosotros-titulo'> SOMOS LA PLATAFORMA DE INVERSIONES EN LA QUE <span style={{fontWeight:'700'}}> TODOS PUEDEN PARTICIPAR </span> </h5>
      </div>        
    </div>    
    {/* <img className='elipse-roja' src='https://res.cloudinary.com/blackhound/image/upload/v1657897316/BLACKHOUND/elipse_roja_jlhzma_bsmklv.svg'/> */}
    <div  data-aos="zoom-in" data-aos-duration="1400" data-aos-delay="500">
        <div className='containerSectionNosotros nosotros'>
            <div className='invertir-info-container caja-icono-medio'>
                <h4> MISIÓN  </h4>
                <p className='invertir-text'>               
                  <span> Somos una plataforma de inversión </span> que llegó para revolucionar el mundo de las finanzas. 
                  Queremos que cualquier persona pueda poner a trabajar su dinero.
                  <br></br>
                  <br></br>
                  El monto depositado irá a proyectos productivos reales, 
                  en <span> sectores como desarrollo urbanístico, construcción, agro y finanzas. </span>
                  Todos son verificables, y ofrecen altas tasas de rentabilidad en pesos y en dólares.
                </p>
                <br></br>
                <h4> VISIÓN </h4>
                <p className='invertir-text'> 
                  <span> Black Hound </span> nace con la intención de permitir que distintos inversores 
                  puedan gestionar sus participaciones en los proyectos de forma muy simple, 
                  con la posibilidad de ser parte de más de uno a la vez, a la medida de su capacidad 
                  financiera y necesidad en cuanto a plazos de retorno.
                </p>
            </div>
          </div>
    </div>  
    <div className='cajas-container'>
      <div className='nosotros-cajas' >

          <div className='caja'  data-aos="zoom-in" data-aos-duration="1400" data-aos-delay="300">
            <div className='caja-perro'>
              <img className='caja-icono' src="https://res.cloudinary.com/blackhound/image/upload/v1660241835/BLACKHOUND/Vector_iudxj5.webp" alt="Blackhound" />
              <h2 className='caja-titulo'> INSTINTO </h2>
            </div>
            <div className='container-text-desc'>
              <div className='subcontainer-caja-desc'>
                <p className='caja-desc'>
                  La <span>seguridad </span>de un olfato que no falla. 
                  Sabemos cuál es el mejor negocio para tu inversión según tus posibilidades.
                </p>
              </div>
            </div>
          </div>

          <div className='caja'  data-aos="zoom-in" data-aos-duration="1400" data-aos-delay="300">
            <div className='caja-perro'>
              <img className='caja-icono caja-icono-medio' src="https://res.cloudinary.com/blackhound/image/upload/v1660241835/BLACKHOUND/Vector_1_bu7c21.webp" alt="Blackhound" />
              <h2 className='caja-titulo'> LEALTAD </h2>
            </div>
            <div className='container-text-desc'>
              <div className='subcontainer-caja-desc'>
                <p className='caja-desc'>
                Un <span>acompañamiento incondicional</span> basado en la confianza. 
                Te acompañamos a lo largo de todo el proceso, con toda 
                la información siempre disponible.
                </p>
              </div>
            </div>
          </div>

          <div className='caja2'  data-aos="zoom-in" data-aos-duration="1400" data-aos-delay="300" >
            <div className='caja-perro'>
              <img className='caja-icono' src="https://res.cloudinary.com/blackhound/image/upload/v1660241835/BLACKHOUND/Vector_2_rvkdxw.webp" alt="Blackhound" />
              <h2 className='caja-titulo'> AGILIDAD </h2>
            </div>
            <div className='container-text-desc'>
              <div className='subcontainer-caja-desc'>
                <p className='caja-desc'>
                  Una operatoria sencilla y dinámica, 
                  basada en procesos eficientes que garanticen 
                  <span> rentabilidad</span> desde el primer minuto.
                </p>
              </div>
            </div>
          </div>
{/* 
          <div className='caja'  data-aos="zoom-in" data-aos-duration="1400" data-aos-delay="300">
              <img className='caja-icono' src='https://res.cloudinary.com/grupo-delsud/image/upload/v1653074089/Blackhound/Frame_1_hnzxbv.svg'/>
              <h5 className='caja-titulo'>SENCILLEZ</h5>
              <p className='caja-desc'>Interfaz simple, con pocos pasos desde el registro hasta la primera inversión. Además, te acompañamos durante todo el proceso.</p>
          </div>

          <div className='caja'  data-aos="zoom-in" data-aos-duration="1400" data-aos-delay="300">
              <img className='caja-icono' src='https://res.cloudinary.com/grupo-delsud/image/upload/v1653074089/Blackhound/Frame_2_vfp985.svg'/>
              <h5 className='caja-titulo'>TRANSPARENCIA</h5>
              <p className='caja-desc'>Toda la información a tu alcance desde el primer momento, con posibilidad de fiscalizar personalmente el avance de los proyectos invertidos.</p>
          </div> */}

      </div>
    </div>
    </div>
    </>
  )
}
