import React, { useEffect, useState } from 'react'
import {Loader} from "rsuite"
import { useSpring, animated } from 'react-spring';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboard } from "../../../Actions/dashboard";
import { Link } from 'react-router-dom';
import './dashboard.css'

export default function Dashboard() {
    
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDashboard());
        
    }, []);
    const dashboardAdmin = useSelector(state => state.reducerDashboard.data);


    const [loading, setLoading] = useState(true); {
        setTimeout(() => {
          setLoading(false);
        },500);
      }
    
      const fadeOut = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        delay: 500,
    })   

    return (
      <>
        {loading ?
        <div className='loading'>
            <Loader className='loadingColor' size="lg" />
        </div>
        :
        <animated.div style={fadeOut}>
            <div className='box-separador'>
                <div>
                    <h5 className='admin-welcome'>BIENVENIDO <span style={{textTransform:'uppercase'}}>ADMIN</span></h5>
                </div>
                <div className="categoria-titulo2">
                    <div className="circle-categoria"> </div> 
                    <div className="proyecto-titulo-dash">INFORMACIÓN GENERAL</div>
                </div>
            </div>
            <div className='dasboard-container'>
                <div className='dasboard-col-right'>
                    <div className='usuarios-familia'>
                        <div className='dashboard-box-header'>
                            <div className='container-icon-dash'>
                                <img className='icon-dash' src='https://res.cloudinary.com/blackhound/image/upload/v1658520797/BLACKHOUND/user-pendiente_yzf3da.svg'/>
                            </div>
                            <div className='text-container-dash'>
                                <h5 className='dashboard-box-tittle'> USUARIOS PENDIENTES</h5>
                                <p> Hay { dashboardAdmin != null ? dashboardAdmin.cantUserPendientes : "----" } usuarios pendientes de aprobación. </p>
                                <div className='container-button'>
                                    <Link to="/HomeAdmin/usuarios" className='button-dash'>Ver solicitudes</Link>
                                </div>
                            </div>
                        </div>
                        <div className='buttonMobile'>
                            <Link to="/HomeAdmin/clientes" className='button-dash'>Ver solicitudes</Link>
                        </div>                    
                    </div> 

                    <div className='usuarios-familia'>
                        <div className='dashboard-box-header'>
                            <div className='container-icon-dash'>
                                <img className='icon-dash' src='https://res.cloudinary.com/blackhound/image/upload/v1658520797/BLACKHOUND/inversion-activa_vvgvhh.svg'/>
                            </div>
                            <div className='text-container-dash'>
                                <h5 className='dashboard-box-tittle'> INVERSIONES ACTIVAS</h5>
                                <p> Hay { dashboardAdmin != null ? dashboardAdmin.cantSolicitudEstadoAprobado : "----" } inversiones activas. </p>
                                <div className='container-button'>
                                    <Link to="/HomeAdmin/inversiones" className='button-dash'>Ver inversiones</Link>
                                </div>
                            </div>
                        </div>
                        <div className='buttonMobile'>
                            <Link to="/HomeAdmin/inversiones" className='button-dash'>Ver inversiones</Link>
                        </div>                       
                    </div>    
                </div>

                <div className='dasboard-col-right'>
                    <div className='usuarios-familia'>
                        <div className='dashboard-box-header'>
                            <div className='container-icon-dash'>
                                <img className='icon-dash' src='https://res.cloudinary.com/blackhound/image/upload/v1661179475/BLACKHOUND/Administrador/SolicitudDeInversion_sojv3w.svg'/>
                            </div>
                            <div className='text-container-dash'>
                                <h5 className='dashboard-box-tittle'> SOLICITUDES DE INVERSIÓN </h5>
                                <p> Hay { dashboardAdmin != null ? dashboardAdmin.cantSolicitudEstadoRevision : "----" } solicitudes pendientes de aprobación. </p>
                                <div className='container-button'>
                                    <Link to="/HomeAdmin/solicitudes-inversion" className='button-dash'>Ver solicitudes</Link>
                                </div>
                            </div>
                        </div>
                        <div className='buttonMobile'>
                            <Link to="/HomeAdmin/clientes" className='button-dash'>Ver solicitudes</Link>
                        </div>                     
                    </div> 

                    <div className='usuarios-familia'>
                        <div className='dashboard-box-header'>
                            <div className='container-icon-dash'>
                                <img className='icon-dash' src='https://res.cloudinary.com/blackhound/image/upload/v1658520797/BLACKHOUND/turnos-asignados_hrsgg9.svg'/>
                            </div>
                            <div className='text-container-dash'>
                                <h5 className='dashboard-box-tittle'> TURNOS ASIGNADOS </h5>
                                <p> Hay { dashboardAdmin != null ? dashboardAdmin.turnosCantidad : "----" } turnos asignados </p>
                                <div className='container-button'>
                                    <Link to="/HomeAdmin/turnos" className='button-dash'>Ver turnos</Link>
                                </div>
                            </div>
                        </div>
                        <div className='buttonMobile'>
                            <Link to="/HomeAdmin/clientes" className='button-dash'>Ver turnos</Link>
                        </div>                     
                    </div>                 
                </div>
            </div>
        </animated.div>    
    }
      </>

  )
}
