import { actionTypes } from "../Constantes/ActionTypes"; 

export const getTurno = (data) => {
    return {
        type: actionTypes.GET_TURNO_USER,
        data
    }
}

export const setTurno = (data) => {
    return {
        type: actionTypes.SET_TURNO_USER,
        data
    }
}

export const guardarTurno = (data) => {
    return {
        type: actionTypes.GUARDAR_TURNO_USER,
        data
    }
}
