import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call,put } from'redux-saga/effects';
import { notificacionesUsuario } from "../api/notificacionesUser";
import { setNotificacionUsuario } from "../Actions/notificacionUsuario"
import { contadorNotificaciones } from "../Actions/notificacionUsuario";

export const watchNotificacionesUsuario = function * (){
    yield takeEvery(actionTypes.GET_NOTIFICACIONES_USUARIO, fetchNotificacionesUsuario)
}

function* fetchNotificacionesUsuario(action){
    try{
        const data = yield call(notificacionesUsuario, action.data)
        if(data){
            yield put(setNotificacionUsuario(data))
            yield put(contadorNotificaciones(data))
        }
    }catch(err){
        console.log(err)
    }
}