import React, { useEffect, useState, useRef } from "react";
import { getChat, postEnviarMensaje } from '../../Actions/obtenerChat';
import { useSelector, useDispatch } from "react-redux";
import Accordion from 'react-bootstrap/Accordion';
import { changeEstadoMensajes } from "../../Actions/obtenerChat";
import './chat.css'

export default function ChatClient(props) {
  const mensajeEmisor = useRef(null);
  const dataCliente = props.dataUserChat;
  const dispatch = useDispatch();
  const today = new Date();
  var listaConversacion = [];
  var idConversacionUser = 0;
  const scrollRef = useRef()
  const datosUsuarioLogeado = useSelector(
    (state) => state.reducerUsuarioDatos.data
  );//OBTENER DATOS DEL USUARIO

  const conversacion = useSelector(
    (state) => state.reducerObtenerChat.data
  );

  //OBTENGO MENSAJES
  const [enviarMensaje, sendEnviarMensaje] = useState({ mensaje: "" });

  function handleCargarMensaje(e) {
    sendEnviarMensaje({
      ...enviarMensaje,
      [e.target.name]: e.target.value,
    });
  };

  const pressEnter = (e) => {
    if (e.key === 'Enter') {
      handleEnviarMensaje();
    }
  }

  function handleEnviarMensaje(e) {
    if (enviarMensaje.mensaje != "") {
      let data = new FormData();
      data.append('contenido', enviarMensaje.mensaje);
      if (datosUsuarioLogeado.rol[0] === 'ROLE_ASESOR') {
        data.append('userDestinatario', dataCliente.idUser);//35 DEBE SER UN ID DEL CLIENTE
      } else {
        data.append('userDestinatario', datosUsuarioLogeado.idasesor);
      }
      setTimeout(() => {
        dispatch(postEnviarMensaje(data));
      }, 500);
    }
    sendEnviarMensaje({
      mensaje: ""
    })
  };

  // const el = document.getElementById('scroll-mensaje');
  // if (el) {
  //   el.scrollTop = el.scrollHeight;
  // }

  useEffect(() => {
    const el = document.getElementById('scroll-mensaje');
    if (el) {
      el.scrollTop = el.scrollHeight;
    }
  }, [conversacion, enviarMensaje])

  // useEffect(()=> {
  //   scrollRef?.current.scrollIntoView({behavior: "smooth"})
  // }, [conversacion, enviarMensaje])

  let mensajesUser = 0;
  let mensajesAsesor = 0;
  if (conversacion !== null && conversacion.status !== "Conversacion sin mensajes") {
    for (var i = 0; i < conversacion.mensaje.length; i++) {
      if (conversacion.mensaje[i].user == datosUsuarioLogeado.id) {
        if (conversacion.mensaje[i].estado == 1) {
          mensajesAsesor++;
        }
      } else {
        if (conversacion.mensaje[i].estado == 1) {
          idConversacionUser = conversacion.mensaje[i].idConversacion
          mensajesUser++;
        }
      }
      listaConversacion.push(<>
        {conversacion.mensaje[i].user == datosUsuarioLogeado.id ?
          //MESSAGGE ASESOR
          <>
            <div className="container-message">
              <div className="child-container-message">
                {/* <p className="text-message"> {conversacion.mensaje[i].user} </p> */}
                <p className="text-message"> {conversacion.mensaje[i].contenido} </p>
                <p className="text-message-time"> {conversacion.mensaje[i].fechaCreacion.date.substring(10, 16)} </p>
              </div>
            </div>
          </>
          :
          //MESSAGE USER
          <>
            <div className="container-message-asesor">
              {/* <p className="text-message"> {conversacion.mensaje[i].user} </p> */}
              <p className="text-message"> {conversacion.mensaje[i].contenido} </p>
              <p className="text-message-time"> {conversacion.mensaje[i].fechaCreacion.date.substring(10, 16)} </p>
            </div>
          </>
        }
      </>)

    }
  }
  else {
    console.log("Estoy sin mensajes todavia")
  }

  function handleActualizarUser(){
    const cambiarEstadoMensaje = {
      idconver: idConversacionUser,
      iduser:  datosUsuarioLogeado.idasesor,
    }
    dispatch(changeEstadoMensajes(cambiarEstadoMensaje));
    setTimeout(() => {
      dispatch(getChat({ userReceptor: datosUsuarioLogeado.idasesor }));
    }, 1000); 
 }


  return (
    <>
      {
        datosUsuarioLogeado != null && datosUsuarioLogeado.rol[0] == 'ROLE_ASESOR' ?
          <Accordion className="container-layout-chat" defaultActiveKey="0">
            <Accordion.Item className="chatItem">
              <Accordion.Header className="layout-header">
                <p className="tittle-chat"> {dataCliente != null ? dataCliente.nombre : 'Seleccione un chat'} </p>
                {/* {mensajesUser > 0 ?
                  <div className="notificacion-number-chat">{mensajesUser}</div>
                  : ''
                } */}
              </Accordion.Header>
              <Accordion.Body className="container-child-layout">
                <div >
                  <div>
                    <div className="container-all-message">
                      {listaConversacion}
                    </div>
                    <div className="containerChatWithButton">
                      <textarea
                        value={enviarMensaje.mensaje}
                        autoComplete="off"
                        className="input-chat"
                        name="mensaje"
                        type="text"
                        onChange={handleCargarMensaje}
                        onKeyPress={(e) => pressEnter(e)}
                      />
                      <button className="boton-enviar" onClick={()=>{handleEnviarMensaje()}}>Enviar</button>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          :

          datosUsuarioLogeado != null && datosUsuarioLogeado.idasesor != 0 ?
            <Accordion className="container-layout-chat" defaultActiveKey="0">
              <Accordion.Item>
                <Accordion.Header className="layout-header" onClick={handleActualizarUser}>
                    <p className="tittle-chat">
                      Asesor Comercial
                    </p>
                    {mensajesUser > 0 ?
                      <div className="notificacion-number-chat">{mensajesUser}</div>
                      : ''
                    }
                </Accordion.Header>
                <Accordion.Body className="container-child-layout">
                  <div>
                    <div>
                      <div className="container-all-message" id="scroll-mensaje">
                        {listaConversacion}
                      </div>
                      <div className="containerChatWithButton">
                        <textarea
                          value={enviarMensaje.mensaje}
                          className="input-chat"
                          autoComplete="off"
                          name="mensaje"
                          type="text"
                          onChange={handleCargarMensaje}
                          onKeyPress={(e) => pressEnter(e)}
                        />
                        <button className="boton-enviar" onClick={handleEnviarMensaje}>Enviar</button>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            : ''

      }

    </>
  )
}
