import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call,put } from'redux-saga/effects';

import { apiEstadoNotificaciones } from "../api/estadoNotificacion";

export const watchEstadoNotificaciones = function * (){
    yield takeEvery(actionTypes.CAMBIAR_ESTADO_NOTIFICACIONES, fetchEstadoNotificaciones)
}

function* fetchEstadoNotificaciones(action){
    try{
        const data = yield call(apiEstadoNotificaciones, action.data)
        if(data){
            //yield put(cambiarEstadoNotificaciones(data))
        }
    }catch(err){
        console.log(err)
    }
}