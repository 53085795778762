import React from 'react'
import { Link } from 'react-router-dom'
import './proyectoinversor.css'
import { useMediaQuery } from "react-responsive"

export default function ProyectoInversor() {
  
  const isMobile = useMediaQuery({ maxDeviceWidth: 1023 })
  return (
    <>
    <div className='nosotros-header'>
      <div className='nosotros-header-text-container' data-aos="zoom-in" data-aos-duration="1000">
        {/* <span className='nosotros-barra'> </span> */}
        <h5 className='nosotros-titulo'>Llegamos para que invertir sea <span>sencillo</span>, <span>accesible</span> y <span>seguro</span>. </h5>
      </div>        
    </div> 
    <div className='invertir-container' data-aos="zoom-in" data-aos-duration="1000">
      {!isMobile ?
        <div className='gifDog'>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1664905077/BLACKHOUND/ComoInvertir/perritoQueCorreHDPeroCorreUnToqueMasLento_jwplyd.gif" alt='' />
        </div>      
              :
              <></>
            }
                {/* <p className='invertir-text'>
                  Llegamos para que invertir sea sencillo, accesible y seguro. 
                  Nuestros proyectos de inversión contemplan diferentes montos y tiempos, 
                  ofreciendo un amplio abanico de posibilidades que se ajuste a la capacidad 
                  financiera de nuestros clientes.
                </p>
                <br></br>
                <Link to="/Nosotros">VER MÁS</Link> */}
    </div>  

    </>
  )
}

