import { actionTypes } from "../Constantes/ActionTypes"; 

export const getUpdateAsesor = (data) => {
    return {
        type: actionTypes.GET_UPDATE_ASESOR,
        data
    }
}


