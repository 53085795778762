import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';
import { agregarAsesorSolicitud } from "../api/agregarAsesorSolicitud";
import { setAgregarAsesorSolicitud } from "../Actions/agregarAsesorSolicitud";

export const watchAgregarAsesorSolicitud = function * (){
    yield takeEvery(actionTypes.GET_ASESOR_A_SOLICITUD, fetchAgregarAsesorSolicitud)
}

function* fetchAgregarAsesorSolicitud(action){
    try{
        const data = yield call(agregarAsesorSolicitud, action.data)
        if(data){
            yield put(setAgregarAsesorSolicitud(data))
        }
    }catch(err){
        console.log(err)
    }
}