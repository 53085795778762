import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive"
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "../../FichasTecnicas/fichasTecnicas.css";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";


export default function FichaDesarrollos() {
  
  const isMobile = useMediaQuery({ maxDeviceWidth: 1024 })
  const data = JSON.parse(localStorage.getItem("datosFichaDesarrollosDelSud"))
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  
  const ScrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'

    })
}

const datosUsuarioLogeado = useSelector(
  (state) => state.reducerUsuarioDatos.data
);


  return (
    <div className="ficha-tecnica-container">
      <div className="ficha-tecnica-inner-container">
        <div className={`header-img-ficha-tecnica ${ "background-color-" + data.categoria }`}>
          <img
            src={
              "https://www.blackhound.com.ar/server/public/getImgId/" +
              data.idImagenUno
            }
          />
        </div>
        <div className="ficha-tecnica-proyect-description">
          <p>
            Desarollos Delsud es una de las principales desarrolladoras
            urbanísticas de la ciudad de La Plata. Con más de 12 barrios
            realizados, apunta a un público amplio que accede por primera vez al
            terreno propio.
            <br />
            <br />
            Con financiación propia y a medida de la necesidad del cliente,
            Desarrollos Delsud marca la diferencia sobre sus competidores,
            teniendo todas las variables de la operación bajo control. Equipos
            legal, financiero, de ventas, de diseño, marketing y comunicación
            componen su plantilla.
          </p>
          <a className="red-text" target="_blank" href="https://desarrollosdelsud.com.ar/">www.desarrollosdelsud.com.ar</a>
        </div>
        <div className="carrousel-ficha-tecnica">
          <Swiper
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            loop={true}
            spaceBetween={10}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Thumbs]}
            className="slider-superior-grande"
          >
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663937660/BLACKHOUND/Ficha%20Tecnica/Desarollos%20Delsud/image_21_daqlbv.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663937653/BLACKHOUND/Ficha%20Tecnica/Desarollos%20Delsud/image_21_mrryiv.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663937647/BLACKHOUND/Ficha%20Tecnica/Desarollos%20Delsud/image_21_hlngk1.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663937635/BLACKHOUND/Ficha%20Tecnica/Desarollos%20Delsud/image_21_xttjcw.jpg" />
            </SwiperSlide>
          </Swiper>
          <hr className="linea-divisora-ficha-tecnica" />
          <Swiper
            onSwiper={setThumbsSwiper}
            navigation={true}
            loop={true}
            spaceBetween={20}
            slidesPerView={3}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="slider-inferior-thumbail"
          >
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663937660/BLACKHOUND/Ficha%20Tecnica/Desarollos%20Delsud/image_21_daqlbv.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663937653/BLACKHOUND/Ficha%20Tecnica/Desarollos%20Delsud/image_21_mrryiv.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663937647/BLACKHOUND/Ficha%20Tecnica/Desarollos%20Delsud/image_21_hlngk1.jpg" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1663937635/BLACKHOUND/Ficha%20Tecnica/Desarollos%20Delsud/image_21_xttjcw.jpg" />
            </SwiperSlide>
          </Swiper>
        </div>
        <hr className="linea-divisora-ficha-tecnica" />
        <div className="ficha-tecnica-productos">
          <p className="ficha-tecnica-productos-titulo">PRODUCTOS</p>
          <div className="ficha-tecnica-productos-imagenes-contenedor">
            <div className="ficha-tecnica-productos-imagenes-fila-desarrollo">
              <img
                alt="Las Victorias"
                src="https://res.cloudinary.com/blackhound/image/upload/v1661438637/BLACKHOUND/Ficha%20Tecnica/Desarollos%20Delsud/Proyecto/Las-Victorias_1_s3njyu.svg"
              />
              <img
                alt="Las Orianas"
                src="https://res.cloudinary.com/blackhound/image/upload/v1661438637/BLACKHOUND/Ficha%20Tecnica/Desarollos%20Delsud/Proyecto/Las-Orianas_1_bpglcj.svg"
              />
              <img
                alt="Lomas de Arana"
                src="https://res.cloudinary.com/blackhound/image/upload/v1661438637/BLACKHOUND/Ficha%20Tecnica/Desarollos%20Delsud/Proyecto/Lomas-arana_1_evyaim.svg"
              />
            </div>
            <div className="ficha-tecnica-productos-imagenes-fila-desarrollo">
              <img
                alt="La Campiña"
                src="https://res.cloudinary.com/blackhound/image/upload/v1661438637/BLACKHOUND/Ficha%20Tecnica/Desarollos%20Delsud/Proyecto/La-campina_1_ysrh86.svg"
              />
              <img
                alt="El Juncal"
                src="https://res.cloudinary.com/blackhound/image/upload/v1661438637/BLACKHOUND/Ficha%20Tecnica/Desarollos%20Delsud/Proyecto/El-Juncal_1_lc4gec.svg"
              />
            </div>
          </div>
        </div>
        <hr className="linea-divisora-ficha-tecnica" />
          <div className="proyecto-data-en-ficha-tecnica">
            {!isMobile ?
            <>
            <div className="proyectos-descripcion-item">
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897298/BLACKHOUND/tna_nhms4e_iz5fy8.svg" />
              <p className="proyectos-iconos-titulo-desarrollo">TNA</p>
              <p className="proyectos-iconos-desc">{data.tna}%</p>
            </div>
            <hr className="linea-divisora-proyectos-descripcion-item" />
            <div className="proyectos-descripcion-item">
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1661865313/BLACKHOUND/Proyectos/montoMinimo_j0ym0l.svg" />
              <p className="proyectos-iconos-titulo-desarrollo">Monto mínimo</p>
              <p className="proyectos-iconos-desc">
                {data.moneda} {data.montoMinimo}
              </p>
            </div>
            <hr className="linea-divisora-proyectos-descripcion-item" />
            <div className="proyectos-descripcion-item">
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897305/BLACKHOUND/calendario_bulhhb_fwnlxz.svg" />
              <p className="proyectos-iconos-titulo-desarrollo">Plazo mínimo</p>
              <p className="proyectos-iconos-desc">{data.plazo} meses</p>
            </div>
            </>
            :
            <>
            <div className="proyectos-descripcion-item-fichaDesarrollos">
              <div className="containerResponsiveItemsFicha">
                <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897298/BLACKHOUND/tna_nhms4e_iz5fy8.svg" />
                <div className="containerResponsiveItemP">
                  <p className="proyectos-iconos-titulo">TNA</p>
                  <p className="proyectos-iconos-desc">{data.tna}%</p>
                </div>
              </div>
            </div>
            <div className="proyectos-descripcion-item-fichaDesarrollos">
            <div className="containerResponsiveItemsFicha">
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1661865313/BLACKHOUND/Proyectos/montoMinimo_j0ym0l.svg" />
              <div className="containerResponsiveItemP">
                <p className="proyectos-iconos-titulo">Monto mínimo</p>
                <p className="proyectos-iconos-desc">
                  {data.moneda} {data.montoMinimo}
                </p>
              </div>
              </div>
            </div>
            <div className="proyectos-descripcion-item-fichaDesarrollos">
            <div className="containerResponsiveItemsFicha">
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897305/BLACKHOUND/calendario_bulhhb_fwnlxz.svg" />
              <div className="containerResponsiveItemP">
                <p className="proyectos-iconos-titulo">Plazo mínimo</p>
                <p className="proyectos-iconos-desc">{data.plazo} meses</p>
              </div>
            </div>  
            </div>
            </>
            }
          </div>
        {datosUsuarioLogeado != null && datosUsuarioLogeado.estadoNombre === "Aprobado" ?
        <div className="buttonToCalculator">
          <Link 
          onClick={() => {ScrollToTop(); localStorage.setItem("datosFicha", JSON.stringify(data))}} 
          to={"/HomeUsuarios/Dashboard/solicitudInversion"}
          >
          Proyectar inversión
          </Link>
        </div>
        : ""
        }
      </div>
    </div>
  );
}
