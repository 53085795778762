import React, { useRef, useState, useEffect } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector, useDispatch } from 'react-redux';
import MisInversionesProyecto from "./MisInversionesProyecto";
import {Button} from 'rsuite';
import {getInversionesUsuario} from "../../../Actions/inversionesUsuario"

//React Icons
import { ImCross } from 'react-icons/im';
import { BsCheckLg } from 'react-icons/bs';
import { HiOutlineClock } from 'react-icons/hi';
import { MdCleaningServices } from 'react-icons/md';

// Import Swiper styles
import "swiper/css";
import "./misinversiones.css";


export default function MisInversiones() {
  var proyectosUsuario = [];
    const [estadoFiltro, setEstadoFiltro] = useState(null)
    const [filtroActivado,setfiltroActivado] = useState(false);
    const usuarioListaInversiones = useSelector(state => state.reducerInversionesUsuario.data);
    if (usuarioListaInversiones != null) {
        
        const pu = usuarioListaInversiones.data;
        for (var i = 0; i < pu.length; i++) {
            if(estadoFiltro==null){
                proyectosUsuario.push(<SwiperSlide><MisInversionesProyecto data={pu[i]}/></SwiperSlide>)
            }
            if (estadoFiltro === pu[i].estadoSolicitud){
                proyectosUsuario.push(<SwiperSlide><MisInversionesProyecto data={pu[i]}/></SwiperSlide>)
            }
        }
        }

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getInversionesUsuario({id:14}));
      },[])
      const  handleSetearBoton=(e)=> {
        if(e.target.value==1){
          setfiltroActivado("activado-limpiar");
          setEstadoFiltro(null);
        }
        if(e.target.value==2){
          setfiltroActivado("activado-pendiente");
          setEstadoFiltro("Revision")
        }
        if(e.target.value==3){
          setfiltroActivado("activado-aprobado");
          setEstadoFiltro("Aprobado")
        }
        if(e.target.value==4){
          setfiltroActivado("activado-rechazado");
          setEstadoFiltro("Rechazado")
        }
        }



  return (
    <>


        {proyectosUsuario.length > 0 ?     
        <>
        <div className='contenedor-filtros'>
          <div className='botones-filtros botones-filtros-left'>
            <Button value={1}  onClick={(e)=>handleSetearBoton(e)} className={`butFilter filtro-limpiar ${filtroActivado == "activado-limpiar" ? "activado-limpiar": ""}`}> <MdCleaningServices/>LIMPIAR </Button>
            <Button value={2}  onClick={(e)=>handleSetearBoton(e)}  className={`butFilter filtro-pendiente ${filtroActivado == "activado-pendiente" ? "activado-pendiente": ""}`}><HiOutlineClock/> PENDIENTE </Button>
            <Button value={3}  onClick={(e)=>handleSetearBoton(e)} className={`butFilter filtro-aprobado ${filtroActivado == "activado-aprobado" ? "activado-aprobado": ""}`}> <BsCheckLg/> APROBADO </Button>
            <Button value={4}  onClick={(e)=>handleSetearBoton(e)} className={`butFilter filtro-rechazado ${filtroActivado == "activado-rechazado" ? "activado-rechazado": ""}`}><ImCross/> RECHAZADO </Button>
          </div>
       </div> 
       <Swiper
        slidesPerView={1}
        spaceBetween={40}
        className="Proyectos"
        breakpoints={{     
          768: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
          1700: {
            slidesPerView: 4,
            spaceBetween: 50,
          }
        }}
      >
        {proyectosUsuario}   </Swiper>
        </>

       :
        <p className="sin-inversiones">Actualmente no cuentas con inversiones</p>}
        

       
 
      </>
  );
}