import { actionTypes } from "../Constantes/ActionTypes"; 

export const getImagenPerfil = (data) => {
    return {
        type: actionTypes.GET_IMAGEN_PERFIL,
        data
    }
}

export const setImagenPerfil = (data) => {
    return {
        type: actionTypes.SET_IMAGEN_PERFIL,
        data
    }
}
