import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call,put } from'redux-saga/effects';
import { imagenPerfil } from "../api/imagenPerfil";
import { setImagenPerfil } from "../Actions/imagenPerfil";

export const watchImagenPerfil = function * (){
    yield takeEvery(actionTypes.GET_IMAGEN_PERFIL, fetchImagenPerfil)
}

function* fetchImagenPerfil(action){
    try{
        const data = yield call(imagenPerfil, action.data)
        if(data){
            yield put(setImagenPerfil(data))
            //MODAL USUARIO REGISTRADO EXITOSO
        }
    }catch(err){
        console.log(err)
    }
}