import React from 'react'
import './caracteristicas.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useMediaQuery } from "react-responsive"
AOS.init();



export default function Caracteristicas() {
    const isMobile = useMediaQuery({ maxDeviceWidth: 1024 })
  return (
      <>
      {!isMobile ?
        <div className='caracteristicas-container' >
            <div className='caja-caracteristicas-iconos iconos-home' data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="500">
                <div className='caracteristicas-icono-item'>
                    <img src='https://res.cloudinary.com/blackhound/image/upload/v1660238574/BLACKHOUND/Miembros_kvmeo2.webp' alt=''/>
                    <p className='text-icon'>+1000<br/>miembros<br/>Black Hound</p>    
                </div>   
                <div className='caracteristicas-icono-item'>
                    <img className='asterisco' src='https://res.cloudinary.com/blackhound/image/upload/v1660238742/BLACKHOUND/__m2skls.webp' alt=''/>
                </div>
                <div className='caracteristicas-icono-item' >
                    <img className='billetin' src='https://res.cloudinary.com/blackhound/image/upload/v1660238672/BLACKHOUND/Operacion_cwtppa.webp' alt=''/>
                    <p className='text-icon'>2M U$S en<br/>operaciones</p>    
                </div>    
                <div className='caracteristicas-icono-item'>
                    <img className='asterisco' src='https://res.cloudinary.com/blackhound/image/upload/v1660238742/BLACKHOUND/__m2skls.webp' alt=''/>
                </div>
                <div className='caracteristicas-icono-item'>
                    <img src='https://res.cloudinary.com/blackhound/image/upload/v1660238684/BLACKHOUND/Alternativas_giepin.webp' alt=''/>
                    <p className='text-icon'>5<br/>alternativas<br/>de inversión</p>    
                </div>  
            </div>
        </div>
        :
        <div className='caracteristicas-container' >
        <div className='caja-caracteristicas-iconos responsiveLaptop iconos-home' data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="500">
            <div className='caracteristicas-icono-item'>
                <img src='https://res.cloudinary.com/blackhound/image/upload/v1660238574/BLACKHOUND/Miembros_kvmeo2.webp' alt=''/>
                <p className='text-icon'>+1000<br/>miembros<br/>Black Hound</p>    
            </div>   
            <div className='caracteristicas-icono-item' >
                <img className='billetin' src='https://res.cloudinary.com/blackhound/image/upload/v1660238672/BLACKHOUND/Operacion_cwtppa.webp' alt=''/>
                <p className='text-icon'>2M US$ en<br/>operaciones</p>    
            </div>    
            <div className='caracteristicas-icono-item'>
                <img src='https://res.cloudinary.com/blackhound/image/upload/v1660238684/BLACKHOUND/Alternativas_giepin.webp' alt=''/>
                <p className='text-icon'>4<br/>alternativas<br/>de inversión</p>    
            </div>  
        </div>
    </div>
    }
    </>
  )
}
