import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
// import "./listaSolicitudesAprobadas.css";

export default function ListaSolicitudesPendientes(props) {
  const data = props.data;
  const estadisticas = props.estadisticas;

//   if(estadisticas != null){
//   var fechaAprobacion = data.fecha.date;
//   //var fechaAprobacionRecortada =  estadisticas.fechaInicioInversion.date.substring(0, 10);
//   var fechaAprobacionFormateada = moment(fechaAprobacionRecortada).format("YYYY-MM-DD");
//   var fechaVencimiento = moment(fechaAprobacionFormateada).add(estadisticas.plazoInvertido, "month").calendar();
// }
  const [chatUser, setChatUser] = useState(null);

  const ScrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'

    })
  }


  return (
    <div className="proyecto-acordion-container-solicitudes-aprobadas">
      <div className="proyecto-data-en-acordion-solicitudes-aprobadas-imagenes">
        <img
          src={
            "https://www.blackhound.com.ar/server/public/getImgId/" +
            data.idImagenProyecto
          }
        />
      </div>
      <div className="proyecto-accordion-datos-solicitudes-aprobadas">
        <div className="proyecto-data-en-acordion-solicitudes-aprobadas">
          <div className="proyecto-data-en-acordion-solicitudes-aprobadas-item">
            <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897305/BLACKHOUND/calendario_bulhhb_fwnlxz.svg" />
            <div className="proyecto-data-en-acordion-solicitudes-aprobadas-item-text">
              <p className="proyecto-data-en-acordion-solicitudes-aprobadas-text-sub">
                Fecha
              </p>
              <p className="proyecto-data-en-acordion-solicitudes-aprobadas-text-sub-bold">
                {data.fecha.date.substring(10, 0)}
              </p>
            </div>
          </div>
          <div className="proyecto-data-en-acordion-solicitudes-aprobadas-item">
            <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897291/BLACKHOUND/monto_cxwn8l_w1fwel.svg" />
            <div className="proyecto-data-en-acordion-solicitudes-aprobadas-item-text">
              <p className="proyecto-data-en-acordion-solicitudes-aprobadas-text-sub">
                Monto
              </p>
              <p className="proyecto-data-en-acordion-solicitudes-aprobadas-text-sub-bold">
                {data.moneda}
                {data.montoInvertido}
              </p>
            </div>
          </div>
        </div>

        {/* <div className="proyecto-data-rendimiento">
          <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897290/BLACKHOUND/moneda_uxljoi_uqwcrb.svg" />
          <div>
            <p className="proyectos-data-rendimiento-titulo">Rendimientos</p>
            <p className="proyectos-data-rendimiento-valor">
              {data.moneda}
              {estadisticas != null ? estadisticas.retornoInvertidoALaFecha : ''}
            </p>
          </div>
          <div>
          </div>
        </div> */}
        
        <div className="container-tittle-revision">
            <p className="proyectos-data-rendimiento-titulo">Tu solicitud está siendo revisada</p>
        </div>
        

        <div className="proyecto-data-seccion-botones">
          {data.idProyecto == "25" && (
            <Link
              target="_blank" 
              rel="noopener noreferrer"
              className="proyecto-data-en-acordion-boton"
              to={"/FichaTecnicaDesarrollosDelsud"}
              onClick={() => {ScrollToTop(); localStorage.setItem("datosFichaDesarrollosDelSud", JSON.stringify(data))}} 
            >
              Ficha técnica
            </Link>
          )}
          {data.idProyecto == "26" && (
            <Link
              target="_blank" 
              rel="noopener noreferrer"
              className="proyecto-data-en-acordion-boton"
              to={"/FichaTecnicaElemental"}
              onClick={() => {ScrollToTop(); localStorage.setItem("datosFichaElemental", JSON.stringify(data))}}
            >
              Ficha técnica
            </Link>
          )}
          {data.idProyecto == "27" && (
            <Link
              target="_blank" 
              rel="noopener noreferrer"
              className="proyecto-data-en-acordion-boton"
              to={"/FichaTecnicaEugenie"}
              onClick={() => {ScrollToTop(); localStorage.setItem("datosFichaEugenie", JSON.stringify(data))}}
            >
              Ficha técnica
            </Link>
          )}
          {data.idProyecto == "28" && (
            <Link
              target="_blank" 
              rel="noopener noreferrer"
              className="proyecto-data-en-acordion-boton"
              to={"/FichaTecnicaElal"}
              onClick={() => {ScrollToTop(); localStorage.setItem("datosFichaElal", JSON.stringify(data))}}
            >
              Ficha técnica
            </Link>
          )}
        </div>
      </div>

    </div>
  );
}
