import React, { useState, useEffect } from "react";
import { IoMdDownload } from "react-icons/io";
import { getRecibirDoc } from "../../../../Actions/enviarDocumentos";
import { useSelector, useDispatch } from "react-redux";

import { Button, Modal } from "rsuite";

import "../usuarios.css";
import "./infocliente.css";

//Import Icons
import { FiDownload } from "react-icons/fi";

export default function InfoCliente(props) {
  const data = props.dataInfoCliente;

  const dispatch = useDispatch();
  useEffect(() => {
    if (data != null && data.isDocumentacion == true) {
      dispatch(getRecibirDoc(data.idUser));
    }
  }, [data]);

  const [open, setOpen] = useState(false);
  const [backdrop, setBackdrop] = useState("static");
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (data != null) {
      setOpen(data);
    }
  }, [data]);

  const infoDoc = useSelector((state) => state.reducerDocumentos.data);

  return (
    <>
      {open && (
        <div className="modal-container">
          <Modal
            backdrop={backdrop}
            open={open}
            className="modal-documentos"
            size={"lg"}
            onClose={handleClose}
          >
            {/* <Modal.Title className='solicitud-modal-header'>
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897288/BLACKHOUND/logo_rd8xt5_qvimbm.svg" />
              <p>{data.nombre + ' ' + data.apellido}</p>
              <p>#{data.idUser}</p>
          </Modal.Title> */}
            <Modal.Body>
              <div className="informacion-cliente-container">
                <p className="titulo-info-cliente">Documentación de usuario</p>
                <br></br>
                <div className="informacion-cliente">
                  {infoDoc != null && data.isDocumentacion == true ? (
                    <>
                      <div className="informacion-cliente-col">
                        <img
                          src={
                          
                            "https://www.blackhound.com.ar/server/public/getImgIdDni/" +
                            infoDoc.data[0].id
                          }
                        />
                        <a
                          href={
                            "https://www.blackhound.com.ar/server/public/getImgIdDni/" +
                            infoDoc.data[0].id
                          }
                        >
                          <div className="box-download-icon">
                            <FiDownload className="download-icon" />
                          </div>
                        </a>
                      </div>
                      <div className="informacion-cliente-col">
                        <img
                          src={
                            "https://www.blackhound.com.ar/server/public/getImgIdDni/" +
                            infoDoc.data[1].id
                          }
                        />
                        <a
                          href={
                            "https://www.blackhound.com.ar/server/public/getImgIdDni/" +
                            infoDoc.data[1].id
                          }
                        >
                          <div className="box-download-icon">
                            <FiDownload className="download-icon" />
                          </div>
                        </a>
                      </div>
                    </>
                  ) : 
                    ""
                  }
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={handleClose}
                className="modal-clientes-boton-salir"
              >
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
}
