import React from 'react';
import { useRef, useEffect } from 'react'

import { Link } from "react-router-dom";
import './tufuturo.css'

const ScrollToTop = () => {
  window.scrollTo({
      top: 0,
      behavior: 'smooth'
  })
}

export default function TuFuturo() {

  return (
      <>
        <div className='tu-futuro-container'  data-aos="zoom-in" data-aos-duration="1000">
            <div className='tu-futuro-titulo'>
                <h4 className='dejanos-consulta'> <span> Dejanos tu consulta. </span> <br></br> Nos pondremos en contacto a la brevedad. </h4>
            </div>
            <Link className="escribinos" to="/Contacto" onClick={ScrollToTop}> Escribinos </Link>            
        </div>
      </>
  )
}
