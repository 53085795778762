import React, { useState, useEffect } from "react";
import { Table, Pagination, Loader, Popover, Whisper } from "rsuite";
import { useSpring, animated } from 'react-spring';
import { useSelector, useDispatch } from "react-redux";
import { getlistaSolicitudesRegistradas } from "../../../../Actions/listaSolicitudesRegistradas";
import { setIdCliente } from "../../../../Actions/obtenerChat";
import ModalRechazarSolicitud from "./ModalRechazarSolicitud/ModalRechazarSolicitud";
import ModalEnviarMail from "./ModalEnviarMail/ModalEnviarMail";
import AgregarTurno from "./agregarTurno/AgregarTurno";
import ChatClient from "../../../../Components/Chat/ChatClient";
import { changeEstadoMensajes } from "../../../../Actions/obtenerChat";
import { getChat } from "../../../../Actions/obtenerChat";
import { allConversacionesAsesor } from "../../../../Actions/obtenerChat";
import { setAllConversacionesAsesor } from "../../../../Actions/obtenerChat";
import CanalesChat from "../../canalesChat/canalesChat";

import "./pendientes.css";
import ModalGeneric from "../../../../Components/ModalGeneric/ModalGeneric";

const { Column, HeaderCell, Cell } = Table;

export default function Pendientes() {
  const [agregarTurno, setAgregarTurno] = useState(null);
  const [chatUser, setChatUser] = useState(null);
  const [notificacion, setNotificacion] = useState(null);
  const [modalRechazar, setModalRechazar] = useState(null);
  const [modalEnviarMail, setModalEnviarMail] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [email, setEmail] = useState()
  const [open, setOpen] = useState();
  const [estadoCategoria, setEstadoCategoria] = useState(1);
  const [busqueda, setBusqueda] = useState({
    dataBase: "",
  });

  const [chat, setChat] = useState("Sin Mensajes");
  const [loading, setLoading] = useState(true);
  {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }
  
  const fadeOut = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 500,
  })

  const modalConfirmacion = {
    content: "Usuario notificado correctamente.",
    button: "Cerrar"
}

  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(allConversacionesAsesor())
  // }, [])

  useEffect(() => {
    const data = {
      busqueda: busqueda.dataBase,
      categoria: estadoCategoria,
      page: activePage,
    };
    dispatch(getlistaSolicitudesRegistradas(data));
  }, [activePage, busqueda, estadoCategoria,notificacion]);

  const tablaListaSolicitudes = useSelector(
    (state) => state.reducerlistaSolicitudesRegistradas.data
  );
  const datosUsuarioLogeado = useSelector((state) => state.reducerUsuarioDatos.data);//OBTENER DATOS DEL USUARIO
  const tablaListaClientes = useSelector(state => state.reducerlistaClientesRegistrados.data);
  const guardarIdChat = useSelector(
    (state) => state.reducerGuardarIdChat.data
  );
  const allConversaciones = useSelector(
    (state) => state.reducerAllConversaciones.data
  );//OBTENER DATOS DEL USUARIO


  useEffect(() => {
    if (tablaListaClientes != null) {
      dispatch(getChat({ userReceptor: guardarIdChat }));//35 DEBE SER UN ID DEL CLIENTE
    }
  }, [chat, guardarIdChat]);


  const canalChat = []

  if (tablaListaSolicitudes != null) {
    const canal = tablaListaSolicitudes.data;
    for (let i = 0; i < canal.length; i++) {
      canalChat.push(
        <CanalesChat data={canal[i].idUser} />
      )
    }
  }

  if (datosUsuarioLogeado != null) {
    const url = new URL(
      "https://mercure.blackhound.com.ar/.well-known/mercure"
    );
    url.searchParams.append(
      "topic",
      "https://blackhoundnotifications/notification/" + datosUsuarioLogeado.id
    ); //MERCURE
    const eventSource = new EventSource(url);
    eventSource.onmessage = function ({ data }) {
      setNotificacion(JSON.parse(data).message);
    };
  }

  useEffect(() => {
    if (datosUsuarioLogeado && tablaListaClientes != null) {
      for (var i = 0; i < tablaListaClientes.data.length; i++) {
        const urlAsesor = new URL("https://mercure.blackhound.com.ar/.well-known/mercure");
        dispatch(getChat({ userReceptor: guardarIdChat != null ? guardarIdChat : '' }));//35 DEBE SER UN ID DEL CLIENTE
        urlAsesor.searchParams.append(
          "topic",
          "https://blackhoundcomunication/chat/" + datosUsuarioLogeado.id + guardarIdChat != null ? guardarIdChat : '' //35 DEBE SER UN ID DEL CLIENTE
        );
        const eventSourceUser = new EventSource(urlAsesor);
        eventSourceUser.onmessage = function ({ data }) {
          setChat(JSON.parse(data).message);
        };
      }
    }
  }, [chat, guardarIdChat]);

  var idConversacion = 0;
  return (
    <>
      {loading ?
        <div className='loading'>
          <Loader className='loadingColor' size="lg" />
        </div>
        :
        <animated.div style={fadeOut}>
          {canalChat}
          <div className="containerGeneric">
            <div>
              <h5 className="user-wellcome"> INVERSIONES / PENDIENTES </h5>
            </div>
            <div className="categoria-titulo2">
              <div className="circle-categoria"> </div>
              <div className="proyecto-titulo-dash">USUARIOS PENDIENTES DE CITA</div>
            </div>

            <div className="tabla-container">
              <Table
                height={550}
                data={tablaListaSolicitudes != null ? tablaListaSolicitudes.data : ""}
                locale={{ emptyMessage: "Sin solicitudes pendientes" }}
              >
                <Column flexGrow={1.5}>
                  <HeaderCell>Nombre</HeaderCell>
                  <Cell>
                    {(rowData) => {
                      return (
                        <div className="boxNombre">
                          <div className="box-img-imagen-user">
                            <img
                              className="fotoUsuario"
                              alt="logoLogin"
                              src={
                                rowData.idUser != null && rowData.imgPerfil != null
                                  ? "https://www.blackhound.com.ar/server/public/getImgProfile/" +
                                  rowData.idUser
                                  : "https://res.cloudinary.com/blackhound/image/upload/v1660676600/BLACKHOUND/Logprofile_wvmpml.png"
                              }
                            />
                          </div>
                          <div className="ajusteUbicacion">
                            {rowData.nombre + " " + rowData.apellido}
                          </div>
                        </div>
                      );
                    }}
                  </Cell>
                </Column>

                <Column flexGrow={0.6}>
                  <HeaderCell>DNI</HeaderCell>
                  <Cell>
                    {(rowData) => {
                      return rowData.dni;
                    }}
                  </Cell>
                </Column>

                <Column flexGrow={0.8}>
                  <HeaderCell>Teléfono</HeaderCell>
                  <Cell>
                    {(rowData) => {
                      return rowData.telefono;
                    }}
                  </Cell>
                </Column>

                <Column flexGrow={1.2}>
                  <HeaderCell>Correo electrónico</HeaderCell>
                  <Cell>
                    {(rowData) => {
                      return rowData.email;
                    }}
                  </Cell>
                </Column>

                <Column flexGrow={1}>
                  <HeaderCell>Proyecto</HeaderCell>
                  <Cell>
                    {(rowData) => {
                      return (
                        <>
                          {rowData.proyecto == "Desarrollos Delsud" ? (
                            <Whisper placement="bottom" followCursor speaker={<Popover className="container-pop"> <b>Plazo:</b> {rowData.plazoInversion + ' meses'} <br></br> <b>Monto:</b> ${rowData.monto}</Popover>}>
                              <img
                                className="proyecto-tamaño-asesor-pendiente"
                                src="https://res.cloudinary.com/blackhound/image/upload/v1659525488/BLACKHOUND/desarrollos2_jw19om.svg"
                                alt="desarrollos"
                              />
                            </Whisper>
                          ) : (
                            ""
                          )}
                          {rowData.proyecto == "Elemental Constructora" ? (
                            <Whisper placement="bottom" followCursor speaker={<Popover className="container-pop"> <b>Plazo:</b> {rowData.plazoInversion + ' meses'} <br></br> <b>Monto:</b> ${rowData.monto}</Popover>}>
                              <img
                                className="proyecto-tamaño-asesor-pendiente"
                                src="https://res.cloudinary.com/blackhound/image/upload/v1659540935/BLACKHOUND/elemental_oohxsp.svg"
                                alt="Elemental Constructora"
                              />
                            </Whisper>
                          ) : (
                            ""
                          )}
                          {rowData.proyecto == "Eugénie" ? (
                            <Whisper placement="bottom" followCursor speaker={<Popover className="container-pop"> <b>Plazo:</b> {rowData.plazoInversion + ' meses'} <br></br> <b>Monto:</b> ${rowData.monto}</Popover>}>
                              <img
                                className="proyecto-tamaño-asesor-pendiente"
                                src="https://res.cloudinary.com/blackhound/image/upload/v1659541685/BLACKHOUND/eugenie_jljwc2.svg"
                                alt="Microcréditos"
                              />
                            </Whisper>
                          ) : (
                            ""
                          )}
                          {rowData.proyecto == "Petricor Agrícola" ? (
                            <Whisper placement="bottom" followCursor speaker={<Popover className="container-pop"> <b>Plazo:</b> {rowData.plazoInversion + ' meses'} <br></br> <b>Monto:</b> ${rowData.monto}</Popover>}>
                              <img
                                className="proyecto-tamaño-asesor-pendiente"
                                src="https://res.cloudinary.com/blackhound/image/upload/v1659541779/BLACKHOUND/petricor_yutbqr.svg"
                                alt="Petricor Agrícola"
                              />
                            </Whisper>
                          ) : (
                            ""
                          )}
                          {rowData.proyecto == "Elal" ? (
                            <Whisper placement="bottom" followCursor speaker={<Popover className="container-pop"> <b>Plazo:</b> {rowData.plazoInversion + ' meses'} <br></br> <b>Monto:</b> ${rowData.monto}</Popover>}>
                              <img
                                className="proyecto-tamaño"
                                src="https://res.cloudinary.com/blackhound/image/upload/v1662854931/BLACKHOUND/elalll_sxpkmu.png"
                                alt="Petricor Agrícola"
                              />
                            </Whisper>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    }}
                  </Cell>
                </Column>

                <Column flexGrow={1.5}>
                  <HeaderCell>Acciones</HeaderCell>
                  <Cell className="estado-inversion">
                    {(rowData) => {
                      const cambiarEstadoMensajes = {
                        iduser: rowData.idUser,
                        idconver: idConversacion,
                      }
                      function handleChat() {
                        setChatUser({ ...rowData });
                        dispatch(setIdCliente(rowData.idUser));
                        dispatch(changeEstadoMensajes(cambiarEstadoMensajes));
                        dispatch(allConversacionesAsesor());
                      }
                      function handleAgregarTurno() {
                        setAgregarTurno({ ...rowData });
                      }
                      function handleModalRechazar() {
                        setModalRechazar({ ...rowData });
                      }
                      function handleModalEnviarMail() {
                        setModalEnviarMail(true)
                        setEmail(rowData.email)
                      }

                      let totalNoVisto = 0;
                      if (allConversaciones !== null && allConversaciones.conversaciones != null) {
                        for (var i = 0; i < allConversaciones.conversaciones.length; i++) {
                          idConversacion = allConversaciones.conversaciones[i].idConversacion
                          for (var j = 0; j < allConversaciones.conversaciones[i].mensajes.length; j++) {
                            if (rowData.idUser == allConversaciones.conversaciones[i].mensajes[j].user && allConversaciones.conversaciones[i].mensajes[j].estado == 1) {
                              totalNoVisto++;
                            }
                          }
                        }

                      }
                      return (
                        <>
                          <div className="container-buttons">
                            {/* <p><b>{totalNoVisto}</b></p> */}
                            <Whisper placement="top" followCursor speaker={
                              <Popover className="container-pop">
                                <b>Chatear</b>
                                {totalNoVisto > 0 ?
                                  <p>Tiene <b>{totalNoVisto}</b> mensajes</p>
                                  :
                                  ''
                                }
                              </Popover>}>

                              <input
                                className={totalNoVisto != 0 ? "button-mensajes-nuevos" : "button-asesor-chatear"}
                                type="button"
                                onClick={handleChat}
                              />
                            </Whisper>
                            <Whisper placement="bottom" followCursor speaker={<Popover className="container-pop"> <b>Modalidad de contacto:</b> {rowData.tipoContacto} <br></br>  <b>Horario:</b> {rowData.franjaHorario} </Popover>}>
                              <input
                                className="button-asesor-telefono"
                                type="button"
                                value={2}
                              />
                            </Whisper>
                            <Whisper placement="top" followCursor speaker={<Popover className="container-pop">Asignar Turno</Popover>}>
                              <input
                                className="button-asesor-turno"
                                type="button"
                                value={3}
                                onClick={handleAgregarTurno}
                              />
                            </Whisper>

                            <Whisper placement="bottom" followCursor speaker={<Popover className="container-pop">Rechazar</Popover>}>
                              <input
                                className={`button-cliente-rechazo ${rowData.idSolicitudEStado == 3
                                    ? "button-rechazo-active"
                                    : ""
                                  }`}
                                type="button"
                                value={3}
                                onClick={handleModalRechazar}
                              />
                            </Whisper>
                            <Whisper placement="top" followCursor speaker={<Popover className="container-pop">Notificar</Popover>}>
                              <input
                                className={`button-cliente-mail ${rowData.idSolicitudEStado == 4
                                    ? "button-rechazo-mail"
                                    : ""
                                  }`}
                                type="button"
                                value={4}
                                onClick={handleModalEnviarMail}
                              />
                            </Whisper>
                          </div>
                        </>
                      );
                    }}
                  </Cell>
                </Column>
              </Table>
              <Pagination
                prev
                next
                size="md"
                total={
                  tablaListaSolicitudes != null
                    ? tablaListaSolicitudes.totalRegister
                    : ""
                }
                limit={10}
                activePage={activePage}
                onChangePage={setActivePage}
                style={{
                  justifyContent: "center",
                  marginTop: "20px",
                  maginBottom: "50px",
                }}
              />
            </div>

            <ChatClient dataUserChat={chatUser} />
            <ModalEnviarMail 
            modalEnviarMail={modalEnviarMail}
            setModalEnviarMail={setModalEnviarMail}
            email={email}
            setOpen={setOpen}
            />
            <ModalGeneric
              open={open}
              setOpen={setOpen}
              content={modalConfirmacion.content}
              button={modalConfirmacion.button}                            
            />    
            <ModalRechazarSolicitud dataModalRechazarSolicitud={modalRechazar} />
            <AgregarTurno dataModalAgregarTurno={agregarTurno} />
          </div>
        </animated.div>
      }
    </>
  );
}
