import { actionTypes } from "../Constantes/ActionTypes"; 

export const setlistaAsesores = (data) => {
    return {
        type: actionTypes.SET_LISTA_ASESORES,
        data
    }
}

export const getlistaAsesores = (data) => {
    return {
        type: actionTypes.GET_LISTA_ASESORES,
        data
    }
}

