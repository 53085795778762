import { actionTypes } from "../Constantes/ActionTypes";
import { store } from "../store/index";

function reducerlistaClientesRegistrados (state = store.clientes, action){
    switch(action.type){
        case actionTypes.SET_LISTA_CLIENTES_REGISTRADOS:
            let ClientesRegistrados = action.data
            return Object.assign({}, state, {
                data: ClientesRegistrados,
            })
            default:
                return state
    }
}

export default reducerlistaClientesRegistrados;