import './mensajes.css'
import { useSelector } from 'react-redux';
import { format, register } from 'timeago.js';
export default function Mensajes({message, own}) {


const datosUsuarioLogeado = useSelector((state) => state.reducerUsuarioDatos.data);

const localeFunc = (number, index, totalSec) => {
    return [
      ['Justo ahora', 'En un rato'],
      ['Hace %s segundos', 'En %s segundos'],
      ['Hace 1 minuto', 'En 1 minuto'],
      ['Hace %s minutos', 'En %s minutos'],
      ['Hace 1 hora', 'En 1 hora'],
      ['Hace %s horas', 'en %s horas'],
      ['Hace 1 día', 'En 1 día'],
      ['Hace %s días', 'En %s días'],
      ['Hace 1 semana', 'En 1 semana'],
      ['Hace %s semanas', 'En %s semanas'],
      ['Hace 1 mes', 'En 1 mes'],
      ['Hace %s meses', 'En %s meses'],
      ['Hace 1 año', 'En 1 año'],
      ['Hace %s años', 'En %s años'],
    ][index];
  };
  register('es ES', localeFunc);


return (
    <div className={own ? "message own" : "message"}>
      {!own ? 
      <>
        <div className="messageTop">
            <img
                className="messageImg" 
                src={datosUsuarioLogeado != null && datosUsuarioLogeado.fotoPerfil === true ?
                    datosUsuarioLogeado.fotoPerfil : "https://res.cloudinary.com/blackhound/image/upload/v1662396917/BLACKHOUND/Group_17778_ujcekc.svg"}
            />
            <p className="messageText">{message.content}</p>
        </div>
        <div className="messageBottom">{format(message.fecha_creacion, 'es ES')}</div>
        </>
        :
        <>
          <div className="messageTopOwn">
            <p className="messageText">{message.content}</p>
            <div className='containerImgMessage'>
              <img
                  className="messageImgOwn" 
                  src={datosUsuarioLogeado != null && datosUsuarioLogeado.fotoPerfil === true ?
                      datosUsuarioLogeado.fotoPerfil : "https://res.cloudinary.com/blackhound/image/upload/v1662396917/BLACKHOUND/Group_17778_ujcekc.svg"}
              />
            </div>
          </div>
          <div className="messageBottomOwn">{format(message.fecha_creacion, 'es ES')}</div>
        </>
        }
    </div>
  )
}
