import apiInstance from './api';

export const apiCancelarTurno = (params) => {
    return apiInstance.get('api/cancelarTurno/' + params,
    { headers:{"Content-Type": "multipart/form-data"}})
    .then(res =>{
            return res.data;
    })

    .catch(error => {
        console.error(error.response);
        throw error.response
    });
}