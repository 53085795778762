import { actionTypes } from "../Constantes/ActionTypes";
import { store } from "../store/index";

function reducerGuardarIdContrato(state = store.guardarIdContrato, action) {
    switch (action.type) {
        case actionTypes.SET_ID_CONTRATO:
            let idContratos = action.data
            return Object.assign({}, state, {
                data: idContratos,
            })
        default:
            return state
    }
}

export default reducerGuardarIdContrato;