import React, { useState, useEffect } from "react";
import { Table, Pagination, Loader, Popover, Whisper} from "rsuite";
import { useSelector, useDispatch } from "react-redux";
import { getlistaSolicitudesRegistradas } from "../../../../Actions/listaSolicitudesRegistradas";
import { setEstadoSolicitud } from "../../../../Actions/estadoSolicitud";
import ModalEditar from "./ModalEditar/ModalEditar";
import ModalEnviarMail from "./ModalEnviarMail/ModalEnviarMail";
import { useSpring, animated } from 'react-spring';
import "./rechazados.css";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { getlistaClientesRegistrados } from "../../../../Actions/listaClientesRegistrados";

const { Column, HeaderCell, Cell } = Table;

export default function Rechazados() {
  const [open, setOpen] = useState(false);
  const [modalEditarEstado, setModalEditarEstado] = useState(null);
  const [modalEnviarMail, setModalEnviarMail] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [estadoCategoria, setEstadoCategoria] = useState(3);
  const [busqueda, setBusqueda] = useState({
    dataBase: "",
  });
  const [loading, setLoading] = useState(true);
  {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  const fadeOut = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 500,
}) 

  const dispatch = useDispatch();

  useEffect(() => {
    const data = {
      busqueda: busqueda.dataBase,
      estado: estadoCategoria,
      page: activePage,
    };
    dispatch(getlistaClientesRegistrados(data));
  }, [activePage, busqueda, estadoCategoria]);

  const inputChange = (event) => {
    setBusqueda({
      ...busqueda,
      [event.target.name]: event.target.value,
    });
  };

  const tablaListaClientes = useSelector(
    (state) => state.reducerlistaClientesRegistrados.data
  );


  return (
    <>
    {loading ?
        <div className='loading'>
            <Loader className='loadingColor' size="lg" />
        </div>
      :
      <animated.div style={fadeOut}>
      <div className="containerGeneric">
        <div>
          <h5 className="user-wellcome"> USUARIOS / RECHAZADOS </h5>
        </div>
        <div className="categoria-titulo2">
          <div className="circle-categoria"> </div>
          <div className="proyecto-titulo-dash">CONFIRMACIÓN DE INVERSIÓN</div>
        </div>

        <div className="tabla-container">
          <Table
            height={550}
            data={tablaListaClientes != null ? tablaListaClientes.data : ""}
            locale={{ emptyMessage: "Sin solicitudes rechazadas" }}
          >
            <Column flexGrow={1}>
              <HeaderCell>Nombre</HeaderCell>
              <Cell>
                {(rowData) => {
                  return (
                    <div className="boxNombre">
                      <div className="box-img-imagen-user">
                        <img
                          className="fotoUsuario"
                          alt="logoLogin"
                          src={
                            rowData.idUser != null && rowData.imgPerfil != null
                              ? "https://www.blackhound.com.ar/server/public/getImgProfile/" +
                                rowData.idUser
                              : "https://res.cloudinary.com/blackhound/image/upload/v1660676600/BLACKHOUND/Logprofile_wvmpml.png"
                          }
                        />
                      </div>
                      <div className="ajusteUbicacion">
                        {rowData.nombre + " " + rowData.apellido}
                      </div>
                    </div>
                  );
                }}
              </Cell>
            </Column>

            <Column flexGrow={0.8}>
              <HeaderCell>DNI</HeaderCell>
              <Cell>
                {(rowData) => {
                  return rowData.dni;
                }}
              </Cell>
            </Column>

            <Column flexGrow={0.8}>
              <HeaderCell>Teléfono</HeaderCell>
              <Cell>
                {(rowData) => {
                  return rowData.telefono;
                }}
              </Cell>
            </Column>

            <Column flexGrow={1.2}>
              <HeaderCell>Correo electrónico</HeaderCell>
              <Cell>
                {(rowData) => {
                  return rowData.email;
                }}
              </Cell>
            </Column>

            <Column flexGrow={1}>
              <HeaderCell>Acciones</HeaderCell>
              <Cell className="estado-inversion">
                {(rowData) => {
                  function handleModalEditar() {
                    setModalEditarEstado({ ...rowData });
                  }
                  function handleModalEnviarMail() {
                    setModalEnviarMail({ ...rowData });
                  }
                  return (
                    <>
                      <div className="container-buttons">
                      <Whisper placement="bottom" followCursor speaker={<Popover className="container-pop">Editar estado</Popover>}>
                        <input
                          className="button-asesor-cambiarEstado"
                          type="button"
                          value={1}
                          onClick={handleModalEditar}
                        />
                       </Whisper>
                      </div>
                    </>
                  );
                }}
              </Cell>
            </Column>
          </Table>
          <Pagination
            prev
            next
            size="md"
            total={
              tablaListaClientes != null
                ? tablaListaClientes.totalRegister
                : ""
            }
            limit={10}
            activePage={activePage}
            onChangePage={setActivePage}
            style={{
              justifyContent: "center",
              marginTop: "20px",
              maginBottom: "50px",
            }}
          />
        </div>
        {/* <InfoSolicitud dataModalSolicitud={modalSolicitud} />
              <ModalMailSolicitud dataModalMail={modalMailAprobado} /> */}
        <ModalEditar dataModalEditar={modalEditarEstado} />
        <ModalEnviarMail dataModalEnviarMail={modalEnviarMail} />
      </div>
      </animated.div>
    }
    </>
  );
}
