import React, { useState, useEffect } from "react";
import { Table, Pagination, Loader, Popover, Whisper} from "rsuite";
import { useSpring, animated } from 'react-spring';
import { useSelector, useDispatch } from "react-redux";
import { getlistaClientesRegistrados } from "../../../Actions/listaClientesRegistrados";
import { getlistaAsesores } from "../../../Actions/listaAsesores";

//MODALES
import InfoCliente from "./InfoCliente/InfoCliente";
import ModalAprobado from "./modalAprobado/modalAprobado";
import ModalRechazado from "./modalRechazar/modalRechazar";
import ModalEnviarMail from "./modalEnviarMail/modalEnviarMail";

import "./usuarios.css";
import ModalAsignarAsesorUser from "./ModalAsignarAsesorUser/ModalAsignarAsesorUser";

const { Column, HeaderCell, Cell } = Table;

const Usuarios = () => {
  
  const tablaListaClientes = useSelector((state) => state.reducerlistaClientesRegistrados.data);
  const listasesores = useSelector(state => state.reducerListaAsesores.data);
  const datosUsuarioLogeado = useSelector(state => state.reducerUsuarioDatos.data);
  
  const [modalVer, setModalVer] = useState(null);
  const [modalAprobado, setModalAprobado] = useState(null);
  const [modalRechazado, setModalRechazado] = useState(null);
  const [modalEnviarMail, setModalEnviarMail] = useState(null);
  const [modalAsignarAsesorUser, setModalAsignarAsesorUser] = useState(false);

  const [activePage, setActivePage] = useState(1);
  const [estadoFiltro, setEstadoFiltro] = useState(1);
  const [busqueda, setBusqueda] = useState({
    dataBase: null,
  });

  const [select, setSelect] = useState(true)
  const [idCliente, setIdCliente] = useState()
  const [idAsesor, setIdAsesor] = useState()
  const dispatch = useDispatch();

  useEffect(() => {
    const data = {
      busqueda: busqueda.dataBase,
      estado: estadoFiltro,
      page: activePage,
    };
    dispatch(getlistaClientesRegistrados(data));
    dispatch(getlistaAsesores(data));
  }, [activePage, busqueda, estadoFiltro]);

  const inputChange = (event) => {
    setBusqueda({
      ...busqueda,
      [event.target.name]: event.target.value,
    });
  };

  

  const [loading, setLoading] = useState(true); {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  const fadeOut = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 500,
}) 

  return (
    <>
      {loading ?
        <div className='loading'>
            <Loader className='loadingColor' size="lg" />
        </div>
      :
      <animated.div style={fadeOut}>
      <div>
        <h5 className="user-wellcome"> SOLICITUDES / USUARIOS </h5>
      </div>
      <div className="categoria-titulo2">
        <div className="circle-categoria"> </div>
        <div className="proyecto-titulo-dash separacion-usuarios">
          CONFIRMACIÓN DE DATOS
        </div>
      </div>

      <div className="tabla-container">
        <Table
          height={550}
          data={tablaListaClientes != null ? tablaListaClientes.data : ""}
          locale={{ emptyMessage: "Sin solicitudes de clientes..." }}
        >
          <Column flexGrow={2}>
            <HeaderCell
              style={{
                paddingLeft: "20px",
                backgroundColor: "#96040E",
                color: "#fff",
              }}
            >
              Nombre
            </HeaderCell>
            <Cell>
              {(rowData) => {
                return (
                  <div className="boxNombre">
                    <div className="box-img-imagen-user">
                      <img
                        className="fotoUsuario"
                        alt="logoLogin"
                        src={
                          rowData.idUser != null && rowData.idFotoPerfil != null
                            ? "https://www.blackhound.com.ar/server/public/getImgProfile/" +
                              rowData.idUser
                            : "https://res.cloudinary.com/blackhound/image/upload/v1660676600/BLACKHOUND/Logprofile_wvmpml.png"
                        }
                      />
                    </div>
                    <div className="ajusteUbicacion">
                      {rowData.nombre + " " + rowData.apellido}
                    </div>
                  </div>
                );
              }}
            </Cell>
          </Column>

          <Column flexGrow={0.8}>
            <HeaderCell
              style={{
                fontWeight: "600",
                backgroundColor: "#96040E",
                color: "#fff",
              }}
            >
              DNI
            </HeaderCell>
            <Cell dataKey="dni" />
          </Column>

          <Column flexGrow={0.8}>
            <HeaderCell style={{ backgroundColor: "#96040E", color: "#fff" }}>
              Teléfono
            </HeaderCell>
            <Cell dataKey="telefono" />
          </Column>

          <Column flexGrow={2}>
            <HeaderCell style={{ backgroundColor: "#96040E", color: "#fff" }}>
              Correo electrónico
            </HeaderCell>
            <Cell dataKey="email" />
          </Column>
          {datosUsuarioLogeado != null && datosUsuarioLogeado.rol[0] === "ROLE_ADMIN" ?
            <Column flexGrow={1.5}>
                <HeaderCell style={{ backgroundColor: '#96040E', color: "#fff" }}> Asesor </HeaderCell>
                <Cell className='estado-inversion'>
                  {rowData => {
                    let selectAsesores = []
                    if (listasesores && tablaListaClientes != null) {
                      for (let i = 0; i < listasesores.data.length; i++) {
                        selectAsesores.push(
                        <option value={listasesores.data[i].idUser} 
                          selected={listasesores.data[i].nombre + ' ' + listasesores.data[i].apellido === rowData.asesorAsignado}> 
                          {listasesores.data[i].nombre + ' ' + listasesores.data[i].apellido} 
                        </option>)
                      }
                    }
                    
                    function handleModalAsesor(e){
                      setModalAsignarAsesorUser(true);
                      setIdCliente(rowData.idUser)
                      setIdAsesor(e.target.value)
                    }
                    // onChange={(event) => handleAsesor(rowData.idSolicitud, event)}
                    return (
                      <> 
                          <select onChange={(e) => {handleModalAsesor(e)}} name='estado'>
                            {/* <option selected={select === true ? "true" : "false" && select === false ? setSelect(true) : ""} disabled="disabled">
                              {}
                            </option> */}
                            {selectAsesores}
                          </select>
                      </>
                    )
                  }
                  }
                </Cell>
              </Column>
              :
              ""
          }

          <Column flexGrow={1}>
            <HeaderCell style={{ backgroundColor: "#96040E", color: "#fff" }}>
              Documentación
            </HeaderCell>
            <Cell center>
              {(rowData) => {
                function handleView() {
                  setModalVer({ ...rowData });
                }
                return (
                  <>
                    {rowData.isDocumentacion != null ? (
                      <div>
                        <input
                          type="button"
                          value="VER"
                          className="ver-boton-pop-up"
                          onClick={handleView}
                        />
                      </div>
                    ) : (
                      <p>Sin documentos</p>
                    )}
                  </>
                );
              }}
            </Cell>
          </Column>

          <Column flexGrow={1}>
            <HeaderCell style={{ backgroundColor: "#96040E", color: "#fff" }}>
              Acciones
            </HeaderCell>
            <Cell className="estado-inversion">
              {(rowData) => {
                function handleModalAprobado() {
                  setModalAprobado({ ...rowData });
                }
                function handleModalRechazado() {
                  setModalRechazado({ ...rowData });
                }
                function handleModalEnviarMail() {
                  setModalEnviarMail({ ...rowData });
                }
                return (
                  <>
                    <div className="container-buttons">
                      <Whisper placement="top" followCursor speaker={<Popover className="container-pop">Aprobar</Popover>}>
                        <input
                          className={`button-cliente-aprob ${
                            rowData.idEstado == 2 ? "button-aprob-active" : ""
                          }`}
                          type="button"
                          value={2}
                          onClick={handleModalAprobado}
                        />
                      </Whisper>
                      <Whisper placement="bottom" followCursor speaker={<Popover className="container-pop">Rechazar</Popover>}>
                        <input
                          className={`button-cliente-rechazo ${
                            rowData.idEstado == 3 ? "button-rechazo-active" : ""
                          }`}
                          type="button"
                          value={3}
                          onClick={handleModalRechazado}
                        />
                      </Whisper>
                      <Whisper placement="top" followCursor speaker={<Popover className="container-pop">Notificar</Popover>}>
                        <input
                          className={`button-cliente-mail ${
                            rowData.idEstado == 4 ? "button-rechazo-active" : ""
                          }`}
                          type="button"
                          value={4}
                          onClick={handleModalEnviarMail}
                        />
                      </Whisper>
                    </div>
                  </>
                );
              }}
            </Cell>
          </Column>
        </Table>
        <Pagination
          prev
          next
          size="md"
          total={
            tablaListaClientes != null ? tablaListaClientes.totalRegister : ""
          }
          limit={10}
          activePage={activePage}
          onChangePage={setActivePage}
          style={{
            justifyContent: "center",
            marginTop: "20px",
            maginBottom: "50px",
          }}
        />
      </div>
      <InfoCliente dataInfoCliente={modalVer} />
      <ModalAprobado dataModalAprobado={modalAprobado} />
      <ModalRechazado dataModalRechazado={modalRechazado} />
      <ModalEnviarMail dataModalEnviarMail={modalEnviarMail} />
      <ModalAsignarAsesorUser
      modalAsignarAsesorUser={modalAsignarAsesorUser}
      setModalAsignarAsesorUser={setModalAsignarAsesorUser}
      idAsesor={idAsesor}
      idCliente={idCliente}
      select={select}
      setSelect={setSelect}
      />
    </animated.div>
    }
    </>
  );
};

export default Usuarios;
