import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';
import { dashboard } from'../api/dashboard';
import { setDashboard } from '../Actions/dashboard';

export const watchDashboard = function * (){
    yield takeEvery(actionTypes.DASHBOARD_RECIBE_GET_USER, fetchDashboard)
}

function* fetchDashboard(action){
    try{
        const data = yield call(dashboard, action.data)
        if(data){
            yield put(setDashboard(data))
            //MODAL USUARIO REGISTRADO EXITOSO
        }
    }catch(err){
        console.log(err)
    }
}