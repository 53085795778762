import React, { useState, useEffect } from 'react'
import { Table, Pagination, Loader, Popover, Whisper } from "rsuite";
import { useSpring, animated } from 'react-spring';
import { useSelector, useDispatch } from 'react-redux';
import { getlistaSolicitudesRegistradas } from '../../../Actions/listaSolicitudesRegistradas';
import { getlistaAsesores } from '../../../Actions/listaAsesores';

import ModalAprobadoSolicitud from './modalAprobadoSolicitud/modalAprobadoSolicitud';
import ModalRechazadoSolicitud from './modalRechazadoSolicitud/modalRechazadoSolicitud';
import ModalEnviarMailSolicitud from './modalEnviarMailSolicitud/modalEnviarMailSolicitud';
import './solicitudes.css'
import { getTraerEstadosSolicitudAdministrador } from '../../../Actions/listAdministrador';

const { Column, HeaderCell, Cell } = Table;

export default function Solicitudes() {
  const [modalAprobado, setModalAprobado] = useState(null);
  const [modalRechazado, setModalRechazado] = useState(null);
  const [modalEnviarMail, setModalEnviarMail] = useState(null);
  const [modalAsignarAsesor, setModalAsignarAsesor] = useState(false);

  const [activePage, setActivePage] = useState(1)
  const [estadoCategoria, setEstadoCategoria] = useState(null)
  const [busqueda, setBusqueda] = useState({
    dataBase: ""
  })
  const [select, setSelect] = useState(true)
  const [idSolicitud, setIdSolicitud] = useState()
  const [idAsesor, setIdAsesor] = useState()
  // const [show, setShow] = useState(false);
  // const handleShow = () => setShow(true);
  // const handleCloseConfirm = () => setShow(false);

  const [loading, setLoading] = useState(true); {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  const dispatch = useDispatch();

  //SELECCIONAR EL ASESOR
  // const handleAsesor = (idSolicitud, event) => {
  //   const dataAsesor = {
  //     idSolicitud: idSolicitud,
  //     idUser: event.target.value,
  //   }
  //   const asignarSolicitud = {
  //     id: idSolicitud,
  //     idEstado: 1,
  //   }  
  //   const data = {
  //     busqueda: busqueda.dataBase,
  //     categoria: estadoCategoria,
  //     page: activePage,
  //   }
  //   if (dataAsesor != null) {
  //     dispatch(getAgregarAsesorSolicitud(dataAsesor));
  //     dispatch(setEstadoSolicitud(asignarSolicitud));
  //     setTimeout(() => {
  //       dispatch(getlistaSolicitudesRegistradas(data))
  //     }, 1200); 
  //     handleShow();
  //   }
  // }

  const tablaListaSolicitudes = useSelector(state => state.reducerListaSolicitudesAdministrador.data);
  const listasesores = useSelector(state => state.reducerListaAsesores.data);
  

  const fadeOut = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 500,
  })

  useEffect(() => {
    const data = {
      busqueda: busqueda.dataBase,
      categoria: estadoCategoria,
      page: activePage,
    }
    dispatch(getTraerEstadosSolicitudAdministrador(data));
    dispatch(getlistaAsesores(data));
  }, [activePage, busqueda, estadoCategoria])

  return (
    <>
      {loading ?
        <div className='loading'>
          <Loader className='loadingColor' size="lg" />
        </div>
        :
        <animated.div style={fadeOut}> 
          <div>
            <h5 className='user-wellcome'> SOLICITUDES / INVERSIONES </h5>
          </div>
          <div className="categoria-titulo2">
            <div className="circle-categoria"> </div>
            <div className="proyecto-titulo-dash separacion-usuarios">CONFIRMACIÓN DE INVERSIÓN</div>
          </div>

          <div className='tabla-container'>
            <Table
              // loading={loading}
              height={550}
              data={tablaListaSolicitudes != null ? tablaListaSolicitudes.data : ''}
              locale={{ emptyMessage: "Sin solicitudes pendientes..." }}
            >
              <Column flexGrow={2}>
                <HeaderCell style={{ backgroundColor: '#96040E', color: "#fff" }} >Nombre</HeaderCell>
                <Cell>
                  {(rowData) => {
                    return (
                      <div className="boxNombre">
                        <div className="box-img-imagen-user">
                          <img
                            className="fotoUsuario"
                            alt="logoLogin"
                            src={
                              rowData.idUser != null && rowData.imgPerfil != null
                                ? "https://www.blackhound.com.ar/server/public/getImgProfile/" +
                                rowData.idUser
                                : "https://res.cloudinary.com/blackhound/image/upload/v1660676600/BLACKHOUND/Logprofile_wvmpml.png"
                            }
                          />
                        </div>
                        <div className="ajusteUbicacion">
                          {rowData.nombre + " " + rowData.apellido}
                        </div>
                      </div>
                    );
                  }}
                </Cell>
              </Column>

              <Column flexGrow={1} >
                <HeaderCell style={{ backgroundColor: '#96040E', color: "#fff" }}>DNI</HeaderCell>
                <Cell>
                  {rowData => {
                    return (

                      rowData.dni

                    )
                  }
                  }
                </Cell>
              </Column>

              <Column flexGrow={1}>
                <HeaderCell style={{ backgroundColor: '#96040E', color: "#fff" }}>Empresa</HeaderCell>
                <Cell >
                  {rowData => {
                    return (
                      <>                        {
                          rowData.proyecto == "Desarrollos Delsud" ?
                            <img className='proyecto-tamaño' src="https://res.cloudinary.com/blackhound/image/upload/v1659525488/BLACKHOUND/desarrollos2_jw19om.svg" alt="desarrollos" />
                            : ''
                        }
                        {
                          rowData.proyecto == "Elemental Constructo" ?
                            <img className='proyecto-tamaño' src="https://res.cloudinary.com/blackhound/image/upload/v1659540935/BLACKHOUND/elemental_oohxsp.svg" alt="Elemental Constructora" />
                            : ''
                        }
                        {
                          rowData.proyecto == "Eugénie" ?
                            <img className='proyecto-tamaño' src="https://res.cloudinary.com/blackhound/image/upload/v1659541685/BLACKHOUND/eugenie_jljwc2.svg" alt="Microcréditos" />
                            : ''
                        }
                        {
                          rowData.proyecto == "Petricor Agrícola" ?
                            <img className='proyecto-tamaño' src="https://res.cloudinary.com/blackhound/image/upload/v1659541779/BLACKHOUND/petricor_yutbqr.svg" alt="Petricor Agrícola" />
                            : ''
                        }
                        {rowData.proyecto == "Elal" ? (
                          <img
                            className="proyecto-tamaño"
                            src="https://res.cloudinary.com/blackhound/image/upload/v1662854931/BLACKHOUND/elalll_sxpkmu.png"
                            alt="Petricor Agrícola"
                          />
                        ) : (
                          ""
                        )}
                      </>

                    )
                  }
                  }
                </Cell>
              </Column>

              <Column width={100} >
                <HeaderCell style={{ backgroundColor: '#96040E', color: "#fff" }}>Monto</HeaderCell>
                <Cell>
                  {rowData => {
                    return (

                      rowData.moneda + rowData.monto

                    )
                  }
                  }
                </Cell>
              </Column>

              <Column width={80} >
                <HeaderCell style={{ backgroundColor: '#96040E', color: "#fff" }} > Plazo </HeaderCell>
                <Cell>
                  {rowData => {
                    return (

                      rowData.plazoInversion + ' meses'
                      
                    )
                  }
                  }
                </Cell>
              </Column>

              <Column flexGrow={1.5}>
                <HeaderCell style={{ backgroundColor: '#96040E', color: "#fff" }}> Asesor </HeaderCell>
                <Cell className='estado-inversion' dataKey='asesorAsignado'/>
              </Column>

              <Column flexGrow={1}>
                  <HeaderCell>Turno</HeaderCell>
                  <Cell>
                    {
                      (rowData) => {
                        return rowData.turnoUser != 'sin turno' ? rowData.turnoUser.date.substring(0, 16) + 'hs' : 'sin turno';
                      }}
                  </Cell>
                </Column>    

              <Column width={120} >
                <HeaderCell style={{ backgroundColor: '#96040E', color: "#fff" }}>Acciones</HeaderCell>
                <Cell className='estado-inversion'>{
                  rowData => {
                    function handleModalAprobado() {
                      setModalAprobado({ ...rowData })
                    }
                    function handleModalRechazado() {
                      setModalRechazado({ ...rowData })
                    }
                    function handleModalEnviarMail() {
                      setModalEnviarMail({ ...rowData })
                    }

                    return (
                      <>
                        <div className='container-buttons'>
                          <Whisper placement="top" followCursor speaker={<Popover className="container-pop">Aprobar</Popover>}>
                            <input
                              className={`button-cliente-aprob ${rowData.idEstado == 2 ? "button-aprob-active" : ""
                                }`}
                              type="button"
                              value={2}
                              onClick={handleModalAprobado}
                            />
                          </Whisper>
                          <Whisper placement="bottom" followCursor speaker={<Popover className="container-pop">Rechazar</Popover>}>
                            <input
                              className={`button-cliente-rechazo ${rowData.idEstado == 3 ? "button-rechazo-active" : ""
                                }`}
                              type="button"
                              value={3}
                              onClick={handleModalRechazado}
                            />
                          </Whisper>
                          <Whisper placement="top" followCursor speaker={<Popover className="container-pop">Notificar</Popover>}>
                            <input
                              className={`button-cliente-mail ${rowData.idEstado == 4 ? "button-rechazo-active" : ""
                                }`}
                              type="button"
                              value={4}
                              onClick={handleModalEnviarMail}
                            />
                          </Whisper>
                        </div>
                      </>
                    )
                  }
                }
                </Cell>
              </Column>

            </Table>
            <Pagination
              prev
              next
              size="md"
              total={tablaListaSolicitudes != null ? tablaListaSolicitudes.totalRegister : ""}
              limit={10}
              activePage={activePage}
              onChangePage={setActivePage}
              style={{ justifyContent: 'center', marginTop: '20px', maginBottom: '50px' }}
            />
          </div>

          <ModalAprobadoSolicitud dataModalAprobadoSolicitud={modalAprobado} />
          <ModalRechazadoSolicitud dataModalRechazadoSolicitud={modalRechazado} />
          <ModalEnviarMailSolicitud dataModalEnviarMailSolicitud={modalEnviarMail} />

          {/* MODAL */}
          {/* <Modal centered open={show} onClose={handleCloseConfirm} backdrop="static" >
            <Modal.Header>
              <Modal.Body
                style={{ fontSize: "20px", color: "white", textAlign: "center" }}
              > Asesor asignado correctamente.
              </Modal.Body>
            </Modal.Header>
            <Modal.Footer className='contFooter'>
              <button className='buttonModal' onClick={handleCloseConfirm}> Cerrar </button>
            </Modal.Footer>
          </Modal> */}

        </animated.div>
      }
    </>
  )
}
