import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {  Pagination } from 'rsuite';
//Import React Icon
import { FaRegBell } from "react-icons/fa";
import { ImNotification } from "react-icons/im";
import { getNotificacionUsuario } from "../../../Actions/notificacionUsuario";
import { cambiarEstadoNotificaciones } from "../../../Actions/notificacionUsuario";
import "./notificaciones.css";

export default function Notificaciones() {
  const notificacionesBD = useSelector((state) => state.reducerNotificacionesUsuario.data); //OBTENER NOTIFICACIONES
  const dispatch = useDispatch();
  const [pagina, setPagina] = useState(1)
  
  useEffect(() => {
    const data = {
      page : pagina
    }
    dispatch(cambiarEstadoNotificaciones());
    setTimeout(() => {
      dispatch(getNotificacionUsuario(data))
    }, 500)
  }, [pagina]);


  let listaNotificaciones = [];

  if (notificacionesBD != null && notificacionesBD.status != 'Sin Notificaciones') {
    for (let i = 0; i < notificacionesBD.data.length; i++) {
      listaNotificaciones.push(
        <>
          {notificacionesBD.data[i].estado === "No visto" ?
            <div className="container-notificaciones-no-vistas">
              <div className="container-mensaje-icono">
                {notificacionesBD.data[i].estado === "No visto" ?
                  <ImNotification className="sin-datos-icon" />
                  :
                  <FaRegBell className="sin-datos-icon" />
                }
                &nbsp;&nbsp;&nbsp;
                <p className="tittle-notificacion"> {notificacionesBD.data[i].contenido}</p>
              </div>
              <p className="tittle-notificacion">{notificacionesBD.data[i].fecha.date.slice(0, 10).replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}</p>
            </div>
            :
            <div className="container-notificaciones">
              <div className="container-mensaje-icono">
                {notificacionesBD.data[i].estado === "No visto" ?
                  <ImNotification className="sin-datos-icon" />
                  :
                  <FaRegBell className="sin-datos-icon" />
                }
                &nbsp;&nbsp;&nbsp;
                {/* <p className="tittle-notificacion"> {notificacionesBD.response[i].estado}</p> */}
                <p className="tittle-notificacion"> {notificacionesBD.data[i].contenido} </p>
              </div>
              <p className="tittle-notificacion">{notificacionesBD.data[i].fecha.date.slice(0, 10).replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}</p>
            </div>
          }
        </>

      );
    }
  } else {
    listaNotificaciones.push(
      <>
        <div className="sinNotificaciones">
          <img src="https://res.cloudinary.com/blackhound/image/upload/v1662137934/BLACKHOUND/notification-svgrepo-com_1_2_x5j9qk.png" />
          <p>No hay notificaciones disponibles</p>
        </div>
      </>
    );
  }

  return (
    <>
    {notificacionesBD != null ?
      <>
        <div className="responsiveNotificaciones">
          <h5 className="seccion-usuario-titulo">NOTIFICACIONES</h5>
          <div className="containerNotificaciones">{listaNotificaciones}</div>
        </div>
        {notificacionesBD != null && notificacionesBD.status === "Con Notificaciones" ?
            <Pagination
              className="paginationNotifications"
              prev
              next
              size="xs"
              total={notificacionesBD != null ? notificacionesBD.totalRegisters : ''}
              limit={7}
              activePage={pagina}
              onChangePage={setPagina}
            />
          
        : ""
        }
      </>
        :
        <div className="sinNotificaciones">
          <img src="https://res.cloudinary.com/blackhound/image/upload/v1662137934/BLACKHOUND/notification-svgrepo-com_1_2_x5j9qk.png" />
          <p>No hay notificaciones disponibles</p>
        </div>
      }
    </>
  );
}
