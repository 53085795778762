import { actionTypes } from "../Constantes/ActionTypes"; 

export const getAgregarAsesorSolicitud = (data) => {
    return {
        type: actionTypes.GET_ASESOR_A_SOLICITUD,
        data
    }
}

export const setAgregarAsesorSolicitud = (data) => {
    return {
        type: actionTypes.SET_ASESOR_A_SOLICITUD,
        data
    }
}
