import { actionTypes } from "../Constantes/ActionTypes";
import { store } from "../store/index";

function reducerGuardarIdChat(state = store.guardarIdChat, action) {
    switch (action.type) {
        case actionTypes.SET_ID_CLIENTE:
            let idClientes = action.data
            return Object.assign({}, state, {
                data: idClientes,
            })
        default:
            return state
    }
}

export default reducerGuardarIdChat;

