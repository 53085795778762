import { actionTypes } from "../Constantes/ActionTypes";
import { store } from "../store/index";

function reducerCrearProyecto (state = store.proyecto, action){
    switch(action.type){
        case actionTypes.CREAR_PROYECTO_NUEVO:
            let CrearProyecto = action.data
            return Object.assign({}, state, {
                data: CrearProyecto,
            })
            default:
                return state
    }
}

export default reducerCrearProyecto;