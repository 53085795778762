import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';
import { agregarAsesorAUnUsuario } from "../api/agregarAsesorAUnUsuario";
import { AgregarAsesorAUnUsuario } from "../Actions/agregarAsesorAUnUsuario";

export const watchAgregarAsesorAUnUsuario = function * (){
    yield takeEvery(actionTypes.AGREGAR_ASESOR_A_UN_USUARIO, fetchAgregarAsesorAUnUsuario)
}

function* fetchAgregarAsesorAUnUsuario(action){
    try{
        yield call(agregarAsesorAUnUsuario, action.data)
        // if(data){
        //     yield put(AgregarAsesorAUnUsuario(data))
        // }
    }catch(err){
        console.log(err)
    }
}