import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from 'redux-saga/effects';

import { editarProyectoActivos } from '../api/editarProyectoActivo';
import { editarProyecto } from "../Actions/editarProyecto";
// import { toast } from 'react-toastify';

export const watchEditarProyecto = function* () {
    yield takeEvery(actionTypes.SET_EDIT_PROYECTO, fetchEditarProyecto)
}

function* fetchEditarProyecto(action) {
    try {
        const data = yield call(editarProyectoActivos, action.data)
        if (data) {
            // yield put(editarProyecto(data))
            // toast.success('Proyecto editado!', {});
        }
    } catch (err) {
        console.log(err)
    }
}