import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { Navbar, Nav, Container, Offcanvas, NavDropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getDatosUsuario } from '../../Actions/datosUsuario';
import { FiPower } from 'react-icons/fi';
import { useMediaQuery } from "react-responsive"

import 'bootstrap/dist/css/bootstrap.min.css';
import './navbar.css';

import { verificarLogin } from '../../Actions/login';


export default function BlackHoundNavbar() {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [errorLogin, setErrorLogin] = useState(false);

  const toggleShow = () => setShow((s) => !s);

  const dispatch = useDispatch();

  const datosUsuarioLogeado = useSelector(state => state.reducerUsuarioDatos.data);
  const token = useSelector(state => state.reducerLogin.user_data);
  const userLoginNav = localStorage.getItem('token')

  const [userLogin, setUserLogin] = useState("");

  useEffect(() => {
    dispatch(getDatosUsuario());
  }, [token, userLoginNav])

  useEffect(() => {
    if (datosUsuarioLogeado != null) {
      if (datosUsuarioLogeado.rol[0] == "ROLE_ADMIN") {
        setUserLogin("ROLE_ADMIN")
      }
      if (datosUsuarioLogeado.rol[0] == "ROLE_USER") {
        setUserLogin("ROLE_USER")
      }
      if (datosUsuarioLogeado.rol[0] == "ROLE_ASESOR") {
        setUserLogin("ROLE_ASESOR")
      }
    }
  }, [datosUsuarioLogeado])


  


  //ELIMINAR TOKEN
  const borrarToken = () => {
    dispatch(verificarLogin(false))
    localStorage.removeItem('token');
    localStorage.clear()
    setShow(false);
  }

  //CERRAR SESION
  const navegarCerrarSesion = useNavigate();
  const cerraSession = () => {
    dispatch(verificarLogin(false))
    localStorage.removeItem('token');
    localStorage.clear()
    // return (navegarCerrarSesion('/')) 
    window.location = ("/");
    setShow(false);
  }

  useEffect(() => {
    if (datosUsuarioLogeado != null) {
      if (datosUsuarioLogeado.rol[0] == "ROLE_ADMIN") {

      }
    }
  }, [datosUsuarioLogeado])

  useEffect(() => {
    if (datosUsuarioLogeado != null) {
      if (datosUsuarioLogeado.estadoNombre == "Aprobado") {
        estadoUsuario = 1;
      }
      else if (datosUsuarioLogeado.estadoNombre == "Revision" || datosUsuarioLogeado.estadoNombre == "Rechazado") {
        estadoUsuario = 0;
      }
    }
  }, [datosUsuarioLogeado, estadoUsuario])

  var estadoUsuario = 0;
  if (datosUsuarioLogeado != null) {
    if (datosUsuarioLogeado.estadoNombre == "Aprobado") {
      estadoUsuario = 1;
    }
    else if (datosUsuarioLogeado.estadoNombre == "Revision" || datosUsuarioLogeado.estadoNombre == "Rechazado") {
      estadoUsuario = 0;
    }
  }
  const isMobile = useMediaQuery({ maxDeviceWidth: 1023 })

  const ScrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    })
  }


  return (
    <>
      <Navbar collapseOnSelect key={"xl"} expand="lg" className="navbar">
        {userLogin != "" && userLogin != "ROLE_USER" ?         
        <Container className='navbar-web'>
          <Navbar.Brand>
            <NavLink onClick={ScrollToTop} to="/"><img className='iconNavBar' src={'https://res.cloudinary.com/blackhound/image/upload/v1663159497/BLACKHOUND/NavBar/IconNavBar_mkmpaf.svg'} /></NavLink>
            {isMobile && userLogin == "ROLE_USER" ?
            <span>
              {datosUsuarioLogeado != null && datosUsuarioLogeado.fotoPerfil === true ? 
              <img src={"https://www.blackhound.com.ar/server/public/getImgProfile/" + datosUsuarioLogeado.id} />
              :
               <img src='https://res.cloudinary.com/blackhound/image/upload/v1660676600/BLACKHOUND/Logprofile_wvmpml.png' />
              }
              </span>
              : 
              ''
            }
            </Navbar.Brand>
          <img className="botonMenuResponsive me-2" src='https://res.cloudinary.com/blackhound/image/upload/v1657897290/BLACKHOUND/menu-bar-01_waarrr_ahtjz7.svg' width={"35px"} variant="primary" onClick={toggleShow} />
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
            show={show}
            onHide={handleClose}
          >
            <Offcanvas.Header >
              <Offcanvas.Title className='offcanvasLogo' id={`offcanvasNavbarLabel-expand-${"md"}`}>
                <img className="boton-cierre" onClick={handleClose} src={'https://res.cloudinary.com/blackhound/image/upload/v1660933404/BLACKHOUND/NavBar/equis_lg2spj.svg'} />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body onHide={handleClose}>
              {userLogin === "ROLE_ADMIN" || userLogin === "ROLE_ASESOR" 
               ? 
                userLoginNav != null ? 
                '' 
                :
                <Nav className="justify-content-end navBarResponsiveLinks flex-grow-1 pe-3 ms-auto menu-item"  >
                  <NavLink onClick={() => {handleClose(); ScrollToTop()}} className={({ isActive }) => (isActive ? 'active' : 'nav-link hoverBorde')} to="/Nosotros">Nosotros</NavLink>
                  <NavLink onClick={() => {handleClose(); ScrollToTop()}} className={({ isActive }) => (isActive ? 'active' : 'nav-link hoverBorde')} to="/ComoInvertir">Cómo invertir</NavLink>
                  <NavLink onClick={() => {handleClose(); ScrollToTop()}} className={({ isActive }) => (isActive ? 'active' : 'nav-link hoverBorde')} to="/Proyectos">Proyectos</NavLink>
                  <NavLink onClick={() => {handleClose(); ScrollToTop()}} className={({ isActive }) => (isActive ? 'active' : 'nav-link hoverBorde')} to="/Contacto"> Contacto </NavLink>
                  <NavLink onClick={() => {handleClose(); ScrollToTop()}} className={({ isActive }) => (isActive ? 'active' : 'nav-link hoverBorde')} to="/Faqs"> FAQ's </NavLink>
                </Nav>
                :
                <Nav className="justify-content-end navBarResponsiveLinks flex-grow-1 pe-3 ms-auto menu-item"  >
                  <NavLink onClick={() => {handleClose(); ScrollToTop()}} className={({ isActive }) => (isActive ? 'active' : 'nav-link hoverBorde')} to="/Nosotros">Nosotros</NavLink>
                  <NavLink onClick={() => {handleClose(); ScrollToTop()}} className={({ isActive }) => (isActive ? 'active' : 'nav-link hoverBorde')} to="/ComoInvertir">Cómo invertir</NavLink>
                  <NavLink onClick={() => {handleClose(); ScrollToTop()}} className={({ isActive }) => (isActive ? 'active' : 'nav-link hoverBorde')} to="/Proyectos">Proyectos</NavLink>
                  <NavLink onClick={() => {handleClose(); ScrollToTop()}} className={({ isActive }) => (isActive ? 'active' : 'nav-link hoverBorde')} to="/Contacto"> Contacto </NavLink>
                  <NavLink onClick={() => {handleClose(); ScrollToTop()}} className={({ isActive }) => (isActive ? 'active' : 'nav-link hoverBorde')} to="/Faqs"> FAQ's </NavLink>
                </Nav>
              }
              {datosUsuarioLogeado != null && userLoginNav ?
                <div className='acceso-usuario-roles'  >
                  {
                    userLogin === "ROLE_ADMIN" &&  userLoginNav
                      ?
                      <>
                        <Nav className="justify-content-end navBarResponsiveLinks flex-grow-1 pe-3 ms-auto menu-item-admin"  >
                          <NavLink className='nav-link logeado' style={{border:'0'}} onClick={ScrollToTop} to="/HomeAdmin/dashboard">ADMINISTRADOR</NavLink>
                          <NavDropdown className='colorDrop' title="" >
                            <NavDropdown.Item >
                                  <div>
                                    <img alt='logoCerrar' src='https://res.cloudinary.com/blackhound/image/upload/v1661188876/BLACKHOUND/NavBar/iconoCerrarSesion_s51myk.webp'/> 
                                  </div>
                                  <div className='navItem' onClick={() => {handleClose(); ScrollToTop(); cerraSession()}}>
                                    Cerrar Sesión
                                  </div>
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                      </>
                      :
                      ''
                  }
                  {
                    userLogin === "ROLE_ASESOR" &&  userLoginNav
                      ?
                      <>
                        {/* <NavLink NavLink className='nav-link logeado' to={"/HomeAsesores/dashboard"} > ¡Hola asesor {datosUsuarioLogeado.nombre}! </NavLink> */}
                        <Nav className="justify-content-end navBarResponsiveLinks flex-grow-1 pe-3 ms-auto menu-item-admin"  >
                          <NavLink className='nav-link logeado' style={{border:'0'}} onClick={ScrollToTop} to="/HomeAsesores/dashboard">ASESOR</NavLink>
                          <NavDropdown className='colorDrop' title="" >
                            <NavDropdown.Item >
                                  <div>
                                    <img alt='logoCerrar' src='https://res.cloudinary.com/blackhound/image/upload/v1661188876/BLACKHOUND/NavBar/iconoCerrarSesion_s51myk.webp'/> 
                                  </div>
                                  <div className='navItem' onClick={() => {handleClose(); ScrollToTop(); cerraSession()}}>
                                    Cerrar Sesión
                                  </div>
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                      </>
                      :
                      ''
                  }
                  {
                    userLogin == "ROLE_USER" &&  userLoginNav
                      ?
                      <> 
                        {/* <NavLink NavLink className='nav-link logeado' to={"/HomeUsuarios/perfil"} > ¡Hola {datosUsuarioLogeado.nombre}! </NavLink> */}
                        <NavLink className='nav-link logeadoAsesorUsuario' onClick={() => {handleClose();ScrollToTop()}} to={"/HomeUsuarios/Dashboard"} > Mi perfil </NavLink>
                        <span style={{width: '5px'}}> </span>
                        <button style={{background: "transparent"}} className='cerrar-sesion nav-link' onClick={() => {cerraSession();handleClose()}}>Cerrar Sesión</button>
                        {isMobile ?
                      <div className='containerRedesUser'>
                        <a target="_blank" rel="noreferrer" href='https://www.instagram.com/blackhound.inversiones/'><img width='35px' src='https://res.cloudinary.com/blackhound/image/upload/v1660915398/BLACKHOUND/NavBar/instagram_qv4xaf.svg' /></a>
                        <a target="_blank" rel="noreferrer" href='https://www.facebook.com/Black-Hound-100753315619600'><img width='35px' src='https://res.cloudinary.com/blackhound/image/upload/v1660915400/BLACKHOUND/NavBar/facebook_op0knx.svg' /></a>
                      </div>
                      :
                      <></>
                    }
                      </>
                      :
                      ''
                  }
                </div>
                :
                <>
                  <div className='containerValidation'>
                    <div className='acceso-usuario'>
                      <NavLink className="logearse nav-link" to="/IniciarSesion" onClick={()=>{borrarToken(); ScrollToTop()}}>Iniciar Sesión</NavLink>
                      <NavLink onClick={() => { handleClose() ; ScrollToTop() }} className='registrarse nav-link' to="/Registrarse">Registrate</NavLink>
                    </div>
                    {isMobile ?
                      <div className='containerRedes'>
                        <a target="_blank" rel="noreferrer" href='https://www.instagram.com/blackhound.inversiones/'><img width='35px' src='https://res.cloudinary.com/blackhound/image/upload/v1660915398/BLACKHOUND/NavBar/instagram_qv4xaf.svg' /></a>
                        <a target="_blank" rel="noreferrer" href='https://www.facebook.com/Black-Hound-100753315619600'><img width='35px' src='https://res.cloudinary.com/blackhound/image/upload/v1660915400/BLACKHOUND/NavBar/facebook_op0knx.svg' /></a>
                      </div>
                      :
                      <></>
                    }
                  </div>
                </>
              }
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container> 
        : 
        <Container>
                <Navbar.Brand>
                  <NavLink onClick={ScrollToTop} to="/"><img className='iconNavBar' src={'https://res.cloudinary.com/blackhound/image/upload/v1663159497/BLACKHOUND/NavBar/IconNavBar_mkmpaf.svg'} /></NavLink>
                  {isMobile && userLogin == "ROLE_USER" ?
                  <span>
                    {datosUsuarioLogeado != null ? 
                    <img src={"https://www.blackhound.com.ar/server/public/getImgProfile/" + datosUsuarioLogeado.idUser} />
                    
                    :
                     
                     <img src='https://res.cloudinary.com/blackhound/image/upload/v1660676600/BLACKHOUND/Logprofile_wvmpml.png' />
                    }
                    </span>
                    : 
                    ''
                  }
                  </Navbar.Brand>
                <img className="botonMenuResponsive me-2" src='https://res.cloudinary.com/blackhound/image/upload/v1657897290/BLACKHOUND/menu-bar-01_waarrr_ahtjz7.svg' width={"35px"} variant="primary" onClick={toggleShow} />
                <Navbar.Offcanvas
                  id="offcanvasNavbar"
                  aria-labelledby="offcanvasNavbarLabel"
                  placement="end"
                  show={show}
                  onHide={handleClose}
                >
                  <Offcanvas.Header >
                    <Offcanvas.Title className='offcanvasLogo' id={`offcanvasNavbarLabel-expand-${"md"}`}>
                      <img className="boton-cierre" onClick={handleClose} src={'https://res.cloudinary.com/blackhound/image/upload/v1660933404/BLACKHOUND/NavBar/equis_lg2spj.svg'} />
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body onHide={handleClose}>
                    {userLogin === "ROLE_ADMIN" || userLogin === "ROLE_ASESOR" 
                     ? 
                      userLoginNav != null ? 
                      '' 
                      :
                      <Nav className="justify-content-end navBarResponsiveLinks flex-grow-1 pe-3 ms-auto menu-item"  >
                        <NavLink onClick={() => {handleClose(); ScrollToTop()}} className={({ isActive }) => (isActive ? 'active' : 'nav-link hoverBorde')} to="/Nosotros">Nosotros</NavLink>
                        <NavLink onClick={() => {handleClose(); ScrollToTop()}} className={({ isActive }) => (isActive ? 'active' : 'nav-link hoverBorde')} to="/ComoInvertir">Cómo invertir</NavLink>
                        <NavLink onClick={() => {handleClose(); ScrollToTop()}} className={({ isActive }) => (isActive ? 'active' : 'nav-link hoverBorde')} to="/Proyectos">Proyectos</NavLink>
                        <NavLink onClick={() => {handleClose(); ScrollToTop()}} className={({ isActive }) => (isActive ? 'active' : 'nav-link hoverBorde')} to="/Contacto"> Contacto </NavLink>
                        <NavLink onClick={() => {handleClose(); ScrollToTop()}} className={({ isActive }) => (isActive ? 'active' : 'nav-link hoverBorde')} to="/Faqs"> FAQ's </NavLink>
                      </Nav>
                      :
                      <Nav className="justify-content-end navBarResponsiveLinks flex-grow-1 pe-3 ms-auto menu-item"  >
                        <NavLink onClick={() => {handleClose(); ScrollToTop()}} className={({ isActive }) => (isActive ? 'active' : 'nav-link hoverBorde')} to="/Nosotros">Nosotros</NavLink>
                        <NavLink onClick={() => {handleClose(); ScrollToTop()}} className={({ isActive }) => (isActive ? 'active' : 'nav-link hoverBorde')} to="/ComoInvertir">Cómo invertir</NavLink>
                        <NavLink onClick={() => {handleClose(); ScrollToTop()}} className={({ isActive }) => (isActive ? 'active' : 'nav-link hoverBorde')} to="/Proyectos">Proyectos</NavLink>
                        <NavLink onClick={() => {handleClose(); ScrollToTop()}} className={({ isActive }) => (isActive ? 'active' : 'nav-link hoverBorde')} to="/Contacto"> Contacto </NavLink>
                        <NavLink onClick={() => {handleClose(); ScrollToTop()}} className={({ isActive }) => (isActive ? 'active' : 'nav-link hoverBorde')} to="/Faqs"> FAQ's </NavLink>
                      </Nav>
                    }
                    {datosUsuarioLogeado != null && userLoginNav ?
                      <div className='acceso-usuario-roles'  >
                        {
                          userLogin === "ROLE_ADMIN" &&  userLoginNav
                            ?
                            <>
                              <Nav className="justify-content-end navBarResponsiveLinks flex-grow-1 pe-3 ms-auto menu-item-admin"  >
                                <NavLink className='nav-link logeado' style={{border:'0'}} to="/HomeAdmin/dashboard">ADMINISTRADOR</NavLink>
                                <NavDropdown className='colorDrop' title="" >
                                  <NavDropdown.Item >
                                        <div>
                                          <img alt='logoCerrar' src='https://res.cloudinary.com/blackhound/image/upload/v1661188876/BLACKHOUND/NavBar/iconoCerrarSesion_s51myk.webp'/> 
                                        </div>
                                        <div className='navItem' onClick={() => {handleClose(); ScrollToTop(); cerraSession()}}>
                                          Cerrar Sesión
                                        </div>
                                      </NavDropdown.Item>
                                  </NavDropdown>
                              </Nav>
                            </>
                            :
                            ''
                        }
                        {
                          userLogin === "ROLE_ASESOR" &&  userLoginNav
                            ?
                            <>
                              {/* <NavLink NavLink className='nav-link logeado' to={"/HomeAsesores/dashboard"} > ¡Hola asesor {datosUsuarioLogeado.nombre}! </NavLink> */}
                              <Nav className="justify-content-end navBarResponsiveLinks flex-grow-1 pe-3 ms-auto menu-item-admin"  >
                                <NavLink className='nav-link logeado' style={{border:'0'}} to="/HomeAsesores/dashboard">ASESOR</NavLink>
                                <NavDropdown className='colorDrop' title="" >
                                  <NavDropdown.Item >
                                        <div>
                                          <img alt='logoCerrar' src='https://res.cloudinary.com/blackhound/image/upload/v1661188876/BLACKHOUND/NavBar/iconoCerrarSesion_s51myk.webp'/> 
                                        </div>
                                        <div className='navItem' onClick={() => {handleClose(); ScrollToTop(); cerraSession()}}>
                                          Cerrar Sesión
                                        </div>
                                      </NavDropdown.Item>
                                  </NavDropdown>
                              </Nav>
                            </>
                            :
                            ''
                        }
                        {
                          userLogin == "ROLE_USER" &&  userLoginNav
                            ?
                            <> 
                              {/* <NavLink NavLink className='nav-link logeado' to={"/HomeUsuarios/perfil"} > ¡Hola {datosUsuarioLogeado.nombre}! </NavLink> */}
                              <NavLink className='nav-link logeadoAsesorUsuario' onClick={() => {handleClose();ScrollToTop()}} to={"/HomeUsuarios/Dashboard"} > Mi perfil </NavLink>
                              <span style={{width: '5px'}}> </span>
                              <button style={{background: "transparent"}} className='cerrar-sesion nav-link' onClick={() => {cerraSession();handleClose()}}>Cerrar Sesión</button>
                              {isMobile ?
                            <div className='containerRedesUser'>
                              <a target="_blank" rel="noreferrer" href='https://www.instagram.com/blackhound.inversiones/'><img width='35px' src='https://res.cloudinary.com/blackhound/image/upload/v1660915398/BLACKHOUND/NavBar/instagram_qv4xaf.svg' /></a>
                              <a target="_blank" rel="noreferrer" href='https://www.facebook.com/Black-Hound-100753315619600'><img width='35px' src='https://res.cloudinary.com/blackhound/image/upload/v1660915400/BLACKHOUND/NavBar/facebook_op0knx.svg' /></a>
                            </div>
                            :
                            <></>
                          }
                            </>
                            :
                            ''
                        }
                      </div>
                      :
                      <>
                        <div className='containerValidation'>
                          <div className='acceso-usuario'>
                            <NavLink className="logearse nav-link" to="/IniciarSesion" onClick={()=>{borrarToken(); ScrollToTop()}}>Iniciar Sesión</NavLink>
                            <NavLink onClick={() => { handleClose() ; ScrollToTop() }} className='registrarse nav-link' to="/Registrarse">Registrate</NavLink>
                          </div>
                          {isMobile ?
                            <div className='containerRedes'>
                              <a target="_blank" rel="noreferrer" href='https://www.instagram.com/blackhound.inversiones/'><img width='35px' src='https://res.cloudinary.com/blackhound/image/upload/v1660915398/BLACKHOUND/NavBar/instagram_qv4xaf.svg' /></a>
                              <a target="_blank" rel="noreferrer" href='https://www.facebook.com/Black-Hound-100753315619600'><img width='35px' src='https://res.cloudinary.com/blackhound/image/upload/v1660915400/BLACKHOUND/NavBar/facebook_op0knx.svg' /></a>
                            </div>
                            :
                            <></>
                          }
                        </div>
                      </>
                    }
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
        </Container>
        }
      </Navbar>
    </>
  )
}

