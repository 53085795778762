import { actionTypes } from "../Constantes/ActionTypes"; 

export const getlistaSolicitudesRegistradas = (data) => {
    return {
        type: actionTypes.GET_LISTA_SOLICITUDES_REGISTRADAS,
        data
    }
}

export const setlistaSolicitudesRegistradas = (data) => {
    return {
        type: actionTypes.SET_LISTA_SOLICITUDES_REGISTRADAS,
        data
    }
}



