import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call,put } from'redux-saga/effects';
import {datosUsuario  } from'../api/datosUsuario';
import { setDatosUsuario } from "../Actions/datosUsuario";

export const watchDatosUsuario = function * (){
    yield takeEvery(actionTypes.GET_USUARIO_DATA, fetchDatosUsuario)
}

function* fetchDatosUsuario(action){
    try{
        const data = yield call(datosUsuario, action.data)
        if(data){
            yield put(setDatosUsuario(data))
            //MODAL USUARIO REGISTRADO EXITOSO
        }
    }catch(err){
        console.log(err)
    }
}