import { actionTypes } from "../Constantes/ActionTypes";
import { store } from "../store/index";

function reducerAgregarAsesorSolicitud(state = store.agregarAsesorSolicitud, action) {
    switch (action.type) {
        case actionTypes.SET_ASESOR_A_SOLICITUD:
            let addAsesorSolicitud = action.data
            return Object.assign({}, state, {
                data: addAsesorSolicitud,
            })
        default:
            return state
    }
}

export default reducerAgregarAsesorSolicitud;