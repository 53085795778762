import apiInstance from './api';

export const apiEnviarContrato = (params) => {
    return apiInstance.post('/api/setContratoUser',params,
    { headers:{"Content-Type": "multipart/form-data"}})
    .then(res =>{
            return res.data;
    })

    .catch(error => {
        console.error(error.response);
        throw error.response
    });
}


export const apiIdContrato = (params) => {
    return apiInstance.get('/api/idsContrato/' + params)
    .then(res =>{
            return res.data;
    })

    .catch(error => {
        console.error(error.response);
        throw error.response
    });
}