import React, { useEffect, useState } from "react";
import { getCrearProyecto } from '../../../Actions/crearProyecto';

import { useSelector, useDispatch } from 'react-redux';
import { getListaMonedas } from "../../../Actions/listaMonedas";
import { getCategoriaProyectos } from "../../../Actions/categoriasProyecto";

import './crearproyecto.css'

export default function CrearProyecto() {
  const [datos, setDatos] = useState({
    fecha: "",
    categoria: "",
    tna: "",
    moneda: "Pesos",
    montomin: "",
    plazomin: "",
    color:"#1f1f1f",
    nombre: "Titulo del Proyecto",
    descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam mattis tempus ipsum. Morbi varius hendrerit neque sed vulputate. Nullam nulla sapien, auctor vitae accumsan et, venenatis non metus. Nulla id commodo massa, rutrum venenatis libero. Morbi id metus egestas, congue eros nec, accumsan velit. Nulla luctus porta efficitur. Fusce posuere elit et arcu posuere, sed consequat risus scelerisque. Vivamus nisi quam, cursus non libero  at, consequat commodo nisl. Pellentesque volutpat leo risus, at pharetra lacus mattis accumsan.",
  });

  const dispatch = useDispatch();

  const categorias = useSelector(state => state.reducerCategoriasProyecto.data);
  const listaMonedas = useSelector(state => state.reducerListaMonedas.data);

  useEffect(() => {
    dispatch(getCategoriaProyectos())
  }, []);


  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState("https://res.cloudinary.com/blackhound/image/upload/v1657897325/BLACKHOUND/imagencrearproyecto-01_vuvryh_k4zcjs.png");

  const [mostrarImagenUno, setMostrarImagenUno] = useState();
  const [imagenUno, setImagenUno] = useState("https://res.cloudinary.com/blackhound/image/upload/v1657897325/BLACKHOUND/imagencrearproyecto-01_vuvryh_k4zcjs.png");

  const [mostrarImagenDos, setMostrarImagenDos] = useState();
  const [imagenDos, setImagenDos] = useState("https://res.cloudinary.com/blackhound/image/upload/v1657897325/BLACKHOUND/imagencrearproyecto-01_vuvryh_k4zcjs.png");

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onChangeImagenUno = (e) => {
    if (e.target.files[0]) {
      setMostrarImagenUno(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImagenUno(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };


  const onChangeImagenDos = (e) => {
    if (e.target.files[0]) {
      setMostrarImagenDos(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImagenDos(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };


  const onChangeDatos = (e) => {
    setDatos({
      ...datos,
      [e.target.name]: e.target.value
    });


  };

  function handleCrearProyecto() {
    const nuevoProyecto = {
      fecha: datos.fecha,
      categoria: nombreCategoria,
      tna: datos.tna,
      moneda: nombreMoneda,
      montoMinimo: datos.montomin,
      plazoMinimo: datos.plazomin,
      nombre: datos.nombre,
      descripcion: datos.descripcion,
      color:datos.color,
    }

    let formData = new FormData();
    formData.append("data", JSON.stringify(nuevoProyecto))
    formData.append("imagenProyecto", picture)
    formData.append("imagenUno", mostrarImagenUno)
    formData.append("ImagenDos", mostrarImagenDos)
    dispatch(getCrearProyecto(formData));
  }

  //TIPO DE MONEDA
  if (listaMonedas != null) {
    var monedas = [];
    const pu = listaMonedas.data;
    for (var i = 0; i < pu.length; i++) {
      monedas.push(<option value={pu[i].nombre} > {pu[i].nombre} </option>)
    }
  }

  //TIPO DE CATEGORIA
  if (categorias != null) {
    var tipoCategorias = [];
    const catego = categorias.data;
    for (var i = 0; i < catego.length; i++) {
      tipoCategorias.push(<option value={catego[i].nombre} > {catego[i].nombre} </option>)
    }
  }


  let nombreCategoria = 1;
  let nombreMoneda = 1;
  useEffect(() => {

    if (categorias != null && datos.categoria != null) {
      const post = categorias.categorias;
      for (var i = 0; i < post.length; i++) {
        if (post[i].nombre == datos.categoria) {
          nombreCategoria = post[i].id;
        }
      }
    }

    //TIPO DE MONEDA
    if (listaMonedas != null) {
      const mon = listaMonedas.data;
      for (var i = 0; i < mon.length; i++) {
        if (mon[i].nombre == datos.moneda) {
          nombreMoneda = mon[i].id;
        }
      }
    }
  }, [datos]);


  return (
    <div className="crear-proyecto-container">
      <div className="crear-proyecto-formulario">
        <form>
          <div className="contForm">
            <div className="crear-proyecto-form">
              <div className="crear-proyecto-campo">
                <label >Fecha:</label>
                <input type="date" id="fecha" name="fecha" onChange={onChangeDatos} />
              </div>
              <div className="crear-proyecto-campo">
                <div className="campo-checkbox-categoria">
                  <p>Categoría de proyecto:</p>
                  <select name="categoria" onChange={onChangeDatos} >
                    {tipoCategorias}
                  </select>
                  <div className="campo-categoria-radio">
                    <label>desarrollo</label>
                    <input type="radio" id="1" name="categoria" value="Desarrollos" onChange={onChangeDatos} /><br />
                    <label>constructora</label>
                    <input type="radio" id="2" name="categoria" value="Constructora" onChange={onChangeDatos} /><br />
                    <label>micro - creditos</label>
                    <input type="radio" id="3" name="categoria" value="Micro-Creditos" onChange={onChangeDatos} /><br />
                    <label>agro</label>
                    <input type="radio" id="4" name="categoria" value="Agro" onChange={onChangeDatos} /><br />
                  
                  </div>
                </div>
                <p>Valores:</p>
                <div className="crear-proyecto-campo">
                  <label >TNA</label>
                  <input type="text" id="tna" name="tna" onChange={onChangeDatos} />
                </div>
                <div className="crear-proyecto-campo">
                  <label >Moneda</label>
                  <select name="moneda" onChange={onChangeDatos} >
                    {monedas}
                  </select>
                </div>
                <div className="crear-proyecto-campo">
                  <label > Monto mínimo</label>
                  <input type="text" id="montomin" name="montomin" onChange={onChangeDatos} />
                </div>
                <div className="crear-proyecto-campo">
                  <label > Plazo mínimo</label>
                  <input type="text" id="plazomin" name="plazomin" onChange={onChangeDatos} />
                </div>
              </div>
            </div>
            <div className="contForm2">
              <div className="crear-proyecto-imagen">
                <input className="boton-seleccionar-archivo" id="imagen" type="file" name="portada" onChange={onChangePicture} />
              </div>
              <p className="informacion-proyecto">Información del proyecto</p>
              <div className="crear-proyecto-campo">
                <label >Nombre del proyecto</label>
                <input type="text" id="nombre-asesor" name="nombre" onChange={onChangeDatos} />
              </div>
              <div className="crear-proyecto-campo">
                <label >Descripción del proyecto</label><br />
                <textarea type="text" id="nombre-asesor" name="descripcion" onChange={onChangeDatos} />
              </div>
            </div>
          </div>

        </form>

      </div>

      <div className="proyecto-preview">
        <div className="crear-proyecto-preview">
          <div className="proyecto-imagen "><img src={imgData} /></div>
          <p className="proyecto-titulo">{datos.categoria}</p>
          <div className="proyectos-descripcion">
            <div className="proyectos-descripcion-item">
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897298/BLACKHOUND/tna_nhms4e_iz5fy8.svg" />
              <p className="proyectos-iconos-titulo">TNA</p>
              <p className="proyectos-iconos-desc">{datos.tna}%</p>
            </div>
            <div className="proyectos-descripcion-item">
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1661865313/BLACKHOUND/Proyectos/iconMoneda_hmcbjw.svg" />
              <p className="proyectos-iconos-titulo">Moneda</p>
              <p className="proyectos-iconos-desc">{datos.moneda}$</p>
            </div>
            <div className="proyectos-descripcion-item">
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1661865313/BLACKHOUND/Proyectos/montoMinimo_j0ym0l.svg" />
              <p className="proyectos-iconos-titulo">Monto mínimo </p>
              <p className="proyectos-iconos-desc">{datos.montomin}</p>
            </div>
            <div className="proyectos-descripcion-item">
              <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897305/BLACKHOUND/calendario_bulhhb_fwnlxz.svg" />
              <p className="proyectos-iconos-titulo">Plazo mínimo</p>
              <p className="proyectos-iconos-desc">{datos.plazomin} meses</p>
            </div>
          </div>
        </div>
        <div className="crear-proyecto-preview">
          <p className="proyecto-back-titulo">{datos.nombre}</p>
          <p className="proyecto-back-desc">{datos.descripcion}</p>
          <div className="proyecto-back-img"><img src={imagenUno} /><img src={imagenDos} /></div>
        </div>
      </div>
      <div className="imagenes-adjuntas">
        <div style={{ width: '50%', textAlign: 'center' }}>
          <input type="file" name="imagenUno" onChange={onChangeImagenUno} className="foto-adjunta" />
        </div>
        <div style={{ width: '50%', textAlign: 'center' }}>
          <input type="file" name="imagenDos" onChange={onChangeImagenDos} className="foto-adjunta" />
        </div>
      </div>
      <div className="contButtonSend">
        <input className="boton-cargar-proyecto" onClick={handleCrearProyecto} type="button" value="Cargar Proyecto" />
      </div>
    </div>


  );
};
