import apiInstance from './api';

export const imagenPerfil = (params) => {
    return apiInstance.post('/api/setImgProfile',params,
    { headers:{"Content-Type": "multipart/form-data"}})
    .then(res =>{
            return res.data;
    })

    .catch(error => {
        console.error(error.response);
        throw error.response
    });
}