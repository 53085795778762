import React, { useState, useEffect } from 'react'

import { Button, Modal } from 'rsuite';
import { useSelector, useDispatch } from 'react-redux';
import { enviarContrato } from '../../../../Actions/agregarContrato';
import "./modalDocumentacion.css"

export default function ModalDocumentacion(props) {
    const contrato = props.datosUserContrato;

    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const [backdrop, setBackdrop] = useState('static');
    function handleClose(){
        setShow(false)
        setOpen(false);
        setFileSelectedPrevious(null);
    }
    const dispatch = useDispatch();

    const [FileSelectedPrevious, setFileSelectedPrevious] = useState(null);

    const agregarFileContrato = (e) => {
        if (e.target.files[0]) {
            setFileSelectedPrevious(e.target.files[0]);
        }
    };

    function handleCrearContrato() {
        if (contrato != null) {
            const dataSolicitud = {
                id: contrato.idUser,
                idSolicitud: contrato.idSolicitud,
            }
            let dataContratos = new FormData();
            dataContratos.append("contrato", FileSelectedPrevious)
            dataContratos.append("data", JSON.stringify(dataSolicitud))
            dispatch(enviarContrato(dataContratos));
            setFileSelectedPrevious(null);

            setOpen(false);
            handleShow();
        }
    }

    useEffect(() => {
        if (contrato != null) {
            setOpen(contrato);
        }
    }, [contrato])

    return (
        <>
            {open &&
                <Modal backdrop={backdrop} open={open} className='modal-clientes' size={'lg'} onClose={handleClose}>
                    <Modal.Header className='headerModal'>
                        <Modal.Title className='headerModal'>
                            <div>
                                <h4 style={{ margin: 'auto', color: '#E50913', textAlign: 'center' }}> Cargar documentación</h4>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>                  
                            {FileSelectedPrevious ? 
                             <div className='menssageUnpload' style={{ color: "white" }}>Su archivo se cargo correctamente</div>
                             :
                            <>
                        <div className='containerFiles'>
                                <input
                                    className='inputFile'
                                    type="file"
                                    multiple
                                    onChange={(e) => {
                                        agregarFileContrato(e)
                                    }}
                                />
                                <div className='containerInsideTextFile'>
                                    <div className='insideTextFile'>
                                        <div className='imageFile'>
                                            <img src='https://res.cloudinary.com/blackhound/image/upload/v1661426896/BLACKHOUND/Asesor/Clientes/dowloand_dehcrl.svg' alt='' />
                                            <div className='textFile'>
                                                <h3>Arrastre y suelte su archivo aquí para cargarlo.</h3>
                                            </div>
                                        </div>
                                    </div>
                              </div>
                              </div>
                              </>   
                            }
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='boton-invertir' onClick={handleCrearContrato}>
                            Aceptar
                        </button>
                        <button className='boton-invertir' onClick={handleClose} >
                            Cancelar
                        </button>
                    </Modal.Footer>
                </Modal>
            }

                <Modal centered open={show} onClose={handleClose}
                  backdrop="static"
                >
                  <Modal.Header>
                  <Modal.Body
                      style={{ fontSize: "20px", color: "white", textAlign: "center" }}
                      > Contrato cargado correctamente!
                  </Modal.Body>
                  </Modal.Header>
                  <Modal.Footer className='contFooter'>
                  <button className='buttonModal' onClick={handleClose}> Cerrar </button>
                </Modal.Footer>
                </Modal>
              {/* MODAL */}

        </>
    )
}
