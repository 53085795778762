import { actionTypes } from "../Constantes/ActionTypes"; 

export const getCategoriaProyectos = (data) => {
    return {
        type: actionTypes.GET_CATEGORIA_PROYECTOS,
        data
    }
}

export const setCategoriaProyectos = (data) => {
    return {
        type: actionTypes.SET_CATEGORIA_PROYECTOS,
        data
    }
}
