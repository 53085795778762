import apiInstance from './api';

export const notificacionesUsuario = (params) => {
    return apiInstance.post('/api/listNotificationsUser', params)
    .then(res => {
        return res.data;
    })
    .catch(error => {
        console.error(error.response);
        throw error.response
    });
}