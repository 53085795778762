import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import RangeSlider from "react-bootstrap-range-slider";
import { getEnviarCita } from "../../../../../../Actions/enviarCita";
import { getInversionesUsuario } from "../../../../../../Actions/inversionesUsuario";
import { getEstadisticasUsuario } from "../../../../../../Actions/estadisticasUsuario";
import { Link } from "react-router-dom";
//useDispatch
import { useDispatch } from "react-redux";
//import Styles
import "./solicitudInversion.css";
import ModalGeneric from "../../../../../../Components/ModalGeneric/ModalGeneric";
import { Button } from "rsuite";


export default function SolicitudInversion() {


  const dispatch = useDispatch();
  const location = useLocation();
  const data = JSON.parse(localStorage.getItem("datosFicha"))
  const [value, setValue] = useState(data.montoMinimo);
  const [errorMontoMin, setErrorMontoMin] = useState(false);
  const [errorValue, setErrorValue] = useState(false);
  const [errorHorario, setErrorHorario] = useState(false);
  const [loading, setLoading] = useState()
  
  const [formDatos, setFormDatos] = useState({
    plazoInversion: data.plazo,
    contacto: "3",
    desde: "",
    hasta: "",
  });
  const modal = {
    title: "Su consulta se envió exitosamente",
    content: "Un asesor se pondrá en contacto a la brevedad",
    button: "Aceptar",
    size:"md"
  }
  const [retorno, setRetorno] = useState();
  const idProyecto = data.idProyecto;
  var montoRetorno;
  var tnaMensual;
  var ganancia;
  var gananciaTotal;

  const currencyMask = (e) => {
    if (e != null) {
      let valor = e.toString();
      valor = valor.replace(/\D/g, "");
      valor = valor
        .toString()
        .split("")
        .reverse()
        .join("")
        .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
      valor = valor.split("").reverse().join("").replace(/^[/.]/, "");
      e = valor;
      return e;
    }
  };
  const onChangeFormDatos = (e) =>{
    let name = e.target.name
    let value = e.target.value
    if(name === "plazoInversion" && value.length <= 2 ) {
      setFormDatos({
        ...formDatos,
        [name]: e.target.value,
      })
    } else if (name !== "plazoInversion") {
      setFormDatos({
        ...formDatos,
        [name]: e.target.value,
      })
    }
  }

  useEffect(() => {
    if (formDatos.plazoInversion < parseInt(data.plazo)) {
      setErrorMontoMin(true);
    }
    if (formDatos.plazoInversion <= parseInt(48)) {
      setErrorValue(false);
    }
    if (formDatos.plazoInversion > parseInt(48)) {
      setErrorValue(true);
    }
    if (formDatos.plazoInversion >= parseInt(data.plazo)) {
      setErrorMontoMin(false);
    }
    if (formDatos.plazoInversion != null) {
      tnaMensual = parseInt(data.tna) / 12;
      ganancia = (tnaMensual * parseInt(value)) / 100;
      gananciaTotal =
        ganancia * parseInt(formDatos.plazoInversion) + parseInt(value);
      setRetorno(gananciaTotal);
      // montoRetorno = Math.round((value * from.tna) / 100 + parseInt(value));
      // setRetorno(montoRetorno);
    }
    // /*MULTIPLICAR plazoInversion*/
    // if (formDatos.plazoInversion == "12 meses") {
    //   montoRetorno = Math.round((value * from.tna) / 100 + parseInt(value));
    //   setRetorno(montoRetorno);
    // }
    // if (formDatos.plazoInversion == "18 meses") {
    //   montoRetorno = Math.round(
    //     (value * (parseFloat(from.tna) + parseFloat(from.tna / 2))) / 100 +
    //       parseInt(value)
    //   );
    //   setRetorno(montoRetorno);
    // }
    // if (formDatos.plazoInversion == "24 meses") {
    //   montoRetorno = Math.round(
    //     (value * (parseFloat(from.tna) + parseFloat(from.tna))) / 100 +
    //       parseInt(value)
    //   );
    //   setRetorno(montoRetorno);
    // }
  }, [value, formDatos]);
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    mode: "onChange"
  });
  const onSubmit = (data) => {
    const franjaHoraria =
      " desde " + data.desde + " " + "hasta " + data.hasta;
    const nuevaSolicitud = {
      fecha: null,
      franja: franjaHoraria,
      contacto: formDatos.contacto,
      montoInvertido: value,
      idProyecto: idProyecto,
      plazoInversion: formDatos.plazoInversion,
    };
    dispatch(getEnviarCita(nuevaSolicitud));
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      handleOpen();
      dispatch(getInversionesUsuario());
    }, 1500);
  };


  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const navigate = useNavigate()

  const goTo = () => {
    {
      navigate("/HomeUsuarios/Dashboard")
      ScrollToTop()
    }
  }

  return (
    <div className="solicitud-inversion-container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="containerSectionUserTitle">
          <h5 className="seccion-usuario-titulo">SOLICITUD DE INVERSIÓN</h5>
        </div>
        <div
          style={{ backgroundColor: data.color }}
          className={"header-imagen-solicitud"}
        >
          <img
            src={
              "https://www.blackhound.com.ar/server/public/getImgId/" +
              data.idImagenUno
            }
          />
        </div>
        <div className="solicitud-calculator-container">
          <div className="solicitud-calculator-inner-container">
            <div className="separacion-text">
              <p>
                Realizá tu solicitud de inversión y uno de nuestros asesores se
                pondrá en contacto
              </p>
            </div>
            <div className="calculador-capital">
              <span>Inversión: {data.moneda} </span>
              <span className="rango-filtro">{currencyMask(value)}</span>
            </div>
            <div className="calculador-slider">
              <RangeSlider
                defaultValue={0}
                type="range"
                className="slider-filtro"
                step={1000}
                min={data.montoMinimo}
                max={1000000}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            {/* Esto deberia se dinamico */}
            <p>Ingrese la cantidad de meses que desea invertir (Plazo mínimo de inversion {data.plazo} meses)</p>
            <div className="botones-cuotas">
              <input
              type="number"
              maxLength="2"
              max={48}
              name="plazoInversion"
              onChange={(e)=>{onChangeFormDatos(e)}}
              value={formDatos.plazoInversion}
              />
              <p>Meses</p>
              {/* <input
              defaultChecked
              id="12meses"
              type="radio"
              name="plazoInversion"
              value="12 meses"
              onChange={onChangeFormDatos}
            />
            <label for="12meses">12 meses</label>
            <input
              id="18meses"
              type="radio"
              name="plazoInversion"
              value="18 meses"
              onChange={onChangeFormDatos}
            />
            <label for="18meses">18 meses</label>
            <input
              id="24meses"
              type="radio"
              name="plazoInversion"
              value="24 meses"
              onChange={onChangeFormDatos}
            />
            <label for="24meses">24 meses</label> */}
            </div>
            {errorValue && (
              <p className="error">
                El maximo plazo de inversión no debe ser mayor a 48 meses.
              </p>
            )}
            {errorMontoMin && (
              <p className="error">
                El mes ingresado no supera el plazo mínimo de inversión
              </p>
            )}
            <p className="retorno-minimo-asegurado">
              RETORNO MÍNIMO ASEGURADO*:
              <span>
                {data.moneda} {currencyMask(Math.round(retorno))}
              </span>
            </p>
            <div className="referencia-box">
              <p>* Retorno calculado en base a la tasa fija.</p>
              <p>
                ** Rendimiento variable aplica para inversiones a partir de los 12
                meses.
              </p>
            </div>
            <div className="section-contacto">
              <div className="section-contacto-column">
                <div>
                  <p>Modalidad</p>
                </div>
                <div className="section-contacto-fila botones-cuotas">
                  <input
                    defaultChecked
                    id="tipoLlamada"
                    type="radio"
                    name="contacto"
                    value="3"
                    onChange={onChangeFormDatos}
                  />
                  <label for="tipoLlamada">Llamada</label>
                  <input
                    id="tipoMail"
                    type="radio"
                    name="contacto"
                    value="1"
                    onChange={onChangeFormDatos}
                  />
                  <label for="tipoMail">Mail</label>
                </div>
              </div>
              <div className="section-contacto-column">
                <div>
                  <p>Preferencia horaria</p>
                </div>
                <div className="section-contacto-fila">
                  <p>Desde</p>
                  <input type="text" maxLength="2" {...register("desde", { required: true, max: 32, min: 0, pattern: /(?=.*?[0-9])/g, maxLength: 3 })} />
                  <p>Hasta</p>
                  <input type="text" maxLength="2" {...register("hasta", { required: true, max: 32, min: 0, pattern: /(?=.*?[0-9])/g, maxLength: 3 })} />
                  <p>hs.</p>
                </div>
                <div className="section-contacto-fila">
                  {errors?.desde?.type === "required" && <p className='error'>Campo requerido</p>}
                  {errors?.desde?.type === "max" && <p className='error'>La hora no debe ser mayor a 24</p>}
                  {errors?.desde?.type === "pattern" && (<p className='error'>Solo caracteres numericos</p>)}

                  {errors?.hasta?.type === "pattern" && (<p className='error'>Solo caracteres numericos</p>)}
                  {errors?.hasta?.type === "required" && <p className='error'>Campo requerido</p>}
                  {errors?.hasta?.type === "max" && <p className='error'>La hora no debe ser mayor a 24</p>}
                </div>
              </div>
            </div>
            <Button loading={loading} type="submit" className="boton-invertir">Solicitar Asesoramiento</Button>
          </div>
        </div>
        <ModalGeneric
          open={open}
          setOpen={setOpen}
          title={modal.title}
          content={modal.content}
          button={modal.button}
          goTo={goTo}
          size={modal.size}
        />
      </form>
    </div>
  );
}