import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';
import { enviarDocumentos } from'../api/enviarDocumentos';
// import {  toast } from 'react-toastify';
import { getDatosUsuario } from "../Actions/datosUsuario";


export const watchEnviarDocumentos = function * (){
    yield takeEvery(actionTypes.ENVIAR_DOCUMENTOS_USUARIO, fetchEnviarDocumentos)
}

function* fetchEnviarDocumentos(action){
    try{
        const data = yield call(enviarDocumentos, action.data)
        if(data){
            // toast.success('Documentos cargados correctamente', {
            // });
            yield put(getDatosUsuario())

        }
    }catch(err){
        console.log(err)
        // toast.error('Error al cargar documentos,', {     
        // }); 
    }
}

