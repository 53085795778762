import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';
import { apiAllConversacion } from "../api/allConversaciones";
import { setAllConversacionesAsesor } from "../Actions/obtenerChat";
import { cantidadMensajes } from "../Actions/obtenerChat";
import { allCantidadMensajes } from "../Actions/obtenerChat";

export const watchAllConversaciones = function * (){
    yield takeEvery(actionTypes.TODOS_LAS_CONVERSACIONES, fetchConversaciones)
}

function* fetchConversaciones(action){
    try{
        const data = yield call(apiAllConversacion, action.data)
        if(data){
            yield put(setAllConversacionesAsesor(data))
            yield put(allCantidadMensajes(data))
        }
    }catch(err){
        console.log(err)
    }
}