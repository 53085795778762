import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Modal from 'react-bootstrap/Modal'
import Button from 'rsuite/Button';
import './registrarse.css'
//Actions
import { setValidarUserRegistro } from '../../Actions/ValidarUerRegistro';
import { getRegistro } from '../../Actions/registroUser';

export default function Registrarse() {
    const [show, setShow] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [dniRegistrado, setDniRegistrado] = useState(null);
    const [mailRegistrado, setMailRegistrado] = useState(null);
    const validarDocumentacion = useSelector(state => state.reducerValidarUserRegistro.data);
    const handleClose = () => setShow(false);

    useEffect(() => {
        if (validarDocumentacion != null && validarDocumentacion.message === "Dni ya registrado") {
            setDniRegistrado(true);
        }
        if (validarDocumentacion != null && validarDocumentacion.message === "El Usuario y Dni ya existen ") {
            setDniRegistrado(true);
            setMailRegistrado(true);
        }
        if (validarDocumentacion != null && validarDocumentacion.message === "El Usuario ya existe ") {
            setMailRegistrado(true);
        } 
        if (validarDocumentacion != null && validarDocumentacion.message === "Usuario creado") {
            setTimeout(() => {
               setLoadingButton(false);
                return (navegarRegistro('/HomeUsuarios/Dashboard'))
            }, 1600);
       } else {
           setLoadingButton(false);
       }
       
    }, [validarDocumentacion]);

    function verificarDni() {
        if (dniRegistrado === true) {
            setDniRegistrado(null);
        }
    }

    function verificarMail() {
        if (mailRegistrado === true) {
            setMailRegistrado(null);
        }
    }

    const dispatch = useDispatch();
    // ENVIAR FORMULARIO//
    const {
        register,
        formState: { errors },
        getValues,
        handleSubmit
    } = useForm({
        reValidateMode: "onChange"
    });
    
    const navegarRegistro = useNavigate();
 
    const onSubmit = (data) => {
        setLoadingButton(true);
        dispatch(getRegistro(data));
    }

    const pressEnter = (e) => {
        if (e.key === 'Enter') {
            onSubmit();
        }
    };

    const ScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }
    return (
        <>      
            <div className='registrarse-header'  >
                <div className='registrarse-header-text-container' data-aos="zoom-in" data-aos-duration="1000">
                    <div className='registrarse-header-text-container-tittle' >
                        {/* <span className='nosotros-barra'> </span> */}
                        <h5 className='nosotros-titulo' > REGISTRATE </h5>
                    </div>
                    <p className='forma-parte' >Formá parte de la experiencia Black.<br />Empezá ya a invertir en tu futuro.</p>
                </div>
            </div>
            <img className='markLeftRegister' src='https://res.cloudinary.com/blackhound/image/upload/v1660243064/BLACKHOUND/Mask_group_ur2p0o.png' alt='' />
            <img className='markRight' src='https://res.cloudinary.com/blackhound/image/upload/v1660917252/BLACKHOUND/gotitadeaguaVistas_v2zou6.png' alt='' />
            <div className='registrarse-container' data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="300">
                <div className='registrarse-info'>
                    <div className='registrarse-info-container'>
                        <div className='formulario-registrarse-container'>
                            <form className='formulario-registrarse' onKeyPress={(e) => pressEnter(e)} onSubmit={handleSubmit(onSubmit)}>
                                <div className='formulario-registrarse-titulo'>
                                    <img className='punto-rojo punto-rojo-registrarse' src='https://res.cloudinary.com/blackhound/image/upload/v1660847734/BLACKHOUND/rectangulo_vp1ora.svg' />
                                    <p className='completa-registro'>Completá tus datos</p>
                                </div>
                                <div className='fomulario-campo-registrarse'>
                                    <label>Nombre</label>
                                    <input
                                        autoComplete='off'
                                        //value={registro.nombre}   
                                        {...register("nombre", {
                                            required: true,
                                            maxLength: 20,
                                            pattern: /^[a-zA-Z\s]*$/i
                                        })}
                                    // onChange={handleRegistro}
                                    />

                                </div>
                                {errors?.nombre?.type === "required" && <p className='error'>Este campo es requerido</p>}
                                {errors?.nombre?.type === "maxLength" && (
                                    <p className='error'>El nombre no puede exceder los 20 caracteres</p>
                                )}
                                {errors?.nombre?.type === "pattern" && (
                                    <p className='error'>Solo caracteres alfabéticos</p>
                                )}
                                <div className='fomulario-campo-registrarse'>
                                    <label>Apellido</label>
                                    <input
                                        autoComplete='off'
                                        // value={registro.apellido}  
                                        {...register("apellido",
                                            {
                                                required: true,
                                                pattern: /^[a-zA-Z\s]*$/i
                                            })}
                                    //onChange={handleRegistro} 
                                    />
                                </div>
                                {errors?.apellido?.type === "required" && <p className='error'>Este campo es requerido</p>}
                                {errors?.apellido?.type === "pattern" && (
                                    <p className='error'>Solo caracteres alfabéticos</p>)}
                                <div className='fomulario-campo-registrarse'>
                                    <label>Documento</label>
                                    <input
                                        onWheel={(e) => e.target.blur()}
                                        type='number'
                                        autoComplete='off'
                                        //value={registro.documento}  
                                        {...register("dni", {
                                            onChange: () => verificarDni(),
                                            required: true,
                                            maxLength: 8,
                                            minLength: 8,
                                        })}
                                    //onChange={handleRegistro}
                                    />
                                </div>
                                {dniRegistrado && <p className='error'>El documento ya está registrado</p>}
                                {errors?.dni?.type === "required" && <p className='error'>Este campo es requerido</p>}
                                {errors?.dni?.type === "maxLength" && (
                                    <p className='error'>El documento debe contener 8 numeros</p>
                                )}
                                {errors?.dni?.type === "minLength" && (
                                    <p className='error'>El documento debe contener 8 numeros</p>
                                )}
                                <div className='fomulario-campo-registrarse'>
                                    <label>Teléfono</label>
                                    <input
                                        onWheel={(e) => e.target.blur()}
                                        type='number'
                                        autoComplete='off'
                                        //value={registro.telefono}    
                                        {...register("telefono", {
                                            required: true,
                                            maxLength:15

                                        })}
                                    //onChange={handleRegistro}
                                    />

                                </div>
                                {errors?.telefono?.type === "pattern" && (
                                    <p className='error'>Solo caracteres numericos</p>
                                )}
                                {errors.telefono?.type === 'maxLength' && <p className='error'>Máximo 15 carácteres.</p>}
                                <div className='fomulario-campo-registrarse'>
                                    <label>Correo&nbsp;electrónico</label>
                                    <input
                                        autoComplete='off'
                                        type="text"
                                        //value={registro.email}  
                                        {...register("email", {
                                            onChange: () => verificarMail(),
                                            required: true,
                                            pattern: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
                                        })}
                                    //onChange={handleRegistro} 
                                    />

                                </div>
                                {mailRegistrado && <p className='error'>El mail ya está registrado</p>}
                                {errors.email?.type === "required" && <p className='error'>Este campo es requerido</p>}
                                {errors.email?.type === "pattern" && (
                                    <p className='error'>Formato de mail incorrecto</p>
                                )}
                                <div className='fomulario-campo-registrarse'>
                                    <label>Contraseña </label>
                                    <input
                                        autoComplete='off'
                                        type="password"
                                        //value={registro.password}    
                                        {...register("password", { required: true, pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/g, })}
                                    // onChange={handleRegistro}
                                    />
                                </div>
                                {errors.password?.type === "required" && <p className='error'>Este campo es requerido</p>}
                                {errors?.password?.type === "pattern" && (
                                    <p className='error'>La contraseña debe tener al menos una mayúscula, una minúscula, un número y un mínimo de 8 caracteres</p>
                                )}



                                <div className='fomulario-campo-registrarse'>
                                    <label>Repetir&nbsp;Contraseña </label>
                                    <input
                                        autoComplete='off'
                                        type="password"
                                        //value={registro.passwordConfirmation}   
                                        {...register("passwordConfirmation", {
                                            required: true, pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/g, validate: {
                                                matchesPreviousPassword: (value) => {
                                                    const { password } = getValues();
                                                    return password === value || "Las contraseñas no coinciden";
                                                }
                                            }
                                        })}
                                    />
                                </div>
                                {errors.passwordConfirmation?.type === "required" && <p className='error'>Confirmar contraseña para continuar</p>}
                                {errors?.passwordConfirmation?.type === "pattern" && (
                                    <p className='error'>La contraseña debe tener al menos una mayúscula, una minúscula, un número y un mínimo de 8 caracteres</p>
                                )}
                                <div className='formulario-terminos'>
                                    <input
                                        autoComplete='off'
                                        type="checkbox" placeholder="terms" {...register("terms", { required: true })} />
                                    <p className='terms-text'>Haz clic aquí para aceptar nuestros. <span onClick={() => setShow(true)}>Términos y condiciones.</span></p></div>
                                {errors?.terms?.type === "required" && (
                                    <p className='error'>Debe aceptar los términos y condiciones para continuar</p>
                                )}
                                <Button type="submit" onClick={ScrollToTop} loading={loadingButton} className={"form-login-enviar"}>CREAR CUENTA</Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                backdrop="static"
                keyboard={false}
                size="lg"
                className='modal-terminos-y-condiciones'
                scrollable
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <p className='modal-terminos-title'>Política de uso de datos y declaración de privacidad</p>
                    <p className='modal-terminos-title-fecha'>Última revisión: 1/6/22</p>
                    <p className='modal-terminos-sub'> 1. Información del prestador</p>
                    <p>Black Hound es un servicio de inversiones perteneciente a Grupo Delsud Administración<br />
                        Financiera e Inmobiliaria SRL, CUIT 30-71616529-5, con sede en Av. 7 N°840, de la ciudad de La<br />
                        Plata, Provincia de Buenos Aires, República Argentina.</p>
                    <p className='modal-terminos-sub'> 2. Qué información recopilamos</p>
                    <p>Recopilamos los datos informados por el usuario como nombre completo, fecha de
                        nacimiento, número de documento, dirección de correo electrónico y teléfono de contacto.
                        Además, podemos solicitar una imagen del documento nacional de identidad frente y dorso,
                        con objeto de constatar su identidad. También recopilamos datos sobre acceso e interacción
                        con la plataforma, como dirección IP, características del navegador y dispositivo, URL de
                        referencia, entre otras. También usamos la información obtenida a través de cookies para
                        analizar las búsquedas realizadas, mejorar nuestras iniciativas comerciales y promocionales,
                        mostrar publicidad o promociones, banners de interés, noticias sobre Black Hound o las
                        empresas del Grupo, perfeccionar y personalizar nuestra oferta de contenidos y artículos,
                        presentación y servicios, así como promover y hacer cumplir las reglas y seguridad del sitio;
                        también las utilizamos para que el usuario no tenga que introducir su clave tan
                        frecuentemente durante una sesión de navegación, también para contabilizar y corroborar las
                        inscripciones, la actividad del usuario y otros conceptos para acuerdos comerciales, siempre
                        teniendo como objetivo de la instalación de las cookies, el beneficio del usuario que la recibe,
                        y las cuales no serán usadas con fines ajenos a Black Hound. Asimismo, almacenamos cookies
                        para poder ofrecer una experiencia más interactiva en el sitio, basada en las acciones del
                        usuario. La plataforma no pide, bajo ninguna circunstancia, número de tarjetas de
                        débito/crédito, cuentas bancarias, claves de acceso, ni cualquier otro tipo de información
                        financiera y/o personal sensible para el usuario.</p>
                    <p className='modal-terminos-sub'> 3. Cómo usamos la información</p>
                    <p>La información recopilada es exclusivamente para uso interno de Black Hound, Grupo Delsud y
                        sus empresas asociadas, con el fin de establecer contacto con los clientes interesados,
                        mantener relación e identificar a los mismos, optimizar la interactividad del sitio, conocer las
                        preferencias de los usuarios, identificar la pertinencia de los productos ofrecidos y generar
                        acciones de promoción en base a las preferencias del usuario.</p>
                    <p className='modal-terminos-sub'> 4. Información compartida</p>
                    <p>El resguardo de la privacidad de los usuarios es muy importante para Black Hound, Grupo
                        Delsud y empresas asociadas. Por ello, no vendemos ni comercializamos información que
                        identifique a nuestros usuarios. Tampoco compartimos o transferimos de ningún otro modo su
                        información personal a terceros, excepto con: a) “Grupo Delsud”: sociedades que pertenezcan
                        a nuestro grupo corporativo, que operan bajo nuestros mismos procesos y políticas internas,
                        ya sean sociedades controladas, controlantes o afiliadas con Grupo Delsud, para cumplir con
                        nuestra normativa interna, prevenir fraudes, gestionar riesgos y facilitar la gestión de los
                        servicios y productos de Grupo Delsud; b) “Autoridades públicas”: las autoridades
                        administrativas y judiciales que en ejercicio de su competencia requieran información, aunque
                        no exista una orden ni una citación ejecutiva o judicial al efecto, con la finalidades de: (i)
                        colaborar en la investigación y denunciar fraudes, piratería, violaciones de propiedad
                        intelectual o industrial o cualquier otro ilícito, así como cualquier actividad o circunstancia que
                        pudiera generarle responsabilidad legal a Grupo Delsud y/o a sus usuarios; (ii) salvaguardar de
                        un interés público, la procuración o administración de justicia, el reconocimiento, ejercicio o
                        defensa de un derecho en un proceso judicial o administrativo, y/o la resolución de
                        controversias; y (iii) dar cumplimiento a alguna ley, reglamento o disposición legal aplicable, o
                        a algún mandato de autoridad competente debidamente fundado y motivado; c)
                        "Intervinientes en Disputas”: autoridades, amigables componedores, tribunales o entidades
                        que intervengan en solución de disputas con la finalidad de resolver las controversias que se
                        llegaran a suscitar entre usuarios o entre éstos y cualquiera de las empresas de Grupo Delsud
                        y/o sus asociadas; d) “Entidades públicas y/o privadas que prestan servicios de información
                        crediticia”: Black Hound y/o Grupo Delsud podrá, siempre que la legislación aplicable así lo
                        permita, compartir con entidades públicas o privadas que brindan servicios de información o
                        riesgo crediticio, información vinculada al comportamiento crediticio o al cumplimiento o
                        incumplimiento de obligaciones de contenido patrimonial del usuario.<br /><br />
                        Asimismo, Black Hound, Grupo Delsud y/o sus asociadas podrá divulgar información personal
                        discrecionalmente a otros usuarios de los Sitios Web y/o los Servicios, entidades o terceros
                        cuando haya motivos suficientes para considerar que la actividad del usuario sea sospechosa
                        de intentar o cometer un delito o intentar perjudicar a otras personas.<br /><br />
                        Asimismo, el usuario presta su consentimiento expreso e informado para que Black Hound y/o
                        Grupo Delsud ceda, transmita o transfiera su información personal a los destinatarios
                        detallados en esta Declaración de Privacidad. Black Hound y/o Grupo Delsud no será
                        responsable por el uso indebido de la información personal de sus usuarios que haga cualquier
                        tercero cuando sean estos terceros quienes directamente recolecten y/o traten su información
                        personal.</p>
                    <p className='modal-terminos-sub'> 5. Negación, error y eliminación de la información</p>
                    <p>En caso de que el usuario no proporcione alguno de los datos requeridos en el formulario
                        habilitado para la creación de una cuenta en la plataforma, no podrá concretar exitosamente
                        el registro. En caso de error o modificación de los datos proporcionados, el usuario tiene la
                        facultad de editar lo registrado desde la configuración de su perfil. El usuario está facultado de
                        eliminar su cuenta en cualquier momento sin previa notificación, debiendo posteriormente
                        Black Hound eliminar de su base los datos registrados del usuario dado de baja. </p>
                    <p className='modal-terminos-sub'> 6. Cookies y tecnologías de seguimiento</p>
                    <p>El usuario reconoce y acepta expresamente que Black Hound podrá utilizar un sistema de
                        seguimiento de conducta mediante la utilización de "cookies" y/u otras tecnologías similares
                        de seguimiento. Estas tecnologías se utilizan con el fin de conocer los intereses y el
                        comportamiento de quienes visitan o son usuarios de nuestro sitio web y, de esa forma, darles
                        un mejor servicio o proveerles información relacionada. Tus datos personales obtenidos a
                        través de estas tecnologías no serán transferidos a terceros de una manera diferente a las
                        descritas en esta Declaración de Privacidad. Adicionalmente, se pueden encontrar "cookies" u
                        otros sistemas similares instalados por terceros en ciertas páginas de nuestros sitios web o
                        utilizados por anunciantes ajenos a Black Hound. Tener en cuenta que la instalación,
                        permanencia y existencia de las cookies en tu computadora o dispositivo depende de la
                        exclusiva voluntad del usuario y pueden ser eliminadas cuando así lo desee. Se puede
                        configurar las preferencias de cookies desde la sección de Privacidad o desde tu navegador.
                    </p>
                    <p className='modal-terminos-sub'> 7. Actualización de términos</p>
                    <p>Con motivo de mejorar la calidad de los servicios ofrecidos, Black Hound y/o Grupo Delsud
                        puede modificar estas condiciones. En caso de representar un cambio sustancial en su política
                        de tratamiento de datos, el mismo será notificado por correo electrónico y/o por los canales
                        habituales a todos los usuarios.</p>
                    <p className='modal-terminos-sub'> 8. Responsabilidad del usuario</p>
                    <p>a) Usar la plataforma conforme a su finalidad
                        b) Mantener en reserva la clave de acceso. El Grupo no se hace responsable por los
                        perjuicios que pueda causar la operación de una cuenta por un tercero a partir del
                        conocimiento de la clave de acceso
                        c) Mantener actualizados los datos personales. El usuario no sólo debe consignar con
                        precisión los datos personales al momento de la registración, sino también debe
                        rectificarlos cuando se produzcan modificaciones
                        d) Intercambiar o compartir información de acuerdo a las pautas estipuladas por el
                        operador.
                        e) Comunicarse inmediatamente con el Grupo, por los canales indicados, ante actividad
                        inusual o sospechosa en su cuenta.
                    </p>
                    <p className='modal-terminos-sub'> 9. Plazo de almacenamiento de información</p>
                    <p>Solo almacenaremos la información personal durante el tiempo necesario para cumplir con el
                        propósito para el que se ha recopilado, para cumplir con requisitos reglamentarios o legales, o
                        durante el periodo de prescripción legal de posibles responsabilidades legales o contractuales.
                        Una vez concluido el lapso, los datos serán eliminados o anonimizados de manera tal que no
                        pueda ser individualizada ninguna persona, según lo permita la normativa vigente.
                    </p>
                    <p className='modal-terminos-sub'> 10. Contacto</p>
                    <p>Los usuarios pueden comunicarse en todo momento con la empresa a través del correo
                        electrónico <a href="mailto:info@blackhound.com.ar">info@blackhound.com.ar.</a></p>
                    <p className='modal-terminos-sub'> 11. Jurisdicción y ley aplicable</p>
                    <p>Esta Declaración de Privacidad se regirá por las leyes de la República Argentina. Ante cualquier
                        controversia o divergencia relacionada con la interpretación, validez, celebración o
                        cumplimiento de las mismas, el usuario y Black Hound declaran que se someten a la
                        jurisdicción exclusiva de los Tribunales Ordinarios de La Plata, Pcia de Bs As, renunciando
                        expresamente a cualquier otro fuero y/o jurisdicción que pudiera corresponderles.</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className='boton-invertir' onClick={handleClose} appearance="primary">
                        Cerrar
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
