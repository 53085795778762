import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//ACTION
import { setEstadoCliente } from '../../../../Actions/estadoCliente';
import { getlistaClientesRegistrados } from '../../../../Actions/listaClientesRegistrados';
import { Modal } from 'rsuite';
import './modaContrato.css'

const ModalVerContratos = (props) => {
    const data3 = props.dataModalVerContratos;
    const [open, setOpen] = useState(data3 != null);
    const [backdrop, setBackdrop] = useState('static');
    const handleClose = () => setOpen(false);
    const dispatch = useDispatch();

    const [activePage, setActivePage] = useState(1)
    const [estadoFiltro, setEstadoFiltro] = useState(null)
    const [busqueda, setBusqueda] = useState({
        dataBase: ""
    })

    useEffect(() => {
        if (data3 != null) {
            setOpen(data3);
        }
    }, [data3])

    const listaContratos = useSelector(state => state.reducerGuardarIdContrato.data);

    if (listaContratos != null) {
        var listaContratosArray = [];
        if (Array.isArray(listaContratos.data) && listaContratos.data.length > 0) {
            for (var i = 0; i < listaContratos.data.length; i++) {
                listaContratosArray.push(<div className='contratos-user'>
                  <div className='container-contratos'>
                    {listaContratos.data[i].idProyecto == 34 ? (
                        <img
                          className="proyecto-tamaño-contrato"
                          src="https://res.cloudinary.com/blackhound/image/upload/v1659525488/BLACKHOUND/desarrollos2_jw19om.svg"
                          alt="desarrollos"
                        />
                      ) : (
                        ""
                      )}
                      {listaContratos.data[i].idProyecto == 32 ? (
                        <img
                          className="proyecto-tamaño-contrato"
                          src="https://res.cloudinary.com/blackhound/image/upload/v1659540935/BLACKHOUND/elemental_oohxsp.svg"
                          alt="Elemental Constructora"
                        />
                      ) : (
                        ""
                      )}
                      {listaContratos.data[i].idProyecto == 31 ? (
                        <img
                          className="proyecto-tamaño-contrato"
                          src="https://res.cloudinary.com/blackhound/image/upload/v1659541685/BLACKHOUND/eugenie_jljwc2.svg"
                          alt="Microcréditos"
                        />
                      ) : (
                        ""
                      )}
                      {listaContratos.data[i].idProyecto == 30 ? (
                        <img
                          className="proyecto-tamaño-contrato"
                          src="https://res.cloudinary.com/blackhound/image/upload/v1659541779/BLACKHOUND/petricor_yutbqr.svg"
                          alt="Petricor Agrícola"
                        />
                      ) : (
                        ""
                      )}
                        {/* <img src={"https://www.blackhound.com.ar/server/public/getImgId/" + listaContratos.data[i].idProyecto} />
                        <p>Documentacion.pdf</p> */}
                    </div>
                    <div className='contratos-user-buttons'>
                        {/* <a target="_blank" href={"https://www.blackhound.com.ar/server/public/getDocumentacionProyectoUser/" + listaContratos.data[i].id }  >Ver</a> */}
                        <a href={"https://www.blackhound.com.ar/server/public/getDocumentacionProyectoUser/" + listaContratos.data[i].id} download>Descargar</a>
                    </div>

                </div>)
            }
        } else {
            listaContratosArray.push(      
                <h4 style={{ margin: 'auto', color: 'white', textAlign: 'center', fontSize:'18px' }}> No hay documentos disponibles </h4>
            )
        }
    }

    return (
        <>
            {(open) &&
                <div className='modalEnvioDoc'>
                    <Modal className='proyecto-modal infoModal' backdrop={backdrop} open={open} size="md" onClose={handleClose} >
                        <Modal.Header className='headerModal'>
                            <Modal.Title className='headerModal'>
                                <div>
                                    <h4 style={{ margin: 'auto', color: 'white', textAlign: 'center', fontSize:"25px" }}> Ver contratos </h4>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {listaContratosArray}
                        </Modal.Body>
                        <Modal.Footer className='contFooter'>
                            <button className='buttonModal' onClick={handleClose}> Cerrar </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            }
        </>
    );
}

export default ModalVerContratos;
