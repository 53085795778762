import React from 'react'
import "../FAQs/faqs.css"
import {Loader} from "rsuite"
import { useSpring, animated } from 'react-spring';
import { useState } from 'react';
export default function Glosario() {
    
    const [loading, setLoading] = useState(true); {
        setTimeout(() => {
          setLoading(false);
        }, 300);
      }
    
      const fadeOut = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        delay: 300,
    }) 

    return (
        <>
        {loading ?
        <div className='loading'>
            <Loader className='loadingColor' size="lg" />
        </div>
        :
        <animated.div style={fadeOut}>
        <div className='containerManualDeVentas'>
            <div>
                <h5 className='user-wellcome'> MANUAL DE VENTA / GLOSARIO </h5>
            </div>
            <div className='containerSubTitle'>
                <div className="categoria-titulo2">
                    <div className="circle-categoria"> </div>
                    <div className="proyecto-titulo-dash">A</div>
                </div>
                <div className='download-manual'>
                    <img src='https://res.cloudinary.com/blackhound/image/upload/v1661273493/BLACKHOUND/Asesor/Faqs/Dowloand_ftqge0.svg' alt=''/>
                </div>
            </div>
            <div className='containerFaqs'>
                <div className='content-faqs'>
                    <h5> Ahorro</h5>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                        nisi ut aliquip ex ea commodo consequat.
                    </p>
                </div>

                <div className='content-faqs'>
                    <h5> Ahorro</h5>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                        nisi ut aliquip ex ea commodo consequat.
                    </p>
                </div>

                <div className='content-faqs'>
                    <h5> Ahorro</h5>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                        nisi ut aliquip ex ea commodo consequat.
                    </p>
                </div>
                
                <div className='content-faqs'>
                    <h5> Ahorro</h5>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                        nisi ut aliquip ex ea commodo consequat.
                    </p>
                </div>
            </div>
        </div>    
        </animated.div>
        }
        </>
    )
}
