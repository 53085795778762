import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call,put } from'redux-saga/effects';

import { apiEstadoMensajes } from "../api/estadoMensaje";

export const watchEstadoMensaje = function * (){
    yield takeEvery(actionTypes.ESTADO_DE_MENSAJES, fetchEstadoMensaje)
}

function* fetchEstadoMensaje(action){
    try{
        const data = yield call(apiEstadoMensajes, action.data)
        if(data){
            //yield put(cambiarEstadoNotificaciones(data))
        }
    }catch(err){
        console.log(err)
    }
}