import { actionTypes } from "../Constantes/ActionTypes"; 

export const cancelarTurnoUser = (data) => {
    return {
        type: actionTypes.CANCELAR_TURNO,
        data
    }
}



