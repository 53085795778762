import { actionTypes } from "../Constantes/ActionTypes"; 

export const getlistaClientesRegistrados = (data) => {
    return {
        type: actionTypes.GET_LISTA_CLIENTES_REGISTRADOS,
        data
    }
}

export const setlistaClientesRegistrados = (data) => {
    return {
        type: actionTypes.SET_LISTA_CLIENTES_REGISTRADOS,
        data
    }
}

