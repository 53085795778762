import React, { useEffect, useState } from "react";
import { Panel, PanelGroup, Placeholder, Button } from "rsuite";
import { useSelector, useDispatch } from "react-redux";
import { getMostrarProyecto } from "../../../../Actions/mostrarProyectos";
import ContenidoAcordion from "./ContenidoAcordion/ContenidoAcordion";
import ListaSolicitudesAprobadas from "./ListaSolicitudesAprobadas/ListaSolicitudesAprobadas";
import ListaSolicitudesPendientes from "./ListaSolicitudesPendientes/ListaSolicitudesPendientes";
import { postEnviarDoc } from "../../../../Actions/enviarDocumentos";
import { getDatosUsuario } from "../../../../Actions/datosUsuario";
import { useMediaQuery } from "react-responsive";
//import Style
import "./dashboardPasos.css";

//import Icons
import { FiCheck } from "react-icons/fi";
import { ImCross } from "react-icons/im";
import ModalGeneric from "../../../../Components/ModalGeneric/ModalGeneric";
import { getNotificacionUsuario } from "../../../../Actions/notificacionUsuario";

export default function DashboardPasos() {
  const [activePage, setActivePage] = useState(1);
  const [estadoFiltro, setEstadoFiltro] = useState(null);
  const [imagenesDoc, setImagenesDoc] = useState({
    imagenFrente: null,
    imagenDorso: null
  });
  const [botonEnviarDoc, setBotonEnviarDoc] = useState(false);
  const [mostrarImagenFrente, setMostrarImagenFrente] = useState();
  const [mostrarImagenDorso, setMostrarImagenDorso] = useState();
  const [loadingButton, setLoadingButton] = useState(false);
  const [open, setOpen] = useState()
  const modal = {
    content: "Su documentación fue enviada con éxito.",
    button: "Cerrar",
    size:"xs"
  }
  const [monedita, setMonedita] = useState(null);
  const [busqueda, setBusqueda] = useState({
    dataBase: "",
  });
  

  const listaProyectos = useSelector(
    (state) => state.reducerMostrarProyecto.data
  );
  const datosUsuarioLogeado = useSelector(
    (state) => state.reducerUsuarioDatos.data
  );

  const usuarioListaInversiones = useSelector(
    (state) => state.reducerInversionesUsuario.data
  );

  const estadisticasUsuario = useSelector(
    (state) => state.reducerEstadisticasUsuario.data
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const data = {
      busqueda: busqueda.dataBase,
      estado: estadoFiltro,
      page: activePage,
      categoria: estadoFiltro,
      moneda: monedita,
    };
    dispatch(getMostrarProyecto(data));
  }, [activePage, busqueda, estadoFiltro, monedita, datosUsuarioLogeado]);

  //PROYECTOS USUARIO
  if (datosUsuarioLogeado != null && datosUsuarioLogeado.estadoNombre === "Aprobado" || datosUsuarioLogeado.estadoNombre === "Revision") {
    if (listaProyectos != null) {

      var accordionArray = [];
      const datosProyectos = listaProyectos.data;
      for (var i = 0; i < listaProyectos.data.length; i++) {
        accordionArray.push(
          <Panel
            header={
              <div
                className="rs-panel-header header-accordion"
                style={{ backgroundColor: datosProyectos[i].color }}
              >
                <img
                  className="logo-proyecto"
                  src={
                    "https://www.blackhound.com.ar/server/public/getImgId/" +
                    datosProyectos[i].idImagenUno
                  }
                />
              </div>
            }
            key={i}
            id={"panel" + i}
          >
            <ContenidoAcordion datosDeUsuarioLogeado={datosUsuarioLogeado} data={datosProyectos[i]} />
          </Panel>
        );
      }
    }
  }

  /* INVERSIONES PENDIENTES*/
  const inversionesPendientes = [];
  let contPendienteInversiones = 0;
  if (usuarioListaInversiones != null && estadisticasUsuario != null) {
    const inversionesUsuario = usuarioListaInversiones.data;
    for (let i = 0; i < inversionesUsuario.length; i++) {
      if (inversionesUsuario[i].estadoSolicitud === 'Revision' || inversionesUsuario[i].estadoSolicitud === 'Citado') {
        contPendienteInversiones = 1;
        inversionesPendientes.push(
          <Panel
            header={
              <div
                className="rs-panel-header header-accordion"
                style={{ backgroundColor: inversionesUsuario[i].color }}
              >
                <img className="logo-proyecto"
                  src={
                    "https://www.blackhound.com.ar/server/public/getImgId/" +
                    inversionesUsuario[i].idImagenUno
                  }
                />
              </div>
            }

            key={i}
            id={"panel" + i}
          >
            <ListaSolicitudesPendientes
              data={inversionesUsuario[i]}
            />
          </Panel>
        );
      }
    }
  }

  

  /* INVERSIONES APROBADAS*/
  if (usuarioListaInversiones != null && estadisticasUsuario != null) {
    var inversionesAprobadas = [];
    const inversionesUsuario = usuarioListaInversiones.data;
    const dataEstadisticasUsuario = estadisticasUsuario.data;
    for (var i = 0; i < inversionesUsuario.length; i++) {
      if (inversionesUsuario[i].estadoSolicitud == "Aprobado") {
        inversionesAprobadas.push(
          <Panel
            header={
              <div
                className="rs-panel-header header-accordion"
                style={{ backgroundColor: inversionesUsuario[i].color }}
              >
                <img className="logo-proyecto"
                  src={
                    "https://www.blackhound.com.ar/server/public/getImgId/" +
                    inversionesUsuario[i].idImagenUno
                  }
                />
              </div>
            }
            
            key={i}
            // className={"color-accordion-" + dataEstadisticasUsuario[i].idProyecto}a
            id={"panel" + i}
          >
            <ListaSolicitudesAprobadas
              estadisticas={dataEstadisticasUsuario[i]}
              data={inversionesUsuario[i]}
            />
          </Panel>
        );
      }
    }
  }

  const mostrarImagenDeFrente = (e) => {
    if (e.target.files[0]) {
      imagenesDoc.imagenFrente = e.target.files[0];
      const readerFrente = new FileReader();
      readerFrente.addEventListener("load", () => {
        setMostrarImagenFrente(readerFrente.result);
      });
      readerFrente.readAsDataURL(e.target.files[0]);
    }
  };


  function eliminarImagenFrente() {
    imagenesDoc.imagenFrente = null;
    setMostrarImagenFrente(null);
  }

  const mostrarImagenDeDorso = (e) => {
    if (e.target.files[0]) {
      imagenesDoc.imagenDorso = e.target.files[0];
      const readerDorso = new FileReader();
      readerDorso.addEventListener("load", () => {
        setMostrarImagenDorso(readerDorso.result);
      });
      readerDorso.readAsDataURL(e.target.files[0]);
    }
  };

  function eliminarImagenDorso() {
    setMostrarImagenDorso(null);
    imagenesDoc.imagenDorso = null;
  }


  const onChangeFotosDni = (e) => {
    setImagenesDoc({
      ...imagenesDoc,
      [e.target.name]: e.target.files[0]
    })
  }

  function handleEnviarDocumentos() {
    const data = {
      page: activePage
    }
    let documentosData = new FormData();
    documentosData.append("imgFrente", imagenesDoc.imagenFrente);
    documentosData.append("imgDorso", imagenesDoc.imagenDorso);
    setLoadingButton(true);
    dispatch(postEnviarDoc(documentosData));
    setTimeout(() => {
      setLoadingButton(false);
      dispatch(getDatosUsuario())
      dispatch(getNotificacionUsuario(data))
      setImagenesDoc({
        imagenFrente: null,
        imagenDorso: null,
      })
      setOpen(true);
    }, 1500);
  }







  const isMobile = useMediaQuery({ maxDeviceWidth: 1024 });

  return (
    <>
    <div className="dashboard-pasos-container">
      <div className="containerSectionUserTitle">
        <h5 className="seccion-usuario-titulo">
          BIENVENIDO<span>{datosUsuarioLogeado.nombre}</span>
        </h5>
      </div>
      <div className="pasos-header-bar">
        <div className={`pasos-header-etapa`}>
          <FiCheck />
          <p>Crear cuenta</p>
        </div>
        <div
          className={`pasos-header-etapa ${datosUsuarioLogeado.estadoNombre != "Aprobado" ? "etapa-mid" : ""
            }`}
        >
          {datosUsuarioLogeado.estadoNombre == "Aprobado" && <FiCheck />}
          <p>Validá</p>
          <p>tus datos</p>
        </div>
        <div
          className={`pasos-header-etapa ${datosUsuarioLogeado.estadoNombre == "Aprobado" ? "etapa-mid" : "paso-desactivado"
            }`}
        >
          <p>Seleccioná</p>
          <p>tu</p>
          <p> inversión</p>
        </div>
        <div className={`pasos-header-etapa paso-desactivado`}>
          <p>Inversión</p> 
          <p>realizada</p>
        </div>
      </div>

      {/* VALIDAR DATOS DEL USUARIO */}
      <div className="mostrar-etapa-dato">
        {datosUsuarioLogeado != null && datosUsuarioLogeado.isDocumentacion == false ? (
          <div className="etapa-documentacion">
            <div className="validacion-seccion-titulo">
              <span className="cuadrado"></span>
              <p>VALIDACIÓN DE DATOS</p>
            </div>
            {!isMobile ? (
              <div className="etapa-documentacion-box">
                <div className="etapa-documentacion-seccion-adjuntar-docs">
                  <div className="etapa-documentacion-column-right">
                    <img src="https://res.cloudinary.com/blackhound/image/upload/v1661353251/BLACKHOUND/Usuario/Dashboard/sin_documentos.svg" />
                    <div className="etapa-documentacion-text">
                      <p className="etapa-documentacion-box-bold">
                        Enviar copia de DNI / LE / LC
                      </p>
                      <p className="subtitulo-validacion">
                       (Formato .jpg, .png hasta 10MB)
                      </p>
                    </div>
                  </div>
                  <div className='botones-adjuntar-doc'>
                    <p className="text-adjuntar">Adjuntar frente</p>
                    <label id="documentacion" htmlFor="adjuntarFrente" className="boton-adjuntar activado">Seleccionar archivo</label>
                    <input name="imagenFrente" onChange={mostrarImagenDeFrente} disabled={datosUsuarioLogeado != null && datosUsuarioLogeado.isDocumentacion == false ? false : true} id="adjuntarFrente" type="file" accept="image/png, image/jpeg" />
                    <p className="text-adjuntar">Adjuntar dorso</p>
                    <label htmlFor="adjuntarDorso" className="boton-adjuntar activado" >Seleccionar archivo</label>
                    <input name="imagenDorso" onChange={mostrarImagenDeDorso} disabled={datosUsuarioLogeado != null && datosUsuarioLogeado.isDocumentacion == false ? false : true} id="adjuntarDorso" type="file" accept="image/png, image/jpeg" />
                    {datosUsuarioLogeado != null && datosUsuarioLogeado.isDocumentacion == true ? <span className="texto-documentos-pendientes">Documentos enviados y pendientes de validación. </span> : ""}
                  </div>
                </div>
                {imagenesDoc.imagenFrente != null ||
                  imagenesDoc.imagenDorso != null ? (
                  <>
                    <hr className="linea-divisora-documentacion" />
                    <div className="etapa-documentacion-seccion-mostrar-docs">
                      {/* <p>
                        {imagenesDoc.imagenDorso != null && imagenesDoc.imagenFrente != null ? (
                          "2 archivos adjuntos"
                        ) : (
                          <p className="error-cargarndo-docs">
                            Falta adjuntar un archivo
                          </p>
                        )}
                      </p> */}
                      <div className="seccion-imagenes-documentos">
                        <div className="seccion-imagenes-documentos-box-imagenes">
                          {imagenesDoc.imagenFrente != null && (
                            <div className="mostrar-imagen-frente-caja">
                              <ImCross
                                className="eliminar-imagen-doc"
                                onClick={eliminarImagenFrente}
                              />
                              <img src={mostrarImagenFrente} />
                            </div>
                          )}
                          {imagenesDoc.imagenDorso != null && (
                            <div className="mostrar-imagen-dorso-caja">
                              <ImCross
                                className="eliminar-imagen-doc"
                                onClick={eliminarImagenDorso}
                              />
                              <img src={mostrarImagenDorso} />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {
                imagenesDoc.imagenFrente != null && imagenesDoc.imagenDorso === null || imagenesDoc.imagenFrente === null && imagenesDoc.imagenDorso != null || 
                imagenesDoc.imagenFrente != null && imagenesDoc.imagenDorso != null ?
                  <div className="seccion-imagenes-documentos-boton-enviar">
                    <Button className="boton-enviar-docs" disabled={imagenesDoc.imagenFrente != null && imagenesDoc.imagenDorso === null || 
                    imagenesDoc.imagenFrente === null && imagenesDoc.imagenDorso != null } 
                    loading={loadingButton} onClick={handleEnviarDocumentos} >Enviar</Button>
                  </div> : ""
                  }
              </div>
            ) : (
              <div className="etapa-documentacion-box">
                <div className="etapa-documentacion-seccion-adjuntar-docs">
                  <div className="etapa-documentacion-column-right">
                    <img src="https://res.cloudinary.com/blackhound/image/upload/v1661353251/BLACKHOUND/Usuario/Dashboard/sin_documentos.svg" />
                    <div className="etapa-documentacion-text">
                      <p className="etapa-documentacion-box-bold">
                        Enviar copia de DNI / LE / LC
                      </p>
                      <p className="subtitulo-validacion">
                      (Formato .jpg, .png hasta 10MB)
                      </p>
                    </div>
                  </div>
                  {/* <div className="botones-adjuntar-doc">
                    <label
                      id="documentacion"
                      htmlFor="adjuntarFrente"
                      className="boton-adjuntar activado"
                    >
                      Seleccionar archivos
                    </label>
                    <input                      
                      name="imagenFrente"
                      style={{ display: "none" }}
                      onChange={mostrarImagen}
                      disabled={
                        datosUsuarioLogeado != null &&
                        datosUsuarioLogeado.isDocumentacion == false
                          ? false
                          : true
                      }
                      id="adjuntarFrente"
                      type="file"
                      accept="image/png, image/jpeg, image/jpg,application/pdf"
                    />
                  </div> */}
                  <div className='botones-adjuntar-doc'>
                    <div className="containerAdjuntarDocMobile">
                      <div className="containerDocumentMobile">
                        <p className="text-adjuntar">Adjuntar frente</p>
                        <label id="documentacion" htmlFor="adjuntarFrente" className="boton-adjuntar activado">Seleccionar archivo</label>
                        <input name="imagenFrente" onChange={mostrarImagenDeFrente} disabled={datosUsuarioLogeado != null && datosUsuarioLogeado.isDocumentacion == false ? false : true} id="adjuntarFrente" type="file" accept="image/png, image/jpeg" />
                      </div>
                      <div className="containerDocumentMobile">
                        <p className="text-adjuntar">Adjuntar dorso</p>
                        <label htmlFor="adjuntarDorso" className="boton-adjuntar activado" >Seleccionar archivo</label>
                        <input name="imagenDorso" onChange={mostrarImagenDeDorso} disabled={datosUsuarioLogeado != null && datosUsuarioLogeado.isDocumentacion == false ? false : true} id="adjuntarDorso" type="file" accept="image/png, image/jpeg" />
                      </div>
                    </div>
                    {datosUsuarioLogeado != null && datosUsuarioLogeado.isDocumentacion == true ? <span className="texto-documentos-pendientes">Documentos enviados y pendientes de validación. </span> : ""}
                  </div>
                  {imagenesDoc.imagenFrente != null ||
                    imagenesDoc.imagenDorso != null ? (
                    <>
                      <hr className="linea-divisora-documentacion" />
                      <div className="etapa-documentacion-seccion-mostrar-docs">
                        <p>
                          {imagenesDoc.imagenDorso != null ? (
                            "2 archivos adjuntos"
                          ) : (
                            <p className="error-cargarndo-docs">
                              Falta adjuntar un archivo
                            </p>
                          )}
                        </p>
                        <div className="seccion-imagenes-documentos">
                          <div className="seccion-imagenes-documentos-box-imagenes">
                            {imagenesDoc.imagenFrente != null && (
                              <div className="mostrar-imagen-frente-caja">
                                <ImCross
                                  className="eliminar-imagen-doc"
                                  onClick={eliminarImagenFrente}
                                />
                                <img src={mostrarImagenFrente} />
                              </div>
                            )}
                            {imagenesDoc.imagenDorso != null && (
                              <div className="mostrar-imagen-dorso-caja">
                                <ImCross
                                  className="eliminar-imagen-doc"
                                  onClick={eliminarImagenDorso}
                                />
                                <img src={mostrarImagenDorso} />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {
                    imagenesDoc.imagenFrente != null && imagenesDoc.imagenDorso === null || imagenesDoc.imagenFrente === null && imagenesDoc.imagenDorso != null || 
                    imagenesDoc.imagenFrente != null && imagenesDoc.imagenDorso != null ?
                      <div className="seccion-imagenes-documentos-boton-enviar">
                        <Button className="boton-enviar-docs" disabled={imagenesDoc.imagenFrente != null && imagenesDoc.imagenDorso === null || 
                        imagenesDoc.imagenFrente === null && imagenesDoc.imagenDorso != null } 
                        loading={loadingButton} onClick={handleEnviarDocumentos} >Enviar</Button>
                      </div> : ""
                  }
                </div>
              </div>
            )}
          </div>
        ) : (
          datosUsuarioLogeado != null && datosUsuarioLogeado.estadoNombre != "Aprobado" ?
            <>
              <div className="etapa-documentacion-box">
                <div className="etapa-documentacion-seccion-adjuntar-docs">
                  <div className="etapa-documentacion-column-right">
                    <img src="https://res.cloudinary.com/blackhound/image/upload/v1661353251/BLACKHOUND/Usuario/Dashboard/sin_documentos.svg" />
                  </div>
                  <div className="etapa-documentacion-text">
                    <p className="etapa-documentacion-box-bold">
                      Tu documentación está siendo revisada.
                    </p>
                  </div>
                </div>
              </div>
            </>
            : ''
        )}
      </div>

      {contPendienteInversiones == 1 && (
        <>
          <div className="validacion-seccion-titulo">
            <span className="cuadrado"></span>
            <p>SOLICITUDES PENDIENTES</p>
          </div>
          <PanelGroup className="acordion-proximas-inversiones" accordion>
            {inversionesPendientes}
          </PanelGroup>
        </>
      )}

      {estadisticasUsuario != null && estadisticasUsuario.data != "" && (
        <>
          <div className="validacion-seccion-titulo">
            <span className="cuadrado"></span>
            <p>MIS INVERSIONES</p>
          </div>
          <PanelGroup className="acordion-proximas-inversiones" accordion>
            {inversionesAprobadas}
          </PanelGroup>
        </>
      )}

      {datosUsuarioLogeado != null &&
        datosUsuarioLogeado.estadoNombre == "Aprobado" ? (
        <>
          <div className="validacion-seccion-titulo">
            <span className="cuadrado"></span>
            <p>PRÓXIMA INVERSIÓN</p>
          </div>
          <PanelGroup className="acordion-proximas-inversiones" accordion>
            {accordionArray}
          </PanelGroup>
        </>
      ) : (
        ""
      )}
      {datosUsuarioLogeado != null &&
        datosUsuarioLogeado.estadoNombre == "Revision" ? (
        <>
          <div className="validacion-seccion-titulo">
            <span className="cuadrado"></span>
            <p>PRÓXIMA INVERSIÓN</p>
          </div>
          <PanelGroup className="acordion-proximas-inversiones" accordion>
            {accordionArray}
          </PanelGroup>
        </>
      ) : (
        ""
      )}
    </div>
    <ModalGeneric
    open={open}
    setOpen={setOpen}
    size={modal.size}
    content={modal.content}
    button={modal.button}
    />
    </>
  );
}
