//THREE JS
import * as THREE from "three"
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';

let currentMount = null

//SIZES
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

//CURSOR
    const cursor = {}
    cursor.x = 0
    cursor.y = 0

window.addEventListener('mousemove', (event) =>
{
    cursor.x = event.clientX / sizes.width - 0.25
    //cursor.y = event.clientY / sizes.height - 0.5
})

//SCENE
    const scene = new THREE.Scene();
    scene.background = new THREE.Color('#000000')
    const cameraGroup = new THREE.Group()
    scene.add(cameraGroup)

    const camera = new THREE.PerspectiveCamera(
        25,
        100 / 100,
        0.1,
        1000
    )
    camera.position.z = 145
    camera.position.y = 20
    camera.position.x = 50
    scene.add(camera);
    //cameraGroup.add(camera)

//RENDERER
    const renderer = new THREE.WebGLRenderer()

//ORBIT CONTROLS
    const controls = new OrbitControls(camera, renderer.domElement)
    controls.enableDamping = true;
    controls.enabled = false;

//RESIZE
const resize = () => {

    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight
    
    renderer.setSize(
        currentMount.clientWidth,
        currentMount.clientHeight
    )
    camera.aspect = currentMount.clientWidth / currentMount.clientHeight
    camera.updateProjectionMatrix()
}
window.addEventListener('resize', resize)

//SPHERE
    const geometry = new THREE.SphereGeometry( 4, 32, 16 );
    const material = new THREE.MeshBasicMaterial( { 
        color: 0xffffff,
        transparent: true,
        opacity: 0.1,
        wireframe: true 
    } );
    
    const sphere = new THREE.Mesh( geometry, material );
    //scene.add( sphere );

    //MODEL GLTF LOADER
    const gltfLoader = new GLTFLoader()
    gltfLoader.load('./moneda/Blackhound.gltf',
        (gltf) => { 
        gltf.scene.position.set(0, -24, 0)
        gltf.scene.rotation.set(0, -0.4, 0)
        scene.add(gltf.scene)
    },
        () => {  },
        () => {  },
    )


//LIGHTS
    //AMBIENT LIGHT
    const AO = new THREE.AmbientLight(0xffffff, 1)
    scene.add(AO)

    //SPOT LIGHT
    const spotLight = new THREE.SpotLight( 0xffffff, 0.05 );
    spotLight.position.set( -40, 7, 70 );
    spotLight.angle = Math.PI / 2;
    spotLight.distance = 200;
    scene.add( spotLight );

    //POINT LIGHT
    const pointLight2 = new THREE.PointLight(0xffffff, 0.15)
    pointLight2.position.set(0, 30, 30)
    scene.add(pointLight2)

    //DIRECTIONAL LIGHT
    const directionalLight = new THREE.DirectionalLight(
        0xffffff,
        0.5
    )
    directionalLight.position.set(20, 20, 50)
    //scene.add(directionalLight)

//HDR ENVIRONMENT MAP
const enviromentMap = new THREE.CubeTextureLoader()
const envMap = enviromentMap.load([
    './media/hdr/px.png',
    './media/hdr/nx.png',
    './media/hdr/py.png',
    './media/hdr/ny.png',
    './media/hdr/pz.png',
    './media/hdr/nz.png'
])
//SCENA CON ENVMAP
scene.environment = envMap
//FONDO ENVMAP
//scene.background = envMap


//RENDER SCENE
const clock = new THREE.Clock()
let previousTime = 0

const animate = () => {
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime
        
    const contX = cursor.x
    const contY = cursor.y


    camera.position.x = (contX - cameraGroup.position.z) * 3000 * deltaTime
    //camera.position.x = (contX + cameraGroup.position.z) * -10* deltaTime

        controls.update()
        renderer.render(scene,camera)
        requestAnimationFrame(animate)
    }
    animate()

//INICIA SCENE
    export const mountScene2 = (mountRef2) => {
        currentMount = mountRef2.current
        resize()
        currentMount.appendChild(renderer.domElement)
    }

//CLEAN SCENE
    export const cleanUpScene2 = () => {
        scene.dispose()
        currentMount.removeChild(renderer.domElement)
    }