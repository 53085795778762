import React,{useState}  from 'react'
import { Modal } from 'rsuite';
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux';
import Calendar from 'react-calendar';
import {getEnviarCita} from '../../../../../Actions/enviarCita';

import './formulariomodal.css'
import 'react-calendar/dist/Calendar.css';


export default function FormularioModal(props) {

    const idProyecto = props.dataProyecto.idProyecto;
    const dispatch = useDispatch();
    const [formDatos,setFormDatos]= useState({hora:"",contacto:"",monto:"",plazoInversion:""});
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const [value, onChange] = useState(new Date());
    const onChangeFormDatos =(e)=> {
    setFormDatos({
        ...formDatos,
        [e.target.name]: e.target.value
      });
  };

    const dataUsuario = useSelector(state => state.reducerUsuarioDatos.data);

  function handleEnviarSolicitud(){
    const fecha= ([value.getFullYear(),(value.getMonth() + 1).toString().padStart(2, '0'), value.getUTCDate()].join('-'));
    const nuevaSolicitud={
        fecha: fecha,
        franja:formDatos.hora,
        contacto:formDatos.hora,
        montoInvertido:formDatos.monto,
        idProyecto:idProyecto,
        plazoInversion:formDatos.plazoInversion,
    }
    dispatch(getEnviarCita(nuevaSolicitud));
    
    setOpen(true);
  }

  var fechaActualSeisMesesAdelante = moment().add(6,"month").calendar(); //resto 12 meses a la fecha actual
   return (
        <>
        <div className='formulario-pasos-container pasouno'>
            <div className='calendario'>
            <Calendar
                onChange={onChange}
                value={value}
                maxDate={new Date(fechaActualSeisMesesAdelante)}
                minDate={value}
            />
            </div>
            <div className='formulario-pasos-box'>
                <p className='formulario-pasos-text-red'>Franjas Horarias</p>
                <div className='formulario-pasos-item-box'>
                    <p>Lunes a Viernes </p>
                    <div className='formulario-botones'>
                         <input id="primerahora" type="radio" name="hora" onChange={onChangeFormDatos}  value="1" />
                         <label for="primerahora">9 hs - 12 hs</label>
                         <input id="segundahora" type="radio" name="hora" onChange={onChangeFormDatos}  value="2" />
                         <label for="segundahora">12hs - 15 hs</label>
                         <input id="ultimahora" type="radio" name="hora" onChange={onChangeFormDatos}  value="3" />
                         <label for="ultimahora">15hs - 18 hs</label>
                    </div>
                </div>
            </div>
            <div className='formulario-pasos-box'>
            <p className='formulario-pasos-text-red'>Método de contacto</p>
                <div className='formulario-pasos-item-box'>
                <p>Seleccione una opción</p>
                <div  className='formulario-botones'>
                    <input id="Llamado" type="radio" name="contacto" onChange={onChangeFormDatos}  value="2" />
                    <label for="Llamado">Whatsapp</label>
                    <input id="E-mail" type="radio" name="contacto" onChange={onChangeFormDatos}  value="1" />
                    <label for="E-mail">E-mail</label>
                    <input id="Whatsapp" type="radio" name="contacto" onChange={onChangeFormDatos}  value="3" />
                    <label for="Whatsapp">Llamada</label>
                </div>
            </div>
            </div>
            <div className='formulario-pasos-box formulario-pasos-monto'>
                <div className='formulario-pasos-monto'>
                    <p>Monto a invertir</p>
                    <input type="text" name="monto" placeholder="$1.000" onChange={onChangeFormDatos}/>
                </div>
                <div className='formulario-pasos-monto'>
                    <p>Plazo de inversión</p>
                    <input type="number" name="plazoInversion" placeholder="12 meses" onChange={onChangeFormDatos}/>
                </div>
            </div>
        </div>
        <input className='confirmacio-boton-enviar' type='submit' value='Enviar' onClick={handleEnviarSolicitud}/>


        <div className="modal-container">
        <Modal className='modal-agradecimiento' open={open} onClose={handleClose}>
            <Modal.Body>
                <div className='agradecimiento-container'>
                    <p className='modal-agradecimiento-text'>¡GRACIAS POR CONFIAR<br/> EN LA FAMILIA BLACK!</p>
                    <img src='https://res.cloudinary.com/grupo-delsud/image/upload/v1648672725/Blackhound/stars_en3psv.svg'/>
                    <p className='modal-agradecimiento-desc'>La solicitud de cita, será confirmada vía<br/> la ruta que escogío dentro de las 48 hs.</p>
                </div>
            </Modal.Body>
        </Modal>
        </div>
    </>
    );
  };