import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getChat } from '../../../Actions/obtenerChat';


export default function CanalesChat(props) {
    const dataCanales = props.data;
    
    const [chat, setChat] = useState("Sin Mensajes");
    const dispatch = useDispatch();
    const datosUsuarioLogeado = useSelector((state) => state.reducerUsuarioDatos.data);//OBTENER DATOS DEL USUARIO

    useEffect(() => {
    if (datosUsuarioLogeado != null) {
        const urlAsesor = new URL("https://mercure.blackhound.com.ar/.well-known/mercure");
        dispatch(getChat({ userReceptor: dataCanales }));//35 DEBE SER UN ID DEL CLIENTE
        urlAsesor.searchParams.append(
         "topic",
            "https://blackhoundcomunication/chat/" + datosUsuarioLogeado.id + dataCanales//35 DEBE SER UN ID DEL CLIENTE
    );
         const eventSourceUser = new EventSource(urlAsesor);
        eventSourceUser.onmessage = function ({ data }) {
         setChat(JSON.parse(data).message);
     };
    }
    }, [chat]);

    return (
        <>

        </>
    )
}
