import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
//ACTION
import { cancelarTurnoUser } from '../../../../Actions/cancelarTurno';
import { getTurno } from '../../../../Actions/turno';
import { Modal } from 'rsuite';


const ModalCancelarTurno = (props) => {
    const data3 = props.dataModalCancelar;
    const [open, setOpen] = useState(data3 != null);
    const [backdrop, setBackdrop] = useState('static');
    const dispatch = useDispatch();
    
    var gapi = window.gapi

    var CLIENT_ID = "524545613071-7aasnlk9alin5q82o0h041pdofmj6pm3.apps.googleusercontent.com"
    var API_KEY = "AIzaSyACvZbhyRlUCDsNiMyJBQ5SeBAEtc6WCFc"
    var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
    var SCOPES = "https://www.googleapis.com/auth/calendar.events"

    const [evento, setEvento] = useState({
        tittle: '',
        description: '',
        dateTimeInicio: '',
        dateTimeFin: '',
        emailAsesor: '',
    })

    const handleClose = () => {
        setEvento({
            tittle: '',
            description: '',
            dateTimeInicio: '',
            dateTimeFin: '',
            emailAsesor: '',
        })
        setOpen(false)
    };

    useEffect(() => {
        if (data3 != null) {
            setOpen(data3);
        }
    }, [data3])

    const handleCancelar = () => {
        dispatch(cancelarTurnoUser(data3.idTurno));

        setTimeout(() => {
            dispatch(getTurno());
        }, 1000);         
        setOpen(false);
    }


    return (
        <>
            {(open) &&
                <div className='modalEnvioDoc'>
                    <Modal className='proyecto-modal infoModal' backdrop={backdrop} open={open} size="md" onClose={handleClose} >
                        <Modal.Header className='headerModal'>
                            <Modal.Title className='headerModal'>
                                <div>
                                    <h4 style={{ margin: 'auto', color: 'white', textAlign: 'center' }}> ¡Mensaje Importante! </h4>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='textModal'>
                                ¿Desea cancelar su turno? <br></br>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='contFooter'>
                            <button className='buttonModal' onClick={handleCancelar}> Si </button>
                            <span style={{ padding: '10px' }}></span>
                            <button className='buttonModal' onClick={handleClose}> No </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            }
        </>
    );
}

export default ModalCancelarTurno;
