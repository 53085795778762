import apiInstance from './api';

export const apiDolar = () => {
    return apiInstance.get('/dolar')
    .then(res =>{
            return res.data;
    })
    .catch(error => {
        console.error(error.response);
        throw error.response
    });
}