import React, { useState, useEffect } from "react";
import { BsInfoCircleFill } from 'react-icons/bs';
import { useSelector, useDispatch } from 'react-redux';
import ProyectoModal from "../../../Usuarios/Vistas/MisInversiones/ProyectoModal/ProyectoModal";
import InfoProyecto from "./InfoProyecto/InfoProyecto"
import IniciarSesion from '../../IniciarSesion/IniciarSesion';
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive"

export default function Proyecto(data) {
  const [abrirModal, setAbrirModal] = useState(null);
  const [abrirModalInfo, setAbrirModalInfo] = useState(null);
  const [userLogin, setUserLogin] = useState("");
  const alinearProyecto = data.clase;
  const datosUsuarioLogeado = useSelector(state => state.reducerUsuarioDatos.data);

  const handleProyectoModal = () => {
    if (abrirModal == true) {
      setAbrirModal(false);
    } else {
      setAbrirModal(!abrirModal);
    }
  }

  useEffect(() => {
    if (abrirModal == false) {
      setAbrirModal(!abrirModal);
    }
    if (abrirModalInfo == false) {
      setAbrirModalInfo(!abrirModalInfo);
    }
  }, [abrirModal, abrirModalInfo]);

  useEffect(() => {
    if (datosUsuarioLogeado != null) {
      if (datosUsuarioLogeado.rol[0] == "ROLE_ADMIN") {
        setUserLogin("ROLE_ADMIN")
      }
      if (datosUsuarioLogeado.rol[0] == "ROLE_USER") {
        setUserLogin("ROLE_USER")
      }
    }
  }, [datosUsuarioLogeado])

  const ScrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    })
  }
  

  const isMobile = useMediaQuery({ maxDeviceWidth: 1023 })
  return (
    <>
      {!isMobile ?
        <div className={alinearProyecto ? 'proyecto-caja pagina-proyectos' : 'proyecto-caja pagina-proyectos reverse-proyecto'} >

          {/* <div className="proyecto-caja pagina-proyectos" > */}
          <div className="container-proyectos">
            <div className="container-data-proyecto">
              <div className="categoria-titulo">
                <div className="circle-categoria"> </div>
                <div className="proyecto-titulo">{data.data.categoria}</div>
              </div>
              <div className="proyecto-imagen logo-imagen" >
                {data != null && data.data.nombre === "Eugénie" ?
                <img src="https://res.cloudinary.com/blackhound/image/upload/v1668014287/BLACKHOUND/Proyectos/Eugenie_jxb0av.svg" alt="" /> 
                :
                <img src={"https://www.blackhound.com.ar/server/public/getImgId/" + data.data.idImagenUno} />
                }
                {/* <img className="logo-imagen" width="300" height="300" src={"http://127.0.0.1:8000/getImgId/" + data.data.idImagenDos} />
                <img className="logo-imagen" width="300" height="300" src={"http://127.0.0.1:8000/getImgId/" + data.data.idImagenUno} /> */}
              </div>

              <div className='modal-sub'>
                <p className="proyecto-descript">{data.data.descripcion}</p>
              </div>

              <div className="proyectos-descripcion">
                <div className="proyectos-descripcion-item">
                  <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897298/BLACKHOUND/tna_nhms4e_iz5fy8.svg" />
                  <p className="proyectos-iconos-titulo">TNA</p>
                  <p className="proyectos-iconos-desc">{data.data.tna}%</p>
                </div>
                <div className="proyectos-descripcion-item">
                  <img src="https://res.cloudinary.com/blackhound/image/upload/v1661865313/BLACKHOUND/Proyectos/iconMoneda_hmcbjw.svg" />
                  <p className="proyectos-iconos-titulo">Moneda</p>
                  <p className="proyectos-iconos-desc">{data.data.moneda}</p>
                </div>
                <div className="proyectos-descripcion-item">
                  <img src="https://res.cloudinary.com/blackhound/image/upload/v1661865313/BLACKHOUND/Proyectos/montoMinimo_j0ym0l.svg" />
                  <p className="proyectos-iconos-titulo">Monto mínimo</p>
                  <p className="proyectos-iconos-desc">{data.data.montoMinimo}</p>
                </div>
                <div className="proyectos-descripcion-item">
                  <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897305/BLACKHOUND/calendario_bulhhb_fwnlxz.svg" />
                  <p className="proyectos-iconos-titulo">Plazo mínimo</p>
                  <p className="proyectos-iconos-desc">{data.data.plazo} meses</p>
                </div>

              </div>

              <div className="container-button-consulta">
                {
                  datosUsuarioLogeado != null && datosUsuarioLogeado.rol[0] == "ROLE_USER"
                    ?
                    <Link className="solicitar-proyecto" onClick={ScrollToTop} to={"/HomeUsuarios/dashboard"}>Consultar</Link>
                    :
                    <Link className="solicitar-proyecto" to="/IniciarSesion" onClick={ScrollToTop}>Consultar</Link>
                }
              </div>
            </div>
          </div>

          <div className="proyecto-imagen">
            <img className="portada-proyecto" width="600" height="600" src={"https://www.blackhound.com.ar/server/public/getImgId/" + data.data.idImagenProyecto} />
            {/* <img className="portada-proyecto" width="600" height="600" src={"http://127.0.0.1:8000/getImgId/" + data.data.idImagenProyecto} /> */}
          </div>
        </div>

        :

        /////////////////////////////////////////////////////////////////////MOBILE - TABLET//////////////////////////////////////////////////////////////////////////////////////////////////////////
        <div className={data.data.categoriaId == 2 || data.data.categoriaId == 4 ? 'proyecto-caja pagina-proyectos' : 'proyecto-caja pagina-proyectos'} >
          {/* <div className="proyecto-caja pagina-proyectos" > */}
          <div className="container-proyectos">
            <div className="container-data-proyecto">
              <div className="categoria-titulo">
                <div className="circle-categoria" />
                <div className="proyecto-titulo">{data.data.categoria}</div>
              </div>
              <div className="proyecto-imagen">
                <img className="portada-proyecto" width="600" height="600" src={"https://www.blackhound.com.ar/server/public/getImgId/" + data.data.idImagenProyecto} />
                {/* <img className="portada-proyecto" width="600" height="600" src={"http://127.0.0.1:8000/getImgId/" + data.data.imagenUno} /> */}
              </div>
              <div className="proyecto-imagen-svg" >
              {data != null && data.data.nombre === "Eugénie" ?
                <img src="https://res.cloudinary.com/blackhound/image/upload/v1668014287/BLACKHOUND/Proyectos/Eugenie_jxb0av.svg" alt="" /> 
                :
                <img src={"https://www.blackhound.com.ar/server/public/getImgId/" + data.data.idImagenUno} />
                }
                {/* <img className="logo-imagen" width="300" height="300" src={"http://127.0.0.1:8000/getImgId/" + data.data.imagenDos} /> */}
              </div>

              <div className='modal-sub'>
                <p className="proyecto-descript">{data.data.descripcion}</p>
              </div>

              <div className="proyectos-descripcion">
                <div className="proyectos-descripcion-item">
                  <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897298/BLACKHOUND/tna_nhms4e_iz5fy8.svg" />
                  <p className="proyectos-iconos-titulo">TNA</p>
                  <p className="proyectos-iconos-desc">{data.data.tna}%</p>
                </div>
                <div className="proyectos-descripcion-item">
                  <img src="https://res.cloudinary.com/blackhound/image/upload/v1661865313/BLACKHOUND/Proyectos/iconMoneda_hmcbjw.svg" />
                  <p className="proyectos-iconos-titulo">Moneda</p>
                  <p className="proyectos-iconos-desc">{data.data.moneda}</p>
                </div>
                <div className="proyectos-descripcion-item">
                  <img src="https://res.cloudinary.com/blackhound/image/upload/v1661865313/BLACKHOUND/Proyectos/montoMinimo_j0ym0l.svg" />
                  <p className="proyectos-iconos-titulo">Monto mínimo</p>
                  <p className="proyectos-iconos-desc">{data.data.montoMinimo}</p>
                </div>
                <div className="proyectos-descripcion-item">
                  <img src="https://res.cloudinary.com/blackhound/image/upload/v1657897305/BLACKHOUND/calendario_bulhhb_fwnlxz.svg" />
                  <p className="proyectos-iconos-titulo">Plazo mínimo</p>
                  <p className="proyectos-iconos-desc">{data.data.plazo} meses</p>
                </div>
              </div>

              <div className="container-button-consulta">
                {
                  datosUsuarioLogeado != null
                    ?
                    <Link className="solicitar-proyecto"  to={"/HomeUsuarios/dashboard"}>Consultar</Link>
                    :
                    <Link className="solicitar-proyecto" to="/IniciarSesion">Consultar</Link>
                }
              </div>
            </div>
          </div>
        </div>
      }
      <InfoProyecto abrirModalInfo={abrirModalInfo} dataModalInfo={data.data} />

    </>

  )
}
