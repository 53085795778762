import { actionTypes } from "../Constantes/ActionTypes";
import { takeEvery, call, put } from'redux-saga/effects';
import { mostrarProyectoActivos } from'../api/mostrarProyectoActivos';
import { setMostrarProyecto } from "../Actions/mostrarProyectos"; 

export const watchMostrarProyectos = function * (){
    yield takeEvery(actionTypes.MOSTRAR_LOS_PROYECTOS, fetchMostrarProyectos)
}

function* fetchMostrarProyectos(action){
    try{
        const data = yield call(mostrarProyectoActivos, action.data)
        if(data){
            yield put(setMostrarProyecto(data))
        }
    }catch(err){
        console.log(err)
    }
}