
import { actionTypes } from "../Constantes/ActionTypes"; 

export const getListaMonedas = (data) => {
    return {
        type: actionTypes.LISTA_DE_MONEDAS,
        data
    }
}

export const setListaMonedas = (data) => {
    return {
        type: actionTypes.SET_LISTA_DE_MONEDAS,
        data
    }
}






